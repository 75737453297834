import { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  SettingToggle,
  TextField,
} from "@shopify/polaris";
import {
  getChatDetails,
  getChatIconTemplates,
  updateChatDetails,
} from "../../../redux/actions/chat-settings";
import ChangeDesignModal from "./ChangeDesignModal";
import { objectDifference } from "../../../utils/helper";
import ChatBtnPreview from "./ChatBtnPreview";
import CustomColorPicker from "../../../common/CustomColorPicker";

const ChatButtonTheme = (props: any) => {
  const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast, setHasChanges } = props;
  const [openChangeDesignModal, setOpenChangeDesignModal] = useState(false);
  const [initialData, setInitialData] = useState({
    is_chat_enabled: true,
    btn_cta: "Chat with us",
    prefilled_msg:
      "I'm interested in SuperLemon WhatsApp app for Shopline. I have a few questions. Can you help?",
    include_product_link: true,
    is_chat_btn_color_custom: true,
    is_chat_btn_solid_background: false,
    chat_btn_bg_color_1: "#b8bc9e",
    chat_btn_bg_color_2: "#463535",
    chat_btn_text_color: "#FFFFFF",
    chat_btn_icon_color: "#FFFFFF",
    buttonId: null,
  });
  const [chatDetails, setChatDetails] = useState({
    is_chat_enabled: true,
    btn_cta: "Chat with us",
    prefilled_msg:
      "I'm interested in SuperLemon WhatsApp app for Shopline. I have a few questions. Can you help?",
    include_product_link: true,
    is_chat_btn_color_custom: true,
    is_chat_btn_solid_background: false,
    chat_btn_bg_color_1: "#b8bc9e",
    chat_btn_bg_color_2: "#463535",
    chat_btn_text_color: "#FFFFFF",
    chat_btn_icon_color: "#FFFFFF",
    buttonId: null,
  });
  const [refchatDetails, setRefChatDetails] = useState({
    is_chat_enabled: true,
    btn_cta: "Chat with us",
    prefilled_msg:
      "I'm interested in SuperLemon WhatsApp app for Shopline. I have a few questions. Can you help?",
    include_product_link: true,
    is_chat_btn_color_custom: true,
    is_chat_btn_solid_background: false,
    chat_btn_bg_color_1: "#b8bc9e",
    chat_btn_bg_color_2: "#463535",
    chat_btn_text_color: "#FFFFFF",
    chat_btn_icon_color: "#FFFFFF",
    buttonId: null,
  });
  const [shopId, setShopId] = useState("");
  const [buttonThemeUpdateData, setButtonthemeUpdatedData] = useState({});
  const [availableTemplates, setAvailableTemplates] = useState<any>([]);
  const [plan] = useState("");
  const [chatBtnTemplateDesigns, setChatBtnTemplateDesigns] = useState<any>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>({
    template: null,
    type: "chat",
    isCustomColors: false,
    isBgStyleColorSolid: true,
    bgColor1: "#22CD5B",
    bgColor2: "#30BF42",
    iconColor: "#ffffff",
    buttonTextColor: "#ffffff",
    allowIconColor: true,
  });

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShopId(shop.id);
      fetchChatDetails(shop.id);
    }
  }, []);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefChatDetails(chatDetails);
      setInitialData(chatDetails);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    var diffObj: any = objectDifference(refchatDetails, chatDetails);
    diffObj = { ...refchatDetails, ...chatDetails }
    // console.log('diffObj 100', diffObj, refchatDetails, chatDetails);
    setButtonthemeUpdatedData({});
    if (diffObj && Object.keys(diffObj).length > 0) {
      if (typeof diffObj.include_product_link !== "undefined") {
        setUpdatedButtonThemeData("templateId", chatDetails.buttonId);
        setUpdatedButtonThemeData("productLink", diffObj.include_product_link);
      }
      if ("btn_cta" in diffObj) {
        setUpdatedButtonThemeData("templateId", chatDetails.buttonId);
        setUpdatedButtonThemeData("buttonCta", diffObj.btn_cta);
      }
      if (diffObj.prefilled_msg) {
        setUpdatedButtonThemeData("templateId", chatDetails.buttonId);
        setUpdatedButtonThemeData("chatMessage", diffObj.prefilled_msg);
      }
      if (diffObj.chat_btn_bg_color_1) {
        setUpdatedButtonThemeData("templateId", chatDetails.buttonId);
        setUpdatedButtonThemeData("bgColor1", diffObj.chat_btn_bg_color_1);
      }
      if (diffObj.chat_btn_bg_color_2) {
        setUpdatedButtonThemeData("templateId", chatDetails.buttonId);
        setUpdatedButtonThemeData("bgColor2", diffObj.chat_btn_bg_color_2);
      }
      if (diffObj.chat_btn_text_color) {
        setUpdatedButtonThemeData("templateId", chatDetails.buttonId);
        setUpdatedButtonThemeData("textColor", diffObj.chat_btn_text_color);
      }
      if (diffObj.chat_btn_icon_color) {
        setUpdatedButtonThemeData("templateId", chatDetails.buttonId);
        setUpdatedButtonThemeData("iconColor", diffObj.chat_btn_icon_color);
      }
      if ("is_chat_btn_solid_background" in diffObj) {
        setUpdatedButtonThemeData("templateId", chatDetails.buttonId);
        setUpdatedButtonThemeData("solidBg", diffObj.is_chat_btn_solid_background);
      }
    }
  }, [chatDetails]);

  useEffect(() => {
    let errorMessage = getErrorMessage();
    if (errorMessage) {
      props.onError(errorMessage, 'chatTheme');
      props.onChanges(null);
    } else {
      props.onError('', 'chatTheme');
      props.onChanges({ ...chatDetails, ...buttonThemeUpdateData });
    }
  }, [buttonThemeUpdateData]);

  useEffect(() => {
    if (props.reset) {
      setRefChatDetails(initialData);
      setChatDetails(initialData);
    }
  }, [props.reset]);

  const getErrorMessage = () => {
    if (validateBtnCta() !== false) {
      return "Invalid Chat Button Text";
    } else if (validateMsgBody() !== false) {
      return "Invalid Chat Message Body";
    } else {
      return false;
    }
  }

  const setUpdatedButtonThemeData = (field: string, val: any) => {
    setButtonthemeUpdatedData((prevData) => ({ ...prevData, [field]: val }));
  };
  const fetchChatDetails = (shopId: string) => {
    getChatDetails(shopId).then((chat: any) => {
      setRefChatDetails(chat.chat);
      setChatDetails(chat.chat);
      setInitialData(chat.chat);
      fetchChatTemplates(shopId, chat.chat.buttonId);
    });
  };

  const fetchChatTemplates = (shopId: string, buttonId: number) => {
    getChatIconTemplates(shopId).then(
      (data: any) => {
        setAvailableTemplates(data);
        setChatBtnTemplateDesigns(data.filter((m: any) => m.custom === true));

        if (buttonId) {
          let template = data.find((m: any) => m.id === buttonId);
          setInitialData((data: any) => ({
            ...data,
            is_chat_btn_color_custom: template.custom,
            is_chat_btn_solid_background: template.solidBg,
            chat_btn_bg_color_1: template?.bgColor1 ? template?.bgColor1 : data?.chat_btn_bg_color_1,
            chat_btn_bg_color_2: template?.bgColor2 ? template?.bgColor2 : data?.chat_btn_bg_color_2,
            chat_btn_text_color: template?.textColor ? template?.textColor : data?.chat_btn_text_color,
            chat_btn_icon_color: template?.iconColor ? template?.iconColor : data?.chat_btn_icon_color,
            buttonId: buttonId,
          }));
          setRefChatDetails((data: any) => ({
            ...data,
            is_chat_btn_color_custom: template.custom,
            is_chat_btn_solid_background: template.solidBg,
            chat_btn_bg_color_1: template?.bgColor1 ? template?.bgColor1 : data?.chat_btn_bg_color_1,
            chat_btn_bg_color_2: template?.bgColor2 ? template?.bgColor2 : data?.chat_btn_bg_color_2,
            chat_btn_text_color: template?.textColor ? template?.textColor : data?.chat_btn_text_color,
            chat_btn_icon_color: template?.iconColor ? template?.iconColor : data?.chat_btn_icon_color,
            buttonId: buttonId,
          }));
          setChatDetails((data: any) => ({
            ...data,
            is_chat_btn_color_custom: template.custom,
            is_chat_btn_solid_background: template.solidBg,
            chat_btn_bg_color_1: template?.bgColor1 ? template?.bgColor1 : data?.chat_btn_bg_color_1,
            chat_btn_bg_color_2: template?.bgColor2 ? template?.bgColor2 : data?.chat_btn_bg_color_2,
            chat_btn_text_color: template?.textColor ? template?.textColor : data?.chat_btn_text_color,
            chat_btn_icon_color: template?.iconColor ? template?.iconColor : data?.chat_btn_icon_color,
            buttonId: buttonId,
          }));
        }
      },
      (err) => { }
    );
  };
  const getChatButtonPreview = () => {
    if (availableTemplates && availableTemplates.length > 0) {
      return ChatBtnPreview({
        template: availableTemplates.find(
          (a: any) => a.id === chatDetails.buttonId
        ),
        custom: chatDetails,
        selected: selectedTemplate
      });
    }
  };

  const getChatButtonPreviewImageClassName = () => {
    let selectedTemplate = availableTemplates.find((m: any) => m.id === chatDetails.buttonId);
    if (selectedTemplate) {
      switch (selectedTemplate.templateType) {
        case 1:
          return "imgchatscale1";
        case 2:
          return "imgchatscale2";
        case 3:
          return "imgchatscale3";
        case 4:
          return "imgchatscale1";
        case 5:
          return "imgchatscale1";
        default:
          return "nocssprop";
      }
    }
  };

  const getCurrentSelectedTemplateUrl = () => {
    for (var i = 0; i < availableTemplates.length; i++) {
      if (availableTemplates[i].id === chatDetails.buttonId) {
        return availableTemplates[i].previewUrl;
      }
    }
  };

  let initCustomChat = {
    template: chatDetails?.buttonId || {},
    type: "chat",
    isCustomColors: chatDetails.is_chat_btn_color_custom,
    isBgStyleColorSolid: chatDetails.is_chat_btn_solid_background,
    bgColor1: chatDetails?.chat_btn_bg_color_1 || "#22CD5B",
    bgColor2: chatDetails?.chat_btn_bg_color_2 || "#30BF42",
    iconColor: chatDetails?.chat_btn_icon_color || "#ffffff",
    buttonTextColor: chatDetails?.chat_btn_text_color || "#ffffff",
    allowIconColor: true,
  };

  const onCustomChatBtnChange = (obj: any) => {
    let buttonDesigns = chatBtnTemplateDesigns;

    let index = 0;
    if (buttonDesigns) {
      index = buttonDesigns.findIndex((m: any) => m.id === chatDetails.buttonId);
    }
    if (index > 0) {
      if (obj.bgColor1) {
        handleChange(obj.bgColor1, "chat_btn_bg_color_1");
        initCustomChat['bgColor1'] = obj.bgColor1;
        setSelectedTemplate(initCustomChat);
        if (index >= 0)
          buttonDesigns[index].bgColor1 = obj.bgColor1;
      }
      if (obj.bgColor2) {
        handleChange(obj.bgColor2, "chat_btn_bg_color_2");
        initCustomChat['bgColor2'] = obj.bgColor2;
        setSelectedTemplate(initCustomChat);
        if (index >= 0)
          buttonDesigns[index].bgColor2 = obj.bgColor2;
      }
      if (obj.buttonTextColor) {
        handleChange(obj.buttonTextColor, "chat_btn_text_color");
        initCustomChat['buttonTextColor'] = obj.buttonTextColor;
        setSelectedTemplate(initCustomChat);
        if (index >= 0)
          buttonDesigns[index].textColor = obj.buttonTextColor;
      }
      if (obj.iconColor) {
        handleChange(obj.iconColor, "chat_btn_icon_color");
        initCustomChat['iconColor'] = obj.iconColor;
        setSelectedTemplate(initCustomChat);
        if (index >= 0)
          buttonDesigns[index].iconColor = obj.iconColor;
      }
      if ("isBgStyleColorSolid" in obj) {
        handleChange(obj.isBgStyleColorSolid, "is_chat_btn_solid_background");
        initCustomChat['isBgStyleColorSolid'] = obj.isBgStyleColorSolid;
        setSelectedTemplate(initCustomChat);
        if (index >= 0) {
          buttonDesigns[index].solidBg = obj.isBgStyleColorSolid;
        }
      }

      if (index >= 0)
        setChatBtnTemplateDesigns(buttonDesigns);
    }
  };

  const handleChange = (val: any, field: string) => {
    setChatDetails((prevData) => ({ ...prevData, [field]: val }));
  };

  const validateBtnCta = () => {
    if (chatDetails.btn_cta && chatDetails.btn_cta.length > 30) {
      return "Max 30 characters";
    }
    return false;
  };

  const validateMsgBody = () => {
    if (chatDetails.prefilled_msg && chatDetails.prefilled_msg.length > 140) {
      return "Max 140 characters";
    }
    return false;
  };

  const changeTemplate = (selected: any, data: any = {}) => {
    var updatedData: any = {
      template: selected,
    };

    for (let key in data) {
      updatedData[key] = data[key];
      setChatDetails((chatData) => ({
        ...chatData,
        [key]: data[key],
      }));
    }

    initCustomChat = {
      template: selected,
      type: 'chat',
      isCustomColors: data.is_chat_btn_color_custom,
      isBgStyleColorSolid: data.is_chat_btn_solid_background,
      bgColor1: data.chat_btn_bg_color_1,
      bgColor2: data.chat_btn_bg_color_2,
      iconColor: data.chat_btn_icon_color || '#FFFFFF',
      buttonTextColor: data.chat_btn_text_color,
      allowIconColor: true
    };
    
    setSelectedTemplate(initCustomChat);

    if (selected) {
      let params = data;
      updateChatDetails(shopId, params).then((response: any) => {
        if (response.id) {
          setChatDetails((chat: any) => ({
            ...chat,
            buttonId: response?.buttonTemplateId ? response?.buttonTemplateId : data?.templateId,
            is_chat_btn_color_custom: data?.is_chat_btn_color_custom
          }))
          setShowSuccessToast((successToast: any) => !successToast)
          setToast("Button Design Saved");
          setHasChanges(false);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast(response?.errorMessage);
        }

        setOpenChangeDesignModal(false);
        document.body.classList.remove("settings-page");
      });
    }
  };
  return (
    <Layout.AnnotatedSection
      title="Button Text & Design"
      description="Configure CTA text for the chat button, pre-filled message, and choose from several beautiful chat button designs on what suits your store best."
    >
      <SettingToggle
        action={{
          content: "Change Design",
          onAction: () => {
            setOpenChangeDesignModal(true);
            document.body.classList.add("settings-page");
          },
        }}
        enabled={true}
      >
        <div
          className={
            chatDetails.is_chat_btn_color_custom
              ? "previewCustomChatBtnBackground"
              : "previewBtnBackground"
          }
        >
          {chatDetails.is_chat_btn_color_custom ? (
            getChatButtonPreview()
          ) : (
            <img
              style={{ maxWidth: "50%" }}
              className={getChatButtonPreviewImageClassName()}
              src={getCurrentSelectedTemplateUrl()}
              alt="WhatsApp icon"
            />
          )}
        </div>
      </SettingToggle>
      <CustomColorPicker
        {...initCustomChat}
        selectedTemplate={selectedTemplate}
        onCustomChange={onCustomChatBtnChange}
      />
      <ChangeDesignModal
        available_templates={availableTemplates}
        chatDetails={chatDetails}
        plan={plan}
        selected_template_id={chatDetails.buttonId}
        changeTemplate={changeTemplate}
        onModalClose={() => {
          setOpenChangeDesignModal(false);
          document.body.classList.remove("settings-page");
        }}
        open={openChangeDesignModal}
        chat_btn_template_designs={chatBtnTemplateDesigns}
      />
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Chat Button Text"
              value={chatDetails.btn_cta}
              onChange={(val) => handleChange(val, "btn_cta")}
              placeholder="This text will display beside the whatsapp chat button. Max 30 characters"
              type={"text"}
              minLength={2}
              error={validateBtnCta()}
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              label="WhatsApp Message Body"
              value={chatDetails.prefilled_msg}
              onChange={(val) => {
                handleChange(val, "prefilled_msg");
              }}
              type={"text"}
              multiline={2}
              minLength={5}
              placeholder="Pre-filled message body. Can be edited by store visitor before sending. Max 140 characters"
              error={validateMsgBody()}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Checkbox
              checked={chatDetails.include_product_link}
              label="Include current page URL in the message"
              onChange={(val) => {
                handleChange(val, "include_product_link");
              }}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default ChatButtonTheme;
