import { useCallback, useEffect, useState } from "react";
import {
  Card,
  DataTable,
  Link,
  Pagination,
  Stack,
  TextStyle,
  Filters,
  ChoiceList,
  TextField,
} from "@shopify/polaris";
import { handleFiltersEmail } from "../../../../redux/actions/email";
import { capitalize } from "../../../../utils/common";
import styles from "../../../MessageLogs/message-logs.module.css";
import obj from "../../../../content/MessageLogs/OverlaysContent/en.json";
import TooltipOverlay from "../../../../common/Tooltip";
import GuideModal from "../../../../common/GuideModal";

const EmailLogs = ({ showPricingBanner, shopId, shopUrl }: any) => {
  const [templateType, setTemplateType] = useState(null);
  const [status, setStatus] = useState(null);
  const [name, setName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orders, setOrders] = useState<any>([]);
  const [pageNum, setPageNum] = useState(0);
  const [descending, setDescending] = useState(true);
  const [orderBy, setOrderBy] = useState("entityId");
  const pageSize = 10;
  const [noDataFound, setNoDataFound] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    loadMessageLogs(shopId, pageNum);
  }, []);

  useEffect(() => {
    loadMessageLogs(shopId, pageNum);
  }, [pageNum]);

  useEffect(() => {
    setPageNum(0);
    loadMessageLogs(shopId, pageNum);
  }, [name, templateType, orderId, orderBy, descending]);

  const loadMessageLogs = (shopid: string, page_num: any) => {
    handleSearchFilters(shopid, page_num);
  };

  const handleSearchFilters = (shopId: string, page_num: number) => {
    if (shopId) {
      handleFiltersEmail(
        shopId,
        templateType,
        orderId,
        name,
        orderBy,
        descending,
        pageSize,
        page_num
      ).then((res: any) => {
        if (res.status === "success" && res.mails) {
          setOrders(res.mails);
          if (res.mails.length === 0) {
            setNoDataFound(true);
          } else {
            setNoDataFound(false);
          }
        }
      });
    }
  };
  const handleTemplateTypeChange = useCallback((value) => {
    setTemplateType(value);
  }, []);
  const handleStatusChange = useCallback((value) => {
    setStatus(value);
  }, []);
  const handleNameChange = (value: string) => {
    setName(value);
  };
  const handleFiltersQueryChange = useCallback(
    (value) => setOrderId(value),
    []
  );
  const handleTemplateTypeRemove = useCallback(() => {
    setTemplateType(null);
  }, []);
  const handleStatusRemove = useCallback(() => {
    setStatus(null);
  }, []);
  const handleNameRemove = useCallback(() => {
    setName("");
  }, []);

  const handleOrderIdRemove = useCallback(() => setOrderId(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleTemplateTypeRemove();
    handleStatusRemove();
    handleOrderIdRemove();
  }, [handleTemplateTypeRemove, handleStatusRemove, handleOrderIdRemove]);

  const filters = [
    {
      key: "templateType",
      label: "Type",
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={[
            { label: "Cart Recovery", value: "CART_RECOVERY" },
            { label: "Cash on Delivery", value: "CASH_ON_DELIVERY" },
            { label: "Order Confirmation", value: "ORDER_CONFIRMATION" },
            { label: "Order Tracking", value: "ORDER_TRACKING" },
          ]}
          selected={templateType || []}
          onChange={handleTemplateTypeChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "name",
      label: "Name",
      filter: (
        <TextField
          label="Search by name"
          value={name}
          onChange={(val) => handleNameChange(val)}
          autoComplete="off"
          placeholder="eg. John Doe"
        />
      ),
      shortcut: true,
    },
    //TBD to enable it or not
    // {
    //   key: 'status',
    //   label: 'Status',
    //   filter: (
    //     <ChoiceList
    //       title="Status"
    //       titleHidden
    //       choices={[
    //         { label: 'PHONE_NO_NOT_FOUND_IN_REQUEST', value: 'PHONE_NO_NOT_FOUND_IN_REQUEST' },
    //         { label: 'AUTOMATED_MESSAGE_DISABLED', value: 'AUTOMATED_MESSAGE_DISABLED' },
    //       ]}
    //       selected={status || []}
    //       onChange={handleStatusChange}
    //       allowMultiple
    //     />
    //   ),
    //   shortcut: true,
    // },
  ];

  const appliedFilters: any = [];
  if (!isEmpty(templateType)) {
    const key = "templateType";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, templateType),
      onRemove: handleTemplateTypeRemove,
    });
  }
  if (!isEmpty(status)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    });
  }
  if (!isEmpty(name)) {
    const key = "name";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, name),
      onRemove: handleNameRemove,
    });
  }
  function disambiguateLabel(key: any, value: any) {
    switch (key) {
      case "templateType":
        return value.map((val: any) => `${val}`).join(", ");
      case "status":
        return value.map((val: any) => `${val}`).join(",  ");
      case "name":
        return `Name - ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  useEffect(() => {
    getRows();
  }, [orders]);

  const getMessageTemplate = (template_type: String) => {
    switch (template_type) {
      case "ORDER_CONFIRMATION":
        return "Order Confirmation";
      case "ORDER_SHIPMENT":
        return "Order Shipment";
      case "ORDER_TRACKING":
        return "Order Tracking";
      case "CART_RECOVERY":
        return "Cart Recovery";
      case "CASH_ON_DELIVERY":
        return "Cash on Delivery";
      default:
        return "-";
    }
  };

  const getEmailStatus = (status: string) => {
    status = capitalize(status.replace(/_/g, " "));
    return <TextStyle variation="subdued">{status}</TextStyle>;
  };
  const getAttemptedDate = (date: number) => {
    if (date === 0) {
      return "-";
    } else {
      const optionsDate: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const optionsTime: any = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const convertedDate = new Date(Number(date));
      const formattedDate = convertedDate.toLocaleDateString(
        "en-US",
        optionsDate
      );
      const formattedTime = convertedDate.toLocaleString("en-US", optionsTime);
      const finalDate = formattedDate + " " + formattedTime;
      return <TextStyle variation="subdued">{finalDate}</TextStyle>;
    }
  };

  const getAmountText = (amount: string, currency: string) => {
    if (currency) {
      return `${amount} ${currency}`;
    } else {
      return amount;
    }
  };

  const getRows = () => {
    var rows = [];
    const localOrders = [...orders];
    if (localOrders) {
      for (var i = 0; i < localOrders.length; i++) {
        rows.push([
          <Link
            external={true}
            url={
              localOrders[i].category !== "AB_CART"
                ? `https://${shopUrl}/admin/orders/${localOrders[
                    i
                  ].entityId.toString()}`
                : `https://${shopUrl}/admin/checkouts/${localOrders[
                    i
                  ].entityId.toString()}`
            }
          >
            {localOrders[i].readableId
              ? localOrders[i].readableId
              : localOrders[i].entityId}
          </Link>,
          localOrders[i].name,
          getAmountText(
            localOrders[i].total.toString(),
            localOrders[i].currency
          ),
          getMessageTemplate(localOrders[i].type),
          getEmailStatus(localOrders[i].status),
          localOrders[i].ts && localOrders[i].ts !== "0"
            ? getAttemptedDate(localOrders[i].ts)
            : getAttemptedDate(localOrders[i].attempted),
        ]);
      }
    }
    return rows;
  };
  const footerMarkup =
    orders && orders.length > 0 ? (
      <Stack vertical>
        <p>{"Page " + (pageNum + 1)}</p>
        <p>{"Showing " + orders.length + " results"}</p>
      </Stack>
    ) : null;

  const handleSort = (headingIndex: number, direction: string) => {
    direction === "descending" ? setDescending(true) : setDescending(false);
    if (headingIndex === 0) {
      setOrderBy("entityId");
    }
    if (headingIndex === 1) {
      setOrderBy("name");
    }
    if (headingIndex === 2) {
      setOrderBy("total");
    }
  };

  const crmLogsMarkup = (
    <Card>
      <Card.Section>
        <Filters
          queryValue={orderId}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleOrderIdRemove}
          onClearAll={handleFiltersClearAll}
          hideQueryField={false}
          queryPlaceholder={"Search by order id"}
        />
      </Card.Section>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "text"]}
        headings={["Order", "Name", "Amount", "Type", "Status", "Attempted"]}
        sortable={[true, true, true, false, false, false]}
        defaultSortDirection="descending"
        initialSortColumnIndex={0}
        hoverable={false}
        onSort={(index, direction) => handleSort(index, direction)}
        rows={getRows()}
        footerContent={
          <Stack vertical>
            {footerMarkup}
            <Pagination
              hasPrevious={pageNum > 0}
              onPrevious={() => {
                setPageNum((pageNum) => pageNum - 1);
              }}
              hasNext={orders?.length === pageSize}
              onNext={() => {
                setPageNum((pageNum) => pageNum + 1);
              }}
            />
          </Stack>
        }
      />
      <Card.Section>
        {noDataFound ? (
          <h1 className="Polaris-TextStyle text-center">
            Not enough data available
          </h1>
        ) : null}
      </Card.Section>
    </Card>
  );

  return (
    <div>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        obj={obj}
        showPricingBanner={showPricingBanner}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
      <div className="Polaris-Page mt-2">
        <div className="Polaris-Page-Header__TitleWrapper">
          <div className="Polaris-Header-Title__TitleAndSubtitleWrapper">
            <div className="Polaris-Header-Title__SubTitle-light Polaris-Header-Title__SubtitleCompact mt-2">
              <p>
                {/* <b>Note:</b> Automated messages can only be sent if a customer
                has opted-in. If a message doesn’t get sent, it could be
                because,
                <br />
                (1) customer is not registered on WhatsApp, or (2) customer did
                not OPT-IN to receive communication over WhatsApp. */}
              </p>
            </div>
            <div className="Polaris-Header-Title__SubTitle-light Polaris-Header-Title__SubtitleCompact mt-1">
              <p>
                Only the recent 7 days data is displayed below. Write to (raj@gupshup.io) to get older data.
              </p>
            </div>
          </div>
        </div>
        <div className="Polaris-Page--divider"></div>
      </div>
      <div className={styles.outerContainerTable}>
        {crmLogsMarkup}
        <div />
      </div>
    </div>
  );
};

export default EmailLogs;
