import { Icon } from '@shopify/polaris';
import React from 'react';

const NavigationSectionWrapper = (props: any) => {

  const badgeMarkup = props.badge ? (
    <div className="Polaris-Navigation__Badge">
      <span className="Polaris-Badge Polaris-Badge--statusNew Polaris-Badge--sizeSmall">{props.badge}</span>
    </div>
  ) : null;

  const labelMarkup = typeof(props.label) === 'string' ? (
    <span className="Polaris-Navigation__Text">{props.label}</span>
  ) : props.label;

  return (
    <ul className="Polaris-Navigation__Section">
      <li className="Polaris-Navigation__ListItem">
        <div className="Polaris-Navigation__ItemWrapper">
          <a className={"Polaris-Navigation__Item" + (props.selected ? " Polaris-Navigation__Item--selected" : "")} 
          tabIndex={0} 
          href={props.url} data-polaris-unstyled="true">
            <div className="Polaris-Navigation__Icon">
              <Icon source={props.icon} />
            </div>
            {labelMarkup}
            {badgeMarkup}
          </a>
        </div>
      </li>
    </ul>
  );
}

export default NavigationSectionWrapper;