export const SERVER_ADDR = process.env.REACT_APP_BASE_URL;
export const COD_SERVER_ADDR = process.env.REACT_APP_COD_URL;

export const MESSAGING = {
  baseUrl: process.env.REACT_APP_MESSAGING_BASE_URL,
  api: {
    // Abandoned cart
    ABANDONED_CART_SETTINGS: "setting/automateMsg/abandonedCart",
    AUTOMATED_PHONE_SETTINGS: "shopWaAutomatePhone",
    MESSAGE_LOGS: "messaging/v1/automated/message/$shopId/log",
    // Orders CRM
    CRM_ORDERS_CONFIRMATION_SETTIGS: "setting/automateMsg/orderConfirmation",
    CRM_ORDERS_SHIPMENT_SETTIGS: "setting/automateMsg/orderShipment",
    // COD
    COD_SETTINGS: "setting/automateMsg/cod",
    // OptIn Numbers
    EXPORT_NUMBERS: "messaging/v1/optin/$shopId/export",
    GET_NUMBERS:
      "shopOptInNumber?page=$pageNumber&size=$pageSize",
    GET_TOTAL_NUMBERS: "shopOptInNumber/count",
    OPTIN_WIDGET_SETTINGS: "messaging/v1/optin/widget/$shopId/settings",
    // Supported Integrations
    INTEGRATION: "messaging/integration",
    SHOP_INTEGRATION: "messaging/integration/shop/$shopId",
  },
};

export const MESSAGING_LOGS_V2 = {
  baseUrl: process.env.REACT_APP_MESSAGING_BASE_URL,
  api: {
    // Search by Name
    MESSAGE_LOGS: "messaging/v1/automated/v2/message/$shopId/logs?",
    //Message Logs
    // MESSAGE_LOGS: "messaging/v1/automated/message/$shopId/logs",
  },
};
export const EMAIL = {
  baseUrl: process.env.REACT_APP_MESSAGING_BASE_URL,
  api: {
    LOGS: "messaging/v1/automated/mail/$shopId/logs?",
    CONFIG_SETTING: "messaging/v1/automated/mail/$shopId/config",
    UPDATE_CONFIG_SETTINGS: "messaging/v1/automated/mail/$shopId/config",
    AB_CART_SETTINGS: "messaging/v1/automated/mail/abcart/$shopId/settings",
    COD_SETTINGS: "messaging/v1/automated/mail/cod/$shopId/settings",
    ORDER_SHIPMENT_SETTINGS:
      "messaging/v1/automated/mail/orderTracking/$shopId/settings",
    ORDER_CONFIRMATION_SETTINGS:
      "messaging/v1/automated/mail/orderCrm/$shopId/settings",
  },
};
export const MANUAL_MESSAGING = {
  baseUrl: process.env.REACT_APP_MESSAGING_BASE_URL,
  accountUrl: process.env.REACT_APP_BASE_URL,
  api: {
    ABANDONED_CART: "account/v1/shop/$shopId/event/checkouts/v2?",
    ORDERS_CRM: "account/v1/shop/$shopId/event/orders/v2?",
    GET_ALL_TAGS: "messaging/v1/tags/$shopId",
    GET_ALL_TEMPLATES: "messaging/v1/manual/$shopId/template",
    ADD_TAG_TO_AB_CART_ORDER:
      "messaging/v1/manual/$shopId/checkout/$checkoutId",
    ADD_TAG_TO_ORDER: "messaging/v1/manual/$shopId/order/$checkoutId",
    SETTINGS: "messaging/v1/manual/$shopId/settings",
    CREATE_TEMPLATE: "messaging/v1/manual/$shopId/template",
    UPDATE_DELETE_TEMPLATE: "messaging/v1/manual/$shopId/template/$templateId",
    CREATE_TAG: "messaging/v1/tags/$shopId",
    UPDATE_DELETE_TAG: "messaging/v1/tags/$shopId/$tagId",
    GET_DISCOUNT_CODE: "messaging/v1/manual/$shopId/msg/$templateId/discount",
  },
};

//Analytics
export const ANALYTICS = {
  baseUrl: process.env.REACT_APP_ANALYTICS_URL,
  api: {
    SHARE_CHAT_CLICK_COUNT: "shopWidgetClick",
    OPTIN: "analytics/v1/optin/$shopId?",
    MESSAGES: "analytics/v1/message/$shopId?",
  },
};

// /account/v1/shop/1/event/checkouts/v2?pageSize=10&pageNo=0&name=Samriddha&phone=919874684451&tagId=3&status=CLOSED&checkoutId=21731487121557

export const ACCOUNT_OAUTH = "/oauth/authorize";
export const CREATE_TOKEN = "/oauth/token/create";
export const OWNER_DETAILS = "account/v1/shop/$shopId/owner";
export const SHOP_DETAILS = "shop";

// Plan
export const PLANS = "superLemonPlan";
export const UPDATE_PLAN = "account/v1/shop/$shopId/plan/$planId";
export const PLAN_DETAILS = "account/v1/plan/$planId";
export const PLAN_CHECK = "account/v1/shop/$shopId/plan/check";

// Shop
export const SHOP_SETTINGS = "/shop/setting";
export const SHOP_TIMINGS = "account/v1/shop/$shopId/store/timings";

// Agents
export const STORE_TIMINGS = "setting/chat/waChatBasic";
export const AGENT_TIMINGS = "setting/chat/waChatPhoneNumber";
export const UPDATE_AGENT = "account/v1/chat/$shopId/agent";
//  old
export const AGENT_SETTINGS = "setting/chat/waChatPhoneNumber";
// export const UPDATE_AGENT_DETAILS = "account/v1/chat/$shopId/agent/$agentId/settings";
// export const CREATE_AGENT = "account/v1/chat/$shopId/agent";
export const UPDATE_AGENT_TIMINGS =
  "account/v1/chat/$shopId/agent/$agentId/timings";

// export const AGENT_SETTINGS = "setting/chat/waChatPhoneNumber/$shopId";
export const UPDATE_AGENT_DETAILS = "setting/chat/waChatPhoneNumber/$agentId";
export const CREATE_AGENT = "setting/chat/waChatPhoneNumber";

// Chat Settings
export const CHAT = "account/v1/chat/$shopId/button";
export const CHAT_BUTTON_STATUS = "setting/chat/waChat";
export const CHAT_BUTTON_TEMPLATES = "account/v1/shop/$shopId/chat/button/templates";
// export const CALL_OUT = "account/v1/chat/$shopId/callout";
// export const GREETING_WIDGET = "account/v1/chat/$shopId/greeting";
// export const CHAT_DEVICES = "account/v1/shop/$shopId/chat/device";
export const PAGES_TO_DISPLAY = "account/v1/chat/$shopId/buttonpages";
export const CUSTOM_THEMES = "account/v1/shop/$shopId/chat/theme/$buttonId";
/** New */
export const GREETING_WIDGET = "setting/chat/waChatGreeting";
export const CHAT_BUTTON_DESIGN = "setting/chat/waChatButtonDesign";
// export const CHAT_BUTTON_TEMPLATES = "setting/chat/waChatButtonDesign";
export const CALL_OUT = "setting/chat/waChatCallout";
export const CHAT_DEVICES = "setting/chat/waChatButtonDisplay";
export const CHAT_BUTTON_DISPLAY_PAGE = "setting/chat/waChatButtonPage";

// End Chat Settings

// Start Share Settings
export const SHARE = "setting/share/waShare";
export const SHARE_THEME = "account/v1/shop/$shopId/share/theme/$buttonId";
// export const SHARE_DEVICES = "account/v1/shop/$shopId/share/device";
export const GET_SHARE_THEMES = "account/v1/shop/$shopId/share/themes";
/** New */
export const SHARE_BUTTON_STATUS = "setting/share/waShare";
export const SHARE_BUTTON_DESIGN_THEMES = "setting/share/waShareButtonDesign";
export const SHARE_DEVICES = "setting/share/waShareButtonDisplay";
export const SHARE_BUTTON_DISPLAY_PAGE = "setting/share/waShareButtonPage";
// export const GET_SHARE_THEMES = "setting/share/waShareButtonDesign";
// End Share Settings

// Private WABA
export const WABA = "account/v1/shop/$shopId/waba";
export const SEND_OTP = "account/v1/shop/$shopId/waba/opt/request";
export const SUBMIT_OTP = "account/v1/shop/$shopId/waba/otp/verify";
export const PROFILE_IMAGE = "account/v1/shop/$shopId/waba/profile/image";
export const PRIVATE_WABA_BUSINESS_DETAILS =
  "account/v1/shop/$shopId/waba/info";
export const PRIVATE_WABA_FACEBOOK_DETAILS =
  "account/v1/shop/$shopId/waba/business";
export const PRIVATE_WABA_PROFILE_DETAILS =
  "account/v1/shop/$shopId/waba/phone";
export const PRIVATE_WABA_CONTACT_DETAILS =
  "account/v1/shop/$shopId/waba/contact";
export const PRIVATE_WABA_PROFILE_ABOUT_DETAILS =
  "account/v1/shop/$shopId/waba/profile/about";
export const WABA_TEMPLATES = "account/v1/shop/$shopId/waba/template/status";
export const LOCALES_DETAILS = "account/util/locales";
export const COUNTRY_CODES = "account/util/countries";

export const OPTIN_ADMIN = "account/v1/shop/$shopId/admin/settings/optin";

// Templates
export const TEMPLATES = "shopExtensionTemplate";

// Extension template category
export const EXTENSION_CATEGORY = 'extensionTemplateCategory'

// COD Order
export const COD_ORDER = "account/cod/order/$token";

//Promotional Automated Templates - Whatsapp
export const AUTOMATED_PROMOTIONAL_TEMPLATES_WHATSAPP = {
    baseUrl: process.env.REACT_APP_MESSAGING_BASE_URL,
    api: {
        CROSS_SELL : "messaging/v1/automated/crossSell/$shopId/settings",
        FEEDBACK : "messaging/v1/automated/feedback/$shopId/settings",
        WIN_BACK : "messaging/v1/automated/winback/$shopId/settings",
    }
}
export const NEW_TRANSACTION_TEMPLATES_WHATSAPP = {
    baseUrl: process.env.REACT_APP_MESSAGING_BASE_URL,
    api: {
        PAYMENT_SUCCESSFUL : "setting/automateMsg/paymentSuccessful",
        ORDER_CANCELLATION: "setting/automateMsg/orderCancellation",
    }
}
// COD Order Email Settings
export const COD_ORDER_EMAIL = "account/cod/order/$token?type=EMAIL";
