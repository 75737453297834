import { Heading, Modal, Stack, TextContainer } from "@shopify/polaris";

const Release_22_03_01_Modal = (props: any) => {
  const modalContentGuideStep = () => {
    return (
      <TextContainer>
        <p className="release-heading ">
          <Heading>1. Analytics is here</Heading>
        </p>
        <p>
          In this section, you will find analytics for all the different
          features of the WhatsApp communication channel.
          <br />
          You will be able to view the analytics for the Share widget and Chat widget.
          <br />
          You can find Analytics in the left menu bar as shown below:
        </p>
        <img
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/1.png?v=1645126382"
          alt="Analytics in Left Navigation"
        />
        <br />
        <p className="release-heading ">
          <Heading>2. Templates Section</Heading>
        </p>
        <p>
          Templates section of SuperLemon is revamped to make it a one stop shop
          for all your templates. The new Templates section will have two new
          sub-sections : <b>Automated</b> and <b>Extension Templates</b>.
          <br />
          <br />
          The <b>Automated</b> sub-section will
          have all Automated Message Templates i.e. Abandoned Cart Template in a new
          sub-section of Automated Messages as shown below:
        </p>
        <img
          className="checkoutOptinModalImage"
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/r-1.png?v=1639474202"
          alt="Automated Templates"
        />
        <p>
          The <b>Extension Templates</b> sub-section will have all Quick Reply Templates as shown below:
        </p>
        <img
          className="checkoutOptinModalImage"
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/r-updated.png?v=1639550236"
          alt="Extension Templates"
        />
        <p>
          To know more about the new features and functionalities please go
          through the guide of the Templates section.
        </p>
        <br />
        <p className="release-heading ">
          <Heading>3. Guides for all major Sections of SuperLemon</Heading>
        </p>
        <p>
          Guides have been added for all the major sections of SuperLemon for ease of
          understanding of all features and their configurations. If you want to
          know more about the feature and functionality you can simply browse
          the guide for that section.
          <br />
          <br />
          You can open the guide by opening any section of SuperLemon and on the
          top right corner of the section, you will be able to see a ? icon as
          shown below
        </p>
        <img
          className="checkoutOptinModalImage"
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/r-3.png?v=1639474439"
          alt="Guide Overview"
        />
        <p>
          Clicking on this button will open a card highlighting the details of
          the section along with the <b>Start Guide</b> button. Clicking the “Start
          Guide” button will open up the guide in a new pop-up and you will be
          able to browse through all the different features and configurations
          available to you in that section.
        </p>
        <br />
        <p className="release-heading ">
          <Heading>4. Admin Configuration</Heading>
        </p>
        <p>
          Admin Configuration now has the setting to add your WhatsApp number
          for Automated messages.
          <br />
          To know more about the Admin Configuration please go through the guide
          of the Admin Configuration section.
        </p>
        <br />
        <p className="release-heading ">
          <Heading>5. SuperLemon Chrome Extension</Heading>
        </p>
        <p>
          The new SuperLemon chrome extension is here!
          <br />
          <br />
          The upgraded SuperLemon chrome extension includes:
          <ul>
            <li>Revamped All Orders</li>
            <li>New Abandoned Cart Section</li>
            <li>Search Functionality for All Orders and Abandoned Cart</li>
            <li>New Card layout</li>
            <li>Multilingual</li>
          </ul>
        </p>
        <img
          className="checkoutOptinModalImageMoreWidth"
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/ext32.png?v=1646387543"
          alt="Extension"
        />
        <img
          className="checkoutOptinModalImageMoreWidth mt-3"
          src="https://cdn.shopify.com/s/files/1/0449/7794/6790/files/Release-extension_img_2.png?v=1646635540"
          alt="Extension Guide Reference"
        />
      </TextContainer>
    );
  };

  const handleClose = () => {
    props.closeModal();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.closeModal}
      title={"Release - 22.08.03"}
      secondaryActions={[
        {
          content: "Close",
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
export default Release_22_03_01_Modal;
