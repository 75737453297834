import { ANALYTICS } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: ANALYTICS.baseUrl,
};

const getClickCount = (shopId: string, from: string, to: string) => {
  let query = "";
  if (from && to) {
    from  = (new Date(from).getTime()).toFixed(0) ;
    to  = (new Date(to).getTime()).toFixed(0) ;
    query = `?startCreateTimestamp=${from}&endCreateTimestamp=${to}`;
  }
  return httpClient.get(
    ANALYTICS.api.SHARE_CHAT_CLICK_COUNT.replace("$shopId", shopId) + query,
    config
  );
};
const getOptinData = (shopId: string, from: string, to: string) => {
  let query = "&dailyAggregator=true";
  if (from && to) {
    query = `from=${from}&to=${to}` + query;
  }
  return httpClient.get(
    ANALYTICS.api.OPTIN.replace("$shopId", shopId) + query,
    config
  );
};
const getMessagesData = (shopId: string, from: string, to: string) => {
  let query = "";
  if (from && to) {
    query = `from=${from}&to=${to}`;
  }
  return httpClient.get(
    ANALYTICS.api.MESSAGES.replace("$shopId", shopId) + query,
    config
  );
};

export { getClickCount, getOptinData, getMessagesData };
