export const APP_NAME = process.env.REACT_APP_NAME || "whatsapp-chat-button";
export const DOMAIN = process.env.REACT_APP_DOMAIN;

export const PLAN_FREE = Number(process.env.REACT_APP_PLAN_FREE);
export const PLAN_PRO = Number(process.env.REACT_APP_PLAN_PRO);
export const PLAN_ULTIMATE = Number(process.env.REACT_APP_PLAN_ULTIMATE);

export const DAY = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const TEMPLATE_LANGUAGE_ENG = "ENGLISH";
export const TEMPLATE_LANGUAGE_PORT = "PORTUGUESE";
export const TEMPLATE_LANGUAGE_SPAN = "SPANISH";
export const TEMPLATE_LANGUAGE_ITAL = "ITALIAN";
export const TEMPLATE_LANGUAGE_FREN = "FRENCH";
export const TEMPLATE_LANGUAGE_INDO = "INDONESIAN";
export const TEMPLATE_LANGUAGE_GERM = "GERMAN";
export const TEMPLATE_LANGUAGE_ARAB = "ARABIC";
export const TEMPLATE_LANGUAGE_TURK = "TURKISH";
export const TEMPLATE_LANGUAGE_HEBR = "HEBREW";
export const TEMPLATE_LANGUAGE_DUTCH = "DUTCH";
export const TEMPLATE_LANGUAGE_RUSSIAN = "RUSSIAN";

export const COUPON_TYPE_PERCENT = "PERCENT";
export const COUPON_TYPE_STATIC = "FIXED";

export const WABA_TEMPLATE_APPROVED = "APPROVED";
export const WABA_TEMPLATE_REJECTED = "REJECTED";

export const PAGE_SIZE = 20;
export const AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW =
  "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/automated_message_how_it_works_explanation.png?v=1587755302";

export const CRM_TEMPLATE_DICT = {
  NONE: {
    order_confirmation_template:
      "Hi {{customer_name}}, thank you for your purchase of {{currency}} {{total}} from {{shop_name}}. Your order is getting ready and we will notify you when it has been shipped. You can view your order here {{order_ID}} {{order_status_url}}",
    order_tracking_template:
      "Hi {{customer_name}}, your order from {{shop_name}} has been shipped and is on its way. Track your shipment using this link {{tracking_ID}} {{tracking_url}}",
  },
  ENGLISH: {
    order_confirmation_template:
      "Hi {{customer_name}}, thank you for your purchase of {{currency}} {{total}} from {{shop_name}}. Your order is getting ready and we will notify you when it has been shipped. You can view your order here {{order_ID}} {{order_status_url}}",
    order_tracking_template:
      "Hi {{customer_name}}, your order from {{shop_name}} has been shipped and is on its way. Track your shipment using this link {{tracking_ID}} {{tracking_url}}",
  },
  PORTUGUESE: {
    order_confirmation_template:
      "Olá {{customer_name}}, obrigado por sua compra de {{currency}} {{total}} de {{shop_name}}. Seu pedido está se preparando e iremos notificá-lo quando ele for enviado. Você pode ver seu pedido aqui {{order_ID}} {{order_status_url}}",
    order_tracking_template:
      "Olá {{customer_name}}, seu pedido de {{shop_name}} foi enviado e está a caminho. Acompanhe sua remessa usando este link {{tracking_ID}} {{tracking_url}}",
  },
  SPANISH: {
    order_confirmation_template:
      "Hola {{customer_name}}, gracias por tu compra de {{currency}} {{total}} de {{shop_name}}. Su pedido se está preparando y le notificaremos cuando haya sido enviado. Puede ver su pedido aquí {{order_ID}} {{order_status_url}}",
    order_tracking_template:
      "Hola {{customer_name}}, su pedido de {{shop_name}} ha sido enviado y está en camino. Rastree su envío usando este enlace {{tracking_ID}} {{tracking_url}}",
  },
  ITALIAN: {
    order_confirmation_template:
      "Ciao {{customer_name}}, grazie per aver acquistato {{currency}} {{total}} da {{shop_name}}. Il tuo ordine è in preparazione e ti informeremo quando sarà spedito. Puoi visualizzare il tuo ordine qui {{order_ID}} {{order_status_url}}",
    order_tracking_template:
      "Ciao {{customer_name}}, il tuo ordine da {{shop_name}} è stato spedito ed è in arrivo. Rintraccia la tua spedizione utilizzando questo link {{tracking_ID}} {{tracking_url}}",
  },
  FRENCH: {
    order_confirmation_template:
      "Bonjour {{customer_name}}, merci pour votre achat de {{currency}} {{total}} effectué chez {{shop_name}}. Votre commande est en cours de préparation et nous vous informerons de son expédition. Vous pouvez consulter votre commande ici {{order_ID}} {{order_status_url}}",
    order_tracking_template:
      "Bonjour {{customer_name}}, votre commande passée chez {{shop_name}} a été expédiée et est en route. Suivez votre envoi à l'aide de ce lien {{tracking_ID}} {{tracking_url}}",
  },
  INDONESIAN: {
    order_confirmation_template:
      "Hi {{customer_name}}, terima kasih atas pembelanjaan Anda di {{shop_name}} sejumlah {{currency}} {{total}}. Lihat rincian pesanan {{order_ID}} {{order_status_url}}",
    order_tracking_template:
      "Hi {{customer_name}}, pesanan Anda di {{shop_name}} telah terkirim dan sedang dalam perjalanan menuju tujuan Anda. Cek status paket Anda {{tracking_ID}} {{tracking_url}}",
  },
  GERMAN: {
    order_confirmation_template:
      "Hallo {{customer_name}}, vielen Dank für Ihren Kauf von {{currency}} {{total}} bei {{shop_name}}. Ihre Bestellung wird fertig und wir werden Sie benachrichtigen, wenn sie versendet wurde. Sie können Ihre Bestellung hier anzeigen {{order_id}} {{order_status_url}}",
    order_tracking_template:
      "Hallo {{customer_name}}, Ihre Bestellung von {{shop_name}} wurde versendet und ist unterwegs. Verfolgen Sie Ihre Sendung über diesen Link {{tracking_ID}} {{tracking_url}}",
  },
  ARABIC: {
    order_confirmation_template:
      "مرحبا {{customer_name}}، شكرا على شرائك  بقيمة {{currency}} {{total}} من {{shop_name}}.  يتم تجهيز طلبك حالياً وسنقوم بإعلامك عند شحنه. يمكنك عرض طلبك هنا 👉{{order_id}}  {{order_status_url}}",
    order_tracking_template:
      "مرحبا {{first_name}},  تم شحن طلبك من متجر {{shop_name}} وهو في الطريق إليك. تتبع شحنتك باستخدام هذا الرابط {{tracking_ID}} {{tracking_url}}.",
  },
  TURKISH: {
    order_confirmation_template:
      "Merhaba {{customer_name}}, {{shop_name}} üzerinden vermiş olduğunuz {{currency}} {{total}} değerindeki sipariş için teşekkür ederiz. Şu anda siparişiniz hazırlanmakta ve kargolandığında size bilgi vereceğiz. Siparişinizi buradan görüntüleyebilirsiniz 👉{{order_id}} {{order_status_url}}",
    order_tracking_template:
      "Merhaba {{customer_name}}, {{shop_name}} üzerinden vermiş olduğunuz siparişiniz kargoya verilmiştir. Kargonuzu buradan takip edebilirsiniz: {{tracking_ID}} {{tracking_url}}",
  },
  HEBREW: {
    order_confirmation_template:
      "הי {{customer_name}}, אנו מודים לך על הקנייה שלך בס''כ {{currency}} {{total}} מ {{shop_name}}. ההזמנה שלך התקבלה והועברה להמשך טיפול, נעדכן אותך שהמשלוח נשלח לפיזור. אפשר לצפות בפרטי הזמנה דרך מכאן {{order_id}} {{order_status_url}}",
    order_tracking_template:
      "הי {{customer_name}}, ההזמנה שלך מ {{shop_name}} בדרך אליכם, אפשר לעקוב אחרי המשלוח דרך הלינק הבא {{tracking_ID}} {{tracking_url}}",
  },
  DUTCH: {
    order_confirmation_template:
      "Hi {{customer_name}}, dank je wel voor jouw aankoop van {{currency}} {{total}} van {{shop_name}}. Jouw order wordt klaargezet en we sturen je een berichtje als het verzonden is. Je kunt je order hier checken 👉 {{order_id}} {{order_status_url}}",
    order_tracking_template:
      "Hi {{customer_name}}, jouw order van {{shop_name}} is verzonden en onderweg. Track de verzending via deze link {{tracking_ID}} {{tracking_url}}",
  },
  RUSSIAN: {
    order_confirmation_template:
      "Здравствуйте, {{customer_name}}, Спасибо за вашу покупку {{currency}} {{total}} у {{shop_name}}. Ваш заказ сейчас оформляется, и мы сообщим вам, когда он будет отправлен. Нажмите на кнопку, чтобы просмотреть ваш заказ 👉 {{order_ID}} {{order_status_url}}",
    order_tracking_template:
      "Здравствуйте, {{customer_name}},Ваш заказ от {{shop_name}} был отправлен и уже в пути. Чтобы отслеживать ваш заказ, нажмите на кнопку {{tracking_ID}} {{tracking_url}}",
  },
};

export const ABANDONED_CART_TEMPLATE_DICT = {
  NONE: {
    abandoned_cart_recovery_template:
      "Hi {{customer_name}}, the payment for your order of {{currency}} {{total}} from {{shop_name}} is still pending. Click on the link to complete the payment and confirm your order 👉 {{checkout_url}}.",
  },
  ENGLISH: {
    abandoned_cart_recovery_template:
      "Hi {{customer_name}}, the payment for your order of {{currency}} {{total}} from {{shop_name}} is still pending. Click on the link to complete the payment and confirm your order 👉 {{checkout_url}}.",
  },
  PORTUGUESE: {
    abandoned_cart_recovery_template:
      "Olá {{customer_name}}, o pagamento do seu pedido de {{currency}} {{total}} de {{shop_name}} ainda está pendente. Clique no link para concluir o pagamento e confirmar seu pedido 👉 {{checkout_url}}.",
  },
  SPANISH: {
    abandoned_cart_recovery_template:
      "Hola {{customer_name}}, el pago de tu pedido de {{currency}} {{total}} de {{shop_name}} aún está pendiente. Haga clic en el enlace para completar el pago y confirmar su pedido 👉 {{checkout_url}}.",
  },
  ITALIAN: {
    abandoned_cart_recovery_template:
      "Ciao {{customer_name}}, il pagamento per il tuo ordine di {{currency}} {{total}} da {{shop_name}} è ancora in sospeso. Fai clic sul link per completare il pagamento e confermare il tuo ordine 👉 {{checkout_url}}.",
  },
  FRENCH: {
    abandoned_cart_recovery_template:
      "Bonjour {{customer_name}}, le paiement de votre commande de {{currency}} {{total}} chez {{shop_name}} est toujours en attente. Cliquez sur le lien suivant pour finaliser le paiement et confirmer votre commande 👉 {{checkout_url}}",
  },
  INDONESIAN: {
    abandoned_cart_recovery_template:
      "Hi {{customer_name}}, pembayaran atas pesanan Anda sebesar {{currency}} {{total}} di {{shop_name}} belum selesai. Silahkan klik link untuk melanjutkan ke menu pembayaran 👉 {{checkout_url}}",
  },
  GERMAN: {
    abandoned_cart_recovery_template:
      "Hallo {{customer_name}}, die Zahlung für Ihre Bestellung von {{currency}} {{total}} von {{shop_name}} steht noch aus. Klicken Sie auf den Link, um die Zahlung abzuschließen und Ihre Bestellung zu bestätigen 👉 {{checkout_url}}.",
  },
  ARABIC: {
    abandoned_cart_recovery_template:
      "مرحبًا {{customer_name}}، الدفع الخاص بطلبك بقيمة {{currency}} {{total}}  من {{shop_name}} لا يزال معلقًا. انقر على الرابط لإكمال الدفع وتأكيد طلبك 👉 {{checkout_url}}.",
  },
  TURKISH: {
    abandoned_cart_recovery_template:
      "Merhaba {{customer_name}}, {{shop_name}} üzerinden vermiş olduğunuz {{currency}} {{total}} değerindeki sipariş için ödeme beklenmektedir. Siparişi görüntülemek ve ödeme işlemini tamamlamak için linke tıklayın: 👉 {{checkout_url}}",
  },
  HEBREW: {
    abandoned_cart_recovery_template:
      "הי {{customer_name}}, ההזמנה שלך בס''כ {{shop_name}} מ {{currency}} {{total}} טרם הושלמה. לאישור ההזמנה והשלמת התשלום תלצו כאן {{checkout_url}}",
  },
  DUTCH: {
    abandoned_cart_recovery_template:
      "Hi {{customer_name}}, de betaling van jouw order van {{currency}} {{total}} van {{shop_name}} is nog steeds niet ontvangen. Klik op de link om de betaling te voldoen en de bestelling te bevestigen 👉 {{checkout_url}}",
  },
  RUSSIAN: {
    abandoned_cart_recovery_template:
      "Здравствуйте {{customer_name}},Оплата вашего заказа {{currency}} {{total}} от {{shop_name}} еще не завершена.Нажмите на ссылку, чтобы завершить оплату и подтвердить заказ 👉 {{checkout_url}}",
  },
};
export const COD_TEMPLATE_DICT = {
  NONE: {
    cod_confirmation_template:
      "Hi {{customer_name}}, thank you for placing an order of {{currency}} {{total}} from {{shop_name}}. Since you paid using Cash on Delivery (COD) option, we need a confirmation from you before we process your order and ship it. Click on this link to confirm your order 👉 {{cod_confirmation_page_url}}.",
  },
  ENGLISH: {
    cod_confirmation_template:
      "Hi {{customer_name}}, thank you for placing an order of {{currency}} {{total}} from {{shop_name}}. Since you paid using Cash on Delivery (COD) option, we need a confirmation from you before we process your order and ship it. Click on this link to confirm your order 👉 {{cod_confirmation_page_url}}.",
  },
  PORTUGUESE: {
    cod_confirmation_template:
      "Olá {{customer_name}}, obrigado por fazer o pedido de {{currency}} {{total}} de {{shop_name}}. Dado que você pagou usando a opção de pagamento com dinheiro na entrega (COD), precisamos que você confirme antes de processarmos e enviarmos o seu pedido. Clique no botão para confirmar o seu pedido 👉 {{cod_confirmation_page_url}}",
  },
  SPANISH: {
    cod_confirmation_template:
      "Hola {{customer_name}}, gracias por generar una orden de {{currency}} {{total}} en {{shop_name}}. Como has pagado usando la opción de pago contra-entrega, necesitamos una confirmación de parte tuya antes de proceder con el procesamiento de tu orden y enviarla. Has click en el link para confirmar tu orden 👉 {{cod_order_confirmation_url}}.",
  },
  ITALIAN: {
    cod_confirmation_template:
      "Ciao {{customer_name}}, grazie per aver effettuato il tuo ordine di {{currency}} {{total}} su {{shop_name}}. Siccome hai scelto di pagare in Contanti alla Consegna, abbiamo bisogno di una conferma da parte tua prima di poterlo spedire. Clicca qui per confermare il tuo ordine 👉 {{cod_order_confirmation_url}}.",
  },
  FRENCH: {
    cod_confirmation_template:
      "Bonjour {{customer_name}}, merci d'avoir passé une commande de {{currency}} {{total}} chez {{shop_name}}. Comme vous avez payé en utilisant l'option de paiement à la livraison (COD), nous avons besoin d'une confirmation de votre part avant de traiter votre commande et de l'expédier. Cliquez sur le bouton pour confirmer votre commande 👉 {{cod_confirmation_page_url}}",
  },
  INDONESIAN: {
    cod_confirmation_template:
      "Hai {{customer_name}}, terima kasih telah menempatkan pesanan {{currency}} {{total}} dari {{shop_name}}. Karena Anda membayar dengan opsi Bayar di Tempat (COD), Anda perlu mengonfirmasi sebelum kami bisa memproses dan mengirimkan pesanan. Klik tombol untuk mengkonfirmasi pesanan Anda 👉 {{cod_confirmation_page_url}}",
  },
  GERMAN: {
    cod_confirmation_template:
      "Hallo {{customer_name}}, vielen Dank für Ihre Bestellung von {{currency}} {{total}} bei {{shop_name}}. Da Sie per Nachnahme bezahlt haben, benötigen wir eine Bestätigung von Ihnen, bevor wir Ihre Bestellung bearbeiten und versenden können. Klicken Sie auf die Schaltfläche, um Ihre Bestellung zu bestätigen 👉 {{cod_confirmation_page_url}}",
  },
  ARABIC: {
    cod_confirmation_template:
      "مرحبا {{customer_name}}، نشكرك على طلبك {{currency}} {{total}} من {{shop_name}} نظرا لانك دفعت باستخدام خيار الدفع عند الاستلام،فنحن بحاجة إلى تأكيدك قبل معالجة طلبك وشحنه. *ادخل على الرابط لتأكيد طلبك* 👉 {{cod_order_confirmation_url}}.",
  },
  TURKISH: {
    cod_confirmation_template:
      "Merhaba {{customer_name}}, {{shop_name}} ürününden {{currency}} {{total}} sipariş verdiğiniz için teşekkür ederiz. Teslimat Sırasında Ödeme (COD) seçeneğini kullanarak ödeme yaptığınız için, siparişinizi işleme koymadan ve gönderimini sağlamadan önce sizden onay almamız gerekiyor. *Siparişinizi onaylamak için butona tıklayın* 👉 {{cod_order_confirmation_url}}",
  },
  HEBREW: {
    cod_confirmation_template:
      "הי {{customer_name}}, תודה שקניתם אצלינו בס''כ {{currency}} {{total}} מ {{shop_name}}. בחרתם באפשרות תשלום במזומן לשליח, ולכן נדרש אימות נוסף לפני שמעבירים ההזמנה להמשך טיפול .לאישור ההזמנה נא ללחוץ על הלינק הבא 👉 {{cod_order_confirmation_url}}.",
  },
  DUTCH: {
    cod_confirmation_template:
      "Hallo {{customer_name}}, dank u voor het plaatsen van een bestelling van {{currency}} {{total}} bij {{shop_name}}. Aangezien u betaald heeft onder rembours (COD), hebben we een bevestiging van u nodig voordat we uw bestelling verwerken en verzenden. Klik op de knop om uw bestelling te bevestigen 👉 {{cod_order_confirmation_url}}",
  },
  RUSSIAN: {
    cod_confirmation_template:
      "Здравствуйте, {{customer_name}}, спасибо за размещение заказа {{currency}} {{total}} от {{shopName}}. Поскольку вы оплатили заказ наложенным платежом (COD), нам необходимо подтверждение от вас, прежде чем мы обработаем ваш заказ и отправим его. Нажмите на кнопку, чтобы подтвердить ваш заказ {{cod_confirmation_page_url}}",
  },
};

export const CROSS_SELL_TEMPLATE_DICT = {
  NONE: {
    discountEnabledPercent:
      "Hi {{customerName}}! Thank you for your Purchase {{name}} We are offering a discount of {{discountPercent}}% for a limited period of time! (coupon code: {{discountCode}}) {{discountUrl}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
    discountEnabledCouponCode:
      "Hi {{customerName}}! Thank you for your Purchase {{name}} We are offering a discount for a limited period of time! Simply apply coupon code {{discountCode}} at the time of checkout {{discountUrl}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
    discountDisabled:
      "Hi {{customerName}}! Thank you for your Purchase {{name}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
  },
  ENGLISH: {
    discountEnabledPercent:
      "Hi {{customerName}}! Thank you for your Purchase {{name}} We are offering a discount of {{discountPercent}}% for a limited period of time! (coupon code: {{discountCode}}) {{discountUrl}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
    discountEnabledCouponCode:
      "Hi {{customerName}}! Thank you for your Purchase {{name}} We are offering a discount for a limited period of time! Simply apply coupon code {{discountCode}} at the time of checkout {{discountUrl}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
    discountDisabled:
      "Hi {{customerName}}! Thank you for your Purchase {{name}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
  },
};
export const CUSTOMER_WINBACK_TEMPLATE_DICT = {
  NONE: {
    discountEnabledPercent:
      "Hi {{customerName}}!<br><br>We miss you at {{storeUrl}}. Thank you for your Purchase {{order_id}} We are offering a discount of {{discountPercent}}% for a limited period of time! (coupon code: {{discountCode}}) {{discountUrl}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
    discountEnabledCouponCode:
      "Hi {{customerName}}!<br><br>We miss you at {{storeUrl}}. Thank you for your Purchase {{order_id}} We are offering a discount for a limited period of time! Simply apply coupon code {{discountCode}} at the time of checkout {{discountUrl}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
    discountDisabled:
      "Hi {{customerName}}!<br><br>We miss you at {{storeUrl}}. Thank you for your Purchase {{order_id}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
  },
  ENGLISH: {
    discountEnabledPercent:
      "Hi {{customerName}}!<br><br>We miss you at {{storeUrl}}. Thank you for your Purchase {{order_id}} We are offering a discount of {{discountPercent}}% for a limited period of time! (coupon code: {{discountCode}}) {{discountUrl}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
    discountEnabledCouponCode:
      "Hi {{customerName}}!<br><br>We miss you at {{storeUrl}}. Thank you for your Purchase {{order_id}} We are offering a discount for a limited period of time! Simply apply coupon code {{discountCode}} at the time of checkout {{discountUrl}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
    discountDisabled:
      "Hi {{customerName}}!<br><br>We miss you at {{storeUrl}}. Thank you for your Purchase {{order_id}}<br><br>Chat with customer support at {{shopName}}:{{supportInfo}}{{replyToViewLinks}}",
  },
};
export const FEEDBACK_TEMPLATE_DICT = {
  NONE: {
    discountDisabled:
      "Hi {{customerName}}! Thank you for your Purchase {{name}}<br><br>We hope you are satisfied with your purchase and would recommend it to your friends and family as well.<br><br>If you have any questions/ideas/suggestions/feedback for us, please feel free to contact our customer support at {{supportInfo}}{{replyToViewLinks}}",
  },
  ENGLISH: {
    discountDisabled:
      "Hi {{customerName}}! Thank you for your Purchase {{name}}<br><br>We hope you are satisfied with your purchase and would recommend it to your friends and family as well.<br><br>If you have any questions/ideas/suggestions/feedback for us, please feel free to contact our customer support at {{supportInfo}}{{replyToViewLinks}}",
  },
};

export const PAYMENT_SUCCESSFUL_TEMPLATE_DICT = {
  NONE: {
    discountEnabledPercent:
      "Hi *{{customer_name}}*, the payment for your order *{{order_ID}}* of *{{order_value}}* is successful.<br><br>You can view your order here 👉 (Order ID *{{order_ID}}* {{order_status_URL}}).*Get {{discountPercent}}% off* on your next order (coupon code: {{discountCode}}) {{discountUrl}}. <br><br>Chat with customer support at {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Hi *{{customer_name}}*, the payment for your order *{{order_ID}}* of *{{order_value}}* is successful.<br><br>You can view your order here 👉 (Order ID *{{order_ID}}* {{order_status_URL}}).Apply coupon code: *{{discountCode}}* on your next order {{discountUrl}}. <br><br>Chat with customer support at {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Hi *{{customer_name}}*, the payment for your order *{{order_ID}}* of *{{order_value}}* is successful.<br><br>You can view your order here 👉 (Order ID *{{order_ID}}* {{order_status_URL}}).<br><br>Chat with customer support at {{store_name}}: {{WA_number}}",
  },
  ENGLISH: {
    discountEnabledPercent:
      "Hi *{{customer_name}}*, the payment for your order *{{order_ID}}* of *{{order_value}}* is successful.<br><br>You can view your order here 👉 (Order ID *{{order_ID}}* {{order_status_URL}}). *Get {{discountPercent}}% off* on your next order (coupon code: {{discountCode}}) {{discountUrl}}. <br><br>Chat with customer support at {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Hi *{{customer_name}}*, the payment for your order *{{order_ID}}* of *{{order_value}}* is successful.<br><br>You can view your order here 👉 (Order ID *{{order_ID}}* {{order_status_URL}}). Apply coupon code: *{{discountCode}}* on your next order {{discountUrl}}. <br><br>Chat with customer support at {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Hi *{{customer_name}}*, the payment for your order *{{order_ID}}* of *{{order_value}}* is successful.<br><br>You can view your order here 👉 (Order ID *{{order_ID}}* {{order_status_URL}}).<br><br>Chat with customer support at {{store_name}}: {{WA_number}}",
  },
  PORTUGUESE: {
    discountEnabledPercent:
      "Olá, *{{customerName}}*, o pagamento do seu pedido *{{order_ID}}* de *{{order_value}}* foi efetuado com sucesso.<br><br>Você pode ver seu pedido aqui 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. *Ganhe {{discountPercent}}% de desconto* no seu próximo pedido (código do cupom: {{discountCode}}) {{discountUrl}}. <br><br>Converse com o suporte ao cliente em {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Olá, *{{customerName}}*, o pagamento do seu pedido *{{order_ID}}* de *{{order_value}}* foi efetuado com sucesso.<br><br>Você pode ver seu pedido aqui 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Aplique código do cupom: *{{discountCode}}* no seu próximo pedido {{discountUrl}}. <br><br>Converse com o suporte ao cliente em {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Olá, *{{customerName}}*, o pagamento do seu pedido *{{order_ID}}* de *{{order_value}}* foi efetuado com sucesso.<br><br>Você pode ver seu pedido aqui 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>Converse com o suporte ao cliente em {{store_name}}: {{WA_number}}",
  },
  SPANISH: {
    discountEnabledPercent:
      "Hola *{{customerName}}*, el pago de tu pedido *{{order_ID}}* de *{{order_value}}* fue exitoso.<br><br>Puedes ver tu pedido aquí 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. *Obtenga {{discountPercent}}% de descuento* en su próximo pedido (código de cupón: {{discountCode}}) {{discountUrl}}.<br><br>Chatea con nuestro servicio de atención al cliente en {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Hola *{{customerName}}*, el pago de tu pedido *{{order_ID}}* de *{{order_value}}* fue exitoso.<br><br>Puedes ver tu pedido aquí 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Aplique código de cupón: *{{discountCode}}* en su próximo pedido {{discountUrl}}.<br><br>Chatea con nuestro servicio de atención al cliente en {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Hola *{{customerName}}*, el pago de tu pedido *{{order_ID}}* de *{{order_value}}* fue exitoso.<br><br>Puedes ver tu pedido aquí 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>Chatea con nuestro servicio de atención al cliente en {{store_name}}: {{WA_number}}",
  },
  ITALIAN: {
    discountEnabledPercent:
      "Ciao *{{customerName}}*, il pagamento per il tuo ordine *{{order_ID}}* di *{{order_value}}* è andato a buon fine.<br><br>Puoi visualizzare il tuo ordine qui 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. *Ricevi {{discountPercent}}% di sconto* sul tuo prossimo ordine (codice coupon: {{discountCode}}) {{discountUrl}}. <br><br>Chatta con l'assistenza clienti su {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Ciao *{{customerName}}*, il pagamento per il tuo ordine *{{order_ID}}* di *{{order_value}}* è andato a buon fine.<br><br>Puoi visualizzare il tuo ordine qui 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Applica codice coupon: *{{discountCode}}* sul tuo prossimo ordine {{discountUrl}}.<br><br>Chatta con l'assistenza clienti su {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Ciao *{{customerName}}*, il pagamento per il tuo ordine *{{order_ID}}* di *{{order_value}}* è andato a buon fine.<br><br>Puoi visualizzare il tuo ordine qui 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>Chatta con l'assistenza clienti su {{store_name}}: {{WA_number}}",
  },
  FRENCH: {
    discountEnabledPercent:
      "Salut *{{customerName}}*, le paiement de votre commande *{{order_ID}}* de *{{order_value}}* a été effectué avec succès.<br><br>Vous pouvez consulter votre commande ici 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. *Bénéficiez de {{discountPercent}}% de réduction* sur votre prochaine commande (code promo: {{discountCode}}) {{discountUrl}}.<br><br>Discutez avec le service clientèle de {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Salut *{{customerName}}*, le paiement de votre commande *{{order_ID}}* de *{{order_value}}* a été effectué avec succès.<br><br>Vous pouvez consulter votre commande ici 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Appliquer le code coupon: *{{discountCode}}* sur votre prochaine commande {{discountUrl}}.<br><br>Discutez avec le service clientèle de {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Salut *{{customerName}}*, le paiement de votre commande *{{order_ID}}* de *{{order_value}}* a été effectué avec succès.<br><br>Vous pouvez consulter votre commande ici 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>Discutez avec le service clientèle de {{store_name}}: {{WA_number}}",
  },
  INDONESIAN: {
    discountEnabledPercent:
      "Hai *{{customerName}}*, pembayaran untuk pesanan *{{order_ID}}* sebesar *{{order_value}}* berhasil.<br><br>Anda dapat melihat pesanan di sini 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. *Dapatkan {{discountPercent}}% OFF* pada pembelian anda berikutnya (kode kupon: {{discountCode}}) {{discountUrl}}.<br><br>Berbincang dengan layanan pelanggan di {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Hai *{{customerName}}*, pembayaran untuk pesanan *{{order_ID}}* sebesar *{{order_value}}* berhasil.<br><br>Anda dapat melihat pesanan di sini 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Gunakan voucher: *{{discountCode}}* pada pembelanjaan berikutnya {{discountUrl}}. <br><br>Berbincang dengan layanan pelanggan di {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Hai *{{customerName}}*, pembayaran untuk pesanan *{{order_ID}}* sebesar *{{order_value}}* berhasil.<br><br>Anda dapat melihat pesanan di sini 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>Berbincang dengan layanan pelanggan di {{store_name}}: {{WA_number}}",
  },
  GERMAN: {
    discountEnabledPercent:
      "Hallo *{{customerName}}*, die Zahlung für Ihre Bestellung *{{order_ID}}* von *{{order_value}}* ist erfolgreich.<br><br>Sie können Ihre Bestellung hier einsehen 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Bei Ihrem nächsten Bestellung *erhalten Sie {{discountPercent}}% Rabatt* (Gutscheincode: {{discountCode}}) {{discountUrl}}.<br><br>Chatten Sie mit dem Kundensupport von {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Hallo *{{customerName}}*, die Zahlung für Ihre Bestellung *{{order_ID}}* von *{{order_value}}* ist erfolgreich.<br><br>Sie können Ihre Bestellung hier einsehen 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Wenden Sie Gutscheincode: *{{discountCode}}* bei Ihrer nächsten Bestellung {{discountUrl}}.<br><br>Chatten Sie mit dem Kundensupport von {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Hallo *{{customerName}}*, die Zahlung für Ihre Bestellung *{{order_ID}}* von *{{order_value}}* ist erfolgreich.<br><br>Sie können Ihre Bestellung hier einsehen 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>Chatten Sie mit dem Kundensupport von {{store_name}}: {{WA_number}}",
  },
  ARABIC: {
    discountEnabledPercent:
      "مرحبًا *{{customerName}}* ، تم الدفع لطلبك *{{order_ID}}* من *{{order_value}}* بنجاح.<br><br>ا 👉 (معرف الطلب *{{order_ID}}* ) {{order_status_URL}}. *احصل على خصم {{discountPercent}}%* على عملية الشراء التالية (رمز الخصم: {{discountCode}}) {{discountUrl}}.<br><br>الدردشة مع دعم العملاء على {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "مرحبًا *{{customerName}}* ، تم الدفع لطلبك *{{order_ID}}* من *{{order_value}}* بنجاح.<br><br>ا 👉 (معرف الطلب *{{order_ID}}* ) {{order_status_URL}}. تطبيق رمز القسيمة: *{{discountCode}}* على عملية الشراء التالية {{discountUrl}}. <br><br>الدردشة مع دعم العملاء على {{store_name}}: {{WA_number}}",
    discountDisabled:
      "مرحبًا *{{customerName}}* ، تم الدفع لطلبك *{{order_ID}}* من *{{order_value}}* بنجاح.<br><br>ا 👉 (معرف الطلب *{{order_ID}}* ) {{order_status_URL}}.<br><br>الدردشة مع دعم العملاء على {{store_name}}: {{WA_number}}",
  },
  TURKISH: {
    discountEnabledPercent:
      "Merhaba *{{customerName}}*, *{{order_value}}* *{{order_ID}}* siparişinizin ödemesi başarılı oldu.<br><br>Siparişinizi buradan görüntüleyebilirsiniz 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Bir sonraki alışverişinizde geçerli *%{{discountPercent}}* (indirim kuponunuz: {{discountCode}}) {{discountUrl}}. <br><br>*{{store_name}}* numaralı telefondan müşteri desteğiyle sohbet edin: {{WA_number}}",
    discountEnabledCouponCode:
      "Merhaba *{{customerName}}*, *{{order_value}}* *{{order_ID}}* siparişinizin ödemesi başarılı oldu.<br><br>Siparişinizi buradan görüntüleyebilirsiniz 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Bir sonraki alışverişinizde geçerli (indirim kuponunuz: *{{discountCode}}*) {{discountUrl}}. <br><br>*{{store_name}}* numaralı telefondan müşteri desteğiyle sohbet edin: {{WA_number}}",
    discountDisabled:
      "Merhaba *{{customerName}}*, *{{order_value}}* *{{order_ID}}* siparişinizin ödemesi başarılı oldu.<br><br>Siparişinizi buradan görüntüleyebilirsiniz 👉 (Order ID *{{order_ID}}* .<br><br>*{{store_name}}* numaralı telefondan müşteri desteğiyle sohbet edin: {{WA_number}}",
  },
  HEBREW: {
    discountEnabledPercent:
      "הי *{{customerName}}*, התשלום להזמנה *{{order_ID}}* of *{{order_value}}* בוצע בהצלחה.<br><br>אתה יכול לצפות בפרטי הזמנה שלך כאן 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. *Get {{discountPercent}}% off* on your next order (coupon code: {{discountCode}}) {{discountUrl}}. <br><br>דבר בצ'ט עם שירות הלקוחות כאן {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "הי *{{customerName}}*, התשלום להזמנה *{{order_ID}}* of *{{order_value}}* בוצע בהצלחה.<br><br>אתה יכול לצפות בפרטי הזמנה שלך כאן 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. ממשו הקופון שלכם:*{{discountCode}}* וקבלו הנחה נוספת בקנייה חוזרת מהאתר {{discountUrl}}.<br><br>דבר בצ'ט עם שירות הלקוחות כאן {{store_name}}: {{WA_number}}",
    discountDisabled:
      "הי *{{customerName}}*, התשלום להזמנה *{{order_ID}}* of *{{order_value}}* בוצע בהצלחה.<br><br>אתה יכול לצפות בפרטי הזמנה שלך כאן 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>דבר בצ'ט עם שירות הלקוחות כאן {{store_name}}: {{WA_number}}",
  },
  DUTCH: {
    discountEnabledPercent:
      "Hallo *{{customerName}}*, de betaling voor uw bestelling *{{order_ID}}* van *{{order_value}}* is geslaagd.<br><br>U kunt uw bestelling hier bekijken 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. *Ontvang {{discountPercent}}% korting* op jouw volgende aankoop (coupon code: {{discountCode}}) {{discountUrl}}. <br><br>Chat met de klantenservice bij {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Hallo *{{customerName}}*, de betaling voor uw bestelling *{{order_ID}}* van *{{order_value}}* is geslaagd.<br><br>U kunt uw bestelling hier bekijken 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. Gebruik coupon code *{{discountCode}}* voor jouw volgende aankoop {{discountUrl}}.<br><br>Chat met de klantenservice bij {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Hallo *{{customerName}}*, de betaling voor uw bestelling *{{order_ID}}* van *{{order_value}}* is geslaagd.<br><br>U kunt uw bestelling hier bekijken 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>Chat met de klantenservice bij {{store_name}}: {{WA_number}}",
  },
  RUSSIAN: {
    discountEnabledPercent:
      "Здравствуйте *{{customerName}}*, оплата вашего заказа *{{order_ID}}* от *{{order_value}}* прошла успешно.<br><br>Вы можете просмотреть свой заказ здесь 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}. *Получите скидку {{discountPercent}}%* на следующий заказ (код купона: {{discountCode}}) {{discountUrl}}.<br><br>Поговорите со службой поддержки по телефону {{store_name}}: {{WA_number}}",
    discountEnabledCouponCode:
      "Здравствуйте *{{customerName}}*, оплата вашего заказа *{{order_ID}}* от *{{order_value}}* прошла успешно.<br><br>Вы можете просмотреть свой заказ здесь 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.Примените код купона: *{{discountCode}}* при следующем заказе {{discountUrl}}.<br><br>Поговорите со службой поддержки по телефону {{store_name}}: {{WA_number}}",
    discountDisabled:
      "Здравствуйте *{{customerName}}*, оплата вашего заказа *{{order_ID}}* от *{{order_value}}* прошла успешно.<br><br>Вы можете просмотреть свой заказ здесь 👉 (Order ID *{{order_ID}}* ) {{order_status_URL}}.<br><br>Поговорите со службой поддержки по телефону {{store_name}}: {{WA_number}}",
  },
};
export const ORDER_CANCELLATION_TEMPLATE_DICT = {
  NONE: {
    discountDisabled:
      "Hi *{{customer_name}}*, your order *{{order_ID}}* of *{{order_value}}* has been successfully cancelled. <br><br>For your reference here is the summary of your canceled order 👉 {{order_status_URL}}<br><br>For refund related inquiries, contact customer support at {{store_name}}: {{WA_number}}",
  },
  ENGLISH: {
    discountDisabled:
      "Hi *{{customer_name}}*, your order *{{order_ID}}* of *{{order_value}}* has been successfully cancelled. <br><br>For your reference here is the summary of your canceled order 👉 {{order_status_URL}}<br><br>For refund related inquiries, contact customer support at {{store_name}}: {{WA_number}}",
  },
  PORTUGUESE: {
    discountDisabled:
      "Olá *{{customerName}}*, seu pedido *{{order_ID}}* de *{{order_value}}* foi cancelado com sucesso. <br><br>Para sua referência, aqui está o resumo do seu pedido cancelado 👉 {{order_status_URL}}<br><br>Para consultas relacionadas a reembolsos, entre em contato com o suporte ao cliente em {{store_name}}: {{WA_number}}",
  },
  SPANISH: {
    discountDisabled:
      "Hola *{{customerName}}*, tu pedido *{{order_ID}}* de *{{order_value}}* ha sido cancelado con éxito. <br><br>Para su referencia, aquí está el resumen de su pedido cancelado 👉 {{order_status_URL}} <br><br>Para consultas relacionadas con el reembolso, ponte en contacto con nuestro servicio de atención al cliente en {{store_name}}: {{WA_number}}",
  },
  ITALIAN: {
    discountDisabled:
      "Ciao *{{customerName}}*, il tuo ordine *{{order_ID}}* di *{{order_value}}* è stato annullato con successo. <br><br>Per riferimento, ecco il riepilogo dell'ordine annullato 👉 {{order_status_URL}}<br><br>Per richieste relative al rimborso, contatta l'assistenza clienti all'indirizzo {{store_name}}: {{WA_number}}",
  },
  FRENCH: {
    discountDisabled:
      "Salut *{{customerName}}*, votre commande *{{order_ID}}* de *{{order_value}}* a été annulée avec succès. <br><br>Pour votre information, voici le récapitulatif de votre commande annulée 👉 {{order_status_URL}}<br><br>Pour toute question relative au remboursement, contactez le service clientèle à {{store_name}}: {{WA_number}}",
  },
  INDONESIAN: {
    discountDisabled:
      "Hai *{{customerName}}*, pesanan *{{order_ID}}* sebesar *{{order_value}}* berhasil dibatalkan. <br><br>Untuk referensi Anda, inilah ringkasan pesanan Anda yang dibatalkan 👉 {{order_status_URL}}<br><br>Untuk pertanyaan terkait pengembalian dana, hubungi layanan pelanggan di {{store_name}}: {{WA_number}}",
  },
  GERMAN: {
    discountDisabled:
      "Hallo *{{customerName}}*, Ihre Bestellung *{{order_ID}}* von *{{order_value}}* wurde erfolgreich storniert.<br><br>Zu Ihrer Information finden Sie hier die Zusammenfassung Ihrer stornierten Bestellung 👉 {{order_status_URL}}<br><br>Bei Fragen zur Rückerstattung wenden Sie sich bitte an den Kundensupport unter {{store_name}}: {{WA_number}}",
  },
  ARABIC: {
    discountDisabled:
      "مرحبًا *{{customerName}}* ، تم إلغاء طلبك *{{order_ID}}* من *{{order_value}}* بنجاح. <br><br>كمرجع لك ، يوجد هنا ملخص لطلبك الذي تم إلغاؤه 👉 {{order_status_URL}}<br><br>للاستفسارات المتعلقة باسترداد الأموال ، اتصل بدعم العملاء على {{store_name}}: {{WA_number}}",
  },
  TURKISH: {
    discountDisabled:
      "Merhaba *{{customerName}}*, *{{order_value}}* tutarındaki *{{order_ID}}* siparişiniz başarıyla iptal edildi. <br><br>İptal edilen 👉 {{order_status_URL}} siparişinizin özetini burada referans olarak bulabilirsiniz.<br><br>Geri ödemeyle ilgili sorunlar için {{store_name}}: {{WA_number}}",
  },
  HEBREW: {
    discountDisabled:
      "הי *{{customerName}}*,ההזמנה שלך *{{order_ID}}*של *{{order_value}}* בוטלה בהצלחה. <br><br>לעיונך הנה סיכום ההזמנה שבוטלה שלך 👉 {{order_status_URL}}<br><br>לענייני החזר כספי, נא ליצור קשר עם שירות הלקחות כאן {{store_name}}: {{WA_number}}",
  },
  DUTCH: {
    discountDisabled:
      "Hallo *{{customerName}}*, uw bestelling *{{order_ID}}* van *{{order_value}}* is met succes geannuleerd. <br><br>Ter referentie vindt u hier de samenvatting van uw geannuleerde bestelling 👉 {{order_status_URL}}<br><br>Voor vragen over terugbetalingen kunt u contact opnemen met de klantenservice op {{store_name}}: {{WA_number}}",
  },
  RUSSIAN: {
    discountDisabled:
      "Здравствуйте *{{customerName}}*! Ваш заказ *{{order_ID}}* из *{{order_value}}* успешно отменен. <br><br>Для справки, вот сводка вашего отмененного заказа 👉 {{order_status_URL}}<br><br>По вопросам, связанным с возмещением, обращайтесь в службу поддержки по телефону {{store_name}}: {{WA_number}}",
  },
};

export const avatarDictionary = {
  1: "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Male-1.png?770",
  2: "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Male-2.png?770",
  3: "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Female-1.png?770",
  4: "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Female-2.png?770",
};

export const DEVICE_TYPE_MOBILE_ONLY = 1;
export const DEVICE_TYPE_DESKTOP_ONLY = 2;
export const DEVICE_TYPE_MOBILE_AND_DESKTOP = 3;
// Images
// TODO Set up assets
// export const ASSETS_PATH = `${process.env.PUBLIC_URL}/assets/images/`;
// export const TOP_BAR_LOGO = 'superlemon-logo.png';
export const GREETING_TEMPLATE_DICT = {
  1: {
    id: 1,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_1.png?v=1586267123",
  },
  2: {
    id: 101,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_1.png?v=1586267351",
  },
  3: {
    id: 201,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-1.png?v=1587009125",
  },
  4: {
    id: 2,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_2.png?v=1586267123",
  },
  5: {
    id: 102,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_2.png?v=1586267351",
  },
  6: {
    id: 202,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-2.png?v=1587009125",
  },
  7: {
    id: 3,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_3.png?v=1586267351",
  },
  8: {
    id: 103,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_3.png?v=1586267351",
  },
  9: {
    id: 203,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-3.png?v=1587009125",
  },
  10: {
    id: 4,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_4.png?v=1586267351",
  },
  11: {
    id: 104,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_4.png?v=1586267352",
  },
  12: {
    id: 204,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-4.png?v=1587009125",
  },
  13: {
    id: 5,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_5.png?v=1586267351",
  },
  14: {
    id: 105,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_5.png?v=1586267352",
  },
  15: {
    id: 205,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-5.png?v=1587009125",
  },
  16: {
    id: 6,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_6.png?v=1586267351",
  },
  17: {
    id: 106,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_6.png?v=1586267351",
  },
  18: {
    id: 206,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-6.png?v=1587009125",
  },
  19: {
    id: 7,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_7.png?v=1586267351",
  },
  20: {
    id: 107,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_7.png?v=1586267352",
  },
  21: {
    id: 207,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-7.png?v=1587009125",
  },
  22: {
    id: 8,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_8.png?v=1586267351",
  },
  23: {
    id: 108,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_8.png?v=1586267351",
  },
  24: {
    id: 208,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-8.png?v=1587009125",
  },
  25: {
    id: 9,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_9.png?v=1586267351",
  },
  26: {
    id: 109,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_9.png?v=1586267351",
  },
  27: {
    id: 209,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-9.png?v=1587009125",
  },
  28: {
    id: 10,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_10.png?v=1586267351",
  },
  29: {
    id: 110,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_10.png?v=1586267351",
  },
  30: {
    id: 210,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-10.png?v=1587009125",
  },
  31: {
    id: 301,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_1.png?v=1586267123",
    is_customizable: true,
    style_type: "simple",
    gradient_type: "horizontal", // horizontal or vertical
    is_solid_background: false,
    bg_color_1: "#20802C",
    bg_color_2: "#30BF42",
  },
  32: {
    id: 302,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_1.png?v=1586267351",
    is_customizable: true,
    style_type: "wavy",
    gradient_type: "horizontal", // horizontal or vertical
    is_solid_background: false,
    bg_color_1: "#20802C",
    bg_color_2: "#30BF42",
  },
  33: {
    id: 303,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/Greetings_Widget-Intercom-1.png?v=1587009125",
    is_customizable: true,
    style_type: "intercom",
    gradient_type: "vertical", // horizontal or vertical
    is_solid_background: false,
    bg_color_1: "#20802C",
    bg_color_2: "#30BF42",
  },
  34: {
    id: 11,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_11.png?v=1609312610",
  },
  35: {
    id: 111,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_11.png?v=1609312610",
  },
  36: {
    id: 211,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_11.png?v=1609312610",
  },
  37: {
    id: 12,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_12.png?v=1609312610",
  },
  38: {
    id: 112,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_12.png?v=1609312610",
  },
  39: {
    id: 212,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_12.png?v=1609312610",
  },
  40: {
    id: 13,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_13.png?v=1609312610",
  },
  41: {
    id: 113,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_13.png?v=1609312610",
  },
  42: {
    id: 213,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_13.png?v=1609312610",
  },
  43: {
    id: 14,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_14.png?v=1609312608",
  },
  44: {
    id: 114,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_14.png?v=1609312609",
  },
  45: {
    id: 214,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_14.png?v=1609312609",
  },
  46: {
    id: 15,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_15.png?v=1609312610",
  },
  47: {
    id: 115,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_15.png?v=1609312610",
  },
  48: {
    id: 215,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_15.png?v=1609312610",
  },
  49: {
    id: 16,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_16.png?v=1609312610",
  },
  50: {
    id: 116,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_16.png?v=1609312610",
  },
  51: {
    id: 216,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_16.png?v=1609312610",
  },
  52: {
    id: 17,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_17.png?v=1609312610",
  },
  53: {
    id: 117,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_17.png?v=1609312610",
  },
  54: {
    id: 217,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_17.png?v=1609312610",
  },
  55: {
    id: 18,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_18.png?v=1609312610",
  },
  56: {
    id: 118,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_18.png?v=1609312608",
  },
  57: {
    id: 218,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_18.png?v=1609312608",
  },
  58: {
    id: 19,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_19.png?v=1609312610",
  },
  59: {
    id: 119,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Wavy_19.png?v=1609312610",
  },
  60: {
    id: 219,
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Greetings_Widget_Intercom_19.png?v=1609312610",
  },
};

export const GREETING_WIDGET_CONST = {
  is_multi_Agent_enabled: true,
  greeting_template: "",
  greeting_template_bg_color_1: "",
  greeting_template_bg_color_2: "",
  greeting_template_desc_text_color: "",
  greeting_template_head_text_color: "",
  is_greeting_template_color_custom: false,
  is_greeting_template_solid_background: false,
  multi_agent_msg: "",
  multi_agent_title: "",
  offline_agent_msg: "",
  offline_store_msg: "",
  should_shuffle_agents: false,
};

export const SHARE_BTN_TEMPLATE_DICT = {
  1: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-1.svg?v=1583928372",
  },
  2: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-2.svg?v=1583928372",
  },
  3: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-3.svg?v=1583928372",
  },
  4: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-4.svg?v=1583928372",
  },
  5: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-5.svg?v=1583928372",
  },
  6: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-6.svg?v=1583928372",
  },
  7: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-7.svg?v=1583928372",
  },
  8: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-8.svg?v=1583928372",
  },
  9: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-9.svg?v=1583928372",
  },
  10: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-10.svg?v=1583928372",
  },
  11: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-11.svg?v=1583928372",
  },
  12: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-12.svg?v=1583928372",
  },
  13: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-13.svg?v=1583928372",
  },
  14: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-14.svg?v=1583928372",
  },
  15: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-15.svg?v=1583928372",
  },
  16: {
    preview_url:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/share-btn-2.svg?v=1583928372",
    is_share_btn_color_custom: true,
    is_share_btn_solid_background: true,
    share_btn_bg_color_1: "#22ce5b",
    share_btn_bg_color_2: "#30BF42",
    share_btn_icon_color: "#ffffff",
    share_btn_text_color: "#ffffff",
  },
};

export const COD_PENDING = "COD_PENDING";
export const COD_CONFIRMED = "COD_CONFIRMED";
export const COD_CANCELLED = "COD_CANCELLED";
export const COD_NO_RESPONSE = "COD_NO_RESPONSE";

export const COD_LANGUAGES = {
  LANGUAGE_ENG: {
    order_details: "ORDER DETAILS",
    payable_amount: "Payable amount (inclusive of shipping & taxes)",
    shipping_details: "SHIPPING DETAILS",
    confirm_order: "Confirm Order",
    cancel_order: "Cancel Order",
    confirmed_message: "You have successfully confirmed your order",
    cancelled_header: "You have successfully cancelled your order",
    cancelled_message:
      "Cancelled by mistake? Contact the store using one of the links below for help.",
    no_response_header:
      "This page is currently disabled due to no response for 48 hours",
    no_response_message:
      "Since there was no response from you about the order for 48 hours, this page is currently disabled. If you would like to confirm your order or have any other questions regarding your order, please contact the store from the options provided below.",
    need_help: "Need help with your order? Contact {{shop_name}}",
    whatsapp_help_message: "I need help with COD order",
    pending_order_header: "Order is pending",
    pending_order_message: "Order is pending",
  },
  LANGUAGE_TURK: {
    order_details: "SİPARİŞ DETAYLARI",
    payable_amount: "Ödenecek tutar (nakliye ve vergiler dahil)",
    shipping_details: "NAKLİYE AYRINTILARI",
    confirm_order: "Sipariş onaylamak",
    cancel_order: "Siparişi iptal et",
    confirmed_message: "Siparişinizi başarıyla onayladınız",
    cancelled_header: "Siparişinizi başarıyla iptal ettiniz",
    cancelled_message:
      "Yanlışlıkla iptal mi edildi? Yardım için aşağıdaki bağlantılardan birini kullanarak mağazayla iletişime geçin.",
    no_response_header:
      "48 saat boyunca yanıt verilmediği için bu sayfa şu anda devre dışı.",
    no_response_message:
      "48 saattir siparişle ilgili herhangi bir geri dönüş yapılmadığından bu sayfa şu anda devre dışı bırakılmıştır. Siparişinizi onaylamak isterseniz veya siparişinizle ilgili başka sorularınız varsa, lütfen aşağıdaki seçeneklerden mağazayla iletişime geçin.",
    need_help:
      "Siparişinizle ilgili yardıma mı ihtiyacınız var? {{shop_name}} ile iletişime geçin",
    whatsapp_help_message: "COD siparişiyle ilgili yardıma ihtiyacım var",
    pending_order_header: "sipariş beklemede",
    pending_order_message: "sipariş beklemede",
  },
  LANGUAGE_DUTCH: {
    order_details: "BESTEL DETAILS",
    payable_amount:
      "Te betalen bedrag (inclusief verzendkosten en belastingen)",
    shipping_details: "VERZENDGEGEVENS",
    confirm_order: "Bevestig bestelling",
    cancel_order: "Annuleer bestelling",
    confirmed_message: "Je hebt je bestelling succesvol bevestigd",
    cancelled_header: "Je hebt je bestelling succesvol geannuleerd",
    cancelled_message:
      "Per ongeluk geannuleerd? Neem voor hulp contact op met de winkel via een van de onderstaande links.",
    no_response_header:
      "Deze pagina is momenteel uitgeschakeld wegens geen reactie gedurende 48 uur",
    no_response_message:
      "Aangezien er 48 uur lang geen reactie van u was over de bestelling, is deze pagina momenteel uitgeschakeld. Als je je bestelling wilt bevestigen of andere vragen hebt over je bestelling, neem dan contact op met de winkel via de onderstaande opties.",
    need_help:
      "Hulp nodig bij je bestelling? Contact opnemen met {{shop_name}}",
    whatsapp_help_message: "Ik heb hulp nodig bij het bestellen onder rembours",
    pending_order_header: "Bestelling is in behandeling",
    pending_order_message: "Bestelling is in behandeling",
  },
  LANGUAGE_RUSSIAN: {
    order_details: "ИНФОРМАЦИЯ ДЛЯ ЗАКАЗА",
    payable_amount: "Сумма к оплате (включая доставку и налоги)",
    shipping_details: "ОТГРУЗОЧНЫЕ РЕКВИЗИТЫ",
    confirm_order: "Подтвердите заказ",
    cancel_order: "Отменить заказ",
    confirmed_message: "Вы успешно подтвердили свой заказ",
    cancelled_header: "Вы успешно отменили свой заказ",
    cancelled_message:
      "Отменено по ошибке? Свяжитесь с магазином, используя одну из ссылок ниже для получения помощи.",
    no_response_header:
      "Эта страница в настоящее время отключена из-за отсутствия ответа в течение 48 часов.",
    no_response_message:
      "Поскольку в течение 48 часов от вас не было ответа о заказе, эта страница в настоящее время отключена. Если вы хотите подтвердить свой заказ или у вас есть другие вопросы относительно вашего заказа, пожалуйста, свяжитесь с магазином одним из вариантов, указанных ниже.",
    need_help: "Нужна помощь с вашим заказом? Свяжитесь с {{shop_name}}",
    whatsapp_help_message: "Мне нужна помощь с заказом наложенным платежом",
    pending_order_header: "Заказ ожидает",
    pending_order_message: "Заказ ожидает",
  },
  LANGUAGE_GERM: {
    order_details: "Bestellung steht an",
    payable_amount: "Zahlbarer Betrag (inklusive Versand & Steuern)",
    shipping_details: "VERSANDDETAILS",
    confirm_order: "Bestellung bestätigen",
    cancel_order: "Bestellung stornieren",
    confirmed_message: "Sie haben Ihre Bestellung erfolgreich bestätigt",
    cancelled_header: "Sie haben Ihre Bestellung erfolgreich storniert",
    cancelled_message:
      "Versehentlich storniert? Wenden Sie sich über einen der unten stehenden Links an das Geschäft, um Hilfe zu erhalten.",
    no_response_header:
      "Diese Seite ist derzeit deaktiviert, da seit 48 Stunden keine Antwort erfolgt ist",
    no_response_message:
      "Da 48 Stunden lang keine Antwort von Ihnen auf die Bestellung kam, ist diese Seite derzeit deaktiviert. Wenn Sie Ihre Bestellung bestätigen möchten oder weitere Fragen zu Ihrer Bestellung haben, wenden Sie sich bitte über die unten angegebenen Optionen an das Geschäft.",
    need_help:
      "Benötigen Sie Hilfe bei Ihrer Bestellung? {{shop_name}} kontaktieren",
    whatsapp_help_message: "Ich brauche Hilfe bei der Nachnahmebestellung",
    pending_order_header: "Bestellung steht an",
    pending_order_message: "Bestellung steht an",
  },
  LANGUAGE_INDO: {
    order_details: "DETAIL PESANAN",
    payable_amount: "Jumlah yang harus dibayar (termasuk pengiriman & pajak)",
    shipping_details: "RINCIAN PENGIRIMAN",
    confirm_order: "Konfirmasi pemesanan",
    cancel_order: "Batalkan pesanan",
    confirmed_message: "Anda telah berhasil mengkonfirmasi pesanan Anda",
    cancelled_header: "Anda telah berhasil membatalkan pesanan Anda",
    cancelled_message:
      "Dibatalkan karena kesalahan? Hubungi toko menggunakan salah satu tautan di bawah ini untuk mendapatkan bantuan.",
    no_response_header:
      "Halaman ini saat ini dinonaktifkan karena tidak ada tanggapan selama 48 jam",
    no_response_message:
      "Karena tidak ada tanggapan dari Anda tentang pesanan selama 48 jam, halaman ini saat ini dinonaktifkan. Jika Anda ingin mengonfirmasi pesanan Anda atau memiliki pertanyaan lain terkait pesanan Anda, silakan hubungi toko dari opsi yang tersedia di bawah ini.",
    need_help: "Butuh bantuan dengan pesanan Anda? Hubungi {{shop_name}}",
    whatsapp_help_message: "Saya butuh bantuan untuk pemesanan COD",
    pending_order_header: "Pesanan tertunda",
    pending_order_message: "Pesanan tertunda",
  },
  LANGUAGE_FREN: {
    order_details: "DÉTAILS DE LA COMMANDE",
    payable_amount: "Montant à payer (frais de port et taxes compris)",
    shipping_details: "LES DÉTAILS D'EXPÉDITION",
    confirm_order: "Confirmer la commande",
    cancel_order: "annuler la commande",
    confirmed_message: "Vous avez confirmé votre commande avec succès",
    cancelled_header: "Vous avez annulé votre commande avec succès",
    cancelled_message:
      "Annulé par erreur ? Contactez le magasin en utilisant l'un des liens ci-dessous pour obtenir de l'aide.",
    no_response_header:
      "Cette page est actuellement désactivée car aucune réponse pendant 48 heures",
    no_response_message:
      "Comme il n'y a pas eu de réponse de votre part concernant la commande pendant 48 heures, cette page est actuellement désactivée. Si vous souhaitez confirmer votre commande ou si vous avez d'autres questions concernant votre commande, veuillez contacter le magasin à partir des options fournies ci-dessous.",
    need_help:
      "Avez-vous besoin d'aide avec votre commande? Contactez {{shop_name}}",
    whatsapp_help_message: "J'ai besoin d'aide pour une commande COD",
    pending_order_header: "La commande est en attente",
    pending_order_message: "La commande est en attente",
  },
  LANGUAGE_PORT: {
    order_details: "DETALHES DO PEDIDO",
    payable_amount: "Valor a pagar (incluindo frete e impostos)",
    shipping_details: "DETALHES DE ENVIO",
    confirm_order: "Confirmar pedido",
    cancel_order: "Cancelar pedido",
    confirmed_message: "Você confirmou seu pedido com sucesso",
    cancelled_header: "Você cancelou seu pedido com sucesso",
    cancelled_message:
      "Cancelado por engano? Entre em contato com a loja usando um dos links abaixo para obter ajuda.",
    no_response_header:
      "Esta página está atualmente desativada devido a nenhuma resposta por 48 horas",
    no_response_message:
      "Como não houve resposta sua sobre o pedido por 48 horas, esta página está desativada no momento. Se você quiser confirmar seu pedido ou tiver outras dúvidas sobre seu pedido, entre em contato com a loja a partir das opções fornecidas abaixo.",
    need_help:
      "Precisa de ajuda com o seu pedido? Entre em contato com {{shop_name}}",
    whatsapp_help_message: "Preciso de ajuda com pedido de COD",
    pending_order_header: "O pedido está pendente",
    pending_order_message: "O pedido está pendente",
  },
  LANGUAGE_SPAN: {
    order_details: "DETALLES DE PEDIDO",
    payable_amount: "Importe a pagar (incluidos gastos de envío e impuestos)",
    shipping_details: "DETALLES DE ENVÍO",
    confirm_order: "Confirmar pedido",
    cancel_order: "Cancelar orden",
    confirmed_message: "Has confirmado con éxito tu pedido",
    cancelled_header: "Has cancelado con éxito tu pedido",
    cancelled_message:
      "Cancelado por error? Póngase en contacto con la tienda utilizando uno de los enlaces a continuación para obtener ayuda.",
    no_response_header:
      "Esta página está actualmente deshabilitada debido a que no hubo respuesta durante 48 horas",
    no_response_message:
      "Como no hubo respuesta de usted sobre el pedido durante 48 horas, esta página está actualmente deshabilitada. Si desea confirmar su pedido o tiene alguna otra pregunta con respecto a su pedido, comuníquese con la tienda a través de las opciones que se proporcionan a continuación.",
    need_help: "¿Necesita ayuda con su pedido? Contacto {{shop_name}}",
    whatsapp_help_message: "Necesito ayuda con el pedido contra reembolso",
    pending_order_header: "El pedido está pendiente",
    pending_order_message: "El pedido está pendiente",
  },
  LANGUAGE_ITAL: {
    order_details: "DETTAGLI DELL'ORDINE",
    payable_amount:
      "Importo da pagare (comprensivo di spese di spedizione e tasse)",
    shipping_details: "DETTAGLI DI SPEDIZIONE",
    confirm_order: "Confermare l'ordine",
    cancel_order: "Annulla Ordine",
    confirmed_message: "Hai confermato con successo il tuo ordine",
    cancelled_header: "Hai annullato con successo il tuo ordine",
    cancelled_message:
      "Annullato per errore? Contatta il negozio utilizzando uno dei link sottostanti per assistenza.",
    no_response_header:
      "Questa pagina è attualmente disabilitata a causa di nessuna risposta per 48 ore",
    no_response_message:
      "Poiché non hai ricevuto risposta da parte tua sull'ordine per 48 ore, questa pagina è attualmente disabilitata. Se desideri confermare il tuo ordine o hai altre domande sul tuo ordine, contatta il negozio tra le opzioni fornite di seguito.",
    need_help: "Hai bisogno di aiuto con il tuo ordine? Contatta {{shop_name}}",
    whatsapp_help_message: "Ho bisogno di aiuto con l'ordine in contrassegno",
    pending_order_header: "L'ordine è in sospeso",
    pending_order_message: "L'ordine è in sospeso",
  },
  LANGUAGE_ARAB: {
    order_details: "تفاصيل الطلب",
    payable_amount: "المبلغ المستحق (شاملاً الشحن والضرائب)",
    shipping_details: "تفاصيل الشحن",
    confirm_order: "أكد الطلب",
    cancel_order: "الغاء الطلب",
    confirmed_message: "لقد أكدت طلبك بنجاح",
    cancelled_header: "لقد ألغيت طلبك بنجاح",
    cancelled_message:
      "ألغيت بالخطأ؟ اتصل بالمتجر باستخدام أحد الروابط أدناه للمساعدة.",
    no_response_header:
      "هذه الصفحة معطلة حاليًا بسبب عدم الاستجابة لمدة 48 ساعةم إلغاء طلبك تلقائيًا لعدم الاستجابة",
    no_response_message:
      "نظرًا لعدم وجود رد منك بشأن الطلب لمدة 48 ساعة ، فإن هذه الصفحة معطلة حاليًا. إذا كنت ترغب في تأكيد طلبك أو لديك أي أسئلة أخرى تتعلق بطلبك ، يرجى الاتصال بالمتجر من الخيارات المقدمة أدناه.",
    need_help: "بحاجة الى مساعدة مع طلبك؟ اتصل بـ {{shop_name}}",
    whatsapp_help_message: "أحتاج إلى مساعدة بشأن الدفع عند الطلب",
    language_direction: "rtl",
    pending_order_header: "الطلب معلق",
    pending_order_message: "الطلب معلق",
  },
  LANGUAGE_HEBR: {
    order_details: "פרטי הזמנה",
    payable_amount: "סכום לתשלום (כולל משלוח ומיסים)",
    shipping_details: "פרטי משלוח",
    confirm_order: "אשר הזמנה",
    cancel_order: "בטל הזמנה",
    confirmed_message: "אישרת בהזמנתך בהצלחה",
    cancelled_header: "ביטלת בהצלחה את ההזמנה שלך",
    cancelled_message:
      "מבוטל בטעות? צרו קשר עם החנות באמצעות אחד מהקישורים הבאים לקבלת עזרה.",
    no_response_header: "עמוד זה מושבת כרגע בגלל תגובה לא במשך 48 שעות",
    no_response_message:
      "מכיוון שלא הייתה תגובה ממך לגבי ההזמנה במשך 48 שעות, עמוד זה מושבת כרגע. אם ברצונך לאשר את ההזמנה שלך או שיש לך שאלות נוספות בנוגע להזמנתך, אנא צור קשר עם החנות מהאפשרויות המופיעות בהמשך.",
    need_help: "זקוק לעזרה בהזמנתך? צור קשר עם {{shop_name}}",
    whatsapp_help_message: "אני זקוק לעזרה במזומן בהזמנת משלוח",
    language_direction: "rtl",
    pending_order_header: "ההזמנה ממתינה",
    pending_order_message: "ההזמנה ממתינה",
  },
};
// export const DOWNTIME_MESSAGE = 'We would be facing downtime from 12pm to 3pm IST | 3:30am to 6:30am BRT today, where messages might not go, due to maintenance activity. Sorry for the inconvenience caused.';
export const DOWNTIME_MESSAGE =
  "We are facing issue in sending Automated messages for abandoned cart template messages. We are working on resolving this. Apologies for the inconvenience caused. Appreciate your patience.";
export const IS_DOWNTIME = false;
export const PRICING_REDUCED_MESSAGE =
  "We heard you! SuperLemon Pro & Ultimate plan prices slashed! Pro plan is now $7.99/month and Ultimate plan is now $9.99/month. To avail the new pricing plans, please switch to Free plan once and back to Pro/Ultimate plan again.";
export const IS_CHECKOUT_OPTIN_WARNING_MESSAGE_VISIBLE = false;
export const IS_PRICING_REDUCED = false;

export const CONST_CHAT_BUTTON_TEMPLATES = [
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-theme-cta-new",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/new_logo_1_0226a498-7303-4b41-a78c-cc5d9c1db062.png?463",
    id: 1,
    name: "New logo big",
    ordering: 1,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_1.png?v=1586260188",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-theme-cta-new",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/new_logo_1_0226a498-7303-4b41-a78c-cc5d9c1db062.png?463",
    id: 2,
    name: "New logo small",
    ordering: 2,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_2.png?v=1586260188",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/icon_only_old.png?465",
    id: 3,
    name: "Icon only new",
    ordering: 3,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_3.png?v=1586260188",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-theme-cta-new-inverted",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/image_2.6.png?463",
    id: 4,
    name: "New logo Inverted big",
    ordering: 5,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_5.png?v=1586260188",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-theme-cta-new-inverted",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/image_2.6.png?463",
    id: 5,
    name: "New logo inverted small",
    ordering: 6,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_6.png?v=1586260188",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/icon_only_new_inverted.png?469",
    id: 6,
    name: "Icon only new inverted",
    ordering: 7,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_7.png?v=1586260188",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-theme-cta-old",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/image_6.4.png?816",
    id: 7,
    name: "Old logo big",
    ordering: 29,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_29.png?v=1586264843",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-theme-cta-old",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/image_6.4.png?816",
    id: 8,
    name: "Old logo small",
    ordering: 30,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_30.png?v=1586264843",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/image_6.4.png?819",
    id: 9,
    name: "Old icon only",
    ordering: 31,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_31.png?v=1586264843",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-theme-cta-black-inverted",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/113.png?819",
    id: 10,
    name: "Black inverted big",
    ordering: 57,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_57.png?v=1586265224",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-theme-cta-black-inverted",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/113.png?819",
    id: 11,
    name: "Black inverted small",
    ordering: 58,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_58.png?v=1586265224",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/icon_only_black_inverted.png?822",
    id: 12,
    name: "Black inverted icon only",
    ordering: 59,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_59.png?v=1586265224",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-theme-cta-black",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/112_2.png?825",
    id: 13,
    name: "Black logo big",
    ordering: 21,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_21.png?v=1586264843",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-theme-cta-black",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/112_2.png?825",
    id: 14,
    name: "Black logo small",
    ordering: 22,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_22.png?v=1586264843",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group_1.png?827",
    id: 15,
    name: "Black logo icon only",
    ordering: 23,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_23.png?v=1586264843",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta-rounded wa-chat-btn-container-size-rounded-big wa-chat-btn-theme-cta-new",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/new_logo_1_0226a498-7303-4b41-a78c-cc5d9c1db062.png?463",
    id: 16,
    name: "rounded big new",
    ordering: 61,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_61.png?v=1586265224",
    solidBg: true,
    templateType: 5,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta-rounded wa-chat-btn-container-size-rounded-big wa-chat-btn-theme-cta-new-inverted",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/image_2.6.png?463",
    id: 17,
    name: "rounded big inverted",
    ordering: 62,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_62.png?v=1586265224",
    solidBg: true,
    templateType: 5,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta-rounded wa-chat-btn-container-size-rounded-big wa-chat-btn-theme-cta-old",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/image_6.4.png?816",
    id: 18,
    name: "rounded big old",
    ordering: 63,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_63.png?v=1586265225",
    solidBg: true,
    templateType: 5,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta-rounded wa-chat-btn-container-size-rounded-big wa-chat-btn-theme-cta-black-inverted",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/113.png?819",
    id: 19,
    name: "rounded big black inverted",
    ordering: 65,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_65.png?v=1586265224",
    solidBg: true,
    templateType: 5,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta-rounded wa-chat-btn-container-size-rounded-big wa-chat-btn-theme-cta-black",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/112_2.png?825",
    id: 20,
    name: "rounded big black",
    ordering: 64,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_64.png?v=1586265225",
    solidBg: true,
    templateType: 5,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-1.svg?v=1584017541",
    id: 21,
    name: "icon with text",
    ordering: 4,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_4.png?v=1586260189",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-2.svg?v=1584017541",
    id: 22,
    name: "icon with text",
    ordering: 8,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_8.png?v=1586260188",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p1-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 23,
    name: "icon with text",
    ordering: 9,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_9.png?v=1586264290",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p1-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 24,
    name: "icon with text",
    ordering: 10,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_10.png?v=1586264290",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-3.svg?v=1584017540",
    id: 25,
    name: "icon with text",
    ordering: 11,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_11.png?v=1586264289",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-3.svg?v=1584017540",
    id: 26,
    name: "icon with text",
    ordering: 12,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_12.png?v=1586264289",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p2-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 27,
    name: "icon with text",
    ordering: 13,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_13.png?v=1586264290",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p2-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 28,
    name: "icon with text",
    ordering: 14,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_14.png?v=1586264290",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-4.svg?v=1584017540",
    id: 29,
    name: "icon with text",
    ordering: 15,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_15.png?v=1586264290",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-4.svg?v=1584017540",
    id: 30,
    name: "icon with text",
    ordering: 16,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_16.png?v=1586264290",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p3-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 31,
    name: "icon with text",
    ordering: 17,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_17.png?v=1586264290",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p3-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 32,
    name: "icon with text",
    ordering: 18,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_18.png?v=1586264290",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-5.svg?v=1584017540",
    id: 33,
    name: "icon with text",
    ordering: 19,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_19.png?v=1586264289",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-5.svg?v=1584017540",
    id: 34,
    name: "icon with text",
    ordering: 20,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_20.png?v=1586264290",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-6.svg?v=1584017540",
    id: 35,
    name: "icon with text",
    ordering: 24,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_24.png?v=1586264844",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p4-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 36,
    name: "icon with text",
    ordering: 25,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_25.png?v=1586264845",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p4-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 37,
    name: "icon with text",
    ordering: 26,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_26.png?v=1586264845",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-7.svg?v=1584017537",
    id: 38,
    name: "icon with text",
    ordering: 27,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_27.png?v=1586264843",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-7.svg?v=1584017537",
    id: 39,
    name: "icon with text",
    ordering: 28,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_28.png?v=1586264845",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only no-radius",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-8.svg?v=1584017550",
    id: 40,
    name: "icon with text",
    ordering: 32,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_32.png?v=1586264845",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p5-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 41,
    name: "icon with text",
    ordering: 33,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_33.png?v=1586264845",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p5-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 42,
    name: "icon with text",
    ordering: 34,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_34.png?v=1586264844",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-9.svg?v=1584017533",
    id: 43,
    name: "icon with text",
    ordering: 35,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_35.png?v=1586264843",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-9.svg?v=1584017533",
    id: 44,
    name: "icon with text",
    ordering: 36,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_36.png?v=1586264844",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p6-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 45,
    name: "icon with text",
    ordering: 37,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_37.png?v=1586264857",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p6-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 46,
    name: "icon with text",
    ordering: 38,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_38.png?v=1586264844",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-10.svg?v=1584017533",
    id: 47,
    name: "icon with text",
    ordering: 39,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_39.png?v=1586264843",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-10.svg?v=1584017533",
    id: 48,
    name: "icon with text",
    ordering: 40,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_40.png?v=1586264843",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p7-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 49,
    name: "icon with text",
    ordering: 41,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_41.png?v=1586265224",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p7-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 50,
    name: "icon with text",
    ordering: 42,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_42.png?v=1586265224",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-11.svg?v=1584017532",
    id: 51,
    name: "icon with text",
    ordering: 43,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_43.png?v=1586265224",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-11.svg?v=1584017532",
    id: 52,
    name: "icon with text",
    ordering: 44,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_44.png?v=1586265224",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p8-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 53,
    name: "icon with text",
    ordering: 45,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_45.png?v=1586265224",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p8-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 54,
    name: "icon with text",
    ordering: 46,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_46.png?v=1586265225",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-12.svg?v=1584017532",
    id: 55,
    name: "icon with text",
    ordering: 47,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_47.png?v=1586265224",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-12.svg?v=1584017532",
    id: 56,
    name: "icon with text",
    ordering: 48,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_48.png?v=1586265224",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p9-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 57,
    name: "icon with text",
    ordering: 49,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_49.png?v=1586265225",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p9-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 58,
    name: "icon with text",
    ordering: 50,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_50.png?v=1586265224",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-13.svg?v=1584017532",
    id: 59,
    name: "icon with text",
    ordering: 51,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_51.png?v=1586265224",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-13.svg?v=1584017532",
    id: 60,
    name: "icon with text",
    ordering: 52,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_52.png?v=1586265224",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-p10-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 61,
    name: "icon with text",
    ordering: 53,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_53.png?v=1586265224",
    solidBg: true,
    templateType: 1,
  },
  {
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-p10-gradient",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Group.svg?v=1583716483",
    id: 62,
    name: "icon with text",
    ordering: 54,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_54.png?v=1586265224",
    solidBg: true,
    templateType: 2,
  },
  {
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-14.svg?v=1584017531",
    id: 63,
    name: "icon with text",
    ordering: 55,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_55.png?v=1586265224",
    solidBg: true,
    templateType: 3,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-14.svg?v=1584017531",
    id: 64,
    name: "icon with text",
    ordering: 56,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_56.png?v=1586265224",
    solidBg: true,
    templateType: 4,
  },
  {
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: false,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ic-15.svg?v=1584017531",
    id: 65,
    name: "icon with text",
    ordering: 60,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ordering_60.png?v=1586265224",
    solidBg: true,
    templateType: 4,
  },
  {
    bgColor1: "#22ce5a",
    buttonCustom:
      "btn_custom_class: wa-chat-btn-base-cta wa-chat-btn-container-size-big wa-chat-btn-theme-cta-new-inverted",
    ctaEnabled: true,
    custom: true,
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa-icon.svg?v=1586940530",
    id: 66,
    name: "Big custom",
    ordering: 101,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa-icon.svg?v=1586940530",
    solidBg: true,
    templateType: 1,
    textColor: "#ffffff",
  },
  {
    bgColor1: "#22ce5a",
    buttonCustom: "wa-chat-btn-base-cta wa-chat-btn-container-size-small",
    ctaEnabled: true,
    custom: true,
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa-icon.svg?v=1586940530",
    id: 67,
    name: "small custom",
    ordering: 102,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa-icon.svg?v=1586940530",
    solidBg: true,
    templateType: 2,
    textColor: "#ffffff",
  },
  {
    bgColor1: "#22ce5a",
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: true,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa-icon.svg?v=1586940530",
    id: 68,
    name: "Icon only",
    ordering: 103,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa-icon.svg?v=1586940530",
    solidBg: true,
    templateType: 3,
    textColor: "#ffffff",
  },
  {
    bgColor1: "#22ce5a",
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-white",
    ctaEnabled: true,
    custom: true,
    iconCustom: "wa-chat-btn-icon-image-only",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa-icon.svg?v=1586940530",
    id: 69,
    name: "icon with tex",
    ordering: 104,
    premium: true,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0265/2572/8803/files/wa-icon.svg?v=1586940530",
    solidBg: true,
    templateType: 4,
    textColor: "#111111",
  },
  {
    bgColor1: "#E9EFF5",
    buttonCustom: "wa-chat-btn-base-cta wa-chat-btn-container-size-big",
    ctaEnabled: true,
    custom: true,
    iconColor: "#46931B",
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/1C_Custom.svg?v=1616182726",
    id: 106,
    name: "1A Custom",
    ordering: 106,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/1A.png?v=1608206068",
    solidBg: true,
    templateType: 1,
    textColor: "#707070",
  },
  {
    bgColor1: "#E9EFF5",
    buttonCustom:
      "wa-chat-btn-base-cta wa-chat-btn-container-size-small wa-chat-btn-theme-cta-grey",
    ctaEnabled: true,
    custom: true,
    iconColor: "#46931B",
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/1C_Custom.svg?v=1616182726",
    id: 107,
    name: "1B Custom",
    ordering: 107,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/1B.png?v=1608206322",
    solidBg: true,
    templateType: 2,
    textColor: "#707070",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: true,
    iconColor: "#46931B",
    iconCustom: "wa-chat-btn-icon-image-only wa-custom-icon w-3",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/1C_Custom.svg?v=1616182726",
    id: 108,
    name: "1C Custom",
    ordering: 108,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/1C.svg?v=1608705804",
    solidBg: true,
    templateType: 3,
    textColor: "#707070",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-grey",
    ctaEnabled: true,
    custom: true,
    iconColor: "#46931B",
    iconCustom: "wa-chat-btn-icon-image-only wa-custom-icon w-3",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/1C_Custom.svg?v=1616182726",
    id: 109,
    name: "1D Custom",
    ordering: 109,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/1D.png?v=1608206322",
    solidBg: true,
    templateType: 4,
    textColor: "#707070",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-cta wa-chat-btn-container-size-big",
    ctaEnabled: true,
    custom: true,
    iconColor: "#ED731D",
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Set-3-Icon.png?v=1608718461",
    id: 110,
    name: "3A Custom",
    ordering: 114,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/3A.png?v=1608206322",
    solidBg: true,
    templateType: 1,
    textColor: "#ED731D",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-cta wa-chat-btn-container-size-small",
    ctaEnabled: true,
    custom: true,
    iconColor: "#ED731D",
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Set-3-Icon.png?v=1608718461",
    id: 111,
    name: "3B Custom",
    ordering: 115,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/3B.png?v=1608206323",
    solidBg: true,
    templateType: 2,
    textColor: "#ED731D",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: true,
    iconColor: "#ED731D",
    iconCustom: "wa-chat-btn-icon-image-only wa-custom-icon no-box-shadow w-3",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/3C_Custom.svg?v=1615805156",
    id: 112,
    name: "3C Custom",
    ordering: 116,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/3C.svg?v=1608706084",
    solidBg: true,
    templateType: 3,
    textColor: "#ED731D",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-grey",
    ctaEnabled: true,
    custom: true,
    iconColor: "#ED731D",
    iconCustom: "wa-chat-btn-icon-image-only wa-custom-icon no-box-shadow w-3",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/3C_Custom.svg?v=1615805156",
    id: 113,
    name: "3D Custom",
    ordering: 117,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/3D.png?v=1608206322",
    solidBg: true,
    templateType: 4,
    textColor: "#ED731D",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-cta wa-chat-btn-container-size-big",
    ctaEnabled: true,
    custom: true,
    iconColor: "#29C765",
    iconCustom: "wa-chat-btn-icon-cta-big",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Set-5-Icon.png?v=1608718461",
    id: 114,
    name: "5A Custom",
    ordering: 122,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/5A.png?v=1608206322",
    solidBg: true,
    templateType: 1,
    textColor: "#29C765",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-cta wa-chat-btn-container-size-small",
    ctaEnabled: true,
    custom: true,
    iconColor: "#29C765",
    iconCustom: "wa-chat-btn-icon-cta-small",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Set-5-Icon.png?v=1608718461",
    id: 115,
    name: "5B Custom",
    ordering: 123,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/5B.png?v=1608206322",
    solidBg: true,
    templateType: 2,
    textColor: "#29C765",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: true,
    iconColor: "#29C765",
    iconCustom: "wa-chat-btn-icon-image-only wa-custom-icon no-box-shadow",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/5C_Custom.svg?v=1614696694",
    id: 116,
    name: "5C Custom",
    ordering: 124,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/5C.svg?v=1608706579",
    solidBg: true,
    templateType: 3,
    textColor: "#29C765",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom: "wa-chat-btn-base-cta-with-icon b-grey",
    ctaEnabled: true,
    custom: true,
    iconColor: "#29C765",
    iconCustom:
      "wa-chat-btn-icon-image-only wa-custom-icon no-box-shadow adjust-icon",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/5C_Custom.svg?v=1614696694",
    id: 117,
    name: "5D Custom",
    ordering: 125,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/5D.png?v=1608206322",
    solidBg: true,
    templateType: 4,
    textColor: "#29C765",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom:
      "wa-chat-btn-base-cta-rounded wa-chat-btn-container-size-rounded-big bordered",
    ctaEnabled: true,
    custom: true,
    iconColor: "#39B7F9",
    iconCustom: "wa-chat-btn-icon-cta-big w-30",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Set-9-Icon.png?v=1608718461",
    id: 118,
    name: "9A Custom",
    ordering: 138,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/9A.png?v=1608206321",
    solidBg: true,
    templateType: 1,
    textColor: "#39B7F9",
  },
  {
    bgColor1: "#FFFFFF",
    buttonCustom:
      "wa-chat-btn-base-cta-rounded wa-chat-btn-container-size-rounded-small bordered",
    ctaEnabled: true,
    custom: true,
    iconColor: "#39B7F9",
    iconCustom: "wa-chat-btn-icon-cta-small w-30",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Set-9-Icon.png?v=1608718461",
    id: 119,
    name: "9B Custom",
    ordering: 139,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/9B.png?v=1608206322",
    solidBg: true,
    templateType: 2,
    textColor: "#39B7F9",
  },
  {
    bgColor1: "#39B7F9",
    buttonCustom: "wa-chat-btn-base-icon",
    ctaEnabled: false,
    custom: true,
    iconColor: "#FFFFFF",
    iconCustom: "wa-chat-btn-icon-image-only wa-custom-icon w-3",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/9C_Custom.svg?v=1616182726",
    id: 120,
    name: "9C Custom",
    ordering: 140,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/9C.svg?v=1608706579",
    solidBg: true,
    templateType: 3,
    textColor: "#39B7F9",
  },
  {
    bgColor1: "#39B7F9",
    buttonCustom: "wa-chat-btn-base-cta-with-icon rounded",
    ctaEnabled: true,
    custom: true,
    iconColor: "#FFFFFF",
    iconCustom: "wa-chat-btn-icon-image-only wa-custom-icon w-3",
    iconUrl:
      "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/9C_Custom.svg?v=1616182726",
    id: 121,
    name: "9D Custom",
    ordering: 141,
    premium: false,
    previewUrl:
      "https://cdn.shopify.com/s/files/1/0440/6548/9052/files/9D.png?v=1608206321",
    solidBg: true,
    templateType: 4,
    textColor: "#39B7F9",
  },
];
