import { GET_SHARE_THEMES, SHARE, SHARE_BUTTON_DESIGN_THEMES, SHARE_BUTTON_DISPLAY_PAGE, SHARE_BUTTON_STATUS, SHARE_DEVICES, SHARE_THEME } from "../../constants/api-constants";
import api from "../../utils/api";
import httpClient from "../../utils/http-client";

export const getShareDetails = (shopId: string) => {
  return new Promise((resolve, reject) => {
    api.get(SHARE.replace("$shopId", shopId)).then((response: any) => {
      if (response.status === "success") {
        resolve(response.share);
      } else {
        reject(response.message);
      }
    });
  });
};

export const updateShareData = (shopId: string, params: any) => {
  return httpClient.put(SHARE.replace("$shopId", shopId), params);
};

export const updateShareDetails = (shopId: string, params: any) => {
  return httpClient.put(SHARE.replace("$shopId", shopId), params);
};

export const getShareCustomThemes = (shopId: string) => {
  // return httpClient.get(GET_SHARE_THEMES.replace("$shopId", shopId));
  return new Promise((resolve, reject) => {
    httpClient.get(SHARE_BUTTON_DESIGN_THEMES.replace("$shopId", shopId)).then((response: any) => {
      if (response.errorMessage) {
        reject(response.message);
      } else if (response.id) {
        let tempResponse = {
          theme: response,
          status: "success",
        };
        resolve(tempResponse);
      }
      resolve(response);
    });
  });
}

export const updateShareTheme = (shopId: string, buttonId: string, params: any) => {
  let tempParams = {
    "buttonTemplateId": buttonId,
    "backgroundStyleType": params?.solidBg ? "single" : "gradient",
    "bgColor1": params?.bgColor1 ? (params?.bgColor1).replace("#", "") : "",
    "bgColor2": params?.bgColor2 ? (params?.bgColor2).replace("#", "") : "",
    "iconColor": params?.iconColor ? (params?.iconColor).replace("#", "") : "",
    "textColor": params?.textColor ? (params?.textColor).replace("#", "") : "",
    "text": params?.buttonCta ? params?.buttonCta : "text",
    "message": params?.shareMessage ? params?.shareMessage : "message",
  }
  return httpClient.put(SHARE_BUTTON_DESIGN_THEMES.replace('$shopId', shopId).replace('$buttonId', buttonId), tempParams);
}

export const getShareDevices = (shopId: string) => {
  return new Promise((resolve, reject) => {
    httpClient.get(SHARE_DEVICES.replace("$shopId", shopId)).then((response: any) => {
      let tempResponse = {};
      if (response && response.id) {
        let mobile = {
          "deviceType": "mobile",
          "sharePosition": response?.mobilePosition + "",
          "verticalOffsetShare": response?.mobileHeightOffset + "",

        };
        let desktop = {
          "deviceType": "desktop",
          "sharePosition": response?.desktopPosition + "",
          "verticalOffsetShare": response?.desktopHeightOffset + "",
        };
        let devices: any[] = [];
        if (response?.displayType === 1) {
          devices = [mobile];
        } else if (response?.displayType === 2) {
          devices = [desktop];
        } else if (response?.displayType === 3) {
          devices = [mobile, desktop];
        }
        tempResponse = { ...tempResponse, "status": "success", "devices": devices }
      }
      // console.log('response 99', response, tempResponse);
      resolve(tempResponse);
    }).catch(error => {
      reject(error);
    });
  });
};

export const updateShareDevices = (shopId: string, params: any) => {
  return httpClient.put(SHARE_DEVICES.replace("$shopId", shopId), params);
}

export const deleteShareDevices = (shopId: string, params: any) => {
  return httpClient.delete(SHARE_DEVICES.replace("$shopId", shopId), { data: params });
}

/** New */
export const getShareButtonStatus = () => {
  return httpClient.get(SHARE_BUTTON_STATUS);
};

export const updateShareButtonStatus = (params: any) => {
  return httpClient.put(SHARE_BUTTON_STATUS, params);
};

/** Display Page  Function */
export const getShareButtonDisplayPages = () => {
  return new Promise((resolve, reject) => {
    httpClient.get(SHARE_BUTTON_DISPLAY_PAGE).then((response: any) => {
      let tempDisplayScope = [];
      if (response.displayScope) {
        tempDisplayScope = response.displayScope.split(',');
      }
      resolve(tempDisplayScope);
    }).catch(error => {
      reject(error);
    });
  });
};
export const updateShareButtonDisplayPages = (shopId: string, params: any) => {
  params = { "displayScope": params?.toString() };
  // return new Promise(() => {});
  return httpClient.put(SHARE_BUTTON_DISPLAY_PAGE.replace("$shopId", shopId), params);
};