import { Card, Stack, TextContainer, TextField } from "@shopify/polaris";
import React, { useEffect, useState } from "react";

const SupportNumberMarkup = (props: any) => {
  const [countryCode, setCountryCode] = useState(props.countryCode);
  const [number, setNumber] = useState(props.phoneNumber);
  useEffect(() => {
    setCountryCode(props.countryCode);
    setNumber(props.phoneNumber);
  }, [props]);
  const validateCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!countryCode || countryCode.match(cc)) {
      return false;
    } else if (countryCode && countryCode[0] === "0") {
      return "cannot start with 0";
    } else {
      return "enter a valid country code";
    }
  };

  const validatePhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!number || number.match(phoneno)) {
      return false;
    } else {
      return "enter a valid phone number";
    }
  };

  return (
    <Card sectioned>
      <Stack vertical>
        <p>
          We include a link to your WhatsApp support number in every message
          (mandatory)
        </p>
        <Stack>
        <Stack>
            <TextField
              label={(
                <TextContainer>
                  Country Code
									<img onClick={() => { window.open("https://docs.google.com/spreadsheets/u/2/d/1GX6yTDK8imK0YuOFCkU-Lpa3nCFifuq_oaujFvQaiCE/edit?usp=sharing", "_blank") }} className="toolTip pointer" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Icon_1.png?1021" alt='Question mark' />
                </TextContainer>)}
              value={countryCode}
              maxLength={15}
              type={"text"}
              placeholder={"Click ? for country codes"}
              error={validateCountryCode()}
              onChange={(value) => {
                props.onCountryChange(value);
                setCountryCode(value);
              }} />
            <TextField
              label={(<TextContainer>WhatsApp Support Number <div className="toolTip" /></TextContainer>)}
              value={number}
              maxLength={15}
              type={"text"}
              placeholder={"Example: 9035026993"}
              error={validatePhoneNo()}
              onChange={(value) => {
                props.onNumberChange(value);
                setNumber(value);
              }}
              helpText={"WhatsApp support number (should not start with +)"} />
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
export default SupportNumberMarkup;
