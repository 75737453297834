import { useEffect, useState } from "react";
import PageMarkup from "../../../common/PageMarkup";
import AutomatedTemplates from "./Automated/Automated";
import EmailLogs from "./EmailLogs/EmailLogs";
// import obj from "../../content/Templates/OverlaysContent/en.json";
// import TooltipOverlay from "../../common/Tooltip";
// import GuideModal from "../../common/GuideModal";

const EmailTemplates = () => {
  const [shopId, setShopId] = useState("");
  const [shopUrl, setShopUrl] = useState("");
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const tabs = [
    {
      id: "Templates",
      content: "Templates",
      accessibilityLabel: "Templates",
      panelID: "templates",
    },
    {
      id: "Email Logs",
      content: "Logs",
      accessibilityLabel: "Email Logs",
      panelID: "emailLogs",
    },
  ];
  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShopId(shop.id);
      setShopUrl(shop.url);
    }
  }, []);

  const tabContent = {
    0: <AutomatedTemplates shopId={shopId} shopUrl={shopUrl} />,
    1: <EmailLogs shopId={shopId} shopUrl={shopUrl} />,
  };

  return (
    <>
      {shopId && (
        <PageMarkup
          title="Email"
          tabs={tabs}
          tabContent={tabContent}
          selected={0}
        ></PageMarkup>
      )}
      {/* <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        btn2Action={() => {
          setTabIndex(1);
          setOpenGuideModal(true);
        }}
        obj={obj}
        showPricingBanner={showPricingBanner}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null} */}
    </>
  );
};
export default EmailTemplates;
