import { Button, Card, Layout, Page } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { checkPlan, getPlanDetails } from "../redux/actions/pricing";
import Success from "../assets/icons/ic_success.png";
import Error from "../assets/icons/ic_error.png";
import Loading from '../assets/icons/ic_pending.svg';
import { RefreshMinor } from '@shopify/polaris-icons';

const Confirmation = (props: any) => {
  const [chargeId] = useState(
    new URLSearchParams(useLocation().search).get("charge_id")
  );
  const [previousPath, setPreviousPath] = useState("");
  const [planName] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    checkStatus();
  }, []);

  const checkStatus = () => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      if (chargeId) {
        checkPlan(shop.id, chargeId).then((res: any) => {
          if (res.status === "success") {
            setPreviousPath(res.charge.returnTo);
            localStorage.setItem("currentPlan", res.charge.planId);
            planDetails(res.charge.planId);
          }
        });
      } else {
        setLoading(false);
      }
    }
  };
  const planDetails = (planid: string) => {
    getPlanDetails(planid).then((res: any) => {
      if (res.status === "success") {
        // INFO: static features added to show all menus
        // localStorage.setItem("features", res.plan.featuresList);
        localStorage.setItem("features", "AUTOMATED_MESSAGING,PRIVATE,EXTENSION,ANALYTICS,WEBHOOK_SYNC");
        setLoading(false);
        history.push('/pricing/?id='+planid);
      }
    });
  };

  const goBack = () => {
    history.push("/" + previousPath);
  }

  const getStarted = () => {
    props.history.push("/widget/settings");
  }

  const confirmationMarkup = planName ? (
    <div className="text-center">
      <img alt="Success Icon" src={Success} title="Success" width="100" className="pb-2"></img>
      <p className="fs-2 strong mt-3">Plan successfully activated.</p>
      <div className="mt-3">
        <Button primary onClick={() => getStarted()}>
          Get Started
        </Button>
      </div>
    </div>
  ) : (
    <div className="text-center">
      <img alt="Error Icon" src={Error} title="Error" width="100" className="pb-2"></img>
      <p className="fs-2 strong mt-2">Something went wrong. Please try again later.</p>
      <div className="mt-3">
        <Button onClick={() => goBack()}>
          Go Back
        </Button>
      </div>
    </div>
  );

  const loadingMarkup = (
    <div className="text-center">
      <img alt="Loading Icon" src={Loading} title="Loading" width="100" className="pb-2"></img>
      <p className="fs-2 strong mt-2">Please wait while your plan gets activated.</p>
      <div className="mt-3">
        <Button icon={RefreshMinor} onClick={() => checkStatus()}>
          Refresh
        </Button>
      </div>
    </div>
  );

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            {
              loading ? loadingMarkup : confirmationMarkup
            }
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Confirmation;
