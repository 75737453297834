import { Page, Layout, Modal } from "@shopify/polaris";
import { useState } from "react";
import InstructionImg1 from "../../assets/images/instructions-1.svg";
import InstructionImg2 from "../../assets/images/instructions-2.svg";
import InstructionImg3 from "../../assets/images/instructions-3.svg";
import MultipleAgentsBanner from "../../common/MultipleAgentsBanner";
import { PlayCircleMajor } from "@shopify/polaris-icons";

const ProSupport = () => {
  const [openTutorial, setopenTutorial] = useState(false);

  const openChromeExtension = () => {
    var url =
      "https://chrome.google.com/webstore/detail/superlemon-shopline/dibgeieoigmomemkamokjpiopgdfjapi";
    window.open(url, "_blank");
  };

  const openWhatsAppWeb = () => {
    var url = "https://web.whatsapp.com/";
    window.open(url, "_blank");
  };
  const openPlaystore = () => {
    var url = "https://play.google.com/store/apps/details?id=io.gupshup.superlemon";
    window.open(url, "_blank");
  };

  return (
    <Page
      title="Instructions"
      subtitle="To send manual messages and support via your personal WhatsApp, download the SuperLemon Google Chrome extension (Available for Pro and Ultimate plans) "
      secondaryActions={[
        {
          content: "Tutorial video",
          icon: PlayCircleMajor,
          accessibilityLabel: "Tutorial Video",
          outline: true,
          onAction: () => {
            setopenTutorial(true);
            document.body.classList.add("tutorial-modal");
          },
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <div className="Polaris-Card">
            <div className="Polaris-CalloutCard__Container">
              <div className="Polaris-Card__Section">
                <div className="Polaris-CalloutCard">
                  <div className="Polaris-Stack Polaris-Stack--alignmentCenter Polaris-Stack--distributionFillEvenly w-100">
                    <div className="Polaris-Stack__Item">
                      <img
                        src={InstructionImg1}
                        alt="Instruction 1"
                        className="Polaris-CalloutCard__Image w-1 h-1"
                      />
                    </div>
                    <div className="Polaris-Stack__Item">
                      <p className="Polaris-DisplayText fs-1">
                        Download this Extension
                      </p>
                    </div>
                    <div className="Polaris-Stack__Item">
                      <button
                        className="Polaris-Button Polaris-Button--primary Polaris-Button--outline outline pull-right"
                        type="button"
                        onClick={openChromeExtension}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            Get Extension
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout.Section>
        <Layout.Section>
          <div className="Polaris-Card">
            <div className="Polaris-CalloutCard__Container">
              <div className="Polaris-Card__Section">
                <div className="Polaris-CalloutCard">
                  <div className="Polaris-Stack Polaris-Stack--alignmentCenter Polaris-Stack--distributionFillEvenly w-100">
                    <div className="Polaris-Stack__Item">
                      <img
                        src={InstructionImg2}
                        alt="Instruction 2"
                        className="Polaris-CalloutCard__Image w-1 h-1"
                      />
                    </div>
                    <div className="Polaris-Stack__Item">
                      <p className="Polaris-DisplayText fs-1">
                        If you have the Extension
                      </p>
                    </div>
                    <div className="Polaris-Stack__Item">
                      <button
                        className="Polaris-Button Polaris-Button--primary Polaris-Button--outline outline pull-right"
                        type="button"
                        onClick={openWhatsAppWeb}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            Go to WhatsApp Web
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <span className="d-flex-center fs-1 strong">OR</span>
            <div className="Polaris-CalloutCard__Container">
              <div className="Polaris-Card__Section">
                <div className="Polaris-CalloutCard">
                  <div className="Polaris-Stack Polaris-Stack--alignmentCenter Polaris-Stack--distributionFillEvenly w-100">
                    <div className="Polaris-Stack__Item">
                      <img
                        src={InstructionImg3}
                        alt="Playstore help image"
                        className="Polaris-CalloutCard__Image w-1 h-1"
                      />
                    </div>
                    <div className="Polaris-Stack__Item">
                      <p className="Polaris-DisplayText fs-1 text-center">
                        Download the App!
                      </p>
                    </div>
                    <div className="Polaris-Stack__Item">
                      <button
                        className="Polaris-Button Polaris-Button--primary Polaris-Button--outline outline pull-right"
                        type="button"
                        onClick={openPlaystore}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            Go to Playstore
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </Layout.Section>
        {/* <Layout.Section>
          <MultipleAgentsBanner />
        </Layout.Section> */}
      </Layout>
      <Modal
        open={openTutorial}
        sectioned={true}
        title={"Tutorial video"}
        onClose={() => {
          setopenTutorial(false);
          document.body.classList.remove("tutorial-modal");
        }}
      >
        <iframe
          id="ytplayer"
          width="100%"
          height="360"
          allow="fullscreen"
          src={"https://www.youtube.com/embed/8ug_qyXxqAs"}
          frameBorder="0"
          title={"Extension Tutorial Video"}
        ></iframe>
      </Modal>
    </Page>
  );
};

export default ProSupport;
