import { useState } from "react";
import ProSupport from "./ProSupport";
import FreeSupport from "./FreeSupport";
import obj from "../../content/Extension/OverlaysContent/en.json";
import TooltipOverlay from "../../common/Tooltip";
import GuideModal from "../../common/GuideModal";

const Support = () => {
  const [features] = useState(localStorage.getItem("features"));
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      <TooltipOverlay btn1Action={() => setOpenGuideModal(true)} obj={obj} />
      {openGuideModal && (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      )}
      {features?.includes("EXTENSION") ? (
        <ProSupport></ProSupport>
      ) : (
        <FreeSupport></FreeSupport>
      )}
    </>
  );
};

export default Support;
