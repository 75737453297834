import {
  Button,
  Card,
  Checkbox,
  ContextualSaveBar,
  DisplayText,
  Layout,
  Link,
  Loading,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { Fragment, useEffect, useState } from "react";
import TemplateLanguageSettingMarkup from "../../common/TemplateLanguageSettingMarkup";
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  COD_TEMPLATE_DICT,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
} from "../../constants/constants";
import {
  fetchCODSettings,
  updateCODSettings,
} from "../../redux/actions/codSettings";
import {
  fetchPhoneSettings,
  updatePhoneSettings,
} from "../../redux/actions/crmOrders";
import { objectDifference } from "../../utils/helper";

const CODSettingPage = (props: any) => {
  const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } = props;
  var TEMPLATE_DICT: any = COD_TEMPLATE_DICT;
  const [isOrderConfirmationEnabled, setIsOrderConfirmationEnabled] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);

  const [initialCodData, setInitialCodData] = useState({
    cancelOnCancelled: true,
    cancelOnNoResponse: true,
    cancelledTag: "",
    confirmationLanguage: "ENGLISH",
    confirmedTag: "",
    countryCode: "",
    email: "",
    emailEnabled: false,
    enable: false,
    language: {
      language: "ENGLISH"
    },
    noResponseTag: "",
    phone: "",
    phoneEnabled: false,
  });

  const [codData, setCodData] = useState({
    cancelOnCancelled: true,
    cancelOnNoResponse: true,
    cancelledTag: "",
    confirmationLanguage: "ENGLISH",
    confirmedTag: "",
    countryCode: "",
    email: "",
    emailEnabled: false,
    enable: false,
    language: {
      language: "ENGLISH"
    },
    noResponseTag: "",
    phone: "",
    phoneEnabled: false,
  });

  const [refCodData, setRefCodData] = useState({
    cancelOnCancelled: true,
    cancelOnNoResponse: true,
    cancelledTag: "",
    confirmationLanguage: "ENGLISH",
    confirmedTag: "",
    countryCode: "",
    email: "",
    emailEnabled: false,
    enable: false,
    language: {
      language: "ENGLISH"
    },
    noResponseTag: "",
    phone: "",
    phoneEnabled: false,
  });
  const [initialPhoneData, setInitialPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [phoneData, setPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [refPhoneData, setRefPhoneData] = useState({
    countryCode: "",
    phone: "",
  });

  const [dataChanges, setDataChanges] = useState<any>({
    cod: null,
    phone: null,
  });
  const [hasChanges, setHasChanges] = useState(false);
	const [languageList, setLanguageList] = useState([]);

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      getOrderData();
      getPhoneData();
    }
  }, []);
  useEffect(() => {
    var diffObj: any = objectDifference(refCodData, codData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges:any) => ({ ...dataChanges, cod: diffObj }));
    } else {
      setDataChanges((dataChanges:any) => ({ ...dataChanges, cod: null }));
    }
  }, [codData]);
  useEffect(() => {
    var diffObj: any = objectDifference(refPhoneData, phoneData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges:any) => ({ ...dataChanges, phone: diffObj }));
    } else {
      setDataChanges((dataChanges:any) => ({ ...dataChanges, phone: null }));
    }
  }, [phoneData]);

  useEffect(() => {
    if (dataChanges.cod || dataChanges.phone) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  const getOrderData = () => {
    fetchCODSettings().then(([data, res]: any) => {
      setInitialCodData({
        ...codData,
        ...data
      });
      setRefCodData({
        ...codData,
        ...data
      });
      setCodData({
        ...codData,
        ...data
      });
			setLanguageList(res?.languageList)
      setIsOrderConfirmationEnabled(data.enable);
    });
  };
  const getPhoneData = () => {
    fetchPhoneSettings().then((res: any) => {
      setInitialPhoneData(res?.setting);
      setRefPhoneData(res?.setting);
      setPhoneData(res?.setting);
    });
  };
  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );
  const order_confirmation_message_template_description = (
    <>
        <p>
        Verify and confirm COD orders using WhatsApp. Message is sent immediately after a COD order is placed.
        </p>
        <br />
        <p>
          <b>
            Automated WhatsApp message templates cannot be modified or edited
          </b>
          , because message templates need to be pre-approved by WhatsApp before
          they can be sent using the API.
        </p>
        <br />
        {"To learn about the cost of sending messages, view"}
        <Link
          external={true}
          url="https://docs.google.com/spreadsheets/u/2/d/1GX6yTDK8imK0YuOFCkU-Lpa3nCFifuq_oaujFvQaiCE/edit?usp=sharing"
        >
          {" pricing chart"}
        </Link>
    </>
  );

  const OrderConfirmationTemplateClick = (enable: boolean) => {
    if (!phoneData.phone || validateSupportPhoneNo()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support phone number must be given");
      return;
    }

    if (!phoneData.countryCode || validateSupportCountryCode()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support country code must be given");
      return;
    }

    setIsLoading(true);

    updateCODSettings({ enable }).then(([data, response]: any) => {
      setIsOrderConfirmationEnabled(data?.enable);
      setToast("COD Order Confirmation message template has been " + (enable ? "ENABLED" : "DISABLED"))
      setIsLoading(false);
    }, err => {
      console.log(err);
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast('Something went wrong');
      setIsLoading(false);
    });
  };

  const getOrderConfirmationPreview = () => {
    switch (codData.confirmationLanguage.toUpperCase()) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/eng-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/por-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/spa-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ita-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/fr-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/indo-order_confirmation_preview.png?v=1587749561";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ger-order_confirmation_preview-updated.png?v=1587819720";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/arabic-order_confirmation_preview.png?v=1587749561";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/turkish-order_confirmation_preview.png?v=1587819648";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/hebrew-order_confirmation_preview.png?v=1591679012";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/dutch-order_confirmation_preview.png?v=1591677402";
    }
  };

  const orderConfirmationTemplateMarkup = (
    <Card>
      <Card.Header
        title="COD Order Confirmation"
        children={[
          isOrderConfirmationEnabled ? (
            <Button key={'cod-disable'} onClick={() => OrderConfirmationTemplateClick(false)}>Disable</Button>
          ) : (
            <Button primary key={'cod-enable'} onClick={() => OrderConfirmationTemplateClick(true)}>
              Enable
            </Button>
          ),
        ]}
      ></Card.Header>

      <Card.Section>
        <p className="paddingRight6px notranslate messageTemplateBackground">
          {TEMPLATE_DICT[codData.confirmationLanguage.toUpperCase()]["cod_confirmation_template"]}
        </p>
        <br />
        <Link external={true} url={getOrderConfirmationPreview()}>
          Preview
        </Link>
      </Card.Section>

      {isOrderConfirmationEnabled ?
        <Card.Section>
          <TextStyle variation="subdued"><b>Note</b>: If both COD Order Confirmation and regular Order Confirmation templates are enabled, then the regular Order Confirmation message will be sent after your customer confirms a COD order.</TextStyle>
        </Card.Section> : null
      }
    </Card>
  );


  const optintext = (
    <span>
      <p>
        <b>
          {
            "WhatsApp requires users to opt-in before we are allowed to send automated messages."
          }
        </b>
        <br />
        <br />
        Storefront opt-in widget is enabled by default if you enable any
        Automated message template.
        <br />
        <br />
        You can disable Storefront opt-in widget after getting approval for
        collecting opt-ins from Checkout page.
        <br />
        <br />
        <b>Note: </b>Opt-in widget is enabled by default for the “Thank you
        page“ if you enable any Automated Orders CRM template.
      </p>
    </span>
  );

  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  const handleLanguageChange = (newValue: any) => {
    return () => {
      handlecodChange("confirmationLanguage", newValue);
    };
  };
  const templateLanguageSettingMarkup = (
    <Card sectioned>
      <Stack vertical>
        <TextContainer>
          {"Message Language (all messages will be sent in this language)"}
        </TextContainer>
        <Stack>
          <RadioButton
            label="English"
            checked={codData.confirmationLanguage === TEMPLATE_LANGUAGE_ENG}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ENG)}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Spanish"
            checked={codData.confirmationLanguage === TEMPLATE_LANGUAGE_SPAN}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_SPAN)}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Italian"
            checked={codData.confirmationLanguage === TEMPLATE_LANGUAGE_ITAL}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ITAL)}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Arabic"
            checked={codData.confirmationLanguage === TEMPLATE_LANGUAGE_ARAB}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_ARAB)}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Hebrew"
            checked={codData.confirmationLanguage === TEMPLATE_LANGUAGE_HEBR}
            onChange={handleLanguageChange(TEMPLATE_LANGUAGE_HEBR)}
            name="confirmationLanguage"
          />
        </Stack>
      </Stack>
    </Card>
  );
  const tag_for_cod_order_description = (
    <span>
      <p>
        Automatically add tags to your orders based on COD order status.{" "}
        <Link
          external={true}
          url="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_tags_preview.png?v=1589530683"
        >
          Preview
        </Link>{" "}
        how tags appear in your Shopline admin.
      </p>
      <br />
      <p>
        No response tag - added if the customer does not confirm or cancel the
        order within 48 hours.
      </p>
      <br />
      <p>
        <b>Note</b> - If you have selected the "automatically cancel the order
        in Shopline" checkbox, we also auto restock items from the cancelled
        order.
      </p>
    </span>
  );
  const cod_order_contact_description = (
    <p>
      Configure the language and contact options to display on the COD order
      confirmation page
    </p>
  );

  const tagForCoDOrdersMarkup = (
    <div>
      <Card>
        <Card.Section>
          <TextField
            label="Order Confirmed tag"
            value={codData.confirmedTag}
            onChange={(value) => {
              setCodData((data) => ({ ...data, "confirmedTag": value }));
            }}
          />
          <br />
          <TextField
            label="Order Cancelled tag"
            value={codData.cancelledTag}
            onChange={(value) => {
              setCodData((data) => ({ ...data, "cancelledTag": value }));
            }}
          />
          <br />
          <TextField
            label="No Response tag"
            value={codData.noResponseTag}
            onChange={(value) => {
              setCodData((data) => ({ ...data, "noResponseTag": value }));
            }}
          />
        </Card.Section>
      </Card>
      <br />
    </div>
  );
  const settingForAutomaticOrderCancellation = (
    <Stack vertical>
      <Checkbox
        label="If an order is cancelled, automatically cancel the order in Shopline"
        checked={codData.cancelOnCancelled}
        onChange={(val) => {
          setCodData((data) => ({
            ...data,
            "cancelOnCancelled": val,
          }));
        }}
      />
      <Checkbox
        label="If an order has no response, automatically cancel the order in Shopline"
        checked={codData.cancelOnNoResponse}
        onChange={(val) => {
          setCodData((data) => ({
            ...data,
            "cancelOnNoResponse": val,
          }));
        }}
      />
    </Stack>
  );

  const getCodConfirmationPagePreivew = (language: string) => {    
    switch (language.toUpperCase()) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_eng.png?v=1591794612";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_spa.png?v=1591794616";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_ita.png?v=1591794616";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_arabic.png?v=1591794611";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview_-_hebrew.png?v=1591794615";
      default:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_page_preview.png?v=1589532117";
    }
  };

  const validateSupportCountryCode = () => {

    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!phoneData.countryCode || phoneData.countryCode.match(cc)) {
      return false;
    } else if (phoneData.countryCode && phoneData.countryCode[0] === "0") {
      return "cannot start with 0"
    } else {
      return "enter a valid country code"
    }
  }

  const validateSupportPhoneNo = () => {

    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!phoneData.phone || phoneData.phone.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number"
    }
  }

  const validateCountryCode = () => {

    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!codData.countryCode || codData.countryCode.match(cc)) {
      return false;
    } else if (codData.countryCode && codData.countryCode[0] === "0") {
      return "cannot start with 0"
    } else {
      return "enter a valid country code"
    }
  }

  const validatePhoneNo = () => {

    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!codData.phone || codData.phone.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number"
    }
  }

  const validateEmail = () => {
    //eslint-disable-next-line
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!codData.email || codData.email.match(emailRegex)) {
      return false;
    } else {
      return "Enter a valid email address";
    }
  }

  const codOrderContactMarkup = (
    <div>
      <Card>
        <Card.Section>
          <Checkbox
            label="Call"
            checked={codData.phoneEnabled}
            onChange={(val) => {
              setCodData((data) => ({ ...data, "phoneEnabled": val }));
            }}
          />
          <Stack>
            <Stack.Item>
              <TextField
                label="Country Code"
                value={codData.countryCode}
                maxLength={15}
                error={validateCountryCode()}
                onChange={(val) => {
                  setCodData((data) => ({ ...data, "countryCode": val }));
                }}
                disabled={!codData.phoneEnabled}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                label="Phone Number"
                value={codData.phone}
                maxLength={15}
                placeholder={"Example: 9035026993"}
                error={validatePhoneNo()}
                onChange={(val) => {
                  setCodData((data) => ({ ...data, "phone": val }));
                }}
                disabled={!codData.phoneEnabled}
              />
            </Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section>
          <Checkbox
            label="Email"
            checked={codData.emailEnabled}
            onChange={(val) => {
              setCodData((data) => ({ ...data, "emailEnabled": val }));
            }}
          />
          <Stack>
            <TextField
              label="Email"
              type="email"
              value={codData.email}
              error={validateEmail()}
              onChange={(val) => {
                setCodData((data) => ({ ...data, "email": val }));
              }}
              disabled={!codData.emailEnabled}
            />
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );

  const handlecodChange = (field: string, value: any) => {    
    setCodData((data) => ({ ...data, [field]: value }));
  };
  const handlePhoneChange = (field: string, value: any) => {
    setPhoneData((data) => ({ ...data, [field]: value }));
  };
  const updateSettings = () => {
    if (!phoneData.phone || validateSupportPhoneNo()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support phone number must be given");
      return
    }

    if (!phoneData.countryCode || validateSupportCountryCode()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support country code must be given");
      return;
    }
    
    if (codData.phoneEnabled) {
      if (!codData.countryCode || validateCountryCode()) {
        setShowErrorToast((errorToast:any)=> !errorToast)
        setErrorToast("A valid country code must be given");
        return;
      }

      if (!codData.phone || validatePhoneNo()) {
        setShowErrorToast((errorToast:any)=> !errorToast)
        setErrorToast("A valid phone number must be given");
        return;
      }
    }

    if (codData.emailEnabled && (!codData.email || validateEmail())) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid email must be given");
      return;
    }

    if (dataChanges.cod) {
      updateCODSettings(dataChanges.cod).then(([updatedData, response]: any) => {
        setShowSuccessToast((successToast:any)=> !successToast)
        setToast("Data Saved");
        setDataChanges({ ...dataChanges, cod: null });
        setInitialCodData((data: any) => ({...data, ...updatedData}));
        setRefCodData((data: any) => ({...data, ...updatedData}));
        setCodData((data: any) => ({...data, ...updatedData}));
      }, err => {
        console.log(err);
        setShowErrorToast((errorToast:any)=> !errorToast)
        setErrorToast('Something went wrong');
        setIsLoading(false);
      });
    }
    if (dataChanges.phone) {
      updatePhoneSettings(dataChanges.phone).then((response: any) => {
        setShowSuccessToast((successToast:any)=> !successToast)
        setToast("Data Saved");
        setDataChanges({ ...dataChanges, phone: null });
        setInitialPhoneData((data: any) => ({...data, ...response?.setting}));
        setRefPhoneData((data: any) => ({...data, ...response?.setting}));
        setPhoneData((data: any) => ({...data, ...response?.setting}));
      }, err => {
        console.log(err);
        setShowErrorToast((errorToast:any)=> !errorToast)
        setErrorToast('Something went wrong');
      });
    }
    setHasChanges(false);
  };

  const resetData = () => {
    setHasChanges(false);
    setDataChanges({
      cod: null,
      phone: null,
    })
    setRefCodData(initialCodData);
    setRefPhoneData(initialPhoneData);
    setCodData(initialCodData);
    setPhoneData(initialPhoneData);
  }

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateSettings,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  const loadingMarkup = isLoading && <Loading />

  
 
  const checkoutOptinFixedContent = (
    <span>
      <DisplayText element="h4" size="small">
        Recover more sales by collecting WhatsApp opt-in from Checkout page
      </DisplayText>
      <br />
      <p>
        Complete the steps in the guide and apply for approval. We will manually
        check your store checkout page to verify the changes.
      </p>
    </span>
  );
  
  return (
    <Fragment>
      {ctxSaveBarMarkup}
      {loadingMarkup}
      
      <Layout.AnnotatedSection
        title="WhatsApp Configuration"
        description={description}
      >
        <Stack vertical>
          {templateLanguageSettingMarkup}
          {/* <SupportNumberMarkup
            phoneNumber={phoneData.phone}
            countryCode={phoneData.countryCode}
            onCountryChange={(val: any) => {
              handlePhoneChange("countryCode", val);
            }}
            onNumberChange={(val: any) => {
              handlePhoneChange("phone", val);
            }}
          ></SupportNumberMarkup> */}
        </Stack>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title="Cash On Delivery (COD) Order Confirmation Template"
        description={order_confirmation_message_template_description}
      >
        <Stack vertical>{orderConfirmationTemplateMarkup}</Stack>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title="Tags for COD Orders"
        description={tag_for_cod_order_description}
      >
        <Stack vertical>{tagForCoDOrdersMarkup}</Stack>
        <Card sectioned>{settingForAutomaticOrderCancellation}</Card>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title="COD Order Confirmation Page - Settings"
        description={cod_order_contact_description}
      >
        <Stack vertical>
          <TemplateLanguageSettingMarkup
						languageList={languageList}
						language={codData.language}
						onChange={(val: any) => {
              setCodData((data) => ({ ...data, "language": val }));
						}}
            getPreview={getCodConfirmationPagePreivew}
					></TemplateLanguageSettingMarkup>
          {codOrderContactMarkup}
          {nudgeCheckoutOptinScrollSection}
        </Stack>
      </Layout.AnnotatedSection>

    
    </Fragment>
  );
};
export default CODSettingPage;
