import { TEMPLATES, EXTENSION_CATEGORY } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

export const getTemplates = () => {
    return httpClient.get(TEMPLATES).then((res: any) => {
        
        const data = res.map((el: any) => {
            return {
                id: el?.id,
                active: el?.enable,
                category: el?.templateCategory?.id,
                name: el?.name,
                text: el?.message
            };
        });
        return { status: 'success', templates: data };
      });
}

export const createTemplate = (bData: any) => {
    const body: any = {
        enable: bData.active,
        templateCategoryId: bData.category,
        name: bData.name,
        message: bData.text,
    };
    return httpClient.post(TEMPLATES, body).then((res: any) => {
        return { status: 'success', template: res };
    });
}

export const editTemplate = (templateId: string, bData: any) => {
    const body: any = {
        enable: bData.active,
        templateCategoryId: bData.category,
        name: bData.name,
        message: bData.text,
    };
    return httpClient.put(`${TEMPLATES}/${templateId}`, body).then((res: any) => {

        const data: any = {
            active: res?.enable,
            category: res?.templateCategoryId,
            name: res?.name,
            text: res?.message
        };
        return { status: 'success', template: data };
    });
}

export const deleteTemplate = (templateId: string) => {
    return httpClient.delete(`${TEMPLATES}/${templateId}`).then((res: any) => {
        return (res?.result == true) ? { status: 'success', template: res } : res?.errorMessage
    })
}

export const getTemplateCategories = () => {
    return httpClient.get(EXTENSION_CATEGORY)
}