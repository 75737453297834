const GreetingTemplatePreview = (props:any) => {
  const template = props.template;
  const custom = props.template.is_customizable ? props.custom : props.template;

  var bgColor1 = custom.bgColor1 || template.bg_color_1 || '#20802C';
  var bgColor2 = custom.bgColor2 || template.bg_color_2 || '#30BF42';

  var classNames = "wa-chat-bubble-floating-popup animated wa-greeting-widget-z-index";
  var headerClassNames = " ";
  var headerStyle = {};

  switch (template.style_type) {
    case 'wavy':
      headerClassNames += 'wavy';
      break;
    case 'intercom':
      classNames+= ' wa-intercom';
      break;
  }

  if (custom.is_greeting_template_solid_background) {
    headerStyle = { background: bgColor1 }
  } else {
    if(template.gradient_type === 'horizontal') {
      headerStyle = { backgroundImage: `linear-gradient(110.56deg, ${bgColor1} 0%, ${bgColor2} 100%)` }
    } else {
      headerStyle = { backgroundImage: `linear-gradient(164.25deg, ${bgColor1} 18.04%, ${bgColor2} 81.96%)` }
    }
  }

  var avatarStyle = { background: bgColor1 };
  var headStyle = { color: custom.buttonTextColor || '#ffffff'};
  var descStyle = { color: custom.descTextColor || '#ffffff' };

  return (
    <div id="wa-chat-bubble" className={`${classNames}`}>
      <div className={`wa-chat-bubble-header-common ${headerClassNames}`} style={headerStyle}>
        <div className="wa-chat-bubble-header-title" style={headStyle}>
          {"Hi there 👋"}
        </div>
        <div className="wa-chat-bubble-header-desc" style={descStyle}>
          {"We are here to help. Chat with us on WhatsApp for any queries."}
        </div>
      </div>
      <div className="wa-chat-bubble-chat">
        <div className="wa-chat-multiple-cs">
          <div className="list-cs">
            <div>
              <img className="wa-chat-bubble-whatsapp-avatar" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tiny-logo.png?840" alt="WhatsApp Avatar" />
              <div className="wa-chat-bubble-avatar" style={avatarStyle}>
                <img className="" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/male-avatar-1.svg?v=1583717709" alt="Male Avatar" />
              </div>
            </div>
            <div className="wa-chat-bubble-cs-profile">
              <div className="wa-chat-bubble-profile-name">
                Tom
              </div>
              <p>Customer Support</p>
            </div>
          </div>

          <div className="list-cs">
            <div>
              <img className="wa-chat-bubble-whatsapp-avatar" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tiny-logo.png?840" alt="WhatsApp Avatar" />
              <div className="wa-chat-bubble-avatar" style={avatarStyle}>
                <img className="" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/female-avatar-1.svg?v=1583717709" alt="Female Avatar" />
              </div>
            </div>
            <div className="wa-chat-bubble-cs-profile">
              <div className="wa-chat-bubble-profile-name">
                Lauren
              </div>
              <p>Technical Support</p>
            </div>
          </div>

          <div className="list-cs">
            <div>
              <img className="wa-chat-bubble-whatsapp-avatar" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tiny-logo.png?840" alt="WhatsApp Avatar" />
              <div className="wa-chat-bubble-avatar" style={avatarStyle}>
                <img className="" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/male-avatar-2.svg?v=1583717709" alt="Male Avatar" />
              </div>
            </div>
            <div className="wa-chat-bubble-cs-profile">
              <div className="wa-chat-bubble-profile-name">
                Harvey
              </div>
              <p>Wholesale Inquiries</p>
            </div>
          </div>

          <div className="list-cs">
            <div>
              <img className="wa-chat-bubble-whatsapp-avatar" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tiny-logo.png?840" alt="WhatsApp Avatar" />
              <div className="wa-chat-bubble-avatar" style={avatarStyle}>
                <img className="" src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/female-avatar-2.svg?v=1583717709" alt="Female Avatar" />
              </div>
            </div>
            <div className="wa-chat-bubble-cs-profile">
              <div className="wa-chat-bubble-profile-name">
                Rachel
              </div>
              <p>Wholesale Inquiries</p>
            </div>
          </div>
        </div>
      </div>
      <div className="wa-chat-widget-footer">
        <span style={{verticalAlign: 'middle'}}>
          Powered by 
          <span className="wa-chat-widget-footer-superlemon"> SuperLemon</span>
        </span>
      </div>
    </div>
  )
}

export default GreetingTemplatePreview;