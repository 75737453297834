import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	Button,
	Card,
	DataTable,
	Link,
	Stack,
	TextContainer,
	TextField,
	TextStyle,
	Tooltip,
} from "@shopify/polaris";
import AgentInfoModal from "./AgentInfoModal";
import { updateAgentDetails } from "../../../redux/actions/agents";

const STORE_TIMINGS = [
	{
		day: 0,
		start_time: "0000",
		end_time: "2359",
	},
	{
		day: 1,
		start_time: "0000",
		end_time: "2359",
	},
	{
		day: 2,
		start_time: "0000",
		end_time: "2359",
	},
	{
		day: 3,
		start_time: "0000",
		end_time: "2359",
	},
	{
		day: 4,
		start_time: "0000",
		end_time: "2359",
	},
	{
		day: 5,
		start_time: "0000",
		end_time: "2359",
	},
	{
		day: 6,
		start_time: "0000",
		end_time: "2359",
	},
];

const AgentInfo = (props: any) => {
	const history = useHistory();
	const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast} = props;	
	const [agents, setAgents] = useState<any[]>([]);
	const [agentTimings, setAgentTimings] = useState<any[]>([]);
	const [agentsToEdit, setAgentsToEdit] = useState<any>([]);
	const [showNewAgentModal, setShowNewAgentModal] = useState(false);
	const newAgent = {
		avatar_id: "",
		avatar_url: "",
		country_code: "",
		name: "",
		number: "",
		phone: "",
		role: "",
	};
	const [shopId, setShopId] = useState("");
	useEffect(() => {
		let shop = JSON.parse(localStorage.getItem("shop") || "{}");
		if (shop && shop.id) {
			setShopId(shop.id);
		}
		setAgents(props.agentSettings);
		setAgentTimings(props.agentTimings);

		let editingAgents: any = [];

		if (agents && agents.length > 0) {
			agents.forEach((agent) => {
				editingAgents[agent.id] = false;
			});
		} else {
			editingAgents[0] = false;
		}

		setAgentsToEdit(editingAgents);
	}, [props]);

	const validateCountryCode = () => {
		var cc = /^(?:[1-9]\d{0,5})$/;
		if (!agents[0].country_code || agents[0].country_code.match(cc)) {
			return false;
		} else if (
			agents[0].country_code &&
			agents[0].country_code[0] === "0"
		) {
			return "cannot start with 0";
		} else {
			return "enter a valid country code";
		}
	};

	const validatePhoneNo = () => {
		var phoneno = /^(?:[0-9]\d{0,14})$/;
		if (!agents[0].number || agents[0].number.match(phoneno)) {
			return false;
		} else {
			return "enter a valid phone number";
		}
	};

	const handleCountryCodeChange = (field: any) => {
		var agent = agents[0];
		agent.country_code = field;
		updateAgentState(agent);
		props.setUnsavedChanges();
	};

	const handlePrimaryNumberChange = (field: any) => {
		var agent = agents[0];
		agent.number = field;
		updateAgentState(agent);
		props.setUnsavedChanges();
	};

	const updateAgentState = (agent: any) => {
		var found = false;
		for (var i = 0; i < agents.length; i++) {
			if (agents[i].id === agent.id) {
				agents[i] = agent;
				found = true;
			}
		}

		//new user
		if (!found) {
			agents.push(agent);
		}

		setAgents(agents);

		// TODO Figure out
		// this.setState({
		//   new_agent: {
		//     avatar_id: 1,
		//     // avatar_url: avatarDictionary[1],
		//     chatsetting: this.props.chatsetting_id,
		//     store_timings: STORE_TIMINGS
		//   }
		// })

		props.modifyAgents(agents);
	};

	const shouldAutoFocus = () => {
		if (!agents[0].number) {
			return true;
		}
		return false;
	};

	const openPricing = () => {
		history.push("/pricing");
	};

	const onAddPrimaryNumberClick = (agent: any) => {
		return () => {
			onEditClick(agent);
		};
	};

	const onEditClick = (agent: any) => {
		var is_editing: any = {};
		if (agents && agents.length > 0) {
			agents.forEach((agentsi) => {
				if (agent.id === agentsi.id) {
					is_editing[agentsi.id] = true;
				} else {
					is_editing[agentsi.id] = false;
				}
			});
		} else {
			is_editing[0] = true;
		}
		setAgentsToEdit(is_editing);
	};

	const addNewNumber = () => {
		localStorage.setItem("previously_edited", "true");
		setShowNewAgentModal(true);
	};

	const getMultiAgents = () => {
		var multi_agents = [];
		if (agents && agentTimings.length > 0) {

			for (let i = 0; i < agents.length; i++) {

				let timings = agentTimings.find(
					(m) => m.agentId === agents[i].id
				);
				var modal = (
					<AgentInfoModal
						agents={agents}
						agent={agents[i]}
						agentTimings={timings?.timings}
						open={agentsToEdit[agents[i].id]}
						onModalClose={onEditComplete(agents[i])}
						updateAgentState={updateAgentState}
						store_timezone={props.timezone}
						setToast={setToast}
						fetchAgents={props.fetchAgents}
						fetchAgentTimings={props.fetchAgentTimings}
					/>
				);

				let agent = agents[i];

				var multiAgents = [
					<div>
						<p>
							{agents[i].country_code}
							{agents[i].phone}
						</p>
					</div>,
					<div>
						<p>{agents[i].name}</p>
					</div>,
					<TextStyle variation="subdued">
						{getAgentStatus(agents[i])}
						<span>{modal}</span>
					</TextStyle>,
					<div className="notranslate">
						<Link
							id={agents[i].id}
							onClick={() => onEditClick(agent)}
							removeUnderline={true}
						>
							Edit details
						</Link>
					</div>,
					<div className="notranslate">
						<Link
							id={agents[i].id}
							onClick={() => toggleAgent(agent, timings?.timings)}
							removeUnderline={true}
						>
							{agents[i].is_enabled ? "Disable" : "Enable"}
						</Link>
					</div>,
				];

				multi_agents.push(multiAgents);
			}
		}

		return multi_agents;
	};

	const onEditComplete = (agent: any) => {
		return () => {
			var is_editing: any = {};
			agents.forEach((agentsi) => {
				is_editing[agentsi.id] = false;
			});
			setAgentsToEdit(is_editing);
		};
	};

	const getAgentStatus = (agent: any) => {
		let agent_timing = agentTimings.find((m) => m.agentId === agent.id);
		if (agent_timing) {
			var is_agent_online = isAgentOnline(agent_timing, props.timezone);
			if (agent.is_enabled) {
				return is_agent_online ? (
					<Tooltip content="Based on the online hours you have set, this chat agent is currently online">
						<TextStyle variation="subdued">
							<span className="onlineText">Online</span>
						</TextStyle>
					</Tooltip>
				) : (
					<Tooltip content="Based on the online hours you have set, this chat agent is currently offline">
						<TextStyle variation="subdued">
							<span className="offlineText">Offline</span>
						</TextStyle>
					</Tooltip>
				);
			} else {
				return <TextStyle variation="subdued">Disabled</TextStyle>;
			}
		}
	};

	var weekday = new Array(7);
	weekday[0] = 6;
	weekday[1] = 0;
	weekday[2] = 1;
	weekday[3] = 2;
	weekday[4] = 3;
	weekday[5] = 4;
	weekday[6] = 5;

	const isAgentOnline = (agent: any, store_timezone = "UTC") => {
		var currentDateInStoreTimezone = new Date(
			new Date().toLocaleString("en-US", { timeZone: store_timezone })
		);
		var currentDayIndex = weekday[currentDateInStoreTimezone.getDay()];
		var currentHourMinutes =
			currentDateInStoreTimezone.getHours() * 60 +
			currentDateInStoreTimezone.getMinutes();

		var startHourMinutes =
			parseInt(
				agent.timings[currentDayIndex].start_time.substring(0, 2)
			) *
			60 +
			parseInt(agent.timings[currentDayIndex].start_time.substring(2, 4));
		var endHourMinutes =
			parseInt(agent.timings[currentDayIndex].end_time.substring(0, 2)) *
			60 +
			parseInt(agent.timings[currentDayIndex].end_time.substring(2, 4));

		return (
			startHourMinutes <= currentHourMinutes &&
			endHourMinutes >= currentHourMinutes
		);
	};

	const toggleAgent = (agent: any, timings: any) => {
		if (isOnlyEnabledAgent(agent)) {
			setShowErrorToast((errorToast: any) => !errorToast)
			setErrorToast("At least one agent should be enabled");
			return;
		}
		// var params: any = new URLSearchParams();
		// params.append("enabled", !agent.is_enabled);
		var params: any = {};
		params = agent;
		params.enabled = !params.is_enabled;
		params = { ...params, "timing": timings };
		
		updateAgentDetails(shopId, agent.id, params).then(
			(response: any) => {
				var is_enabled = !agent.is_enabled;
				agent.is_enabled = is_enabled;
				updateAgentState(agent);
				setShowSuccessToast((successToast: any) => !successToast)
				setToast("Agent is " + (!agent.is_enabled ? "disabled" : "enabled"));
				// if (response.status === "success") {
				// 	var is_enabled = !agent.is_enabled;
				// 	agent.is_enabled = is_enabled;
				// 	updateAgentState(agent);
				// 	setShowSuccessToast((successToast: any) => !successToast)
				// 	setToast("Agent is " + (!agent.is_enabled ? "disabled" : "enabled"));
				// } else {
				// 	setShowErrorToast((errorToast: any) => !errorToast)
				// 	setErrorToast(response);
				// }
			},
			(err) => {
				setShowErrorToast((errorToast: any) => !errorToast)
				setErrorToast("Something went wrong");
			}
		);
	};

	const isOnlyEnabledAgent = (agent: any) => {
		var isOnlyEnabledAgent = true;
		for (var i = 0; i < agents.length; i++) {
			if (agents[i].id !== agent.id && agents[i].is_enabled) {
				isOnlyEnabledAgent = false;
			}
		}

		return isOnlyEnabledAgent;
	};

	const showOfflineText = () => {
		var are_all_agents_offline = true;
		for (let i = 0; i < agents.length; i++) {
			let agent_timing = agentTimings.find(
				(m) => m.agentId === agents[i].id
			);
			if (agent_timing) {
				if (
					agents[i].is_enabled &&
					isAgentOnline(agent_timing, props.timezone)
				) {
					are_all_agents_offline = false;
				}
			}
		}

		return are_all_agents_offline ? (
			<Card.Section>
				<TextStyle>
					Based on the online hours you have set, all your active{" "}
					<b>chat agents are currently</b>{" "}
					<span className="offlineText">offline</span>.
				</TextStyle>
			</Card.Section>
		) : null;
	};

	// let multiAgentEnabled = props.shopSettings.multiAgentEnabled;
	let multiAgentEnabled = true;

	if (!multiAgentEnabled && agents && agents.length > 0) {
		return (
			<Card sectioned>
				<Stack vertical>
					<Stack>
						<TextField
							label={
								<TextContainer>
									Country Code
									<img
										onClick={() => {
											window.open(
												"https://docs.google.com/spreadsheets/u/2/d/1GX6yTDK8imK0YuOFCkU-Lpa3nCFifuq_oaujFvQaiCE/edit?usp=sharing",
												"_blank"
											);
										}}
										className="toolTip pointer"
										src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Icon_1.png?1021"
										alt="Question mark"
									/>
								</TextContainer>
							}
							value={agents[0].country_code}
							maxLength={15}
							placeholder={"Click ? for country codes"}
							error={validateCountryCode()}
							onChange={handleCountryCodeChange}
						/>
						<TextField
							label={
								<TextContainer>
									WhatsApp Phone Number{" "}
									<div className="toolTip" />
								</TextContainer>
							}
							value={agents[0].number}
							maxLength={15}
							placeholder={"Example: 9035026993"}
							error={validatePhoneNo()}
							onChange={handlePrimaryNumberChange}
							helpText={
								"WhatsApp phone number (should not start with +)"
							}
							autoFocus={shouldAutoFocus()}
						/>
					</Stack>
					<Link onClick={() => openPricing()}>
						{"+ Add another number (requires paid plan)"}
					</Link>
				</Stack>
			</Card>
		);
	} else if (
		multiAgentEnabled &&
		agents &&
		agents.length === 1 &&
		(!agents[0].number || !agents[0].country_code)
	) {
		let timings = agentTimings.find((m) => m.agentId === agents[0].id);
		let agent = agents[0];
		return (
			<Card sectioned>
				<AgentInfoModal
					agents={agents}
					agent={agents[0]}
					agentTimings={timings}
					open={agentsToEdit[agents[0].id]}
					onModalClose={onEditComplete(agents[0])}
					updateAgentState={updateAgentState}
					store_timezone={props.timezone}
					setToast={setToast}
					fetchAgents={props.fetchAgents}
					fetchAgentTimings={props.fetchAgentTimings}
				/>
				<Stack>
					<TextContainer>
						<p>
							<b>Add your primary WhatsApp number</b> and other
							information to get started.
						</p>
					</TextContainer>
					<Button onClick={() => onAddPrimaryNumberClick(agent)}>
						Add Primary WhatsApp Number
					</Button>
				</Stack>
			</Card>
		);
	} else if (multiAgentEnabled && agents && agents.length > 0) {
		return (
			<Card
				secondaryFooterActions={[
					{ content: "+ Add another number", onAction: addNewNumber },
				]}
			>
				<AgentInfoModal
					agents={agents}
					agent={newAgent}
					agentTimings={STORE_TIMINGS}
					open={showNewAgentModal}
					store_timezone={props.timezone}
					onModalClose={() => {
						setShowNewAgentModal(false);
					}}
					updateAgentState={updateAgentState}
					isNewNumber={true}
					setToast={setToast}
					fetchAgents={props.fetchAgents}
					fetchAgentTimings={props.fetchAgentTimings}
				/>
				<Card.Section>
					<DataTable
						columnContentTypes={[
							"text",
							"text",
							"text",
							"text",
							"text",
						]}
						headings={[
							"Phone number",
							"Agent name",
							"Status",
							"",
							"",
						]}
						rows={getMultiAgents()}
					/>
				</Card.Section>
				{showOfflineText()}
			</Card>
		);
	} else if (agents && agents.length === 0) {
		let agent = {
			id: 0,
			name: "",
			role: "",
			phone: "",
			countryCode: "",
		};
		return (
			<Card sectioned>
				<AgentInfoModal
					agents={agents}
					agent={agent}
					agentTimings={props.storeTimings}
					open={agentsToEdit[0]}
					onModalClose={onEditComplete(agent)}
					updateAgentState={updateAgentState}
					store_timezone={props.timezone}
					setToast={setToast}
					fetchAgents={props.fetchAgents}
					fetchAgentTimings={props.fetchAgentTimings}
				/>
				<Stack>
					<TextContainer>
						<p>
							<b>Add your primary WhatsApp number</b> and other
							information to get started.
						</p>
					</TextContainer>
					<Button onClick={() => onAddPrimaryNumberClick(agent)}>
						Add Primary WhatsApp Number
					</Button>
				</Stack>
			</Card>
		);
	}

	return null;
};

export default AgentInfo;
