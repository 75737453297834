import { ACCOUNT_OAUTH, CREATE_TOKEN } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

export const authorize = (params: any) => {
  return httpClient.get(`${ACCOUNT_OAUTH}?name=${params.name}&redirectUri=${params.redirectUri}`);
};

export const create_token = (params: any) => {
  return httpClient.post(CREATE_TOKEN,params);
};
