import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PLAN_FREE, PLAN_PRO, PLAN_ULTIMATE } from '../constants/constants';

const MultipleAgentsBanner = () => {

    const history = useHistory();
    const [currentPlan, setCurrentPlan] = useState<any>();

    useEffect(() => {
        setCurrentPlan(localStorage.getItem('currentPlan'));
    }, []);

    const openPricing = () => {
        history.push('/pricing');
    }

    const getBannerText = () => {
        let content = '';
        switch(Number(currentPlan)) {
            case PLAN_FREE:
                content = 'Upgrade to Pro Plan or the Ultimate Plan offered by SuperLemon in order to use the CRM tools';
                break;
            case PLAN_PRO: 
                content = 'Upgrade to the Ultimate Plan offered by SuperLemon in order to send automated WhatsApp messages to your customers';
                break;
            case PLAN_ULTIMATE:
                content = 'Have multiple agents? Get Delighchat customer support tool with the Private waba plan';
                break;
        }
        return content;
    }

    return (
        <>
        {/* {Number(currentPlan) !== PLAN_ULTIMATE && <div className="Polaris-Banner Polaris-Banner--statusSuccess Polaris-Banner--withinPage" tabIndex={0} role="status" aria-live="polite" aria-labelledby="PolarisBanner2Heading" aria-describedby="PolarisBanner2Content">
            <div className="Polaris-Banner__ContentWrapper">
                <div className="Polaris-Banner__Content" id="PolarisBanner2Content">
                    <div className="Polaris-Stack Polaris-Stack--distributionEqualSpacing Polaris-Stack--alignmentCenter">
                        <div className={`Polaris-Stack__Item ${Number(currentPlan) !== PLAN_ULTIMATE ? 'w-80' : 'w-100'} text-center`}>
                            <p>{getBannerText()}</p>
                        </div>
                        { Number(currentPlan) !== PLAN_ULTIMATE && <div className="Polaris-Stack__Item">
                            <button className="Polaris-Button Polaris-Button--primary btn-primary" type="button" onClick={openPricing}>
                                <span className="Polaris-Button__Content">
                                    <span className="Polaris-Button__Text">Upgrade Now</span>
                                </span>
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>} */}
        </>
    );
}

export default MultipleAgentsBanner;