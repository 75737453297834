import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Layout,
  Page,
  TextStyle,
  Toast,
  Heading,
  Stack,
  ContextualSaveBar,
  Checkbox,
  Tooltip,
  Loading,
  Banner,
} from "@shopify/polaris";
import SupportNumberMarkup from "../../common/SupportNumberMarkup";
import { AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW } from "../../constants/constants";
import {
  fetchPhoneSettings,
  updatePhoneSettings,
} from "../../redux/actions/crmOrders";
import { objectDifference } from "../../utils/helper";

import WidgetLanguageMarkup from "../../common/WidgetLanguageMarkup";
import OptinCheckboxesMarkup from "../../common/OptinCheckboxesMarkup";
import CheckoutOptinGuideModal from "../AbandonedCart/CheckoutOptinGuideModal";
// import {
//   updateOptinWidgetSettins,
//   fetchOptinWidgetSettings,
// } from "../../redux/actions/crmOrders";

import { applyOptin, fetchOptinAdminDetails } from "../../redux/actions/optIn";
import GuideModal from "../../common/GuideModal";
import TooltipOverlay from "../../common/Tooltip";
import obj from '../../content/AdminConfiguration/OverlaysContent/en.json'

const AdminConfiguration = (props: any) => {
  const [shopId, setShopId] = useState("");
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [is_loading, setIs_loading] = useState(false);
  const [allPlacesUnchecked, setAllPlacesUnchecked] = useState(false);
  const [openGuideModal, setOpenGuideModal] =
    useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const [open_checkout_optin_modal, setopen_checkout_optin_modal] =
    useState(false);
  const shopData = JSON.parse(localStorage.getItem("shop") || "{}");
  const [checkoutOptinRequest, setCheckoutOptinRequest] = useState({
    status: null,
    responseDetails: null,
  });
  const [checkout_optin_modal_steps_completed] = useState(false);
  const [isStoreFrontOptinEnabled, setIsStoreFrontOptinEnabled] = useState(true);

  const [hasChanges, setHasChanges] = useState(false);
  const [dataChanges, setDataChanges] = useState<any>({
    optin: null,
    phone: null,
  });

  const [initialData, setInitialData] = useState({
    enabled: false,
    language: "ENGLISH",
    places: [],
  });

  const [optinData, setOptinData] = useState({
    enabled: false,
    language: "ENGLISH",
    places: [],
  });
  const [refOptinData, setRefOptinData] = useState({
    enabled: false,
    language: "ENGLISH",
    places: [],
  });

  const [initialPhoneData, setInitialPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [phoneData, setPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [refPhoneData, setRefPhoneData] = useState({
    countryCode: "",
    phone: "",
  });

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShopId(shop.id);
      getPhoneData();
      // getOptinSettingData(shop.id);
      getOptinAdminDetails(shop.id);
    }
  }, []);

  useEffect(() => {
    var diffObj: any = objectDifference(refPhoneData, phoneData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        phone: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, phone: null }));
    }
  }, [phoneData]);

  useEffect(() => {
    var diffObj: any = objectDifference(refOptinData, optinData);
    if (Object.keys(diffObj).length > 0) {
      if ("places" in diffObj) {
        diffObj.widgetPlaces = diffObj.places;
        delete diffObj.places;
        setDataChanges((dataChanges: any) => ({
          ...dataChanges,
          optin: diffObj,
        }));
      } else {
        setDataChanges((dataChanges: any) => ({
          ...dataChanges,
          optin: diffObj,
        }));
      }
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, optin: null }));
    }
  }, [optinData]);

  useEffect(() => {
    if (dataChanges.phone || dataChanges.optin) {      
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  const getPhoneData = () => {
    fetchPhoneSettings().then((res: any) => {
      setInitialPhoneData(res);
      setRefPhoneData(res);
      setPhoneData(res);
    });
  };

  // const getOptinSettingData = (shopId: string) => {
  //   fetchOptinWidgetSettings(shopId).then((res: any) => {
  //     if (res.status === "success") {
  //       setInitialData(res.settings);
  //       setRefOptinData(res.settings);
  //       setOptinData(res.settings);
  //       setIsStoreFrontOptinEnabled(res.settings.enabled);
  //     } else {
  //       showErrorToast(res);
  //     }
  //   });
  // };

  const getOptinAdminDetails = (shopId: string) => {
    fetchOptinAdminDetails(shopId).then((res: any) => {
      if (res.status === "success") {
        setCheckoutOptinRequest(res.optin);
      } else if (res !== 204) {
        showErrorToast(res);
      }
    });
  };
  const loadingMarkup = is_loading && <Loading />;

  const validateSupportCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!phoneData.countryCode || phoneData.countryCode.match(cc)) {
      return false;
    } else if (phoneData.countryCode && phoneData.countryCode[0] === "0") {
      return "cannot start with 0";
    } else {
      return "enter a valid country code";
    }
  };

  const validateSupportPhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!phoneData.phone || phoneData.phone.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number";
    }
  };
  const resetData = () => {
    setDataChanges({
      phone: null,
      optin: null,
    });
    setRefPhoneData(initialPhoneData);
    setPhoneData(initialPhoneData);
    setRefOptinData(initialData);
    setOptinData(initialData);
    setHasChanges(false);
  };

  const updateSettings = async () => {        
    if (!phoneData.countryCode || validateSupportCountryCode()) {
      showErrorToast("A valid support country code must be given");
      return;
    }

    if (!phoneData.phone || validateSupportPhoneNo()) {
      showErrorToast("A valid support phone number must be given");
      return;
    }

    if (dataChanges.phone !== null && dataChanges.optin !== null) {
      {
        await updatePhoneSettings(dataChanges.phone).then((response: any) => {
          showToast("Data Saved");
          setDataChanges({ ...dataChanges, phone: null });
          setInitialPhoneData((data: any) => ({
            ...data,
            ...response?.setting,
          }));
          setRefPhoneData((data: any) => ({
            ...data,
            ...response?.setting,
          }));
          setPhoneData((data: any) => ({
            ...data,
            ...response?.setting,
          }));
        });
      }
      // {
      //   let params = new URLSearchParams();
      //   let dataOptin: any = dataChanges.optin;
      //   Object.keys(dataOptin).forEach((key) => {
      //     params.append(key, dataOptin[key]);
      //   });
      //   await updateOptinWidgetSettins(shopId, params).then((response: any) => {
      //     if (response.status === "success") {
      //       showToast("Data Saved");
      //       setInitialData(response.settings);
      //       setRefOptinData(response.settings);
      //       setOptinData(response.settings);
      //       setDataChanges({ ...dataChanges, optin: null });
      //     } else {
      //       showErrorToast(response);
      //     }
      //   });
      //   setHasChanges(false);
      //   return;
      // }
    }

    if (dataChanges.phone !== null) {
      updatePhoneSettings(dataChanges.phone).then((response: any) => {
        showToast("Data Saved");
        setDataChanges({ ...dataChanges, optin: null });
        setInitialPhoneData((data: any) => ({
          ...data,
          ...response?.setting,
        }));
        setRefPhoneData((data: any) => ({
          ...data,
          ...response?.setting,
        }));
        setPhoneData((data: any) => ({
          ...data,
          ...response?.setting,
        }));
      });
    }

    // if (dataChanges.optin !== null) {
    //   let params = new URLSearchParams();
    //   var data: any = dataChanges.optin;

    //   Object.keys(data).forEach((key) => {
    //     params.append(key, data[key]);
    //   });

    //   updateOptinWidgetSettins(shopId, params).then((response: any) => {
    //     if (response.status === "success") {
    //       showToast("Data Saved");
    //       setInitialData(response.settings);
    //       setRefOptinData(response.settings);
    //       setOptinData(response.settings);
    //       setIsStoreFrontOptinEnabled(response.settings.enabled);
    //       setDataChanges({ ...dataChanges, optin: null });          
    //     } else {
    //       showErrorToast(response);
    //     }
    //   });
    // }

    setHasChanges(false);
  };

  const ApplyForApprovalClick = () => {
    const shop_origin = shopData.url || "";
    var data = {};

    if (!checkoutOptinRequest.status) {
      data = {
        shop: shop_origin,
      };
    } else {
      data = {
        status: 1,
        is_email_sent: false,
      };
    }

    setIs_loading(true);

    applyOptin(shopId).then((res: any) => {
      setIs_loading(false);
      setCheckoutOptinRequest(res);
      getOptinAdminDetails(shopId);
    });
  };

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateSettings,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  const handlePhoneChange = (field: string, value: any) => {
    setPhoneData((data) => ({ ...data, [field]: value }));
  };

  const handleOptinChange = (field: string, value: any) => {
    setOptinData((data) => ({ ...data, [field]: value }));
  };

  const handlePlaceChange = (data: string[]) => {

    if (isStoreFrontOptinEnabled && allPlacesUnchecked && (data && data.length === 0)) {
      showErrorToast(
        "Since Storefront opt-in widget is enabled, at least one display option needs to be selected"
      );
      setHasChanges(false)
      return;
    }
    if (data && data.length > 0) {
      setHasChanges(true);
      setOptinData((optin: any) => ({ ...optin, places: data }));
    }
  };

  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <a
          className="Polaris-Link Polaris-Link-Updated-Color"
          href={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
          data-polaris-unstyled="true"
          target="_blank"
          rel="noopener noreferrer"
        >
          phone number
        </a>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <a
          className="Polaris-Link Polaris-Link-Updated-Color"
          href={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
          data-polaris-unstyled="true"
          target="_blank"
          rel="noopener noreferrer"
        >
          business name
        </a>{" "}
        and{" "}
        <a
          className="Polaris-Link Polaris-Link-Updated-Color"
          href={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
          data-polaris-unstyled="true"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp support number
        </a>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );

  const checkoutoptintext = (
    <>
      <p>
        <b>
          WhatsApp requires users to opt-in before we are allowed to send
          automated messages.
        </b>
      </p>
      <br />
      Complete the steps in the guide and apply for approval to start collecting
      opt-ins from Checkout page.
    </>
  );

  const getCheckoutOptinDynamicContent = () => {
    const modalStepsCompletedContent = checkout_optin_modal_steps_completed ? (
      <span>
        <Button primary onClick={ApplyForApprovalClick}>
          {checkoutOptinRequest.status === "PENDING"
            ? "Re-apply for approval"
            : "Apply for approval"}
        </Button>
      </span>
    ) : (
      <span>
        <Button
          primary
          onClick={() => {
            setopen_checkout_optin_modal(true);
          }}
        >
          View guide and complete steps (takes 2 minutes)
        </Button>
      </span>
    );

    if (checkoutOptinRequest) {
      if (!checkoutOptinRequest.status) {
        return modalStepsCompletedContent;
      } else if (checkoutOptinRequest.status === "PENDING") {
        return (
          <Banner>
            <p>
              Your request has been <b>received</b>. You will get a response
              from us on your registered email address.
            </p>
          </Banner>
        );
      } else if (checkoutOptinRequest.status === "APPROVED") {
        return (
          <div>
            <Banner status="success">
              <p>
                Your request has been <b>approved</b>. WhatsApp Opt-in is now
                being collected from the Checkout page.
              </p>
            </Banner>
            <br />
            <p>
              <TextStyle variation="subdued">
                No further action required from your side. You can now disable
                the storefront opt-in widget if you want to (see section below).
              </TextStyle>
            </p>
          </div>
        );
      } else if (checkoutOptinRequest.status === "REJECTED") {
        return (
          <span>
            <Banner status="warning">
              <p>
                Your request has been <b>rejected</b>. Please fix the issue and
                contact the support team.
              </p>
              <br />
              <p>
                <b>Reason for rejection:</b>{" "}{checkoutOptinRequest.responseDetails}
              </p>
            </Banner>
            <br />
            {modalStepsCompletedContent}
          </span>
        );
      }
    }
  };

  const optintext = (
    <span>
      <p>
        <b>
          {
            "WhatsApp requires users to opt-in before we are allowed to send automated messages."
          }
        </b>
        <br />
        <br />
        Storefront opt-in widget is enabled by default if you enable any
        Automated message template.
        <br />
        <br />
        You can disable Storefront opt-in widget after getting approval for
        collecting opt-ins from Checkout page.
        <br />
        <br />
        <b>Note: </b>Opt-in widget is enabled by default for the “Thank you
        page“ if you enable any Automated Orders CRM template.
      </p>
    </span>
  );

  const checkoutOptinContent = (
    <>
      {checkoutOptinRequest.status === "REJECTED" ? null : (
        <div className="mt-2">
          <Heading element="p">
            Recover more sales by collecting WhatsApp opt-in from Checkout page
          </Heading>
          <br />
          <TextStyle variation="subdued">
            Complete the steps in the guide and apply for approval. We will
            manually check your store checkout page to verify the changes.
          </TextStyle>
        </div>
      )}
      <Card>
        <Card.Section>
          <div className="removebannermargin">
            {getCheckoutOptinDynamicContent()}
          </div>
        </Card.Section>
      </Card>
    </>
  );

  const disableOptinCheckboxTooltipContent =
    checkoutOptinRequest && checkoutOptinRequest.status === "APPROVED" ? (
      <Checkbox
        label={"Disable opt-in widget from storefront "}
        checked={!optinData.enabled}
        onChange={(val) => handleOptinChange("enabled", !val)}
        disabled={false}
      />
    ) : (
      <Tooltip
        preferredPosition="above"
        content="This option is available after getting approval for Checkout page opt-in"
      >
        <Checkbox
          label={"Disable opt-in widget from storefront "}
          checked={!optinData.enabled}
          onChange={(val) => handleOptinChange("enabled", !val)}
          disabled={true}
        />
      </Tooltip>
    );

  const disableOptinOnStorefrontMarkup = (
    <Card sectioned>
      {disableOptinCheckboxTooltipContent}
      <br />
      <br />
      <p>
        <TextStyle variation="subdued">
          Storefront opt-in widget is <b>enabled by default</b>. You can disable
          the storefront widget after getting approval for collecting opt-ins
          from Checkout page.
        </TextStyle>
      </p>
    </Card>
  );

  const closeCheckoutOptinGuideModal = () => {
    setopen_checkout_optin_modal(false);
  };

  const checkoutOptinModalStepsCompleted = () => {
    setopen_checkout_optin_modal(false);
    ApplyForApprovalClick();
  };
  return (
    <Page title="Admin Configurations" divider>
      {toastMarkup}
      {errorToastMarkup}
      <>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        btn2Action={() => {
          setTabIndex(1);
          setOpenGuideModal(true);
        }}
        obj={obj}
        showPricingBanner={props.showPricingBanner}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
        {ctxSaveBarMarkup}
        {/* {loadingMarkup} */}

        <Layout.AnnotatedSection
          title="WhatsApp Configuration"
          description={description}
        >
          <Stack vertical>
            <div className="mt-2">
              <SupportNumberMarkup
                phoneNumber={phoneData.phone}
                countryCode={phoneData.countryCode}
                onCountryChange={(val: any) => {
                  handlePhoneChange("countryCode", val);
                }}
                onNumberChange={(val: any) => {
                  handlePhoneChange("phone", val);
                }}
              ></SupportNumberMarkup>
            </div>
            {/* {templateLanguageSettingMarkup} */}
          </Stack>
        </Layout.AnnotatedSection>
      </>
      {/* <>
        {ctxSaveBarMarkup}
        {loadingMarkup}
        {open_checkout_optin_modal ? (
          <CheckoutOptinGuideModal
            open={true}
            completed={checkout_optin_modal_steps_completed}
            closeModal={closeCheckoutOptinGuideModal}
            stepsCompleted={checkoutOptinModalStepsCompleted}
            shopName={localStorage.getItem("shop")}
          />
        ) : null}

        <Layout.AnnotatedSection
          title="Collect WhatsApp opt-in from Checkout Page"
          description={checkoutoptintext}
        >
          {checkoutOptinContent}
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Collect WhatsApp opt-in from Storefront widget"
          description={optintext}
        >
          <Stack vertical>
            {disableOptinOnStorefrontMarkup}

            <Card sectioned>
              <WidgetLanguageMarkup
                language={optinData.language}
                enabled={true}
                onLanguageChange={(val: any) => {
                  handleOptinChange("language", val);
                }}
              ></WidgetLanguageMarkup>
            </Card>
            <Card sectioned>
              <OptinCheckboxesMarkup
                language={optinData.language}
                places={optinData.places}
                enabled={true}
                onPlaceChange={handlePlaceChange}
                showErrorToast={showErrorToast}
                setAllPlacesUnchecked={setAllPlacesUnchecked}
                isStoreFrontOptinEnabled={isStoreFrontOptinEnabled}
                allPlacesUnchecked={allPlacesUnchecked}
              ></OptinCheckboxesMarkup>
            </Card>
          </Stack>
        </Layout.AnnotatedSection>
      </> */}
    </Page>
  );
};

export default AdminConfiguration;
