import { useEffect, useState } from "react";
import {
  Card,
  Layout,
  Link,
  Modal,
  Select,
  Spinner,
  Stack,
  TextContainer,
} from "@shopify/polaris";
import { getDiscountCode } from "../../../redux/actions/manual-messaging";
function SendMessageModal({
  showToast,
  showErrorToast,
  open,
  closeModal,
  allTemplatesAbCart,
  currentSelectedOrder,
  owner,
  shopId,
  isWhatsappWebEnabled,
}: any) {
  const [openModal, setOpenModal] = useState(open);
  const [selected, setSelected] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [isSendButtonActive, setIsSendButtonActive] = useState(false);

  const handleSelectChange = (value: any) => {
    setSelected(value);
    setIsSendButtonActive(true);
  };

  const getAbandonedCartSettingsMarkup = () => {
    return (
      <>
        <Stack vertical>
          {cartRecoveryTemplateMarkup}
          {nudgeCheckoutOptinScrollSection}
        </Stack>
      </>
    );
  };

  const mobilecheck = () => {
    var check = false;
    //eslint-disable-next-line
    (function (a: any) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  const getWhatsappLink = () => {
    if (mobilecheck()) {
      return "api";
    }
    return "web";
  };

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const resetData = () => {};

  const fetchDiscountCode = async (selected: any) => {
    setShowSpinner(true);
    let discountObj = {
      foundDiscountCode: false,
      code: "",
      discountRatio: "",
    };
    let template = allTemplatesAbCart.find((o: any) => o.message === selected);
    if (template.discount) {
      await getDiscountCode(shopId, template.id).then((response: any) => {
        if (response.status === "success") {
          discountObj.foundDiscountCode = true;
          discountObj.code = response.discountCode;
          discountObj.discountRatio = template.discountRatio;
        } else {
          showErrorToast(response);
        }
      });
    }
    return discountObj;
  };

  const updateSettings = async () => {
    const discount = await fetchDiscountCode(selected);
    //Updating Object Key
    const formattedObject = {
      cart_items: "",
      first_name: "",
      order_value: "",
      currency: "INR",
      details: "",
    };

    if (discount.foundDiscountCode) {
      currentSelectedOrder.discount = `${discount.discountRatio}`;
      currentSelectedOrder.discountCode = `"${discount.code}"`;
      currentSelectedOrder.checkoutUrl = `${currentSelectedOrder.checkoutUrl}&discount=${discount.code}`;
    } else {
      currentSelectedOrder.discount = "";
    }
    delete Object.assign(formattedObject, currentSelectedOrder, {
      ["checkout_url"]: currentSelectedOrder["checkoutUrl"],
    })["checkoutUrl"];
    delete Object.assign(formattedObject, currentSelectedOrder, {
      ["first_name"]: currentSelectedOrder["customerName"],
    })["customerName"];
    delete Object.assign(formattedObject, currentSelectedOrder, {
      ["order_value"]: currentSelectedOrder["total"],
    })["total"];

    const allItems = JSON.parse(formattedObject.details);
    let itemsString = "";
    if (allItems.length > 0) {
      allItems.forEach((el: any) => {
        itemsString += `${el.c} X ${el.p},`;
      });
    }
    formattedObject.cart_items = itemsString;

    formattedObject.order_value = `${formattedObject.order_value} ${formattedObject.currency}`;

    function populateTemplateUsingJSON(
      formattedObject: any,
      stringTemplate: string
    ) {
      Object.keys(formattedObject).forEach((key) => {
        stringTemplate = stringTemplate.replaceAll(
          "{{" + key + "}}",
          formattedObject[key]
        );
      });
      return stringTemplate;
    }

    let finalString = populateTemplateUsingJSON(formattedObject, selected);

    const link = isWhatsappWebEnabled
      ? `https://${getWhatsappLink()}.whatsapp.com/send?phone=${
          currentSelectedOrder.phone
        }&text=${encodeURIComponent(finalString)}`
      : `https://wa.me/${currentSelectedOrder.phone}?text=${encodeURIComponent(
          finalString
        )}`;

    setShowSpinner(false);
    const open = window.open(link);
    if (open === null || typeof open === "undefined") {
      showErrorToast(
        "Please turn off your pop-up blocker from settings to redirect to whatsapp"
      );
    }
    setIsSendButtonActive(false);
    setSelected("");
    closeModal();
  };

  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  const getOptionsForTags = () => {
    const optionss = allTemplatesAbCart.map((template: any) => ({
      label: template.name,
      value: template.message,
    }));
    return optionss;
  };

  const cartRecoveryTemplateMarkup = (
    <Card>
      <Card.Section>
        <TextContainer>
          <Select
            label=""
            value={selected}
            options={getOptionsForTags()}
            onChange={handleSelectChange}
            placeholder={"Select..."}
            helpText="Select message from list above"
            key={selected}
          />
        </TextContainer>

        {showSpinner ? (
          <Spinner accessibilityLabel="Small spinner example" size="large" />
        ) : null}
      </Card.Section>
    </Card>
  );
  return (
    <Modal
      open={openModal}
      onClose={() => {
        setIsSendButtonActive(false);
        setShowSpinner(false);
        setSelected("");
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Send message"}
      primaryAction={{
        content: "Send",
        onAction: updateSettings,
        disabled: !isSendButtonActive,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setIsSendButtonActive(false);
            setShowSpinner(false);
            setSelected("");
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getAbandonedCartSettingsMarkup()}</Modal.Section>
    </Modal>
  );
}

export default SendMessageModal;
