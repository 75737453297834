import React, { useState, useEffect } from "react";
import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Link,
  Stack,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { getCallOutData } from "../../redux/actions/chat-settings";
import CallOut from "../../types/Callout.type";
import { isNullOrEmpty, objectDifference } from "../../utils/helper";

const CallOutTag = (props: any) => {
  const [initialData, setInitialData] = useState<CallOut>({
    shopId: "",
    callout_card_delay: 5,
    callout_card_time_delay: 5,
    is_callout_card_enabled: true,
    is_callout_card_sound_enabled: true,
    callout_card_text: "",
  });
  const [calloutCard, setCalloutCard] = useState<CallOut>({
    shopId: "",
    callout_card_delay: 5,
    callout_card_time_delay: 5,
    is_callout_card_enabled: true,
    is_callout_card_sound_enabled: true,
    callout_card_text: "",
  });
  const [refcalloutCard, setRefCalloutCard] = useState<CallOut>({
    shopId: "",
    callout_card_delay: 5,
    callout_card_time_delay: 5,
    is_callout_card_enabled: true,
    is_callout_card_sound_enabled: true,
    callout_card_text: "",
  });
  const [updatedCalloutCard, setUpdatedCalloutCard] = useState<any>({});
  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      fetchCallOutData(shop.id);
    }
  }, []);

  useEffect(() => {
    if (props.isDataSubmitted) {
        setRefCalloutCard(calloutCard);
        setInitialData(calloutCard);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    var diffObj: any = objectDifference(refcalloutCard, calloutCard);
    setUpdatedCalloutCard({});
    // Update the deffObj it's return new and old all value
    diffObj = {...calloutCard,...diffObj};
    if (Object.keys(diffObj).length !== 0) {
      if ("callout_card_delay" in diffObj)
        updatecalloutCard("delay", diffObj.callout_card_delay);
      if (diffObj.callout_card_time_delay)
        updatecalloutCard("timeDelay", diffObj.callout_card_time_delay);
      if ("is_callout_card_enabled" in diffObj)
        updatecalloutCard("enabled", diffObj.is_callout_card_enabled);
      if ("is_callout_card_sound_enabled" in diffObj)
        updatecalloutCard(
          "notification",
          diffObj.is_callout_card_sound_enabled
        );

      if (diffObj.callout_card_text)
        updatecalloutCard("text", diffObj.callout_card_text);
    } else {
      setUpdatedCalloutCard({});
    }
  }, [calloutCard]);

  useEffect(() => {
    let errorMessage = getErrorMessage();
    if (errorMessage) {
      props.onError(errorMessage, 'callout');
      props.onChanges(null);
    } else {
      props.onError('', 'callout');
      props.onChanges(updatedCalloutCard);
    }
  }, [updatedCalloutCard]);

  useEffect(() => {
    if (props.reset) {
      setRefCalloutCard(initialData);
      setCalloutCard(initialData);
    }
  }, [props.reset]);

  const getErrorMessage = () => {
    if (validateCalloutCardDelay() !== false) {
      return "Invalid Callout Card Delay";
    } else if (validateCalloutCard() !== false) {
      return "Invalid Callout Card Text";
    } else {
      return false;
    }
  }

  const fetchCallOutData = (shopId: string) => {
    getCallOutData(shopId).then(
      (data: any) => {
        setCallOut(data);
        setInitialData(data);
      },
      (err) => {
        console.log("Call Out Error",err);
      }
    ).catch((error) => {
      console.log('exeption error ',error);
    });
  };

  const setCallOut = (data: any) => {
    setRefCalloutCard(data);
    setCalloutCard(data);
  };

  const handleChange = (val: any, field: string) => {
    setCalloutCard((callout) => ({ ...callout, [field]: val }));
  };

  const updatecalloutCard = (field: string, val: any) => {
    setUpdatedCalloutCard((callout: any) => ({ ...callout, [field]: val }));
  };

  const calloutCardDescription = (
    <span>
      <p>
        Callout card makes the chat button more noticeable to new visitors of
        your store.
      </p>
      <br />
      <p>
        To ensure a smooth non-obtrusive experience, callout card is displayed
        only once per day. Open your store in incognito browser if you are
        testing changes.
      </p>
    </span>
  );

  const validateCalloutCardDelay = () => {
    if (calloutCard.callout_card_delay && calloutCard.callout_card_delay < 5) {
      return "Minimum delay is 5 seconds";
    } else if (
      calloutCard.callout_card_delay &&
      calloutCard.callout_card_delay > 60
    ) {
      return "Maximum delay is 60 seconds";
    } else if (!calloutCard.callout_card_delay) {
      return "Callout card delay cannot be empty";
    }
    return false;
  };

  const validateCalloutCard = () => {
    if (
      calloutCard.callout_card_text &&
      calloutCard.callout_card_text.length > 60
    ) {
      return "Max 60 characters";
    }

    if (isNullOrEmpty(calloutCard.callout_card_text)) {
      return "Callout Card Text cannot be empty"
    }
    return false;
  };

  return (
    <Layout.AnnotatedSection
      title="Callout Card"
      description={calloutCardDescription}
    >
      <Card sectioned>
        <FormLayout>
          <Stack alignment="center" spacing="tight" distribution="leading">
            <Checkbox
              checked={calloutCard.is_callout_card_enabled}
              label={
                <p className="polaris-chice-margin-right">
                  Show callout card above chat button
                </p>
              }
              onChange={(val) => {
                handleChange(val, "is_callout_card_enabled");
              }}
            />
            <Tooltip
              content="A store visitor will see the callout card once in 24 hours"
              active={false}
            >
              <img
                src="https://cdn.shopify.com/s/files/1/0272/5983/0365/files/Vector_2.png?25"
                alt="info icon"
                className="callout-card-tooltip-image"
              />
            </Tooltip>{" "}
            <Link
              url={
                "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/callout_card_chat_widget_preview.png?1385"
              }
              external={true}
            >
              Preview
            </Link>
          </Stack>
          <TextField
            label="Callout Card Text"
            value={calloutCard.callout_card_text}
            onChange={(val) => {
              handleChange(val, "callout_card_text");
            }}
            placeholder="This text will display on the callout card above the chat button."
            type={"text"}
            error={validateCalloutCard()}
          />

          <FormLayout.Group>
            <TextField
              label="Callout card delay"
              type="number"
              min={5}
              max={60}
              suffix="seconds"
              value={calloutCard.callout_card_delay.toString()}
              onChange={(val) => {
                handleChange(val, "callout_card_delay");
              }}
              helpText={
                "Time after which callout card is displayed, after chat button has loaded"
              }
              error={validateCalloutCardDelay()}
            />
          </FormLayout.Group>

          <Stack alignment="center" spacing="tight" distribution="leading">
            <Checkbox
              checked={calloutCard.is_callout_card_sound_enabled}
              label={
                <span className="polaris-chice-margin-right">
                  Play notification sound
                </span>
              }
              onChange={(val) => {
                handleChange(val, "is_callout_card_sound_enabled");
              }}
            />
            <Tooltip
              content="Notification sound is not supported by all browsers, and requires users to allow autoplaying of media files."
              active={false}
            >
              <img
                src="https://cdn.shopify.com/s/files/1/0272/5983/0365/files/Vector_2.png?25"
                alt="info icon"
                className="callout-card-tooltip-image"
              />
            </Tooltip>
          </Stack>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
};
export default CallOutTag;
