import React, { useEffect, useState } from "react";
import {
  Card,
  FormLayout,
  Layout,
  RadioButton,
  Stack,
  TextContainer
} from "@shopify/polaris";
import {
  DEVICE_TYPE_DESKTOP_ONLY,
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  DEVICE_TYPE_MOBILE_ONLY,
} from "../../../../constants/constants";
import { getShareDevices } from "../../../../redux/actions/share-settings";
import ShareMobileConfig from "./MobileConfig";
import ShareDesktopConfig from "./DesktopConfig";

const ShareDevices = (props: any) => {
  const [mobileDeviceData, setMobileDeviceData] = useState({
    deviceType: "mobile",
    sharePosition: "left",
    verticalOffset: "",
    horizontalOffset: "",
    productPageHeight: true,
    productPageOffset: "",
    verticalOffsetShare: "50"
  });
  const [desktopDeviceData, setDesktopDeviceData] = useState({
    deviceType: "desktop",
    sharePosition: "left",
    verticalOffset: "",
    horizontalOffset: "",
    productPageHeight: true,
    productPageOffset: "",
    verticalOffsetShare: "50"
  });
  const [updatedDevices, setUpdatedDevices] = useState<any>({ mobile: {}, desktop: {} });

  const [deviceType, setDeviceType] = useState(DEVICE_TYPE_MOBILE_AND_DESKTOP);
  const [initialDeviceType, setInitialDeviceType] = useState(DEVICE_TYPE_MOBILE_AND_DESKTOP);

  const [mobileSelector, setMobileSelector] = useState("");
  const [desktopSelector, setDesktopSelector] = useState("");

  useEffect(() => {
    let data = updatedDevices;
    switch (deviceType) {
      case DEVICE_TYPE_MOBILE_ONLY:
        data['desktop'] = {};
        break;
      case DEVICE_TYPE_DESKTOP_ONLY:
        data['mobile'] = {};
        break;
    }
    props.onChanges(data);
  }, [updatedDevices]);


  useEffect(() => {
    props.getDeviceData({
      deviceType,
      mobileSelector,
      desktopSelector
    })
  }, [mobileSelector, desktopSelector, deviceType])

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      fetchDeviceData(shop.id);
    }
  }, []);

  useEffect(() => {
    if (props.reset) {
      setDeviceType(initialDeviceType);
    }
  }, [props.reset]);

  const fetchDeviceData = (shopId: string) => {
    getShareDevices(shopId).then(
      (data: any) => {
        if (data && data.status === "success") {
          setData(data.devices);
        }
      },
      (err) => { }
    );
  };

  const setData = (data: any) => {

    let mobile = data.find((m: any) => m.deviceType === 'mobile');
    let desktop = data.find((m: any) => m.deviceType === 'desktop');

    if (mobile) {
      setMobileDeviceData(mobile);
      setMobileSelector(mobile.sharePosition);
    } else {
      // sets initial data if undefined || null
      props.onChanges({ ...updatedDevices, mobile: { ...mobileDeviceData } });
      setMobileSelector(mobileDeviceData.sharePosition);
    }

    if (desktop) {
      setDesktopDeviceData(desktop);
      setDesktopSelector(desktop.sharePosition);
    } else {
      // sets initial data if undefined || null
      props.onChanges({ ...updatedDevices, desktop: { ...desktopDeviceData } });
      setDesktopSelector(desktopDeviceData.sharePosition);
    }

    if (mobile && !desktop) {
      setDeviceType(DEVICE_TYPE_MOBILE_ONLY);
      setInitialDeviceType(DEVICE_TYPE_MOBILE_ONLY);
      props.onTypeChange(DEVICE_TYPE_MOBILE_ONLY, false);
    } else if (desktop && !mobile) {
      setDeviceType(DEVICE_TYPE_DESKTOP_ONLY);
      setInitialDeviceType(DEVICE_TYPE_DESKTOP_ONLY);
      props.onTypeChange(DEVICE_TYPE_DESKTOP_ONLY, false);
    } else {
      setDeviceType(DEVICE_TYPE_MOBILE_AND_DESKTOP);
      setInitialDeviceType(DEVICE_TYPE_MOBILE_AND_DESKTOP);
      props.onTypeChange(DEVICE_TYPE_MOBILE_AND_DESKTOP, false);
    }
  };

  const handleDevicesChange = (type: any) => {
    setDeviceType(type);
    props.onTypeChange(type, true);
  };

  const updateChanges = (data: any) => {
    if (data) {
      if (data.deviceType === 'mobile') {
        setUpdatedDevices({ ...updatedDevices, mobile: data });
      } else if (data.deviceType === 'desktop') {
        setUpdatedDevices({ ...updatedDevices, desktop: data });
      }
    }
  }

  const deviceMarkup = (
    <>
      <ShareMobileConfig
        data={mobileDeviceData}
        selected={deviceType}
        onChanges={updateChanges}
        isDataSubmitted={props.isDataSubmitted}
        reset={props.reset}
        onError={props.onError}
        setMobileSelector={setMobileSelector}></ShareMobileConfig>
      <ShareDesktopConfig
        data={desktopDeviceData}
        selected={deviceType}
        onChanges={updateChanges}
        isDataSubmitted={props.isDataSubmitted}
        reset={props.reset}
        onError={props.onError}
        setDesktopSelector={setDesktopSelector}></ShareDesktopConfig>
    </>
  );

  return (
    <Layout.AnnotatedSection
      title="Button Display & Position"
      description="Change the display position of the share button to best suit your store's design."
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <TextContainer>
                <p>Share Button Display</p>
              </TextContainer>
              <Stack>
                <RadioButton
                  label="Mobile + Desktop"
                  checked={deviceType === DEVICE_TYPE_MOBILE_AND_DESKTOP}
                  id={DEVICE_TYPE_MOBILE_AND_DESKTOP.toString()}
                  name="mobilecheck-share"
                  onChange={() =>
                    handleDevicesChange(DEVICE_TYPE_MOBILE_AND_DESKTOP)
                  }
                />
                <RadioButton
                  label="Mobile only"
                  name="mobilecheck-share"
                  id={DEVICE_TYPE_MOBILE_ONLY.toString()}
                  checked={deviceType === DEVICE_TYPE_MOBILE_ONLY}
                  onChange={() => handleDevicesChange(DEVICE_TYPE_MOBILE_ONLY)}
                />
                <RadioButton
                  label="Desktop only"
                  name="mobilecheck-share"
                  id={DEVICE_TYPE_DESKTOP_ONLY.toString()}
                  checked={deviceType === DEVICE_TYPE_DESKTOP_ONLY}
                  onChange={() => handleDevicesChange(DEVICE_TYPE_DESKTOP_ONLY)}
                />
              </Stack>
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      {deviceMarkup}
    </Layout.AnnotatedSection>
  );
};

export default ShareDevices;
