import { useState, useCallback, Fragment, useEffect } from "react";
import { Banner, Button, Card, Layout, Link, SkeletonBodyText, SkeletonDisplayText, Stack, TextContainer } from "@shopify/polaris";
import ApplicationForm from "./ApplicationForm";
import ProfileDetails from "./ProfileDetails";
import SupportedIntegrations from "./SupportedIntegrations";
import { loadWabaDetails } from "../../redux/actions/private-waba";
import PageMarkup from "../../common/PageMarkup";
import { getIntegrationsListForShop } from "../../redux/actions/private-waba";

declare global {
    interface Window {
        wabaStatusPollingInProgress:boolean;
    }
}

const PrivateWABA = () => {

    const [shop, setShop] = useState({id: '', url: ''});
    const [selected, setSelected] = useState(0);
    const [isDetailsLoading, setIsDetailsLoading] = useState(false);
    const [wabaProfile, setWabaProfile] = useState<any>({});
    const [toast, setToast] = useState('');
    const [errorToast, setErrorToast] = useState('');
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [otpSms, setOtpSms] = useState();
    const [timeoutForWabaCall, settimeoutForWabaCall] = useState<number>(300000);
    const [isSupportedIntegrationActivated, setIsSupportedIntegrationActivated] = useState(false);

    useEffect(() => {
        let shop = JSON.parse(localStorage.getItem("shop") || "{}");
        if (shop && shop.id) {
            setShop(shop);
            loadDetails(shop.id);
        }
        checkIntegrations(shop);
    }, []);
    const checkIntegrations = (shop:any) => {
        getIntegrationsListForShop(shop.id).then((response: any) => {
            if (response.status === 'success') {
                if(response.integrations.some((integration :any) => integration.active)){
                    setIsSupportedIntegrationActivated(true);
                }
            } else {
                setShowErrorToast((errorToast:any)=> !errorToast)
                setErrorToast(response);
            }
        }, err => {
            setShowErrorToast((errorToast:any)=> !errorToast)
            setErrorToast('Something went wrong. Unable to fetch integrations of shop.');
        });
    }
    const loadDetails = (shopId: string) => {
        setIsDetailsLoading(true);
        loadWabaDetails(shopId).then((response: any) => {
            if (response.status === "success") {
                let waba = response.waba;
                waba.timezone = response.waba.timezone.toString();
                setWabaProfile(waba);
                setOtpSms(waba.otpSms);
                setSelected(getSelectedTabIndex(waba));

                if (isPipeLineStatusUnknown(waba)) {
                    pollWABAStatus(shopId);
                }
            } else if (response === 204) {
                setSelected(0);
            } else {
                setErrorToast(response);
            }
            setIsDetailsLoading(false);
        }, err => {
            setErrorToast("Something went wrong");
            setIsDetailsLoading(false);
        });
    }

    const getSelectedTabIndex = (waba_profile: any) => {
        if(waba_profile.whatsappVerificationStatus === 'NONE') {
            return 0
        } else if (waba_profile.whatsappVerificationStatus !== 'WHATSAPP_VERIFICATION_DONE') {
            return 1
        } else {
            return 2
        }
    }

    const handleTabChange = useCallback(
        (selectedTabIndex) => setSelected(selectedTabIndex),
        []
    );

    const tabs = [
        {
            id: "checklist",
            content: "Checklist before starting",
            accessibilityLabel: "Checklist before starting",
            panelID: "checklist",
        },
        {
            id: "applicationForm",
            content: "Application Form",
            accessibilityLabel: 'Application Form',
            panelID: "applicationForm",
        },
        {
            id: "profile",
            content: "Profile Details",
            accessibilityLabel: 'Profile Details',
            panelID: "profile",
        },
    ];

    if (wabaProfile && wabaProfile.live && isSupportedIntegrationActivated ) {
        tabs.push({
          id: 'supportedIntegrations',
          content: "Supported integrations",
          accessibilityLabel: 'Supported integrations',
          panelID: 'supportedIntegrations'
        });
    }

    const proceedToApplicationForm = () => {
        setSelected(1);
    }

    const fetchWABAStatus = (shopId: string) => {
        var interval = setInterval(() => {
            loadWabaDetails(shopId).then((response: any) => {
                if (response.status === "success") {
                    let waba = response.waba;
                    let isWhatsappVerification = waba.whatsappVerificationStatus;  
                    waba.timezone = response.waba.timezone.toString();
                    setWabaProfile(waba);
                    if(!isPipeLineStatusUnknown(waba) || waba.live === true || (isWhatsappVerification && isWhatsappVerification === 'NONE') ) {
                        settimeoutForWabaCall(300000)
                        clearInterval(interval)
                        window.wabaStatusPollingInProgress = false
                    }
                } else {
                    window.wabaStatusPollingInProgress = false
                }
            }, err => {
                window.wabaStatusPollingInProgress = false;
            });
            // 5 minutes delay
        }, timeoutForWabaCall);
    }

    const isPipeLineStatusUnknown = (waba_profile: any) => {
        switch(waba_profile.pipeLineStage) {
            case 'FINALIZE':
            case 'VERIFY_OTP':
            case 'REGISTER_PHONE':
            case 'NONE':
            case 'CREATE_BUSINESS':
            return false
            default:
            return true
        }
    }

    const pollWABAStatus = (shopId: string) => {
        if(!window.wabaStatusPollingInProgress) {
            window.wabaStatusPollingInProgress = true
            fetchWABAStatus(shopId)
        }
    }

    const isApplicationProcessComplete = () => {
        if(wabaProfile.whatsappVerificationStatus === 'WHATSAPP_VERIFICATION_DONE') {
            return true
        } else{
          return false
        }
    }

    const ckDescription = (
        <TextContainer spacing="loose">
            <p>In the second step, you need to approve a request from Gupshup in your Facebook Business Manager account page.</p>
            <p>This is necessary to enable Gupshup to provide you with WhatsApp API services.</p>
        </TextContainer>
    );
    const quickOverviewMarkup = isDetailsLoading ? (
        <Card sectioned>
            <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
            </TextContainer>
        </Card>
    ) : (
        <Card sectioned>
            <TextContainer spacing="loose">
                <p>Here is a quick overview of the entire process.</p>
                <p><strong>Step 1: </strong>Setup your business details and Facebook business manager account details. Also select the display name &amp; phone number (not previously registered on WhatsApp) to be associated with your WhatsApp API account. These will be verified by Facebook and takes upto 7 days.</p>
                <p><strong>Step 2: </strong>You will receive a request from Gupshup on your Facebook business manager account, which you will need to approve. This request arrives 10-15 minutes after you "Apply for Approval" in Step 1.</p>
                <p><strong>Step 3: </strong>After WhatsApp verifies the phone number and display name, the final step is to verify the ownership of the phone number using an OTP / verification code sent to your number via IVR or SMS.</p>
            </TextContainer>
        </Card>
    );
    const checklistMarkup = isDetailsLoading ? (
        <Card sectioned>
            <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
            </TextContainer>
        </Card>
    ) : (
        <Card sectioned>
            <TextContainer spacing="loose">
                <p>Before you get started with the process, please make sure you have read and completed the following.</p>
                <p><strong>Facebook business manager account should be verified: </strong>Don't have an FB business manager account? <Link url='https://business.facebook.com/'>Create one</Link>. Go <Link url='https://www.facebook.com/business/help/2058515294227817'>here</Link> to get it verified.</p>
                <p><strong>Type of business: </strong>WhatsApp only allows certain type of businesses to apply for API access. You can read their policy to see whether you qualify <Link url='https://www.whatsapp.com/legal/commerce-policy/' external>here</Link>.</p>
                <p><strong>Phone number: </strong>Phone number should adhere to guidelines given <Link url='https://developers.facebook.com/docs/whatsapp/guides/phone-number/' external>here</Link>.</p>
                <p><strong>Display name: </strong>Display name should adhere to guidelines given <Link url='https://developers.facebook.com/docs/whatsapp/guides/display-name/' external>here</Link>.</p>
            </TextContainer>
        </Card>
    );
    const proceedToApplicationFormMarkup = isDetailsLoading ? (
        <Card sectioned>
            <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
            </TextContainer>
        </Card>
    ) : (
        <TextContainer spacing="loose">
            <p>If you have read and completed the required actions in the checklist, please proceed to the next step.</p>
            <Button primary onClick={() => proceedToApplicationForm()}>
                Proceed to application form
            </Button>
        </TextContainer>
    );

    const tabContent = {
        0: <Fragment>
                <Layout.AnnotatedSection
                    title="Checklist before starting the process"
                    description={ckDescription}>
                    <Stack vertical>
                        {quickOverviewMarkup}
                        {checklistMarkup}
                        {proceedToApplicationFormMarkup}
                    </Stack>
                </Layout.AnnotatedSection>
            </Fragment>,
        1: <ApplicationForm
                shop={shop}
                wabaProfile={wabaProfile}
                setWabaProfile={setWabaProfile}
                settimeoutForWabaCall={settimeoutForWabaCall}
                pollWABAStatus={pollWABAStatus}
                setToast={setToast}
                setErrorToast={setErrorToast}
                setShowSuccessToast={setShowSuccessToast}
                setShowErrorToast={setShowErrorToast}
                otpSms={otpSms}>
            </ApplicationForm>,
        2: isApplicationProcessComplete() ? (
                <ProfileDetails
                    shop={shop}
                    isLoading={isDetailsLoading}
                    wabaProfile={wabaProfile}
                    setToast={setToast}
                    setErrorToast={setErrorToast}
                    setShowSuccessToast={setShowSuccessToast}
                    setShowErrorToast={setShowErrorToast}
                    />
            ) : (
                <Fragment>
                    <Layout.Section>
                        <Banner
                            title="This section will be available after you have completed and gotten approved for your WhatsApp API application."
                            status="warning"/>
                    </Layout.Section>
                </Fragment>
            ),
        3: <SupportedIntegrations
            shop={shop}
            setToast={setToast}
            setErrorToast={setErrorToast}
            setShowSuccessToast={setShowSuccessToast}
            setShowErrorToast={setShowErrorToast}
            >
            </SupportedIntegrations>
    };

    return (
        <PageMarkup
            title="Apply for WhatsApp API to access for your phone number"
            subtitle="WhatsApp API access enables you to send automated messages from a number owned by you. The application form below is to apply for WhatsApp API access to your business."
            tutorial
            tutorialSrc="https://www.youtube.com/embed/Ru9cuOfWY7I"
            tabs={tabs}
            tabContent={tabContent}
            toast={toast}
            errorToast={errorToast}
            handleTabChange={handleTabChange}
            selected={selected} 
            showGupshupBaner={true}
            showErrorToast={showErrorToast}
            showSuccessToast={showSuccessToast}
            />
    );
}

export default PrivateWABA;