const get = require("lodash.get");

function capitalize(input) {
	input = input.toLowerCase();
	var words = input.split(" ");
	var CapitalizedWords = [];
	words.forEach((element) => {
		CapitalizedWords.push(
			element[0].toUpperCase() + element.slice(1, element.length)
		);
	});
	return CapitalizedWords.join(" ");
}

const sortObj = (obj, key) =>
	obj.sort((first, second) => (first[key] > second[key] ? -1 : 1));

	
const getAutomateMessageLanguage = (data) => {
	return get(data, "setting.waAutomateMessageTemplate", { language: "English" });
}

module.exports = { capitalize, sortObj, getAutomateMessageLanguage };
