import { Card, Checkbox, FormLayout, RadioButton, Stack, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { DEVICE_TYPE_MOBILE_AND_DESKTOP, DEVICE_TYPE_MOBILE_ONLY } from "../../../../constants/constants";
import DevicesType from "../../../../types/Devices.type";
import { objectDifference } from "../../../../utils/helper";

const ShareMobileConfig = (props: any) => {

    const [initialData, setInitialData] = useState<DevicesType>(props.data);
    const [data, setData] = useState<DevicesType>(props.data);
    const [refData, setRefData] = useState<DevicesType>(props.data);

    const [activateErrorCheckForVerticalOffsetShare, setActivateErrorCheckForVerticalOffsetShare] = useState(false);

    const [updatedData, setUpdatedData] = useState({});

    useEffect(() => {
        setRefData(props.data);
        setData(props.data);
        setInitialData(props.data);
    }, [props.data]);

    useEffect(() => {
        if (props.isDataSubmitted) {
            setRefData(data);
            setInitialData(data);
        }
    }, [props.isDataSubmitted]);

    useEffect(() => {
        var diffObj: any = objectDifference(refData, data);
        diffObj = { ...refData, ...data }
        setUpdatedData({});
        if (Object.keys(diffObj).length !== 0) {
            switch (props.selected) {
                case DEVICE_TYPE_MOBILE_AND_DESKTOP:
                case DEVICE_TYPE_MOBILE_ONLY:
                    setDataToUpdate(diffObj);
                    break;
            }
        } else {
            setUpdatedData({});
        }
    }, [data]);

    useEffect(() => {
        // props.onChanges(updatedData);
        let errorMessage = getErrorMessage();
        if (errorMessage) {
            props.onError(errorMessage, 'desktop');
            props.onChanges(null);
        } else {
            props.onError('', 'desktop');
            props.onChanges(updatedData);
        }
    }, [updatedData]);

    useEffect(() => {
        switch (props.selected) {
            case DEVICE_TYPE_MOBILE_AND_DESKTOP:
                props.onChanges(updatedData);
                break;
            case DEVICE_TYPE_MOBILE_ONLY:
                props.onChanges(updatedData);
                break;
        }
    }, [props.selected]);

    useEffect(() => {
        if (props.reset) {
            setRefData(initialData);
            setData(initialData);
        }
    }, [props.reset]);

    const getErrorMessage = () => {
        if (!data.sharePosition) {
            return "Invalid Share Button Position"
        }
        else if (validateVerticalOffsetShare() !== false) {
            return "Invalid Height Offset";
        } else {
            return false;
        }
    }

    const setDataToUpdate = (diffObj: any) => {
        let deviceType = "mobile";
        if ("sharePosition" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("sharePosition", diffObj.sharePosition);
        }
        if ("verticalOffset" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("verticalOffset", diffObj.verticalOffset);
        }
        if ("verticalOffsetShare" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("verticalOffsetShare", diffObj.verticalOffsetShare);
        }
        if ("horizontalOffset" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("horizontalOffset", diffObj.horizontalOffset);
        }
    };

    const setUpdatedMobileData = (field: string, value: any) => {
        setUpdatedData((mData: any) => ({ ...mData, [field]: value }));
    };

    const handleButtonPositionChange = (value: any) => {
        props.setMobileSelector(value);
        setData({ ...data, sharePosition: value });
    }

    const handleOffsetChange = (value: string) => {
        if (!activateErrorCheckForVerticalOffsetShare) {
            setActivateErrorCheckForVerticalOffsetShare(true)
        }
        setData({ ...data, verticalOffsetShare: value });
    };

    const validateVerticalOffsetShare = () => {
        if (activateErrorCheckForVerticalOffsetShare) {
            if (data.verticalOffsetShare && Number(data.verticalOffsetShare) < 8) {
                return "Minimum offset should be 8%";
            } else if (data.verticalOffsetShare && Number(data.verticalOffsetShare) > 100) {
                return "Maximum offset should be 100%";
            } else if (!data.verticalOffsetShare) {
                return "Mobile height offset cannot be empty";
            }
        }
        return false;
    };

    const renderMarkup = () => {
        switch (props.selected) {
            case DEVICE_TYPE_MOBILE_AND_DESKTOP:
            case DEVICE_TYPE_MOBILE_ONLY:
                return (
                    <Card sectioned title="Mobile">
                        <FormLayout>
                            <FormLayout.Group>
                                <div className="smallMarginTopSharePage">
                                    <TextContainer>
                                        <p>Share Button Position</p>
                                    </TextContainer>
                                    <Stack>
                                        <RadioButton
                                            label="Left"
                                            checked={data.sharePosition === "left"}
                                            id="m-left"
                                            name="btnpositionmobile"
                                            onChange={() => handleButtonPositionChange('left')}
                                        />
                                        <RadioButton
                                            label="Right"
                                            id="m-right"
                                            name="btnpositionmobile"
                                            checked={data.sharePosition === "right"}
                                            onChange={() => handleButtonPositionChange('right')}
                                        />
                                    </Stack>
                                </div>
                            </FormLayout.Group>

                            <FormLayout.Group>
                                <TextField
                                    label="HEIGHT offset"
                                    type="number"
                                    suffix="%"
                                    value={data.verticalOffsetShare}
                                    max={100}
                                    min={8}
                                    onChange={(val) => handleOffsetChange(val)}
                                    helpText={"Change the height of share button on mobile"}
                                    error={validateVerticalOffsetShare()}
                                />
                            </FormLayout.Group>
                        </FormLayout>
                    </Card>
                );
        }
    }

    return (
        <>{renderMarkup()}</>
    );
}

export default ShareMobileConfig;