import { useState } from "react";
import PageMarkup from "../../common/PageMarkup";
import ExportPage from "./ExportPage";
import SettingsPage from "./SettingsPage";
import obj from "../../content/OptinNumber/OverlaysContent/en.json";
import TooltipOverlay from "../../common/Tooltip";
import GuideModal from "../../common/GuideModal";

const AutomatedCoDPage = ({showPricingBanner}:any) => {
  const [toast, setToast] = useState("");
  const [errorToast, setErrorToast] = useState("");
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    // {
    // 	id: "Settings",
    // 	content: "Settings",
    // 	accessibilityLabel: "Settings",
    // 	panelID: "settings",
    // },
    {
      id: "Opt-in",
      content: "List",
      panelID: "optin",
    },
  ];

  const tabContent = {
    // 0: <SettingsPage setToast={setToast} setErrorToast={setErrorToast} setShowErrorToast={setShowErrorToast} setShowSuccessToast={setShowSuccessToast}></SettingsPage>,
    0: (
      <ExportPage
        setToast={setToast}
        setErrorToast={setErrorToast}
        setShowErrorToast={setShowErrorToast}
        setShowSuccessToast={setShowSuccessToast}
      ></ExportPage>
    ),
  };

  return (
    <>
      <PageMarkup
        title="Opt-in Numbers"
        tabs={tabs}
        tabContent={tabContent}
        toast={toast}
        errorToast={errorToast}
        showErrorToast={showErrorToast}
        showSuccessToast={showSuccessToast}
      ></PageMarkup>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        obj={obj}
        showPricingBanner={showPricingBanner}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
    </>
  );
};
export default AutomatedCoDPage;
