import { Card, Link, RadioButton, Stack, TextContainer } from "@shopify/polaris";

const TemplateLanguageSettingMarkup = ({
  languageList,
  language,
  onChange,
  getPreview
}: any) => {
  return (
    <div>
      <Card sectioned>
        <Stack vertical>
          <TextContainer>
            {"Message Language (all messages will be sent in this language)"}
          </TextContainer>

          <Stack>
            {languageList && languageList.map((lang: any, index: any) => (

              <RadioButton
                key={index}
                label={lang.language}
                checked={language?.language?.toUpperCase() === lang?.language?.toUpperCase()}
                name="language"
                onChange={() => onChange(lang)}
              />
            ))}
          </Stack>

          {getPreview && (
            <Link external={true} url={getPreview(language.language)}>
              Preview
            </Link>
          )}
        </Stack>
      </Card>
    </div>
  );
};
export default TemplateLanguageSettingMarkup;
