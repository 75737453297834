import { PLANS, PLAN_CHECK, PLAN_DETAILS, UPDATE_PLAN } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

export const updatePlan = (shopId: string, id: string, planObj: any) => {
  return httpClient.put(UPDATE_PLAN.replace('$shopId', shopId).replace('$planId', id), planObj);
};
export const getPlans = () => {
  return httpClient.get(PLANS);
};

export const getPlanDetails = (planId: string) => {
  return httpClient.get(PLAN_DETAILS.replace("$planId", planId));
};

export const checkPlan = (shopId: string, chargeId: any) => {
  return httpClient.get(PLAN_CHECK.replace("$shopId", shopId) + "?charge_id=" + chargeId);
};
