import httpClient from "../../utils/http-client";
import { MESSAGING, OPTIN_ADMIN } from "../../constants/api-constants";

let config = {
	baseURL: MESSAGING.baseUrl
};

export const exportNumbers = (shopId: any, data: any) => {
	return httpClient.post(MESSAGING.api.EXPORT_NUMBERS.replace("$shopId", shopId), data, config);
};

export const getNumbers = (pageNumber: any, pageSize: any) => {
	return httpClient.get(MESSAGING.api.GET_NUMBERS
		.replace("$pageSize", pageSize)
		.replace("$pageNumber", pageNumber), config);
};
export const getTotalNumbers = () => {
	return httpClient.get(MESSAGING.api.GET_TOTAL_NUMBERS, config)
}

export const fetchOptinAdminDetails = (shopId: string) => {
	return httpClient.get(OPTIN_ADMIN.replace('$shopId', shopId));
}

export const applyOptin = (shopId: string) => {
	return httpClient.post(OPTIN_ADMIN.replace('$shopId', shopId));
}
