import { useEffect, useState } from "react";
import {
  Card,
  Layout,
  Link,
  Modal,
  Stack,
  TextContainer,
} from "@shopify/polaris";
import { objectDifference } from "../../../../utils/helper";
import {
  updateAbandonedCartSettings,
} from "../../../../redux/actions/abandonedCart";
import TemplateLanguageSettingMarkup from "../../../../common/TemplateLanguageSettingMarkup";
import DiscountMarkup from "../../../AbandonedCart/DiscountMarkup";
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  COUPON_TYPE_PERCENT,
  COUPON_TYPE_STATIC,
} from "../../../../constants/constants";
import MessageTemplateDescription from "../../../AbandonedCart/MessageTemplateDescription";

const AbandonedCartModal = (
  {
    showToast,
    showErrorToast,
    open,
    closeModal,
    setAbandonedCartLanguage,
    abCartData,
    languageList,
  }: any,
  props: any
) => {
  const [openModal, setOpenModal] = useState(open);
  const [initialOrderData, setInitialOrderData] = useState({
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: {
      language: "ENGLISH"
    },
  });
  const [orderData, setOrderData] = useState({
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: {
      language: "ENGLISH"
    },
  });
  const [refOrderData, setRefOrderData] = useState({
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: {
      language: "ENGLISH"
    },
  });

  const [shop, setShop] = useState({
    id: "",
    url: "",
  });
  const [dataChanges, setDataChanges] = useState<any>({
    order: null,
    phone: null,
  });
  const [activateErrorCheckForCouponCode, setActivateErrorCheckForCouponCode] =
    useState(false);
  const [activateErrorCheckForhandleDiscountChange, setActivateErrorCheckForhandleDiscountChange] =
    useState(false);

  useEffect(() => {
    setInitialOrderData(abCartData);
    setRefOrderData(abCartData);
    setOrderData(abCartData);
  }, [abCartData]);

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShop(shop);
    }
    setOpenModal(open);
  }, [props, open]);

  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        order: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, order: null }));
    }
  }, [orderData, refOrderData]);

  const resetData = () => {
    setDataChanges({
      order: null,
      phone: null,
    });
    setRefOrderData(initialOrderData);
    setOrderData(initialOrderData);
  };

  const handleOrderChange = (field: string, value: any) => {
    setOrderData((data) => ({ ...data, [field]: value }));
  };
  const toggleDiscount = () => {
    setOrderData((data) => ({
      ...data,
      discount: !orderData.discount,
      discountType: COUPON_TYPE_PERCENT,
    }));
  };
  const handleDiscountTypeChange = (newValue: any) => {
    setOrderData((data: any) => ({ ...data, discountType: newValue }));
  };

  const handleDiscountChange = (newValue: any) => {
    if (!activateErrorCheckForhandleDiscountChange) {
      setActivateErrorCheckForhandleDiscountChange(true);
    }
    if (newValue <= 100 && newValue >= 0) {
      setOrderData((data) => ({ ...data, discountRatio: newValue }));
    }
  };

  const handleCouponChange = (newValue: any) => {
    if (!activateErrorCheckForCouponCode) {
      setActivateErrorCheckForCouponCode(true);
    }
    setOrderData((data) => ({ ...data, couponCode: newValue }));
  };

  const validateFixedCouponCode = (check = false) => {    
    if (activateErrorCheckForCouponCode || check) {
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_STATIC &&
        !orderData.couponCode
      ) {
        return "Coupon code cannot be empty";
      }
    }
    return false;
  };

  const validateDiscountRatio = (check = false) => {
    if (activateErrorCheckForhandleDiscountChange || check) {
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_PERCENT &&
        String(orderData.discountRatio) === "0"
      ) {
        return "Discount Value cannot be 0";
      }
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_PERCENT &&
        !orderData.discountRatio
      ) {
        return "Discount Value cannot be empty";
      }
    }
    return false;
  };

  const updateSettings = () => {
    setActivateErrorCheckForhandleDiscountChange(true);
    setActivateErrorCheckForCouponCode(true);
    if (validateFixedCouponCode(true) !== false) {
      let message = validateFixedCouponCode() || "Invalid coupon code";
      showErrorToast(message);
      return;
    }

    if (validateDiscountRatio(true) !== false) {
      let message = validateDiscountRatio() || "Invalid discount value";
      showErrorToast(message);
      return;
    }

    if (dataChanges.order) {
      updateAbandonedCartSettings(dataChanges.order).then(([updatedData, response]: any) => {
        showToast("Data Saved");
        setDataChanges({ ...dataChanges, order: null });
        setInitialOrderData((data: any) => ({
          ...data,
          ...updatedData,
        }));
        setRefOrderData((data: any) => ({
          ...data,
          ...updatedData,
        }));
        setOrderData((data: any) => ({
          ...data,
          ...updatedData,
        }));
        setAbandonedCartLanguage(updatedData.language.language);
      },
      (err: any) => {
        console.log(err);
        showErrorToast("Something went wrong");
      });
    }
    setActivateErrorCheckForhandleDiscountChange(false);
    setActivateErrorCheckForCouponCode(false);
    closeModal();
  };
  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );
  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );

  const cartRecoveryTemplateMarkup = (
    <Card>
      <Card.Section>
        <TextContainer>
          <DiscountMarkup
            enable_discount={orderData.discount}
            toggleDiscount={toggleDiscount}
            discount_percentage={orderData.discountRatio}
            handleDiscountChange={handleDiscountChange}
            coupon_code={orderData.couponCode}
            handleCouponChange={handleCouponChange}
            discount_type={orderData.discountType}
            handleDiscountTypeChange={handleDiscountTypeChange}
            validateFixedCouponCode={validateFixedCouponCode}
            validateDiscountRatio={validateDiscountRatio}
            shop={shop}
          />
        </TextContainer>
      </Card.Section>
    </Card>
  );

  const getAbandonedCartSettingsMarkup = () => {
    return (
      <>
        <Layout.AnnotatedSection
          title="WhatsApp Configuration"
          description={description}
        >
          <Stack vertical>
            <TemplateLanguageSettingMarkup
              languageList={languageList}
              language={orderData.language}
              onChange={(val: any) => {
                handleOrderChange("language", val);
              }}
            ></TemplateLanguageSettingMarkup>
          </Stack>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Abandoned Cart Template"
          description={<MessageTemplateDescription />}
        >
          <Stack vertical>
            {cartRecoveryTemplateMarkup}
            {nudgeCheckoutOptinScrollSection}
          </Stack>
        </Layout.AnnotatedSection>
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Abandoned Cart Settings"}
      primaryAction={{
        content: "Save",
        onAction: updateSettings,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getAbandonedCartSettingsMarkup()}</Modal.Section>
    </Modal>
  );
};

export default AbandonedCartModal;
