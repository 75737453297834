import React, { useEffect } from "react";

function WhatsappPreview({ hideFrame }: any) {
  useEffect(() => {
    hideFrame(false);
  }, []);

  return (
    <>
      <h1>Hi this is preview</h1>
    </>
  );
}

export default WhatsappPreview;
