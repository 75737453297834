import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  Page,
  Layout,
  TextStyle,
  TextContainer,
  TextField,
  Button,
  FormLayout,
  Toast,
  Card,
  SkeletonDisplayText,
  SkeletonBodyText,
} from "@shopify/polaris";
import useCustomForm from "../../hooks/useCustomForm";
import MarketingImg from "../../assets/images/marketing.svg";
import {
  createAgent,
  getAgents,
} from "../../redux/actions/agents";
import { getShopId } from "../../utils/helper";

const Widget = (props: any) => {
  const shopIdInitial = getShopId();
  const [shopId,setShopId] = useState(shopIdInitial); 
  const [toast, setToast] = useState({ show: false, message: "" });
  const [fields] = useState({ agentName: "", countryCode: "", personalPhone: "" });
  const [isDetailsLoading, setIsDetailsLoading] = useState(true);
  let { path } = useRouteMatch();

  useEffect(() => {
      isAgentsExists();
  }, [shopId]);

  const isAgentsExists = async () => {    
    if(!shopId) {      
     let intervalId = setInterval(()=>{
        if(shopId){
          clearInterval(intervalId)
        } else {
          setShopId(getShopId())
        }
      }, 10)
    }
    if(shopId) {
      await getAgents(shopId).then(
        (response: any) => {
          if (response.agents.length > 0) {
            props.history.push(`${path}/settings`);
          }
          setIsDetailsLoading(false);
        },
        (err: any) => {
          setIsDetailsLoading(false);
        }
      );
    }
    return ()=> {
      setIsDetailsLoading(false)
    }
    
  };
  const savePhoneNumber = () => {
    let error = validateCountryCode() || validatePhoneNo() || validateAgentName();
    if (inputs.personalPhone && inputs.agentName && inputs.countryCode && !error) {
      let params = new URLSearchParams();
      params.append("name", inputs.agentName);
      params.append("number", inputs.personalPhone);
      params.append("avatarId", "1");
      params.append("countryCode", inputs.countryCode);
      params.append("designation", "Customer Support");
      createAgent(shopId, params).then((response: any) => {
        if (response.status === "success") {
          props.history.push(`${path}/settings`);
        } else {
          setToast({ show: true, message: response });
        }
      });
    } else {
      setToast({ show: true, message: 'Please provide valid details' });
    }
  };

  const { handleInputChange, inputs } = useCustomForm(
    savePhoneNumber,
    fields
  );

  const validateCountryCode = () => {

    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!inputs.countryCode || inputs.countryCode.match(cc)) {
      return false;
    } else if (inputs.countryCode && inputs.countryCode[0] === "0") {
      return "cannot start with 0"
    } else {
      return "Enter a valid country code"
    }
  }

  const validatePhoneNo = () => {
		var phoneno = /^(?:[0-9]\d{0,14})$/;
		if (!inputs.personalPhone || inputs.personalPhone.match(phoneno)) {
			return false;
		} else {
			return "Enter a valid phone number";
		}
	};

  const validateAgentName = () => {
		if (inputs.agentName && inputs.agentName.length > 30) {
			return "Maximum 30 characters";
		}
		return false;
	};

  const toastMarkup = toast.show ? (
    <Toast
      error
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const renderMarkup = isDetailsLoading ? (
    <Page>
      <Card sectioned>
        <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
        </TextContainer>
      </Card>
    </Page>
  ) : (
    <Page
      title="Get Started"
      subtitle="Let's start by creating your personal WhatsApp chat widget, its free!"
    >
      {toastMarkup}
      <Layout>
        <Layout.Section>
          <div className="Polaris-Card Widget-Get-Started-Card">
            <div className="Polaris-MediaCard">
              <div className="Polaris-MediaCard__MediaContainer">
                <img
                  alt="Get Started"
                  className="pt-2"
                  width="90%"
                  src={MarketingImg}
                />
              </div>
              <div className="Polaris-MediaCard__InfoContainer">
                <div className="Polaris-Card__Section">
                  <div className="Polaris-Stack Polaris-Stack--vertical">
                    <div className="Polaris-Stack__Item">
                      <div className="Polaris-MediaCard__Heading">
                        <h2 className="Polaris-Heading fs-1">
                          Your Best Value Proposition
                        </h2>
                      </div>
                    </div>
                    <div className="Polaris-Stack__Item">
                      <TextContainer>
                        <p className="mt-2">
                          <TextStyle variation="subdued">
                            This widget will be displayed on your Shopline store in the pages you want and the customer can tap on it to connect with you or your agent's WhatsApp directly. You can add multiple agents later and customize the widget with your store theme and colours.
                          </TextStyle>
                        </p>
                        <p className="mt-3">
                          <TextStyle variation="subdued">
                            Start by entering your name and WhatsApp number for now. You can edit it later
                          </TextStyle>
                        </p>
                      </TextContainer>
                    </div>
                    <div className="Polaris-Stack__Item pt-2 widget">
                      <FormLayout>
                        <TextField
                          label="Enter Your Name"
                          labelHidden
                          placeholder="Enter Your Name"
                          id="agentName"
                          value={inputs.agentName}
                          error={validateAgentName()}
                          onChange={(val) =>
                            handleInputChange("agentName", val)
                          }
                        />
                        <FormLayout.Group>
                          <TextField
                            label="Country Code"
                            labelHidden
                            placeholder="Enter Your Country Code"
                            id="countryCode"
                            value={inputs.countryCode}
                            error={validateCountryCode()}
                            maxLength={15}
                            onChange={(val) =>
                              handleInputChange("countryCode", val)
                            }
                          />
                          <TextField
                            label="Enter Your Personal Phone Number"
                            labelHidden
                            placeholder="Enter Your Personal Phone Number"
                            id="personalPhone"
                            value={inputs.personalPhone}
                            error={validatePhoneNo()}
                            maxLength={15}
                            onChange={(val) =>
                              handleInputChange("personalPhone", val)
                            }
                          />
                        </FormLayout.Group>
                        <Button
                            id="btnGetWidget"
                            primary
                            onClick={savePhoneNumber}
                          >
                            Get Widget
                          </Button>
                      </FormLayout>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );

  return (
    <>
    {renderMarkup}
    </>
  );
};

export default Widget;
