import { useEffect, useState } from "react";
import AbandonedCart from "./AbandonedCart";
import OrderConfirmation from "./OrderConfirmation";
import OrderShipment from "./OrderShipment";
import CashOnDelivery from "./CashOnDelivery";
import OrderCancellation from "./OrderCancellation";
import PaymentSuccessful from "./PaymentSuccessful";
import { Layout, Page, Toast } from "@shopify/polaris";
import MultipleAgentsBanner from "../../../common/MultipleAgentsBanner";
import InstructionsImg1 from "../../../assets/images/instructions-1.svg";
import { useHistory } from "react-router-dom";
import {
  fetchOrderConfirmationSettings,
  fetchOrderShipmentSettings,
  fetchPhoneSettings,
} from "../../../redux/actions/crmOrders";

const AutomatedTemplates = (props: any) => {
  const history = useHistory();
  const openPricing = () => {
    history.push("/pricing");
  };
  const [features] = useState(localStorage.getItem("features"));
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [phoneData, setPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [languageList, setLanguageList] = useState([]);

  const [shopId, setshopId] = useState<any | null>(null);
  const [orderConfirmationLanguage, setOrderConfirmationLanguage] =
    useState<any>("ENGLISH");
  const [orderData, setorderConfirmationData] = useState({});
  const [isOrderConfirmationEnabled, setIsOrderConfirmationEnabled] =
    useState<boolean>(false);
  const [isOrderShipmentEnabled, setIsOrderShipmentEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setshopId(shop.id);
      getPhoneData();
      getOrderConfirmationData();
      getOrderShipmentData();
    }
  }, []);

  const getOrderConfirmationData = () => {
    fetchOrderConfirmationSettings().then(([data, res]: any) => {
      setIsOrderConfirmationEnabled(data?.confirmationEnabled);
      setOrderConfirmationLanguage(data?.language?.language?.toUpperCase());
      setorderConfirmationData((state: any) => ({
        ...orderData,
        ...state,
        ...data
      }));
      setLanguageList(res.languageList)
    });
  };

  const getOrderShipmentData = () => {
    fetchOrderShipmentSettings().then(([data, res]: any) => {
      setIsOrderShipmentEnabled(data?.shipmentEnabled);
      setorderConfirmationData((state: any) => ({
        ...orderData,
        ...state,
        ...data,
      }));
    });
  };

  const getPhoneData = () => {
    fetchPhoneSettings().then((res: any) => {
      setPhoneData({
        countryCode: res.countryCode,
        phone: res.phone,
      });
    });
  };

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const upgradeToUltimateMarkup = () => {
    return (
      <>
        <Layout.Section>
          <div className="Polaris-Card">
            <div className="Polaris-CalloutCard__Container">
              <div className="Polaris-Card__Section">
                <div className="Polaris-CalloutCard">
                  <div className="Polaris-Stack Polaris-Stack--alignmentCenter Polaris-Stack--distributionFill w-100">
                    <div className="Polaris-Stack__Item">
                      <img
                        src={InstructionsImg1}
                        alt="Upgrade to Pro Plan"
                        className="Polaris-CalloutCard__Image w-1 h-1"
                      />
                    </div>
                    <div className="Polaris-Stack__Item upgrade-text">
                      <p className="Polaris-DisplayText fs-1">Ultimate Plan</p>
                      <p>
                        Upgrade to the Ultimate Plan to send automated template
                        messages via our WhatsApp Business Account or make
                        custom templates via your own private WhatsApp Business
                        Account
                      </p>
                    </div>
                    <div className="Polaris-Stack__Item">
                      <button
                        className="Polaris-Button Polaris-Button--primary Polaris-Button--outline outline pull-right"
                        type="button"
                        onClick={openPricing}
                      >
                        <span className="Polaris-Button__Content">
                          <span className="Polaris-Button__Text">
                            Upgrade Plan
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout.Section>
      </>
    );
  };

  return features?.includes("AUTOMATED_MESSAGING") ? (
    <Page
      subtitle={
        "Automated WhatsApp message templates cannot be modified or edited, because message templates need to be pre-approved by WhatsApp before they can be sent using the API."
      }
      primaryAction={<button style={{ display: "none" }}></button>}
    >
      {toastMarkup}
      {errorToastMarkup}
      <Layout>
        <AbandonedCart
          shopId={shopId}
          showToast={showToast}
          showErrorToast={showErrorToast}
          phoneData={phoneData}
        />
      </Layout>
      <br />
      {/* <div className="Polaris-Page--divider"></div>

      <div className="mt-1">
        <Layout>
          <OrderConfirmation
            shopId={shopId}
            showToast={showToast}
            showErrorToast={showErrorToast}
            phoneData={phoneData}
            orderConfirmationLanguage={orderConfirmationLanguage}
            setOrderConfirmationLanguage={setOrderConfirmationLanguage}
            orderConfirmationData={orderData}
            isOrderConfirmationEnabled={isOrderConfirmationEnabled}
            setIsOrderConfirmationEnabled={setIsOrderConfirmationEnabled}
            languageList={languageList}
          />
        </Layout>
      </div>
      <br />
      <div className="Polaris-Page--divider"></div>
      <div className="mt-1">
        <Layout>
          <OrderShipment
            shopId={shopId}
            showToast={showToast}
            showErrorToast={showErrorToast}
            phoneData={phoneData}
            orderConfirmationLanguage={orderConfirmationLanguage}
            orderShipmentData={orderData}
            isOrderShipmentEnabled={isOrderShipmentEnabled}
            setIsOrderShipmentEnabled={setIsOrderShipmentEnabled}
          />
        </Layout>
      </div>
      <br />
      <div className="Polaris-Page--divider"></div>
      <div className="mt-1 mb-3">
        <Layout>
          <CashOnDelivery
            shopId={shopId}
            showToast={showToast}
            showErrorToast={showErrorToast}
            phoneData={phoneData}
          />
        </Layout>
      </div>
      <div className="Polaris-Page--divider"></div>
      <div className="mt-1 mb-3">
        <Layout>
          <PaymentSuccessful
            shopId={shopId}
            showToast={showToast}
            showErrorToast={showErrorToast}
            phoneData={phoneData}
          />
        </Layout>
      </div>
      <div className="Polaris-Page--divider"></div>
      <div className="mt-1 mb-3">
        <Layout>
          <OrderCancellation
            shopId={shopId}
            showToast={showToast}
            showErrorToast={showErrorToast}
            phoneData={phoneData}
          />
        </Layout>
      </div> */}
      <Layout>
        <Layout.Section>
          <MultipleAgentsBanner />
        </Layout.Section>
      </Layout>
    </Page>
  ) : (
    upgradeToUltimateMarkup()
  );
  // <FreeTemplates></FreeTemplates>
};
export default AutomatedTemplates;
