import React from 'react';
import { Banner, Page } from '@shopify/polaris';
import { DOWNTIME_MESSAGE, IS_DOWNTIME } from '../constants/constants';
import useToken from '../hooks/useToken';

const DowntimeMarkup = () => {
    const { getToken } = useToken();

    let token = getToken();
    
    let downtimeMarkup = token && IS_DOWNTIME ? (
        <Banner status="critical">
          <p>{DOWNTIME_MESSAGE}</p>
        </Banner>
      ) : null;

    return (
        <Page>{downtimeMarkup}</Page>
    );
}

export default DowntimeMarkup;