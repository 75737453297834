import {
	Button,
	Card,
	ContextualSaveBar,
	Layout,
	Link,
	Stack,
	TextContainer,
} from "@shopify/polaris";
import { Fragment, useEffect, useState } from "react";
import TemplateLanguageSettingMarkup from "../../common/TemplateLanguageSettingMarkup";
import {
	ABANDONED_CART_TEMPLATE_DICT,
	AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
	COUPON_TYPE_PERCENT,
	COUPON_TYPE_STATIC,
	TEMPLATE_LANGUAGE_ARAB,
	TEMPLATE_LANGUAGE_DUTCH,
	TEMPLATE_LANGUAGE_ENG,
	TEMPLATE_LANGUAGE_FREN,
	TEMPLATE_LANGUAGE_GERM,
	TEMPLATE_LANGUAGE_HEBR,
	TEMPLATE_LANGUAGE_INDO,
	TEMPLATE_LANGUAGE_ITAL,
	TEMPLATE_LANGUAGE_PORT,
	TEMPLATE_LANGUAGE_SPAN,
	TEMPLATE_LANGUAGE_TURK,
} from "../../constants/constants";
import {
	getAbondonedCartSettings,
	updateAbandonedCartSettings,
} from "../../redux/actions/abandonedCart";
import {
	fetchPhoneSettings,
	updatePhoneSettings,
} from "../../redux/actions/crmOrders";
import { objectDifference } from "../../utils/helper";
import DiscountMarkup from "./DiscountMarkup";
import MessageTemplateDescription from "./MessageTemplateDescription";

const AbandonedCartSettingPage = (props: any) => {
	const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } = props;
	var TEMPLATE_DICT: any = ABANDONED_CART_TEMPLATE_DICT;
	const [initialOrderData, setInitialOrderData] = useState({
		confirmationEnabled: true,
		couponCode: "",
		discount: true,
		discountType: "FIXED",
		discountRatio: 10,
		language: {
			language: "ENGLISH"
		},
		shipmentEnabled: true,
		thankYouPage: true,
		order_tracking_url_type: 1,
	});
	const [orderData, setOrderData] = useState({
		confirmationEnabled: true,
		couponCode: "",
		discount: true,
		discountType: "FIXED",
		discountRatio: 10,
		language: {
			language: "ENGLISH"
		},
		shipmentEnabled: true,
		thankYouPage: true,
		order_tracking_url_type: 1,
	});
	const [refOrderData, setRefOrderData] = useState({
		confirmationEnabled: true,
		couponCode: "",
		discount: true,
		discountType: "FIXED",
		discountRatio: 10,
		language: {
			language: "ENGLISH"
		},
		shipmentEnabled: true,
		thankYouPage: true,
		order_tracking_url_type: 1,
	});
	const [initialPhoneData, setInitialPhoneData] = useState({
		countryCode: "",
		phone: "",
	});
	const [phoneData, setPhoneData] = useState({
		countryCode: "",
		phone: "",
	});
	const [refPhoneData, setRefPhoneData] = useState({
		countryCode: "",
		phone: "",
	});
	const [languageList, setLanguageList] = useState([]);

	const [dataChanges, setDataChanges] = useState<any>({
		order: null,
		phone: null,
	});
	const [hasChanges, setHasChanges] = useState(false);
	const [shop, setShop] = useState({
		id: "",
		url: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [isOrderConfirmationEnabled, setIsOrderConfirmationEnabled] =
		useState<boolean>();

	useEffect(() => {
		let shop = JSON.parse(localStorage.getItem("shop") || "{}");
		if (shop && shop.id) {
			setShop(shop);
			getOrderData();
			getPhoneData();
		}
	}, []);
	useEffect(() => {
		var diffObj: any = objectDifference(refOrderData, orderData);
		if (Object.keys(diffObj).length > 0) {
			setDataChanges((dataChanges:any) => ({ ...dataChanges, order: diffObj }));
		} else {
			setDataChanges((dataChanges:any) => ({ ...dataChanges, order: null }));
		}
	}, [orderData, refOrderData]);
	useEffect(() => {
		var diffObj: any = objectDifference(refPhoneData, phoneData);
		if (Object.keys(diffObj).length > 0) {
			setDataChanges((dataChanges:any) => ({ ...dataChanges, phone: diffObj }));
		} else {
			setDataChanges((dataChanges:any) => ({ ...dataChanges, phone: null }));
		}
	}, [phoneData]);

	useEffect(() => {
		if (dataChanges.order || dataChanges.phone) {
			setHasChanges(true);
		} else {
			setHasChanges(false);
		}
	}, [dataChanges]);

	const getOrderData = () => {
		getAbondonedCartSettings().then(([data, res]: any) => {
			setInitialOrderData({...orderData, ...data});
			setRefOrderData({...orderData, ...data});
			setOrderData({...orderData, ...data});
			setLanguageList(res.languageList)
			setIsOrderConfirmationEnabled(data.enable);
		});
	};
	
	const getPhoneData = () => {
		fetchPhoneSettings().then((res: any) => {
			setInitialPhoneData(res?.setting);
			setRefPhoneData(res?.setting);
			setPhoneData(res?.setting);
		});
	};

	const description = (
		<span>
			<p>
				<b>
					{
						"Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
					}
				</b>
				<Link
					external={true}
					url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
				>
					{"phone number"}
				</Link>
				{" using message templates approved by WhatsApp."}
				<br />
				<br />
				We include your{" "}
				<Link
					url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
					external={true}
				>
					business name
				</Link>{" "}
				and{" "}
				<Link
					url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}
					external={true}
				>
					WhatsApp support number
				</Link>{" "}
				in every automated message. Your customers can recognise you and
				contact you easily if they need help.
			</p>
		</span>
	);
	const order_confirmation_message_template_description = (
		<span>
			<p>
				<p>
					Send an order confirmation message as soon as a customer
					places an order on your store. You can also give customers a
					coupon code for their next order.
				</p>
				<br />
				<p>
					<b>
						Automated WhatsApp message templates cannot be modified
						or edited
					</b>
					, because message templates need to be pre-approved by
					WhatsApp before they can be sent using the API.
				</p>
				<br />
				{"To learn about the cost of sending messages, view"}
				<Link
					external={true}
					url="https://docs.google.com/spreadsheets/u/2/d/1GX6yTDK8imK0YuOFCkU-Lpa3nCFifuq_oaujFvQaiCE/edit?usp=sharing"
				>
					{" pricing chart"}
				</Link>
			</p>
		</span>
	);

	const toggleDiscount = () => {
		setOrderData((data) => ({
			...data,
			"discount": !orderData.discount,
			"discountType": COUPON_TYPE_PERCENT,
		}));
	};

	const handleDiscountTypeChange = (newValue: any) => {
		setOrderData((data: any) => ({ ...data, "discountType": newValue }));
	};

	const handleDiscountChange = (newValue: any) => {
		if(newValue <= 100 && newValue >= 0){
			setOrderData((data) => ({ ...data, "discountRatio": newValue }));
		  }
	};

	const handleCouponChange = (newValue: any) => {
		setOrderData((data) => ({ ...data, "couponCode": newValue }));
	};

	const validateFixedCouponCode = () => {
		if (
			orderData.discount &&
			orderData.discountType === COUPON_TYPE_STATIC &&
			!orderData.couponCode
		) {
			return "Coupon code cannot be empty";
		}
		return false;
	};

	const validateDiscountRatio = () => {
		if (
			orderData.discount &&
			orderData.discountType === COUPON_TYPE_PERCENT &&
			!orderData.discountRatio
		) {
			return "Discount Value cannot be empty";
		}
		if (orderData.discount && orderData.discountType === COUPON_TYPE_PERCENT && String(orderData.discountRatio) === '0') {
			return "Discount Value cannot be 0"
		  }
		return false;
	};

	const getAbandonedCartPreview = () => {
		switch (orderData.language.language.toUpperCase()) {
			case TEMPLATE_LANGUAGE_ENG:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/eng-abandoned_cart_preview.png?v=1587749560";
			case TEMPLATE_LANGUAGE_PORT:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/por-abandoned_cart_preview.png?v=1587749560";
			case TEMPLATE_LANGUAGE_SPAN:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/spa-abandoned_cart_preview.png?v=1587749561";
			case TEMPLATE_LANGUAGE_ITAL:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ita-abandoned_cart_preview.png?v=1587749560";
			case TEMPLATE_LANGUAGE_FREN:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/fr-abandoned_cart_preview.png?v=1587749561";
			case TEMPLATE_LANGUAGE_INDO:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/indo-abandoned_cart_preview.png?v=1587749560";
			case TEMPLATE_LANGUAGE_GERM:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ger-abandoned_cart_preview-updated.png?v=1587819719";
			case TEMPLATE_LANGUAGE_ARAB:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/arabic-abandoned_cart_preview.png?v=1587749558";
			case TEMPLATE_LANGUAGE_TURK:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/turkish-abandoned_cart_preview.png?v=1587819633";
			case TEMPLATE_LANGUAGE_HEBR:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/hebrew-abandoned_cart_preview.png?v=1591679011";
			case TEMPLATE_LANGUAGE_DUTCH:
				return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/dutch-abandoned_cart_preview.png?v=1591677401";
		}
	};

	const nudgeCheckoutOptinScrollSection = (
		<div id="nudgeCheckoutOptin_anchor">
			<Card></Card>
		</div>
	);

	const handleOrderChange = (field: string, value: any) => {
		setOrderData((data) => ({ ...data, [field]: value }));
	};
	const handlePhoneChange = (field: string, value: any) => {
		setPhoneData((data) => ({ ...data, [field]: value }));
	};
	const updateSettings = () => {
		
		if (!phoneData.countryCode || validateSupportCountryCode()) {
			setShowErrorToast((errorToast:any)=> !errorToast)
			setErrorToast("A valid support country code must be given");
			return
		}

		if (!phoneData.phone || validateSupportPhoneNo()) {
			setShowErrorToast((errorToast:any)=> !errorToast)
			setErrorToast("A valid support phone number must be given");
			return
		}

		if (validateFixedCouponCode() !== false) {
			let message = validateFixedCouponCode() || "Invalid coupon code";
			setShowErrorToast((errorToast:any)=> !errorToast)
			setErrorToast(message);
			return;
		}

		if (validateDiscountRatio() !== false) {
			let message = validateDiscountRatio() || "Invalid discount value";
			setShowErrorToast((errorToast:any)=> !errorToast)
			setErrorToast(message);
			return;
		}

		if (dataChanges.order) {
			updateAbandonedCartSettings(dataChanges.order).then(
				(response: any) => {
					if (response.status === "success") {
						setShowSuccessToast((successToast:any)=> !successToast)
						setToast("Data Saved");
						setDataChanges({ ...dataChanges, order: null });
						setInitialOrderData((data: any) => ({
							...data,
							...response?.setting,
						}));
						setRefOrderData((data: any) => ({
							...data,
							...response?.setting,
						}));
						setOrderData((data: any) => ({
							...data,
							...response?.setting,
						}));
					} else {
						setShowErrorToast((errorToast:any)=> !errorToast)
						setErrorToast(response);
					}
				}
			);
		}
		if (dataChanges.phone) {
			updatePhoneSettings(dataChanges.phone).then((response: any) => {
				setShowSuccessToast((successToast:any)=> !successToast)
				setToast("Data Saved");
				setDataChanges({ ...dataChanges, order: null });
				setInitialPhoneData((data: any) => ({
					...data,
					...response?.setting,
				}));
				setRefPhoneData((data: any) => ({
					...data,
					...response?.setting,
				}));
				setPhoneData((data: any) => ({
					...data,
					...response?.setting,
				}));
			});
		}
		setHasChanges(false);
	};

	const resetData = () => {
		setDataChanges({
			order: null,
			phone: null,
		});
		setRefOrderData(initialOrderData);
		setOrderData(initialOrderData);
		setRefPhoneData(initialPhoneData);
		setPhoneData(initialPhoneData);
		setHasChanges(false);
	};

	const ctxSaveBarMarkup = hasChanges ? (
		<ContextualSaveBar
			message="Unsaved changes"
			saveAction={{
				content: "Save Settings",
				onAction: updateSettings,
			}}
			discardAction={{
				onAction: () => {
					resetData();
				},
			}}
		/>
	) : null;

	const validateSupportCountryCode = () => {
		var cc = /^(?:[1-9]\d{0,5})$/;
		if (!phoneData.countryCode || phoneData.countryCode.match(cc)) {
			return false;
		} else if (phoneData.countryCode && phoneData.countryCode[0] === "0") {
			return "cannot start with 0";
		} else {
			return "enter a valid country code";
		}
	};

	const validateSupportPhoneNo = () => {
		var phoneno = /^(?:[0-9]\d{0,14})$/;
		if (!phoneData.phone || phoneData.phone.match(phoneno)) {
			return false;
		} else {
			return "Enter a valid phone number";
		}
	};

	const AbandonedCartRecoveryClick = (enable: boolean) => {
		
		if (!phoneData.countryCode || validateSupportCountryCode()) {
			setShowErrorToast((errorToast:any)=> !errorToast)
			setErrorToast("A valid support country code must be given");
			return;
		}

		if (!phoneData.phone || validateSupportPhoneNo()) {
			setShowErrorToast((errorToast:any)=> !errorToast)
			setErrorToast("A valid support phone number must be given");
			return;
		}

		if (
			orderData.discount &&
			orderData.discountType === COUPON_TYPE_STATIC &&
			!orderData.couponCode
		) {
			setShowErrorToast((errorToast:any)=> !errorToast)
			setErrorToast("Coupon code cannot be empty");
			return;
		}

		if (
			orderData.discount &&
			orderData.discountType === COUPON_TYPE_PERCENT &&
			!orderData.discountRatio
		) {
			setShowErrorToast((errorToast:any)=> !errorToast)
			setErrorToast("Discount % cannot be empty");
			return;
		}

		setIsLoading(true);
		updateAbandonedCartSettings({ enable }).then(
			(response: any) => {
				setIsOrderConfirmationEnabled(response?.settings?.enable);
				setToast(
					"Abandoned Cart Recovery message template has been " +
						(enable ? "ENABLED" : "DISABLED")
				);
				setIsLoading(false);
			},
			(err: any) => {
				setShowErrorToast((errorToast:any)=> !errorToast)
				setErrorToast("Something went wrong");
				setIsLoading(false);
			}
		);
	};

	const cartRecoveryTemplateMarkup = (
		<Card>
			<Card.Header
				title="Abandoned Cart Recovery"
				children={[
					isOrderConfirmationEnabled ? (
						<Button
							key={'disable'}
							onClick={() => AbandonedCartRecoveryClick(false)}
						>
							Disable
						</Button>
					) : (
						<Button
							key={'enable'}
							primary
							onClick={() => AbandonedCartRecoveryClick(true)}
						>
							Enable
						</Button>
					),
				]}
			></Card.Header>

			<Card.Section>
				<p className="paddingRight6px notranslate messageTemplateBackground">
					{
						TEMPLATE_DICT[orderData.language.language][
							"abandoned_cart_recovery_template"
						]
					}
				</p>
				<br />
				<Link external={true} url={getAbandonedCartPreview()}>
					Preview
				</Link>
			</Card.Section>

			<Card.Section>
				<TextContainer>
					<DiscountMarkup
						enable_discount={orderData.discount}
						toggleDiscount={toggleDiscount}
						discount_percentage={orderData.discountRatio}
						handleDiscountChange={handleDiscountChange}
						coupon_code={orderData.couponCode}
						handleCouponChange={handleCouponChange}
						discount_type={orderData.discountType}
						handleDiscountTypeChange={handleDiscountTypeChange}
						validateFixedCouponCode={validateFixedCouponCode}
						validateDiscountRatio={validateDiscountRatio}
						shop={shop}
					/>
				</TextContainer>
			</Card.Section>
		</Card>
	);
	return (
		<Fragment>
			{ctxSaveBarMarkup}
			
			<Layout.AnnotatedSection
				title="WhatsApp Configuration"
				description={description}
			>
				<Stack vertical>
					<TemplateLanguageSettingMarkup
						languageList={languageList}
						language={orderData.language}
						onChange={(val: any) => {
							handleOrderChange("language", val);
						}}
					></TemplateLanguageSettingMarkup>
					{/* <SupportNumberMarkup
						phoneNumber={phoneData.phone}
						countryCode={phoneData.countryCode}
						onCountryChange={(val: any) => {
							handlePhoneChange("countryCode", val);
						}}
						onNumberChange={(val: any) => {
							handlePhoneChange("phone", val);
						}}
					></SupportNumberMarkup> */}
				</Stack>
			</Layout.AnnotatedSection>

			<Layout.AnnotatedSection
				title="Abandoned Cart Template"
				description={<MessageTemplateDescription />}
			>
				<Stack vertical>
					{cartRecoveryTemplateMarkup}
					{nudgeCheckoutOptinScrollSection}
				</Stack>
			</Layout.AnnotatedSection>
		</Fragment>
	);
};
export default AbandonedCartSettingPage;
