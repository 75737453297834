import {
  Card,
  Layout,
  TextStyle,
  Button,
  DatePicker,
  FormLayout,
  Popover,
  Stack,
  TextField,
} from "@shopify/polaris";
import { useEffect, useState, useCallback } from "react";
import styles from "./analytics.module.scss";
import ChatMetricIcon from "../../assets/icons/chat_click_metric.svg";
import ShareMetricIcon from "../../assets/icons/share_click_metric.svg";
import { getClickCount } from "../../redux/actions/analytics";
import moment from "moment";

function StaticMetrics() {
  const [showPicker, setShowPicker] = useState(false);
  const [shopId, setShopId] = useState("");
  const [showDateRangeError, setShowDateRangeError] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);

  const [startInput, setStartInput] = useState(
    moment().subtract(6, "days").format("YYYY-MM-DD")
  );
  const [endInput, setEndInput] = useState(moment().format("YYYY-MM-DD"));
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const [start, setStart] = useState(
    new Date(moment().subtract(6, "days").format())
  );
  const [end, setEnd] = useState(new Date(moment().format()));
  const [month, setMonth] = useState(
    Number(moment().subtract(6, "days").format("MM")) - 1
  );
  const [year, setYear] = useState(
    Number(moment().subtract(6, "days").format("YYYY"))
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePopoverActive = useCallback(
    () => setShowPicker((showPicker) => !showPicker),
    []
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      {isCustomDate ? "Custom" : "Last 7 days"}
    </Button>
  );
  const [clickButtonAnalytics, setClickButtonAnalytics] = useState({
    chatCount: 0,
    shareCount: 0,
  });

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      fetchClickCount(shop.id);
      setShopId(shop.id);
    }
  }, []);

  const fetchClickCount = (shopId: string) => {
    if (shopId) {
      var end = moment().add(1, "days").format("YYYY-MM-DD");
      var start = moment().subtract(7, "days").format("YYYY-MM-DD");
      getClickCount(shopId, start, end).then((res: any) => {
        if (res.hasOwnProperty('chatCount')) {
          setClickButtonAnalytics(res);
        }
      });
    }
  };

  const setSelectedDates = (d: any) => {
    setStart(d.start);
    setEnd(d.end);
    setStartInput(moment(d.start).format("YYYY-MM-DD"));
    setEndInput(moment(d.end).format("YYYY-MM-DD"));
  };

  const handleMonthChange = (month: any, year: any) => {
    setMonth(month);
    setYear(year);
  };

  const checkValidityOfDates = (type: any, val: any) => {
    const analyticsDateDeployed = Number(
      moment("2022-02-01 00:00:00").toDate()
    );

    if (
      !moment(val, "YYYY-MM-DD", true).isValid() ||
      Number(moment()) - Number(moment(val)) < 0
    ) {
      return setIsSubmitBtnDisabled(true);
    }

    var formatVal = moment(val).format("YYYY-MM-DD");

    var finalStart = moment(start).format("YYYY-MM-DD");
    var finalEnd = moment(end).format("YYYY-MM-DD");

    // if end is less than start then set start and end to be the same date.
    if (type === "start") {
      var startValDate = new Date(Number(moment(formatVal)));
      if (startValDate > end) {
        finalEnd = formatVal;
      }
      finalStart = formatVal;
    } else {
      var endValDate = new Date(Number(moment(formatVal)));
      if (endValDate < start) {
        finalStart = formatVal;
      }
      finalEnd = formatVal;
    }
    if (moment(analyticsDateDeployed).diff(moment(finalStart), "days") > 0) {
      setIsSubmitBtnDisabled(true);
      setShowDateRangeError(true);
    } else {
      setIsSubmitBtnDisabled(false);
      setShowDateRangeError(false);
    }
    setStartInput(finalStart);
    setEndInput(finalEnd);
    setStart(new Date(Number(moment(finalStart))));
    setEnd(new Date(Number(moment(finalEnd))));
  };
  const handleSubmit = async () => {
    const finalStart = moment(start).format("YYYY-MM-DD");
    const finalEnd = moment(end).format("YYYY-MM-DD");

    setIsSubmitting(true);
    if (shopId && finalStart && finalEnd) {
      getClickCount(shopId, finalStart, finalEnd).then((res: any) => {
        if (res.hasOwnProperty('chatCount')) {
          setClickButtonAnalytics(res);
        }
        setIsSubmitting(false);
        togglePopoverActive()
        setIsCustomDate(true)
      });
    }
  };

  const selectedDates = { start, end };
  const analyticsDateDeployed = moment("2022-01-21 00:00:00").toDate();
  return (
    <>
      <div>
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Popover
            activator={activator}
            active={showPicker}
            onClose={togglePopoverActive}
            ariaHaspopup={false}
            preferredPosition="mostSpace"
            preferredAlignment="right"
          >
            <Popover.Pane sectioned>
              <FormLayout>
                <Stack distribution="fillEvenly">
                  {showDateRangeError ? (
                    <TextStyle variation="negative">
                      Starting date should be after "2022-02-01"
                    </TextStyle>
                  ) : null}
                  <TextField
                    label="Starting date"
                    value={startInput}
                    onChange={(v) => {
                      setStartInput(v);
                      checkValidityOfDates("start", v);
                    }}
                    placeholder={"YYYY-MM-DD eg.(2021-08-21)"}
                  />
                  <TextField
                    label="Ending date"
                    value={endInput}
                    onChange={(v) => {
                      setEndInput(v);
                      checkValidityOfDates("end", v);
                    }}
                    placeholder={"YYYY-MM-DD eg.(2021-08-21)"}
                  />
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={setSelectedDates}
                    onMonthChange={handleMonthChange}
                    selected={selectedDates}
                    multiMonth
                    allowRange
                    disableDatesAfter={new Date()}
                    disableDatesBefore={analyticsDateDeployed}
                  />
                </Stack>
              </FormLayout>
            </Popover.Pane>
            <Popover.Pane fixed sectioned>
              <Stack>
                <Stack.Item fill>
                  <Button onClick={togglePopoverActive}>Cancel</Button>
                </Stack.Item>
                <Button
                  primary
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  disabled={isSubmitBtnDisabled}
                >
                  Submit
                </Button>
              </Stack>
            </Popover.Pane>
          </Popover>
        </div>
      </div>
      <div>
        <Layout>
          <Layout.Section oneHalf>
            <Card>
              <Card.Section>
                <div className="d-flex-start ml-2 mr-2">
                  <div className="mr-2">
                    <img
                      src={ChatMetricIcon}
                      alt="Chat Metric Icon"
                      className={`${styles.metric_icon}`}
                    />
                  </div>
                  <div className={`${styles.metric_container}`}>
                    <div>
                      <span>
                        <TextStyle variation="subdued">
                          Total Chat Click
                        </TextStyle>
                      </span>
                    </div>
                    <div>
                      <h2>{clickButtonAnalytics.chatCount}</h2>
                    </div>
                  </div>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <Card>
              <Card.Section>
                <div className="d-flex-start ml-2 mr-2">
                  <div className="mr-2">
                    <img
                      src={ShareMetricIcon}
                      alt="Chat Metric Icon"
                      className={`${styles.metric_icon}`}
                    />
                  </div>
                  <div className={`${styles.metric_container}`}>
                    <div>
                      <span>
                        <TextStyle variation="subdued">
                          Total Share Click
                        </TextStyle>
                      </span>
                    </div>
                    <div>
                      <h2>{clickButtonAnalytics.shareCount}</h2>
                    </div>
                  </div>
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </div>
    </>
  );
}

export default StaticMetrics;
