import {
  Button,
  Card,
  ContextualSaveBar,
  Layout,
  Link,
  RadioButton,
  Stack,
  TextContainer,
  TextStyle
} from "@shopify/polaris";
import { Fragment, useEffect, useState } from "react";
import TemplateLanguageSettingMarkup from "../../common/TemplateLanguageSettingMarkup";
import {
  AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW,
  COUPON_TYPE_PERCENT,
  COUPON_TYPE_STATIC,
  CRM_TEMPLATE_DICT,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
} from "../../constants/constants";
import {
  fetchOrderConfirmationSettings,
  fetchOrderShipmentSettings,
  fetchPhoneSettings,
  updateOrderConfirmationSettings,
  updateOrderShipmentSettings,
  updatePhoneSettings,
} from "../../redux/actions/crmOrders";
import { objectDifference } from "../../utils/helper";
import DiscountMarkup from "../AbandonedCart/DiscountMarkup";

const CrmSettingPage = (props: any) => {
  const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } = props;
  var TEMPLATE_DICT: any = CRM_TEMPLATE_DICT;

  const [initialOrderData, setInitialOrderData] = useState({
    confirmationEnabled: true,
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: {
      language: "ENGLISH"
    },
    shipmentEnabled: true,
    thankYouPage: true,
    order_tracking_url_type: 1,
  });
  const [orderData, setOrderData] = useState({
    confirmationEnabled: true,
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: {
      language: "ENGLISH"
    },
    shipmentEnabled: true,
    thankYouPage: true,
    order_tracking_url_type: 1,
  });
  const [refOrderData, setRefOrderData] = useState({
    confirmationEnabled: true,
    couponCode: "",
    discount: true,
    discountType: "FIXED",
    discountRatio: 10,
    language: {
      language: "ENGLISH"
    },
    shipmentEnabled: true,
    thankYouPage: true,
    order_tracking_url_type: 1,
  });
  const [initialPhoneData, setInitialPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [phoneData, setPhoneData] = useState({
    countryCode: "",
    phone: "",
  });
  const [refPhoneData, setRefPhoneData] = useState({
    countryCode: "",
    phone: "",
  });

  const [dataChanges, setDataChanges] = useState<any>({
    order: null,
    phone: null,
  });
  const [hasChanges, setHasChanges] = useState(false);
  const [shop, setShop] = useState({
    id: '',
    url: ''
  });
  const [isOrderConfirmationEnabled, setIsOrderConfirmationEnabled] = useState<boolean>();
  const [isOrderShipmentEnabled, setIsOrderShipmentEnabled] = useState<boolean>();
	const [languageList, setLanguageList] = useState([]);

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShop(shop);
      getOrderConfirmationData();
      getOrderShipmentData();
      getPhoneData();
    }
  }, []);
  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges:any) => ({ ...dataChanges, order: diffObj }));
    } else {
      setDataChanges((dataChanges:any) => ({ ...dataChanges, order: null }));
    }
  }, [orderData]);

  useEffect(() => {
    var diffObj: any = objectDifference(refPhoneData, phoneData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges:any) => ({ ...dataChanges, phone: diffObj }));
    } else {
      setDataChanges((dataChanges:any) => ({ ...dataChanges, phone: null }));
    }
  }, [phoneData]);

  useEffect(() => {
    if (dataChanges.order || dataChanges.phone) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [dataChanges]);

  const getOrderConfirmationData = () => {
    fetchOrderConfirmationSettings().then(([data, res]: any) => {
      setIsOrderConfirmationEnabled(data?.enable);
      setLanguageList(res?.languageList);
      setInitialOrderData({
        ...orderData,
        ...data,
      });
      setRefOrderData({
        ...orderData,
        ...data,
      });
      setOrderData({
        ...orderData,
        ...data,
      });
    });
  };

  const getOrderShipmentData = () => {
    fetchOrderShipmentSettings().then(([data, res]: any) => {
      setIsOrderShipmentEnabled(data?.enable);
      setInitialOrderData({
        ...orderData,
        ...data,
      });
      setRefOrderData({
        ...orderData,
        ...data,
      });
      setOrderData({
        ...orderData,
        ...data,
      });
    });
  };

  const getPhoneData = () => {
    fetchPhoneSettings().then((res: any) => {
      setInitialPhoneData(res?.setting);
      setRefPhoneData(res?.setting);
      setPhoneData(res?.setting);
    });
  };

  const description = (
    <span>
      <p>
        <b>
          {
            "Automated WhatsApp messages are sent from our official WhatsApp Business API approved "
          }
        </b>
        <Link external={true} url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW}>
          {"phone number"}
        </Link>
        {" using message templates approved by WhatsApp."}
        <br />
        <br />
        We include your{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          business name
        </Link>{" "}
        and{" "}
        <Link url={AUTOMATED_MESSAGE_HOW_IT_WORKS_PREVIEW} external={true}>
          WhatsApp support number
        </Link>{" "}
        in every automated message. Your customers can recognise you and contact
        you easily if they need help.
      </p>
    </span>
  );
  const order_confirmation_message_template_description = (
    <>
        <p>
          Send an order confirmation message as soon as a customer places an
          order on your store. You can also give customers a coupon code for
          their next order.
        </p>
        <br />
        <p>
          <b>
            Automated WhatsApp message templates cannot be modified or edited
          </b>
          , because message templates need to be pre-approved by WhatsApp before
          they can be sent using the API.
        </p>
        <br />
        {"To learn about the cost of sending messages, view"}
        <Link
          external={true}
          url="https://docs.google.com/spreadsheets/u/2/d/1GX6yTDK8imK0YuOFCkU-Lpa3nCFifuq_oaujFvQaiCE/edit?usp=sharing"
        >
          {" pricing chart"}
        </Link>
    </>
  );

  const getOrderConfirmationPreview = () => {
    switch (orderData.language.language.toUpperCase()) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/eng-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/por-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/spa-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ita-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/fr-order_confirmation_preview.png?v=1587749562";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/indo-order_confirmation_preview.png?v=1587749561";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ger-order_confirmation_preview-updated.png?v=1587819720";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/arabic-order_confirmation_preview.png?v=1587749561";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/turkish-order_confirmation_preview.png?v=1587819648";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/hebrew-order_confirmation_preview.png?v=1591679012";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/dutch-order_confirmation_preview.png?v=1591677402";
    }
  };
  const toggleDiscount = () => {
    setOrderData((data) => ({ ...data, "discount": !orderData.discount, "discountType": COUPON_TYPE_PERCENT }));
  };

  const handleDiscountTypeChange = (newValue: any) => {
    setOrderData((data) => ({ ...data, "discountType": newValue }));
  };

  const handleDiscountChange = (newValue: any) => {    
    if(newValue <= 100 && newValue >= 0){
      setOrderData((data) => ({ ...data, "discountRatio": newValue }));
    }
  };

  const handleCouponChange = (newValue: any) => {
    setOrderData((data) => ({ ...data, "couponCode": newValue }));
  };

  const validateFixedCouponCode = () => {
    if (orderData.discount && orderData.discountType === COUPON_TYPE_STATIC && !orderData.couponCode) {
      return "Coupon code cannot be empty";
    }
    return false;
  };

  const validateDiscountRatio = () => {    
    if (orderData.discount && orderData.discountType === COUPON_TYPE_PERCENT && !orderData.discountRatio) {
      return "Discount Value cannot be empty"
    }
    if (orderData.discount && orderData.discountType === COUPON_TYPE_PERCENT && String(orderData.discountRatio) === '0') {
      return "Discount Value cannot be 0"
    }
    return false;
  }

  const validateSupportCountryCode = () => {

    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!phoneData.countryCode || phoneData.countryCode.match(cc)) {
      return false;
    } else if (phoneData.countryCode && phoneData.countryCode[0] === "0") {
      return "cannot start with 0"
    } else {
      return "enter a valid country code"
    }
  }

  const validateSupportPhoneNo = () => {

    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!phoneData.phone || phoneData.phone.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number"
    }
  }

  const OrderConfirmationTemplateClick = (enable: boolean) => {
    if (!phoneData.phone || validateSupportPhoneNo()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support phone number must be given");
      return;
    }

    if (!phoneData.countryCode || validateSupportCountryCode()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support country code must be given");
      return;
    }

    if (orderData.discount && orderData.discountType === COUPON_TYPE_STATIC && !orderData.couponCode) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("Coupon code cannot be empty");
      return;
    }

    if (orderData.discount && orderData.discountType === COUPON_TYPE_PERCENT && !orderData.discountRatio) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("Discount % cannot be empty");
      return;
    }

    updateOrderConfirmationSettings({ confirmationEnabled: enable }).then(([data, response]: any) => {
        setIsOrderConfirmationEnabled(data?.enable);
        setToast("Order Confirmation message template has been " + (enable ? "ENABLED" : "DISABLED"))
    }, (err: any) => {
      console.log(err);
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast('Something went wrong');
    });
  }

  const orderConfirmationTemplateMarkup = (
    <Card>
      <Card.Header
        title="Order Confirmation"
        children={[
          isOrderConfirmationEnabled ? (
            <Button
              key={'order-crm-disable'}
              onClick={() => OrderConfirmationTemplateClick(false)}
            >
              Disable
            </Button>
          ) : (
            <Button
              primary
              key={'order-crm-enable'}
              onClick={() => OrderConfirmationTemplateClick(true)}
            >
              Enable
            </Button>
          ),
        ]}
      ></Card.Header>

      <Card.Section>
        <p className="paddingRight6px notranslate messageTemplateBackground">
          {TEMPLATE_DICT[orderData.language.language.toUpperCase()]["order_confirmation_template"]}
        </p>
        <br />
        <Link external={true} url={getOrderConfirmationPreview()}>
          Preview
        </Link>
      </Card.Section>

      <Card.Section>
        <TextContainer>
          <DiscountMarkup
            enable_discount={orderData.discount}
            toggleDiscount={toggleDiscount}
            discount_percentage={orderData.discountRatio}
            handleDiscountChange={handleDiscountChange}
            coupon_code={orderData.couponCode}
            handleCouponChange={handleCouponChange}
            discount_type={orderData.discountType}
            handleDiscountTypeChange={handleDiscountTypeChange}
            validateFixedCouponCode={validateFixedCouponCode}
            validateDiscountRatio={validateDiscountRatio}
            shop={shop}
          />
        </TextContainer>
      </Card.Section>
    </Card>
  );

  const order_shipment_message_template_description = (
    <span>
      <p>
        Order shipment message is sent when you fulfil an order in the Shopline
        admin.
      </p>
      <br />
      <p>Tracking number and URL is taken from your Shopline order data.</p>
    </span>
  );

 

  const trackingShippingUrl = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Tracking URL from order data{" "}
        <TextStyle variation="subdued">
          <Link
            external={true}
            url={`https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tracking_link_shipment_url.png?v=1591762091`}
          >
            (see example)
          </Link>
        </TextStyle>
      </p>
    </div>
  );

  const trackingThankyoupageUrl = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Thank you page{" "}
        <TextStyle variation="subdued">
          <Link
            external={true}
            url={`https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tracking_link_thank_you_page.png?v=1591762200`}
          >
            (see example)
          </Link>
        </TextStyle>
      </p>
    </div>
  );

  const getOrderTrackingPreview = () => {
    switch (orderData.language.language.toUpperCase()) {
      case TEMPLATE_LANGUAGE_ENG:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/eng-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_PORT:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/por-order_shipment_perview.png?v=1587749558";
      case TEMPLATE_LANGUAGE_SPAN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/spa-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_ITAL:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ita-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_FREN:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/fr-order_shipment_perview.png?v=1587749558";
      case TEMPLATE_LANGUAGE_INDO:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/indo-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_GERM:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/ger-order_shipment_perview.png?v=1587749558";
      case TEMPLATE_LANGUAGE_ARAB:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/arabic-order_shipment_perview.png?v=1587749557";
      case TEMPLATE_LANGUAGE_TURK:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/turkish-order_shipment_perview.png?v=1587819647";
      case TEMPLATE_LANGUAGE_HEBR:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/hebrew-order_shipment_perview.png?v=1591679011";
      case TEMPLATE_LANGUAGE_DUTCH:
        return "https://cdn.shopify.com/s/files/1/0070/3666/5911/files/dutch-order_shipment_perview.png?v=1591677401";
    }
  };

  const OrderShipmentTemplateClick = (enable: boolean) => {
    if (!phoneData.phone || validateSupportPhoneNo()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support phone number must be given");
      return;
    }

    if (!phoneData.countryCode || validateSupportCountryCode()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support country code must be given");
      return;
    }

    updateOrderShipmentSettings({ shipmentEnabled: enable }).then(([data, response]: any) => {
        setIsOrderShipmentEnabled(data?.enable);
        setToast("Delivery Update message template has been " + (enable ? "ENABLED" : "DISABLED"))
    }, (err: any) => {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast('Something went wrong');
    });
  }

  const orderTrackingTemplateMarkup = (
    <div>
      <Card>
        <Card.Header
          title="Order Shipment with Tracking URL"
          children={
            isOrderShipmentEnabled ? (
              <Button
                onClick={() => { OrderShipmentTemplateClick(false)}}
              >
                Disable
              </Button>
            ) : (
              <Button
                primary
                onClick={() => { OrderShipmentTemplateClick(true) }}
              >
                Enable
              </Button>
            )
          }
        ></Card.Header>

        <Card.Section>
          <p className="paddingRight6px notranslate messageTemplateBackground">
            {TEMPLATE_DICT[orderData.language.language.toUpperCase()]["order_tracking_template"]}
          </p>
          <br />
          <Link external={true} url={getOrderTrackingPreview()}>
            Preview
          </Link>
        </Card.Section>
      </Card>

      <Card sectioned>
        <div className="verticalMarginTextContainer">
          <TextContainer>
            <p>Configure the {"{{tracking_url}}"} to send in the message</p>
          </TextContainer>
        </div>

        <div className="stackVerticalMarginTopAdjustment">
          <Stack vertical={true}>
            <RadioButton
              label={trackingShippingUrl}
              checked={orderData.thankYouPage === false}
              id="tracking1"
              name="shippingurl"
              onChange={() => {
                setOrderData((order) => ({
                  ...order,
                  "thankYouPage": false,
                }));
              }}
            />
            <RadioButton
              label={trackingThankyoupageUrl}
              id="tracking2"
              name="thankyoupageurl"
              checked={orderData.thankYouPage === true}
              onChange={() => {
                setOrderData((order) => ({
                  ...order,
                  "thankYouPage": true,
                }));
              }}
            />
          </Stack>
        </div>
      </Card>
      <br />
    </div>
  );

  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );
  // TODO
  
 
  const handleOrderChange = (field: string, value: any) => {
    setOrderData((data) => ({ ...data, [field]: value }));
  };
  const handlePhoneChange = (field: string, value: any) => {
    setPhoneData((data) => ({ ...data, [field]: value }));
  };
  const updateSettings = () => {
    if (!phoneData.phone || validateSupportPhoneNo()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support phone number must be given");
      return;
    }

    if (!phoneData.countryCode || validateSupportCountryCode()) {
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast("A valid support country code must be given");
      return;
    }

    if (validateFixedCouponCode() !== false) {
      let message = validateFixedCouponCode() || 'Invalid coupon code';
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast(message);
      return;
    }

    if (validateDiscountRatio() !== false) {
      let message = validateDiscountRatio() || 'Invalid discount value';
      setShowErrorToast((errorToast:any)=> !errorToast)
      setErrorToast(message);
      return;
    }

    if (dataChanges.order) {
      updateOrderConfirmationSettings(dataChanges.order).then(([updatedData, response]: any) => {
          setInitialOrderData((data: any) => ({...data, ...updatedData}));
          setRefOrderData((data: any) => ({...data, ...updatedData}));
          setOrderData((data: any) => ({...data, ...updatedData}));
          setShowSuccessToast((successToast:any)=> !successToast)
          setToast("Data Saved");
          setDataChanges({ ...dataChanges, order: null });
      }, (err: any) => {
        console.log(err);
        setShowErrorToast((errorToast:any)=> !errorToast)
        setErrorToast('Something went wrong');
      });
    }
    if (dataChanges.phone) {
      updatePhoneSettings(dataChanges.phone).then((response: any) => {
        setInitialPhoneData((data: any) => ({...data, ...response?.setting}));
        setRefPhoneData((data: any) => ({...data, ...response?.setting}));
        setPhoneData((data: any) => ({...data, ...response?.setting}));
        setShowSuccessToast((successToast:any)=> !successToast)
        setToast("Data Saved");
        setDataChanges({ ...dataChanges, phone: null });
      }, (err: any) => {
        console.log(err);
        setShowErrorToast((errorToast:any)=> !errorToast)
        setErrorToast('Something went wrong');
      });
    }
    setHasChanges(false);
  };

  const resetData = () => {
    setHasChanges(false);
    setDataChanges({
      order: null,
      phone: null,
    });
    setRefOrderData(initialOrderData);
    setRefPhoneData(initialPhoneData);
    setOrderData(initialOrderData);
    setPhoneData(initialPhoneData);
  }

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateSettings,
      }}
      discardAction={{
        onAction: () => {
          resetData();
        },
      }}
    />
  ) : null;

  return (
    <Fragment>
      {ctxSaveBarMarkup}
      <Layout.AnnotatedSection
        title="WhatsApp Configuration"
        description={description}
      >
        <Stack vertical>
          <TemplateLanguageSettingMarkup
            languageList={languageList}
            language={orderData.language}
            onChange={(val: any) => {
              handleOrderChange("language", val);
            }}
          ></TemplateLanguageSettingMarkup>
          {/* <SupportNumberMarkup
            phoneNumber={phoneData.phone}
            countryCode={phoneData.countryCode}
            onCountryChange={(val: any) => {
              handlePhoneChange("countryCode", val);
            }}
            onNumberChange={(val: any) => {
              handlePhoneChange("phone", val);
            }}
          ></SupportNumberMarkup> */}
        </Stack>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title="Order Confirmation Template"
        description={order_confirmation_message_template_description}
      >
        <Stack vertical>{orderConfirmationTemplateMarkup}</Stack>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection
        title="Order Shipment Template"
        description={order_shipment_message_template_description}
      >
        <Stack vertical>
          {orderTrackingTemplateMarkup}
          {nudgeCheckoutOptinScrollSection}
        </Stack>
      </Layout.AnnotatedSection>

     
     </Fragment>
  );
};
export default CrmSettingPage;
