import { useState } from "react";
import PageMarkup from "../../common/PageMarkup";
import CrmMessageLogPage from "./CrmMessageLogPage";
import CrmSettingPage from "./CrmSettingPage";

const AutomatedCRMPage = () => {
  const [toast, setToast] = useState();
  const [errorToast, setErrorToast] = useState();
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const tabs = [
    {
      id: "Settings",
      content: "Settings",
      accessibilityLabel: "Settings",
      panelID: "settings",
    },
    // {
    //   id: "Message Logs",
    //   content: "Message Logs",
    //   panelID: "messagelogs",
    // },
  ];

  const tabContent = {
    0: <CrmSettingPage 
          setToast={setToast}
          setErrorToast={setErrorToast}
          setShowSuccessToast={setShowSuccessToast}
          setShowErrorToast={setShowErrorToast}
          ></CrmSettingPage>,
    // 1: <CrmMessageLogPage></CrmMessageLogPage>
  };

  return (
    <PageMarkup
      title="Automated Orders CRM"
      tutorial
      tutorialSrc="https://www.youtube.com/embed/7vv80dmfSxc"
      tabs={tabs}
      tabContent={tabContent}
      toast={toast}
      errorToast={errorToast}
      showErrorToast={showErrorToast}
      showSuccessToast={showSuccessToast}
    >
    </PageMarkup>
  );
};
export default AutomatedCRMPage;
