import { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  Layout,
  Link,
  Modal,
  RadioButton,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { objectDifference } from "../../../../utils/helper";
import {
  updateAbCartSettings,
  updateCodSettings,
  updateOrderConfirmationSettings,
  updateOrderShipmentSettings,
} from "../../../../redux/actions/email";
import DiscountMarkup from "../../../AbandonedCart/DiscountMarkup";
import {
  COUPON_TYPE_PERCENT,
  COUPON_TYPE_STATIC,
  TEMPLATE_LANGUAGE_ARAB,
  TEMPLATE_LANGUAGE_DUTCH,
  TEMPLATE_LANGUAGE_ENG,
  TEMPLATE_LANGUAGE_FREN,
  TEMPLATE_LANGUAGE_GERM,
  TEMPLATE_LANGUAGE_HEBR,
  TEMPLATE_LANGUAGE_INDO,
  TEMPLATE_LANGUAGE_ITAL,
  TEMPLATE_LANGUAGE_PORT,
  TEMPLATE_LANGUAGE_SPAN,
  TEMPLATE_LANGUAGE_TURK,
  TEMPLATE_LANGUAGE_RUSSIAN,
} from "../../../../constants/constants";

const TemplateModal = ({
  open,
  closeModal,
  title,
  isDiscount,
  type,
  shopId,
  apiResData,
  language,
  setData,
  shopUrl,
  showSuccessToast,
  showErrorsToast
}: any) => {
  const [openModal, setOpenModal] = useState(open);
  const [initialOrderData, setInitialOrderData] = useState<any>(apiResData);
  const [orderData, setOrderData] = useState<any>(apiResData);
  const [refOrderData, setRefOrderData] = useState<any>(apiResData);

  const [dataChanges, setDataChanges] = useState<any>({
    change: null,
  });
  const [activateErrorCheckForCouponCode, setActivateErrorCheckForCouponCode] =
    useState(false);
  const [
    activateErrorCheckForhandleDiscountChange,
    setActivateErrorCheckForhandleDiscountChange,
  ] = useState(false);
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };
  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useEffect(() => {
    var diffObj: any = objectDifference(refOrderData, orderData);
    if (Object.keys(diffObj).length > 0) {
      setDataChanges((dataChanges: any) => ({
        ...dataChanges,
        change: diffObj,
      }));
    } else {
      setDataChanges((dataChanges: any) => ({ ...dataChanges, change: null }));
    }
  }, [orderData, refOrderData]);

  const resetData = () => {
    setDataChanges({
      change: null,
    });
    setRefOrderData(initialOrderData);
    setOrderData(initialOrderData);
  };

  const handleOrderChange = (field: string, value: any) => {
    setOrderData((data: any) => ({ ...data, [field]: value }));
  };
  const toggleDiscount = () => {
    setOrderData((data: any) => ({
      ...data,
      discount: !orderData.discount,
      discountType: COUPON_TYPE_PERCENT,
    }));
  };
  const handleDiscountTypeChange = (newValue: any) => {
    setOrderData((data: any) => ({ ...data, discountType: newValue }));
  };

  const handleDiscountChange = (newValue: any) => {
    if (!activateErrorCheckForhandleDiscountChange) {
      setActivateErrorCheckForhandleDiscountChange(true);
    }
    if (newValue <= 100 && newValue >= 0) {
      setOrderData((data: any) => ({ ...data, discountRatio: newValue }));
    }
  };

  const handleCouponChange = (newValue: any) => {
    if (!activateErrorCheckForCouponCode) {
      setActivateErrorCheckForCouponCode(true);
    }
    setOrderData((data: any) => ({ ...data, couponCode: newValue }));
  };

  const validateFixedCouponCode = (check = false) => {
    if (activateErrorCheckForCouponCode || check) {
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_STATIC &&
        !orderData.couponCode
      ) {
        return "Coupon code cannot be empty";
      }
    }
    return false;
  };

  const validateDiscountRatio = (check = false) => {
    if (activateErrorCheckForhandleDiscountChange || check) {
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_PERCENT &&
        String(orderData.discountRatio) === "0"
      ) {
        return "Discount Value cannot be 0";
      }
      if (
        orderData.discount &&
        orderData.discountType === COUPON_TYPE_PERCENT &&
        !orderData.discountRatio
      ) {
        return "Discount Value cannot be empty";
      }
    }
    return false;
  };

  const updateSettings = () => {
    setActivateErrorCheckForhandleDiscountChange(true);
    setActivateErrorCheckForCouponCode(true);
    if(type === 'COD'){
      if (orderData.phoneEnabled) {
        if (!orderData.countryCode || validateCountryCode()) {
          showErrorToast("A valid country code must be given");
          return;
        }
  
        if (!orderData.phone || validatePhoneNo()) {
          showErrorToast("A valid phone number must be given");
          return;
        }
      }
  
      if (orderData.emailEnabled && (!orderData.email || validateEmail())) {
        showErrorToast("A valid email must be given");
        return;
      }
  
      if (!orderData.confirmedTag) {
        showErrorToast("Order Confirmed tag cannot be empty");
        return;
      }
      if (!orderData.cancelledTag) {
        showErrorToast("Order Cancelled tag cannot be empty");
        return;
      }
      if (!orderData.noResponseTag) {
        showErrorToast("No Response tag cannot be empty");
        return;
      }
    }

    if (isDiscount) {
      if (validateFixedCouponCode(true) !== false) {
        let message = validateFixedCouponCode() || "Invalid coupon code";
        showErrorToast(message);
        return;
      }

      if (validateDiscountRatio(true) !== false) {
        let message = validateDiscountRatio() || "Invalid discount value";
        showErrorToast(message);
        return;
      }
    }
    if (dataChanges.change) {
      let params = new URLSearchParams();
      let data: any = dataChanges.change;

      Object.keys(data).forEach((key) => {
        params.append(key, data[key]);
      });
      if (type === "ABCART") {
        updateAbCartSettings(shopId, params).then((response: any) => {
          if (response.status === "success") {
            showSuccessToast("Data Saved");
            setData(response.settings);
            setDataChanges({ ...dataChanges, change: null });
            setInitialOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setRefOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            // setAbandonedCartLanguage(response.settings.language);
          } else {
            showErrorsToast(response);
          }
        });
      }
      if (type === "COD") {
        updateCodSettings(shopId, params).then((response: any) => {
          if (response.status === "success") {
            showSuccessToast("Data Saved");
            setData(response.settings);
            setDataChanges({ ...dataChanges, change: null });
            setInitialOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setRefOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            setOrderData((data: any) => ({
              ...data,
              ...response.settings,
            }));
            // setAbandonedCartLanguage(response.settings.language);
          } else {
            showErrorsToast(response);
          }
        });
      }
      if (type === "ORDER_CONFIRMATION") {
        updateOrderConfirmationSettings(shopId, params).then(
          (response: any) => {
            if (response.status === "success") {
              showSuccessToast("Data Saved");
              setData(response.settings);
              setDataChanges({ ...dataChanges, change: null });
              setInitialOrderData((data: any) => ({
                ...data,
                ...response.settings,
              }));
              setRefOrderData((data: any) => ({
                ...data,
                ...response.settings,
              }));
              setOrderData((data: any) => ({
                ...data,
                ...response.settings,
              }));
              // setAbandonedCartLanguage(response.settings.language);
            } else {
              showErrorsToast(response);
            }
          }
        );
      }
      if (type === "ORDER_SHIPMENT") {
        updateOrderShipmentSettings(shopId, params).then(
          (response: any) => {
            if (response.status === "success") {
              showSuccessToast("Data Saved");
              setData(response.settings);
              setDataChanges({ ...dataChanges, change: null });
              setInitialOrderData((data: any) => ({
                ...data,
                ...response.settings,
              }));
              setRefOrderData((data: any) => ({
                ...data,
                ...response.settings,
              }));
              setOrderData((data: any) => ({
                ...data,
                ...response.settings,
              }));
              // setAbandonedCartLanguage(response.settings.language);
            } else {
              showErrorsToast(response);
            }
          }
        );
      }
    }
    setActivateErrorCheckForhandleDiscountChange(false);
    setActivateErrorCheckForCouponCode(false);
    closeModal();
  };
  const nudgeCheckoutOptinScrollSection = (
    <div id="nudgeCheckoutOptin_anchor">
      <Card></Card>
    </div>
  );

  const cartRecoveryTemplateMarkup = (
    <Card>
      <Card.Section>
        <TextContainer>
          <DiscountMarkup
            enable_discount={orderData.discount}
            toggleDiscount={toggleDiscount}
            discount_percentage={orderData.discountRatio}
            handleDiscountChange={handleDiscountChange}
            coupon_code={orderData.couponCode}
            handleCouponChange={handleCouponChange}
            discount_type={orderData.discountType}
            handleDiscountTypeChange={handleDiscountTypeChange}
            validateFixedCouponCode={validateFixedCouponCode}
            validateDiscountRatio={validateDiscountRatio}
            shop={{ url: shopUrl }}
          />
        </TextContainer>
      </Card.Section>
    </Card>
  );
  const handleLanguageChange = (newValue: any) => {
    return () => {
      handlecodChange("language", newValue);
    };
  };

  const handlecodChange = (field: string, value: any) => {
    setOrderData((data: any) => ({ ...data, [field]: value }));
  };

  const templateLanguageSettingMarkup = (
    <Card sectioned>
      <Stack vertical>
        <TextContainer>
          {"Email Language (all emails will be sent in this language)"}
        </TextContainer>
        <Stack>
          <RadioButton
            label="English"
            checked={language === "ENGLISH"}
            onChange={handleLanguageChange("ENGLISH")}
            name="language"
          />
        </Stack>
      </Stack>
    </Card>
  );
  const codMarkup = (
    <div>
      <Card>
        <Card.Section>
          <TextField
            label="Order Confirmed tag"
            value={orderData.confirmedTag}
            onChange={(value) => {
              setOrderData((data: any) => ({ ...data, confirmedTag: value }));
            }}
          />
          <br />
          <TextField
            label="Order Cancelled tag"
            value={orderData.cancelledTag}
            onChange={(value) => {
              setOrderData((data: any) => ({ ...data, cancelledTag: value }));
            }}
          />
          <br />
          <TextField
            label="No Response tag"
            value={orderData.noResponseTag}
            onChange={(value) => {
              setOrderData((data: any) => ({ ...data, noResponseTag: value }));
            }}
          />
        </Card.Section>
      </Card>
      <br />
    </div>
  );
  const settingForAutomaticOrderCancellation = (
    <Stack vertical>
      <Checkbox
        label="If an order is cancelled, automatically cancel the order in Shopline"
        checked={orderData.cancelOnCancelled}
        onChange={(val) => {
          setOrderData((data: any) => ({
            ...data,
            cancelOnCancelled: val,
          }));
        }}
      />
      <Checkbox
        label="If an order has no response, automatically cancel the order in Shopline"
        checked={orderData.cancelOnNoResponse}
        onChange={(val) => {
          setOrderData((data: any) => ({
            ...data,
            cancelOnNoResponse: val,
          }));
        }}
      />
    </Stack>
  );
  const cod_order_contact_description = (
    <p>
      Configure the language and contact options to display on the COD order
      confirmation page
    </p>
  );
  const handleCODConfirmationLanguageChange = (newValue: any) => {
    return () => {
      handlecodChange("confirmationLanguage", newValue);
    };
  };
  const codConfirmationLanguageMarkup = (
    <Card sectioned>
      <Stack vertical>
        <TextContainer>{"Order Confirmation Page Language"}</TextContainer>
        <Stack>
          <RadioButton
            label="English"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_ENG}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_ENG
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Portugese"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_PORT}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_PORT
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Spanish"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_SPAN}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_SPAN
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Italian"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_ITAL}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_ITAL
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="French"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_FREN}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_FREN
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Indonesian"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_INDO}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_INDO
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="German"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_GERM}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_GERM
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Arabic"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_ARAB}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_ARAB
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Turkish"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_TURK}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_TURK
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Hebrew"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_HEBR}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_HEBR
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Dutch"
            checked={orderData.confirmationLanguage === TEMPLATE_LANGUAGE_DUTCH}
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_DUTCH
            )}
            name="confirmationLanguage"
          />
          <RadioButton
            label="Russian"
            checked={
              orderData.confirmationLanguage === TEMPLATE_LANGUAGE_RUSSIAN
            }
            onChange={handleCODConfirmationLanguageChange(
              TEMPLATE_LANGUAGE_RUSSIAN
            )}
            name="confirmationLanguage"
          />
        </Stack>
        <Link
          external={true}
          url={
            "https://cdn.shopify.com/s/files/1/0449/7794/6790/files/ordr-confirmation.png?v=1639563285"
          }
        >
          Preview
        </Link>
      </Stack>
    </Card>
  );
  const validateCountryCode = () => {
    var cc = /^(?:[1-9]\d{0,5})$/;
    if (!orderData.countryCode || orderData.countryCode.match(cc)) {
      return false;
    } else if (orderData.countryCode && orderData.countryCode[0] === "0") {
      return "cannot start with 0";
    } else {
      return "enter a valid country code";
    }
  };

  const validatePhoneNo = () => {
    var phoneno = /^(?:[0-9]\d{0,14})$/;
    if (!orderData.phone || orderData.phone.match(phoneno)) {
      return false;
    } else {
      return "Enter a valid phone number";
    }
  };

  const validateEmail = () => {
    //eslint-disable-next-line
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!orderData.email || orderData.email.match(emailRegex)) {
      return false;
    } else {
      return "Enter a valid email address";
    }
  };

  const codOrderContactMarkup = (
    <div>
      <Card>
        <Card.Section>
          <Checkbox
            label="Call"
            checked={orderData.phoneEnabled}
            onChange={(val) => {
              setOrderData((data: any) => ({ ...data, phoneEnabled: val }));
            }}
          />
          <Stack>
            <Stack.Item>
              <TextField
                label="Country Code"
                value={orderData.countryCode}
                maxLength={15}
                error={validateCountryCode()}
                onChange={(val) => {
                  setOrderData((data: any) => ({ ...data, countryCode: val }));
                }}
                disabled={!orderData.phoneEnabled}
              />
            </Stack.Item>
            <Stack.Item>
              <TextField
                label="Phone Number"
                value={orderData.phone}
                maxLength={15}
                placeholder={"Example: 9035026993"}
                error={validatePhoneNo()}
                onChange={(val) => {
                  setOrderData((data: any) => ({ ...data, phone: val }));
                }}
                disabled={!orderData.phoneEnabled}
              />
            </Stack.Item>
          </Stack>
        </Card.Section>
        <Card.Section>
          <Checkbox
            label="Email"
            checked={orderData.emailEnabled}
            onChange={(val) => {
              setOrderData((data: any) => ({ ...data, emailEnabled: val }));
            }}
          />
          <Stack>
            <TextField
              label="Email"
              type="email"
              value={orderData.email}
              error={validateEmail()}
              onChange={(val) => {
                setOrderData((data: any) => ({ ...data, email: val }));
              }}
              disabled={!orderData.emailEnabled}
            />
          </Stack>
        </Card.Section>
      </Card>
    </div>
  );
  const trackingShippingUrl = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Tracking URL from order data{" "}
        <TextStyle variation="subdued">
          <Link
            external={true}
            url={`https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tracking_link_shipment_url.png?v=1591762091`}
          >
            (see example)
          </Link>
        </TextStyle>
      </p>
    </div>
  );
  const trackingThankyoupageUrl = (
    <div className="discountTypeHelpTextFontSize">
      <p>
        Thank you page{" "}
        <TextStyle variation="subdued">
          <Link
            external={true}
            url={`https://cdn.shopify.com/s/files/1/0070/3666/5911/files/tracking_link_thank_you_page.png?v=1591762200`}
          >
            (see example)
          </Link>
        </TextStyle>
      </p>
    </div>
  );

  const orderShipmentTemplateMarkup = (
    <Card sectioned>
      <div className="verticalMarginTextContainer">
        <TextContainer>
          <p>Configure the {"{{tracking_url}}"} to send in the email</p>
        </TextContainer>
      </div>

      <div className="stackVerticalMarginTopAdjustment">
        <Stack vertical={true}>
          <RadioButton
            label={trackingShippingUrl}
            checked={orderData.thankYouPage === false}
            id="tracking1"
            name="shippingurl"
            onChange={() => {
              setOrderData((order: any) => ({
                ...order,
                thankYouPage: false,
              }));
            }}
          />
          <RadioButton
            label={trackingThankyoupageUrl}
            id="tracking2"
            name="thankyoupageurl"
            checked={orderData.thankYouPage === true}
            onChange={() => {
              setOrderData((order: any) => ({
                ...order,
                thankYouPage: true,
              }));
            }}
          />
        </Stack>
      </div>
    </Card>
  );
  const tag_for_cod_order_description = (
    <span>
      <p>
        Automatically add tags to your orders based on COD order status.{" "}
        <Link
          external={true}
          url="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/cod_-_tags_preview.png?v=1589530683"
        >
          Preview
        </Link>{" "}
        how tags appear in your Shopline admin.
      </p>
      <br />
      <p>
        No response tag - added if the customer does not confirm or cancel the
        order within 48 hours.
      </p>
      <br />
      <p>
        <b>Note</b> - If you have selected the "automatically cancel the order
        in Shopline" checkbox, we also auto restock items from the cancelled
        order.
      </p>
    </span>
  );
  const getAbandonedCartSettingsMarkup = () => {
    return (
      <>
        {toastMarkup}
        {errorToastMarkup}
        <Layout.AnnotatedSection
          title="Select language"
          // description={description}
        >
          <Stack vertical>{templateLanguageSettingMarkup}</Stack>
        </Layout.AnnotatedSection>
        {type === "COD" && (
          <>
            <Layout.AnnotatedSection
              title="Tags for COD Orders"
              description={tag_for_cod_order_description}
            >
              <Stack vertical>{codMarkup}</Stack>
              <Card sectioned>{settingForAutomaticOrderCancellation}</Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title="COD Order Confirmation Page - Settings"
              description={cod_order_contact_description}
            >
              <Stack vertical>
                {codConfirmationLanguageMarkup}
                {codOrderContactMarkup}
                {nudgeCheckoutOptinScrollSection}
              </Stack>
            </Layout.AnnotatedSection>
          </>
        )}
        {isDiscount && (
          <Layout.AnnotatedSection title={"Configure discount"}>
            <Stack vertical>
              {cartRecoveryTemplateMarkup}
              {nudgeCheckoutOptinScrollSection}
            </Stack>
          </Layout.AnnotatedSection>
        )}
        {type === "ORDER_SHIPMENT" && (
          <Layout.AnnotatedSection title={"Configure tracking url"}>
            <Stack vertical>{orderShipmentTemplateMarkup}</Stack>
          </Layout.AnnotatedSection>
        )}
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={title}
      primaryAction={{
        content: "Save",
        onAction: updateSettings,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getAbandonedCartSettingsMarkup()}</Modal.Section>
    </Modal>
  );
};

export default TemplateModal;
