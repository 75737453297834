import { EMAIL } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: EMAIL.baseUrl,
};

const handleFiltersEmail = (
  shopId: string,
  templateType: any,
  orderId: any,
  name: string,
  orderBy: string,
  desc: boolean,
  pageSize: number,
  pageIndex: number
) => {
  let query = `&pageSize=${pageSize}&pageIndex=${pageIndex}`;
  if (orderBy) {
    query = `&orderBy=${orderBy}&desc=${desc}` + query;
  }
  if (templateType && templateType.length !== 0) {
    query = `&templateType=${templateType.join(",")}` + query;
  }
  if (name) {
    query = `&name=${name}` + query;
  }
  if (orderId) {
    query = `&orderId=${orderId}` + query;
  }
  return httpClient.get(
    EMAIL.api.LOGS.replace("$shopId", shopId) + `${query}`,
    config
  );
};

const getConfigurationSettings = (shopId: string) => {
  return httpClient.get(
    EMAIL.api.CONFIG_SETTING.replace("$shopId", shopId),
    config
  );
};

const getAbCartSettings = (shopId: string) => {
  return httpClient.get(
    EMAIL.api.AB_CART_SETTINGS.replace("$shopId", shopId),
    config
  );
};
const updateAbCartSettings = (shopId: string, params: any) => {
  return httpClient.put(
    EMAIL.api.AB_CART_SETTINGS.replace("$shopId", shopId),
    params,
    config
  );
};
const getOrderShipmentSettings = (shopId: string) => {
  return httpClient.get(
    EMAIL.api.ORDER_SHIPMENT_SETTINGS.replace("$shopId", shopId),
    config
  );
};
const updateOrderShipmentSettings = (shopId: string, params: any) => {
  return httpClient.put(
    EMAIL.api.ORDER_SHIPMENT_SETTINGS.replace("$shopId", shopId),
    params,
    config
  );
};
const getOrderConfirmationSettings = (shopId: string) => {
  return httpClient.get(
    EMAIL.api.ORDER_CONFIRMATION_SETTINGS.replace("$shopId", shopId),
    config
  );
};
const updateOrderConfirmationSettings = (shopId: string, params: any) => {
  return httpClient.put(
    EMAIL.api.ORDER_CONFIRMATION_SETTINGS.replace("$shopId", shopId),
    params,
    config
  );
};
const getCodSettings = (shopId: string) => {
  return httpClient.get(
    EMAIL.api.COD_SETTINGS.replace("$shopId", shopId),
    config
  );
};
const updateCodSettings = (shopId: string, params: any) => {
  return httpClient.put(
    EMAIL.api.COD_SETTINGS.replace("$shopId", shopId),
    params,
    config
  );
};

const updateConfigSettings = (shopId: string, params: any) => {
  return httpClient.put(
    EMAIL.api.UPDATE_CONFIG_SETTINGS.replace("$shopId", shopId),
    params,
    config
  );
};

export {
  handleFiltersEmail,
  getConfigurationSettings,
  updateConfigSettings,
  getAbCartSettings,
  getOrderConfirmationSettings,
  getCodSettings,
  getOrderShipmentSettings,
  updateAbCartSettings,
  updateCodSettings,
  updateOrderConfirmationSettings,
  updateOrderShipmentSettings,
};
