import {
  ContextualSaveBar,
  Layout,
  Link,
  SettingToggle,
  TextStyle
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
  getShareDetails,
  updateShareDetails,
  updateShareTheme,
  getShareButtonStatus,
  updateShareButtonStatus,
  updateShareButtonDisplayPages,
  getShareButtonDisplayPages,
} from "../../redux/actions/share-settings";
import ShareDevices from "./Share/Devices/index";
import ShareButtonTheme from "./Share/ShareButtonTheme";
import SharePagesToDisplay from "./Share/SharePagesToDisplay";
import { deleteShareDevices, updateShareDevices } from "../../redux/actions/share-settings";
import { isNullOrEmpty } from "../../utils/helper";
import React from "react";

const ShareSettings = (props: any) => {
  const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } = props;
  const [isWAShareEnabled, setIsWAShareEnabled] = useState(false);
  const [shopId, setShopId] = useState("");
  const [shopUrl, setShopUrl] = useState('');
  const [hasChanges, setHasChanges] = useState(false);

  const [shareData, setShareData] = useState({
    btn_cta: "",
    buttonId: "",
    buttonPages: [],
    include_product_link: false,
    is_share_btn_color_custom: false,
    is_share_btn_solid_background: false,
    is_share_enabled: true,
    prefilled_msg: "Please share about us",
    share_btn_bg_color_1: "#ffffff",
    share_btn_bg_color_2: "#ffffff",
    share_btn_icon_color: "#ffffff",
    share_btn_text_color: "#ffffff",
  });
  const [dataChanges, setDataChanges] = useState<any>({
    share: null,
    shareTheme: null,
    pagesToDisplay: "",
    sharePagesToDisplay: "",
    devices: null
  });
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<any>({
    shareTheme: '',
    mobile: '',
    desktop: ''
  });

  const [devicesData, setDevicesData] = useState({
    deviceType: null,
    mobileSelector: "",
    desktopSelector: ""
  })

  const getDeviceData = (data: any) => {
    setDevicesData(data);
  }
  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShopId(shop.id);
      setShopUrl(shop.url);
      fetchShareData(shop.id);
    }
  }, []);

  useEffect(() => {
    if (hasChanges) {
      setDiscardChanges(false);
    }
  }, [hasChanges]);

  const fetchShareData = (shopId: string) => {
    getShareButtonStatus().then((response: any) => {
      setIsWAShareEnabled(response.enable);
    });

    // getShareDetails(shopId).then(
    //   (data: any) => {
    //     // setIsWAShareEnabled(data.is_share_enabled);
    //     setShareData(data);
    //   },
    //   (err) => { }
    // );

    /** Button Text & Design  */

    /** Display Pages  */
    getShareButtonDisplayPages().then((data: any) => {
      setShareData({ ...shareData, buttonPages: data });
    });
  };

  const getToggleSwitchShareBtnContent = () => {
    return isWAShareEnabled ? "Disable" : "Enable";
  };
  const getToggleSwitchshareTxtContent = () => {
    return isWAShareEnabled ? "enabled" : "disabled";
  };
  const getToggleSwitchShareVariationStyle = () => {
    return isWAShareEnabled ? "strong" : "negative";
  };
  
  const enableWhatsAppShare = () => {
    let isShareEnabledReverse = isWAShareEnabled ? false : true;
    updateShareButtonStatus({ enable: isShareEnabledReverse }).then((share: any) => {
      let toastMessage = isShareEnabledReverse ? "Enabled Share" : "Disabled Share";
      setToast(toastMessage);
      setIsWAShareEnabled(share.enable);
    });
  };

  const shareButtonThemeChanges = (data: any) => {
    
    if (data && Object.keys(data).length > 0) {
      if(data.reallyDiffObj === 0){
        setHasChanges(false);
      }
      if (data.reallyDiffObj) {
        setHasChanges(true);
        setDataChanges({ ...dataChanges, share: data });
      }
    }
  };

  const devicesChanges = (data: any) => {
    if (Object.keys(data).length > 0) {
      if (Object.keys(data.mobile).length > 0) {
        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            mobile: data.mobile
          }
        });
        setHasChanges(true);
      }

      if (Object.keys(data.desktop).length > 0) {
        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            desktop: data.desktop
          }
        });
        setHasChanges(true);
      }
    }
  }

  const sharePagesToDisplayChanges = (data: any) => {
    if (data && data.length > 0) {
      setHasChanges(true);
      setDataChanges({ ...dataChanges, sharePagesToDisplay: data.toString() });
    }
  };

  const isFormValid = () => {

    // here
    let valid = true, message: string = '';
    Object.keys(errorMessage).forEach((item) => {
      if (errorMessage[item]) {
        valid = false;
        message = errorMessage[item];
      }
    });

    return { valid: valid, message: message };
  }

  const updateShare = () => {
    if (devicesData.deviceType === 1) {
      if (!devicesData.mobileSelector) {
        setShowErrorToast((errorToast: any) => !errorToast)
        setErrorToast("Invalid Mobile Share Button Position");
        return;
      }
    }

    if (devicesData.deviceType === 2) {
      if (!devicesData.desktopSelector) {
        setShowErrorToast((errorToast: any) => !errorToast)
        setErrorToast("Invalid Desktop Share Button Position")
        return;
      }
    }

    if (devicesData.deviceType === 3) {
      if (!devicesData.desktopSelector) {
        setShowErrorToast((errorToast: any) => !errorToast)
        setErrorToast("Invalid Desktop Share Button Position")
        return;
      }
      if (!devicesData.mobileSelector) {
        setShowErrorToast((errorToast: any) => !errorToast)
        setErrorToast("Invalid Mobile Share Button Position")
        return;
      }
    }

    if (dataChanges.share && "buttonCta" in dataChanges.share && isNullOrEmpty(dataChanges.share.buttonCta)) {
      setShowErrorToast((errorToast: any) => !errorToast)
      setErrorToast("Share button text cannot be empty");
      return;
    }
    if (dataChanges.share && "shareMessage" in dataChanges.share && isNullOrEmpty(dataChanges.share.shareMessage)) {
      setShowErrorToast((errorToast: any) => !errorToast)
      setErrorToast("Share message text cannot be empty");
      return;
    }

    let form = isFormValid();
    if (!form.valid) {
      setShowErrorToast((errorToast: any) => !errorToast)
      setErrorToast(form.message)
    }
    else {
      setIsDataSubmitted(false);
      if (dataChanges?.share) {
        let share_button_data = dataChanges.share;

        updateShareTheme(shopId, share_button_data?.templateId, share_button_data).then((response: any) => {
          if (response.errorMessage) {
            setShowErrorToast((errorToast: any) => !errorToast)
            setErrorToast(response);
            fetchShareData(shopId);
          } else if (response.displayScope) {
            setShowSuccessToast((successToast: any) => !successToast)
            setToast("Custom Changes Saved");
            setDataChanges({ ...dataChanges, share: null });
            setIsDataSubmitted(true);
          }
        }, (err: any) => {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast("Something went wrong");
        });
      }

      /** Start Button Display & Position */
      if (selectedDeviceType) {
        let putDevice = {
          "displayType": 1,
          "desktopPosition": "left",
          "desktopHeightOffset": "0",
          "mobilePosition": "left",
          "mobileHeightOffset": "0"
        };

        putDevice = { ...putDevice, "displayType": selectedDeviceType };
        if (selectedDeviceType === 1 || selectedDeviceType === 3) {
          let mobileData = { ...dataChanges?.devices?.mobile };
          if (mobileData) {
            putDevice = {
              ...putDevice,
              ...mobileData,
              "mobilePosition": mobileData['sharePosition'],
              "mobileHeightOffset": mobileData['verticalOffsetShare'],
            };
          }
        }
        if (selectedDeviceType === 2 || selectedDeviceType === 3) {
          let desktopData = dataChanges?.devices?.desktop;
          if (desktopData) {
            putDevice = {
              ...putDevice,
              ...desktopData,
            };
          }
        }

        updateShareDevices(shopId, putDevice).then((response: any) => {
          if (response.errorMessage) {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response.errorMessage);
            fetchShareData(shopId);
          } else if (response.id > 0) {
            setShowSuccessToast((successToast: any) => !successToast)
            setToast("Button Display & Position Saved Successfully");
            setDataChanges({ ...dataChanges, device: "" });
            setIsDataSubmitted(true);
          }
        });
      }
      /** End Button Display & Position */

      /** Start Pages to display */
      if (dataChanges.sharePagesToDisplay) {
        // const params = new URLSearchParams();
        // params.append("buttonPages", dataChanges.sharePagesToDisplay);
        updateShareButtonDisplayPages(shopId, dataChanges.sharePagesToDisplay).then((response: any) => {
          if (response.errorMessage) {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response.errorMessage);
            fetchShareData(shopId);
          } else if (response.displayScope) {
            setShowSuccessToast((successToast: any) => !successToast)
            setToast("Data Saved Successfully");
            setDataChanges({ ...dataChanges, pagesToDisplay: "" });
            setIsDataSubmitted(true);
          }
        });
      }
      /** End Pages to display */
    }

    setHasChanges(false);
  };

  const updateShareButtonDetails = (params: any) => {
    updateShareDetails(shopId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setShowSuccessToast((successToast: any) => !successToast)
          setToast("Share Data Saved");
          setDataChanges({ ...dataChanges, share: null });
          setIsDataSubmitted(true);
          setShareData({ ...shareData, ...response.share })
        } else {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast(response);
        }
      },
      (err: any) => {
        setShowErrorToast((errorToast: any) => !errorToast)
        setErrorToast("Something went wrong");
      }
    );
  }

  const updateMobileData = () => {
    if (devicesData.mobileSelector) {
      const params = new URLSearchParams();
      var mData: any = { deviceType: "mobile", sharePosition: devicesData.mobileSelector, ...dataChanges.devices?.mobile };

      Object.keys(mData).forEach((key) => {
        params.append(key, mData[key]);
      });
      updateShareDevices(shopId, params).then((response: any) => {
        if (response.status === "success") {
          setShowSuccessToast((successToast: any) => !successToast)
          setToast("Data Saved Successfully");
          setDataChanges({ ...dataChanges, devices: { ...dataChanges.devices, mobile: null } });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast(response);
        }
      })
    }
  }

  const deleteMobileData = () => {
    const params = new URLSearchParams();
    params.append('deviceType', 'mobile');
    deleteShareDevices(shopId, params).then((response: any) => {
      if (response.status === "success") {
        setDataChanges({ ...dataChanges, devices: { ...dataChanges.devices, mobile: null } });
      }
    });
  }

  const updateDesktopData = () => {
    if (devicesData.desktopSelector) {
      const params = new URLSearchParams();
      var dData: any = { deviceType: "desktop", sharePosition: devicesData.desktopSelector, ...dataChanges.devices?.desktop };


      Object.keys(dData).forEach((key) => {
        params.append(key, dData[key]);
      });
      updateShareDevices(shopId, params).then((response: any) => {
        if (response.status === "success") {
          setShowSuccessToast((successToast: any) => !successToast)
          setToast("Data Saved Successfully");
          setDataChanges({ ...dataChanges, devices: { ...dataChanges.devices, desktop: null } });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast(response);
        }
      })
    }
  }

  const deleteDesktopData = () => {
    const params = new URLSearchParams();
    params.append('deviceType', 'desktop');
    deleteShareDevices(shopId, params).then((response: any) => {
      if (response.status === "success") {
        setDataChanges({ ...dataChanges, devices: { ...dataChanges.devices, desktop: null } });
      }
    });
  }

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateShare,
      }}
      discardAction={{
        onAction: () => {
          setHasChanges(false);
          setDiscardChanges(true);
        },
      }}
    />
  ) : null;

  const handleDeviceTypeChange = (type: number, showCTX: boolean) => {
    setSelectedDeviceType(type);
    setHasChanges(showCTX);
  }

  const handleErrorMessage = (message: string, key: string) => {
    let obj: any = errorMessage;
    obj[key] = message;
    setErrorMessage(obj);
  }

  return (
    <React.Fragment>
      {ctxSaveBarMarkup}
      <Layout.AnnotatedSection
        title="WhatsApp Share"
        description="Enable or Disable the WhatsApp Share button on your store."
      >
        <div className="pt-2">
          <SettingToggle
            action={{
              content: getToggleSwitchShareBtnContent(),
              onAction: enableWhatsAppShare,
            }}
            enabled={isWAShareEnabled}
          >
            WhatsApp Share is currently{" "}
            <TextStyle variation={getToggleSwitchShareVariationStyle()}>
              {getToggleSwitchshareTxtContent()}
            </TextStyle>{" "}
            <Link url={'https://' + shopUrl} external={true}>
              {" "}
              View store
            </Link>
          </SettingToggle>
        </div>
      </Layout.AnnotatedSection>

      <ShareButtonTheme
        shareData={shareData}
        setShareData={setShareData}
        onChanges={shareButtonThemeChanges}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setShowErrorToast={setShowErrorToast}
        setShowSuccessToast={setShowSuccessToast}
        isDataSubmitted={isDataSubmitted}
        reset={discardChanges}
        onError={handleErrorMessage}
        fetchShareData={fetchShareData}
      />

      <ShareDevices
        onChanges={devicesChanges}
        isDataSubmitted={isDataSubmitted}
        onTypeChange={handleDeviceTypeChange}
        reset={discardChanges}
        onError={handleErrorMessage}
        getDeviceData={getDeviceData} />
      <SharePagesToDisplay
        shareData={shareData}
        onChanges={sharePagesToDisplayChanges}
        isDataSubmitted={isDataSubmitted}
        reset={discardChanges}
      />
    </React.Fragment>
  );
};
export default ShareSettings;
