import axios from 'axios';
import cookie from "react-cookies";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common['token'] = cookie.load("access_token");
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded';

const httpClient = axios.create();

const getAuthToken = () => cookie.load("access_token");

httpClient.interceptors.request.use(config => {
    config.headers['token'] = getAuthToken();
    config.headers['Authorization'] = "Bearer " + getAuthToken();
    return config;
});

httpClient.interceptors.response.use(res => {
    switch(res.status) {
        case 204:
        case 202:
            return Promise.resolve(res['status']);
        default:
            return Promise.resolve(res['data']);
    }
}, err => {
    if (err && err.response && err.response.data && err.response.data.message) {
        return Promise.resolve(err.response.data.message);
    } else {
        return Promise.reject(err)
    }
});

export default httpClient;