import React, { useEffect, useState } from "react";
import {
  Card,
  FormLayout,
  Layout,
  SettingToggle,
  TextField
} from "@shopify/polaris";
import { isValidHex, objectDifference } from "../../../utils/helper";
import CustomColorPicker from "../../../common/CustomColorPicker";
import { SHARE_BTN_TEMPLATE_DICT } from "../../../constants/constants";
import ChangeShareTemplateModal from "./ChangeShareTemplateModal";
import ShareBtnPreview from "./ShareBtnPreview";
import { getShareCustomThemes, updateShareDetails, updateShareTheme } from "../../../redux/actions/share-settings";

const ShareButtonTheme = (props: any) => {
  const { setToast, setErrorToast, setShowSuccessToast, setShowErrorToast } = props;
  var availableTemplates: any = SHARE_BTN_TEMPLATE_DICT;
  const [openChangeShareTemplateModal, setOpenChangeShareTemplateModal] = useState(false);
  const [shopId, setShopId] = useState("");

  const [plan] = useState({});
  const [initialShareDetails, setInitialShareDetails] = useState(props.shareData);
  const [shareDetails, setShareDetails] = useState(props.shareData);
  const [refShareDetails, setRefShareDetails] = useState(props.shareData);
  const [shareButtonThemeUpdateData, setShareButtonthemeUpdatedData] = useState({});
  const [shareBtnTemplateDesigns, setShareBtnTemplateDesigns] = useState<any>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>({
    template: null,
    type: "share",
    isCustomColors: true,
    isBgStyleColorSolid: true,
    bgColor1: "#22CD5B",
    bgColor2: "#30BF42",
    iconColor: "#ffffff",
    buttonTextColor: "#ffffff",
    mainLabel: null,
    allowIconColor: true,
  });

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShopId(shop.id);

      if (props && props.shareData) {
        setRefShareDetails(props.shareData);
        setShareDetails(props.shareData);
        setInitialShareDetails(props.shareData);

        getAllShareCustomThemes(shop.id);

        initCustomShare = {
          template: props?.shareData?.buttonId,
          type: "share",
          isCustomColors: props?.shareData?.is_share_btn_color_custom,
          isBgStyleColorSolid: props.shareData.is_share_btn_solid_background,
          bgColor1: isValidHex(props.shareData.share_btn_bg_color_1) ? props.shareData.share_btn_bg_color_1 : "#22CD5B",
          bgColor2: isValidHex(props.shareData.share_btn_bg_color_2) ? props.shareData.share_btn_bg_color_2 : "#30BF42",
          buttonTextColor: isValidHex(props.shareData.share_btn_text_color) ? props.shareData.share_btn_text_color : "#ffffff",
          iconColor: isValidHex(props.shareData.share_btn_icon_color) ? props.shareData.share_btn_icon_color : "#ffffff",
          mainLabel: "Use custom Share Button colours",
          allowIconColor: true,
        };
      }
    }
  }, [props.shareData]);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setRefShareDetails(shareDetails);
      setInitialShareDetails(shareDetails);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    var diffObj: any = objectDifference(refShareDetails, shareDetails);
    setShareButtonthemeUpdatedData({});
    let reallyDiffObj = Object.keys(diffObj).length;
    diffObj = { ...refShareDetails, ...shareDetails, reallyDiffObj: reallyDiffObj }
    setUpdatedButtonThemeData("reallyDiffObj", reallyDiffObj);

    if (diffObj && Object.keys(diffObj).length > 0) {
      if ("include_product_link" in diffObj) {
        setUpdatedButtonThemeData("templateId", shareDetails.buttonId);
        setUpdatedButtonThemeData("includeLink", diffObj.include_product_link);
      }
      if ("btn_cta" in diffObj) {
        setUpdatedButtonThemeData("templateId", shareDetails.buttonId);
        setUpdatedButtonThemeData("buttonCta", diffObj.btn_cta);
      }
      if ("prefilled_msg" in diffObj) {
        setUpdatedButtonThemeData("templateId", shareDetails.buttonId);
        setUpdatedButtonThemeData("shareMessage", diffObj.prefilled_msg);
      }
      if ("share_btn_bg_color_1" in diffObj) {
        setUpdatedButtonThemeData("templateId", shareDetails.buttonId);
        setUpdatedButtonThemeData("bgColor1", diffObj.share_btn_bg_color_1);
      }
      if ("share_btn_bg_color_2" in diffObj) {
        setUpdatedButtonThemeData("templateId", shareDetails.buttonId);
        setUpdatedButtonThemeData("bgColor2", diffObj.share_btn_bg_color_2);
      }
      if ("share_btn_text_color" in diffObj) {
        setUpdatedButtonThemeData("templateId", shareDetails.buttonId);
        setUpdatedButtonThemeData("textColor", diffObj.share_btn_text_color);
      }
      if ("share_btn_icon_color" in diffObj) {
        setUpdatedButtonThemeData("templateId", shareDetails.buttonId);
        setUpdatedButtonThemeData("iconColor", diffObj.share_btn_icon_color);
      }
      if ("is_share_btn_solid_background" in diffObj) {
        setUpdatedButtonThemeData("templateId", shareDetails.buttonId);
        setUpdatedButtonThemeData("solidBg", diffObj.is_share_btn_solid_background);
      }
    }
  }, [shareDetails]);

  useEffect(() => {
    let errorMessage = getErrorMessage();
    if (errorMessage) {
      props.onError(errorMessage, 'shareTheme');
      props.onChanges(null);
    } else {
      props.onError('', 'shareTheme');
      props.onChanges(shareButtonThemeUpdateData);
    }
  }, [shareButtonThemeUpdateData]);

  useEffect(() => {
    if (props.reset) {
      setRefShareDetails(initialShareDetails);
      setShareDetails(initialShareDetails);
    }
  }, [props.reset]);

  const getAllShareCustomThemes = (shopId: string) => {
    getShareCustomThemes(shopId).then((response: any) => {
      if (response.status === "success" && response?.theme?.buttonTemplateId) {
        if (!props.shareData.buttonId || props.shareData.buttonId !== response?.theme?.buttonTemplateId) {
          props.setShareData({
            ...props.shareData,
            buttonId: response?.theme?.buttonTemplateId,
            is_share_btn_color_custom: response?.theme?.bgColor1 !== "" ? true : false,
            is_share_btn_solid_background: response?.theme?.backgroundStyleType === "single" ? true : false,
          })
        }

        let data = response?.theme;
        let customThemes = {};
        if (data) {
          setInitialShareDetails({
            ...props.shareData,
            is_share_btn_color_custom: data?.is_share_btn_color_custom ? data?.is_share_btn_color_custom : props.shareData.is_share_btn_color_custom,
            is_share_btn_solid_background: data?.is_share_btn_solid_background ? data?.is_share_btn_solid_background : props.shareData.is_share_btn_solid_background,
            share_btn_bg_color_1: data?.bgColor1 ? "#" + data.bgColor1 : props.shareData.share_btn_bg_color_1,
            share_btn_bg_color_2: data?.bgColor2 ? "#" + data.bgColor2 : props.shareData.share_btn_bg_color_2,
            share_btn_text_color: data?.textColor ? "#" + data.textColor : props.shareData.share_btn_text_color,
            share_btn_icon_color: data?.iconColor ? "#" + data.iconColor : props.shareData.share_btn_icon_color,
            buttonId: response?.theme?.buttonTemplateId,
            btn_cta: response?.theme?.text,
            prefilled_msg: response?.theme?.message,
          });
          setRefShareDetails({
            ...props.shareData,
            is_share_btn_color_custom: data?.is_share_btn_color_custom ? data?.is_share_btn_color_custom : props.shareData.is_share_btn_color_custom,
            is_share_btn_solid_background: data?.is_share_btn_solid_background ? data?.is_share_btn_solid_background : props.shareData.is_share_btn_solid_background,
            share_btn_bg_color_1: data.bgColor1 ? "#" + data.bgColor1 : props.shareData.share_btn_bg_color_1,
            share_btn_bg_color_2: data.bgColor2 ? "#" + data.bgColor2 : props.shareData.share_btn_bg_color_2,
            share_btn_text_color: data.textColor ? "#" + data.textColor : props.shareData.share_btn_text_color,
            share_btn_icon_color: data.iconColor ? "#" + data.iconColor : props.shareData.share_btn_icon_color,
            buttonId: response?.theme?.buttonTemplateId,
            btn_cta: response?.theme?.text,
            prefilled_msg: response?.theme?.message,
          });
          setShareDetails({
            ...props.shareData,
            is_share_btn_color_custom: data?.is_share_btn_color_custom ? data?.is_share_btn_color_custom : props.shareData.is_share_btn_color_custom,
            is_share_btn_solid_background: data?.is_share_btn_solid_background ? data?.is_share_btn_solid_background : props.shareData.is_share_btn_solid_background,
            share_btn_bg_color_1: data.bgColor1 ? "#" + data.bgColor1 : props.shareData.share_btn_bg_color_1,
            share_btn_bg_color_2: data.bgColor2 ? "#" + data.bgColor2 : props.shareData.share_btn_bg_color_2,
            share_btn_text_color: data.textColor ? "#" + data.textColor : props.shareData.share_btn_text_color,
            share_btn_icon_color: data.iconColor ? "#" + data.iconColor : props.shareData.share_btn_icon_color,
            buttonId: response?.theme?.buttonTemplateId,
            btn_cta: response?.theme?.text,
            prefilled_msg: response?.theme?.message,
          });
          customThemes = {
            is_share_btn_color_custom: data?.is_share_btn_color_custom ? data?.is_share_btn_color_custom : props.shareData.is_share_btn_color_custom,
            is_share_btn_solid_background: data?.is_share_btn_solid_background ? data?.is_share_btn_solid_background : props.shareData.is_share_btn_solid_background,
            share_btn_bg_color_1: data.bgColor1 ? "#" + data.bgColor1 : props.shareData.share_btn_bg_color_1,
            share_btn_bg_color_2: data.bgColor2 ? "#" + data.bgColor2 : props.shareData.share_btn_bg_color_2,
            share_btn_text_color: data.textColor ? "#" + data.textColor : props.shareData.share_btn_text_color,
            share_btn_icon_color: data.iconColor ? "#" + data.iconColor : props.shareData.share_btn_icon_color,
          };
        }
        setShareBtnTemplateDesigns(customThemes);
      }
    });
  }

  const getErrorMessage = () => {
    if (validateBtnCta() !== false) {
      return "Invalid Share Button Text";
    } else if (validatePrefilledMsg() !== false) {
      return "Invalid Share Message";
    } else {
      return false;
    }
  }

  const setUpdatedButtonThemeData = (field: string, val: any) => {
    setShareButtonthemeUpdatedData((prevData) => ({
      ...prevData,
      [field]: val,
    }));
  };
  const getShareButtonPreview = () => {
    return ShareBtnPreview({
      template: getCurrentSelectedShareTemplate(),
      custom: shareDetails,
      selected: selectedTemplate
    });
  };

  const getCurrentSelectedShareTemplateUrl = () => {
    let previewURL = '';
    for (var id in availableTemplates) {
      if (Number(id) === shareDetails.buttonId) {
        previewURL = availableTemplates[id].preview_url;
      }
    }
    return previewURL;
  };

  let initCustomShare = {
    template: shareDetails?.buttonId || {},
    type: "share",
    isCustomColors: shareDetails.is_share_btn_color_custom,
    isBgStyleColorSolid: shareDetails.is_share_btn_solid_background,
    bgColor1: isValidHex(shareDetails.share_btn_bg_color_1) ? shareDetails.share_btn_bg_color_1 : "#22CD5B",
    bgColor2: isValidHex(shareDetails.share_btn_bg_color_2) ? shareDetails.share_btn_bg_color_2 : "#30BF42",
    buttonTextColor: isValidHex(shareDetails.share_btn_text_color) ? shareDetails.share_btn_text_color : "#ffffff",
    iconColor: isValidHex(shareDetails.share_btn_icon_color) ? shareDetails.share_btn_icon_color : "#ffffff",
    mainLabel: "Use custom Share Button colours",
    allowIconColor: true,
  };

  const onCustomShareBtnChange = (obj: any) => {
    let buttonDesigns = shareBtnTemplateDesigns;

    let index = buttonDesigns && buttonDesigns.length > 0 ? 0 : -1;

    if (obj.bgColor1) {
      handleChange(obj.bgColor1, "share_btn_bg_color_1");
      initCustomShare['bgColor1'] = obj.bgColor1;
      setSelectedTemplate(initCustomShare);
      if (index >= 0)
        buttonDesigns[index].share_btn_bg_color_1 = obj.bgColor1;
    }
    if (obj.bgColor2) {
      handleChange(obj.bgColor2, "share_btn_bg_color_2");
      initCustomShare['bgColor2'] = obj.bgColor2;
      setSelectedTemplate(initCustomShare);
      if (index >= 0)
        buttonDesigns[index].share_btn_bg_color_2 = obj.bgColor2;
    }
    if (obj.buttonTextColor) {
      handleChange(obj.buttonTextColor, "share_btn_text_color");
      initCustomShare['buttonTextColor'] = obj.buttonTextColor;
      setSelectedTemplate(initCustomShare);
      if (index >= 0)
        buttonDesigns[index].share_btn_text_color = obj.buttonTextColor;
    }
    if (obj.iconColor) {
      handleChange(obj.iconColor, "share_btn_icon_color");
      initCustomShare['iconColor'] = obj.iconColor;
      setSelectedTemplate(initCustomShare);
      if (index >= 0)
        buttonDesigns[index].share_btn_icon_color = obj.iconColor;
    }
    if ("isBgStyleColorSolid" in obj) {
      let value = obj.isBgStyleColorSolid || false;
      handleChange(value, "is_share_btn_solid_background");
      initCustomShare['isBgStyleColorSolid'] = value;
      setSelectedTemplate(initCustomShare);
      if (index >= 0) {
        buttonDesigns[index].is_share_btn_solid_background = value;
      }
    }

    if (index >= 0)
      setShareBtnTemplateDesigns(buttonDesigns);
  };
  const handleChange = (val: any, field: string) => {
    setShareDetails((prevData: any) => ({ ...prevData, [field]: val }));
  };

  const validateBtnCta = () => {
    if (!shareDetails.btn_cta.trim()) {
      return "Please enter some text";
    }

    if (shareDetails.btn_cta && shareDetails.btn_cta.length > 30) {
      return "Max 30 characters";
    }
    return false;
  };

  const validatePrefilledMsg = () => {
    if (!shareDetails.prefilled_msg.trim()) {
      return "Please enter some text";
    }

    if (shareDetails.prefilled_msg && shareDetails.prefilled_msg.length > 140) {
      return "Max 140 characters";
    }
    return false;
  };
  const getCurrentSelectedShareTemplate = () => {
    //@ts-ignore
    return shareDetails.buttonId ? availableTemplates[shareDetails.buttonId] : {};
  };
  const customShareColorSettings = () => {
    var is_share_btn_solid_background = shareDetails.is_share_btn_solid_background;
    var bgColor1 = shareDetails.share_btn_bg_color_1;
    var bgColor2 = shareDetails.share_btn_bg_color_2;
    var buttonTextColor = shareDetails.share_btn_text_color;
    var iconColor = shareDetails.share_btn_icon_color;

    return {
      is_share_btn_solid_background,
      bgColor1,
      bgColor2,
      buttonTextColor,
      iconColor,
    };
  };
  const changeShareTemplate = (selected: any, data: any = {}) => {
    var updatedData: any = {
      template: selected,
    };

    for (let key in data) {
      updatedData[key] = data[key];
      setShareDetails((shareData: any) => ({
        ...shareData,
        [key]: data[key],
      }));
    }

    initCustomShare = {
      template: selected,
      type: 'share',
      isCustomColors: data.is_share_btn_color_custom,
      isBgStyleColorSolid: data.is_share_btn_solid_background,
      bgColor1: data?.share_btn_bg_color_1 || "",
      bgColor2: data?.share_btn_bg_color_2 || "",
      iconColor: data?.share_btn_icon_color || '',
      buttonTextColor: data?.share_btn_text_color || "",
      mainLabel: '',
      allowIconColor: true
    };

    setSelectedTemplate(initCustomShare);

    if (selected) {
      updateShareTheme(shopId, selected, initCustomShare).then((response: any) => {
        if (response.errorMessage) {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast(response);
        } else if (response.buttonTemplateId) {
          setShowSuccessToast((successToast: any) => !successToast)
          setToast("Share Button Design Saved");
          props.fetchShareData(shopId)
        }
      }, (err: any) => {
        setShowErrorToast((errorToast: any) => !errorToast)
        setErrorToast("Something went wrong");
      });
      setOpenChangeShareTemplateModal(false);
      document.body.classList.remove("settings-page");
      props.onChanges(null);
    }
  };
  return (
    <Layout.AnnotatedSection
      title="Button Text & Design"
      description="Configure WhatsApp Share and personalise it for your store visitors. Choose from several beautiful share button designs on what suits your store best."
    >
      <SettingToggle
        action={{
          content: "Change Design",
          onAction: () => {
            setOpenChangeShareTemplateModal(true);
          },
        }}
        enabled={true}
      >
        <div className="previewCustomShareBtnBackground">
          {shareDetails.is_share_btn_color_custom ? (
            getShareButtonPreview()
          ) : (
            <img
              className="pre-configured-img"
              src={getCurrentSelectedShareTemplateUrl()}
              alt="WhatsApp icon"
            />
          )}
        </div>
      </SettingToggle>
      <ChangeShareTemplateModal
        available_templates={availableTemplates}
        plan={plan}
        shareDetails={shareDetails}
        customSettings={customShareColorSettings()}
        selected_template_id={shareDetails.buttonId}
        changeTemplate={changeShareTemplate}
        onModalClose={() => {
          setOpenChangeShareTemplateModal(false);
        }}
        open={openChangeShareTemplateModal}
        share_btn_template_designs={shareBtnTemplateDesigns}
      />

      <CustomColorPicker
        {...initCustomShare}
        selectedTemplate={selectedTemplate}
        onCustomChange={onCustomShareBtnChange}
      />

      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Share Button Text"
              placeholder="This text will display beside the WhatsApp share button"
              value={shareDetails.btn_cta}
              onChange={(val) => handleChange(val, "btn_cta")}
              error={validateBtnCta()}
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField
              label="Share Message in WhatsApp"
              placeholder="Pre-filled message body, can be edited by store visitor before sending"
              multiline={2}
              value={shareDetails.prefilled_msg}
              onChange={(val) => handleChange(val, "prefilled_msg")}
              error={validatePrefilledMsg()}
              helpText={"Product link will be included in the message"}
            />
          </FormLayout.Group>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default ShareButtonTheme;
