import {useState} from 'react';

const useCustomForm = (callback: any, fields: any) => {
    var [inputs, setInputs] = useState(fields);

    const handleSubmit = (event: any) => {
        if (event) {
            event.preventDefault();
        }
        callback();
    }

    const handleInputChange = (name:string, value: string) => {
        setInputs((inputs: any) => ({...inputs, [name]: value}))
    }

    return {
        handleSubmit,
        handleInputChange,
        inputs
    };
}

export default useCustomForm;