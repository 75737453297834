import {
  Modal,
  Stack,
  TextContainer,
  TextStyle,
  Toast,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import ShareBtnPreview from "./ShareBtnPreview";

const ChangeShareTempalateModal = (props: any) => {
  const [selected_template_id, setselected_template_id] = useState(props.selected_template_id);
  const [shareBtnTemplateDesign, setShareBtnTemplateDesign] = useState(props.share_btn_template_designs);
  const [open, setOpen] = useState(props.open);
  const [toast, setToast] = useState({ show: false, message: "" });

  useEffect(() => {
    setOpen(props.open);
    setselected_template_id(props.selected_template_id);
    setShareBtnTemplateDesign(props.share_btn_template_designs);
  }, [props]);

  const handleTemplateChange = (val: any) => {
    setselected_template_id(val.target.value);
  };

  const onSave = () => {
    var selectedTemplate = props.available_templates[selected_template_id];
    var custom = props.customSettings;
    var data: any = {
      btn_template: selected_template_id,
      is_share_btn_color_custom: selectedTemplate.is_share_btn_color_custom || false,
    };

    if (selectedTemplate.is_share_btn_color_custom) {
      data.is_share_btn_solid_background = custom ? custom.is_share_btn_solid_background : selectedTemplate.solidBg
      data.share_btn_bg_color_1 = selectedTemplate.share_btn_bg_color_1 ? selectedTemplate.share_btn_bg_color_1 : (custom ? custom.bgColor1 : "");
      data.share_btn_bg_color_2 = selectedTemplate.share_btn_bg_color_2 ? selectedTemplate.share_btn_bg_color_2 : (custom ? custom.bgColor2 : "");
      data.share_btn_text_color = selectedTemplate.share_btn_text_color ? selectedTemplate.share_btn_text_color : (custom ? custom.buttonTextColor : "");
      data.share_btn_icon_color = selectedTemplate.share_btn_icon_color ? selectedTemplate.share_btn_icon_color : (custom ? custom.iconColor : "");
    }
    props.changeTemplate(selected_template_id, data);
    props.onModalClose();
  };
  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  // const getPremiumDesignsText = () => {
  //   if (props.plan.allow_premium_templates) {
  //     return <b>Premium Designs</b>;
  //   } else {
  //     return (
  //       <span>
  //         <b>Premium Designs</b>{" "}
  //         <Link
  //           onClick={() => {
  //             window.location.href = "/pricing?referrer=share";
  //           }}
  //         >
  //           {" "}
  //           (requires paid plan){" "}
  //         </Link>
  //       </span>
  //     );
  //   }
  // };

  const renderShareBtnPreview = (template: any, custom: any) => {

    let btn_design;
    if (shareBtnTemplateDesign) {
      btn_design = shareBtnTemplateDesign[0];
    }

    if (btn_design) {
      custom = {
        ...custom,
        is_share_btn_solid_background: btn_design.is_share_btn_solid_background,
        share_btn_bg_color_1: btn_design.share_btn_bg_color_1,
        share_btn_bg_color_2: btn_design.share_btn_bg_color_2,
        share_btn_text_color: btn_design.share_btn_text_color,
        share_btn_icon_color: btn_design.share_btn_icon_color
      };
    } else {
      custom = {
        ...custom,
        is_share_btn_solid_background: template.is_share_btn_solid_background,
        share_btn_bg_color_1: template.share_btn_bg_color_1,
        share_btn_bg_color_2: template.share_btn_bg_color_2,
        share_btn_text_color: template.share_btn_text_color,
        share_btn_icon_color: template.share_btn_icon_color
      };
    }

    return <ShareBtnPreview template={template} custom={custom} />
  }

  const getAvailableTemplates = () => {
    var button_templates = [];
    var customizable_templates = [];
    var available_templates = props.available_templates;
    var custom = props.customSettings;

    var customStyle = {
      display: "flex",
      alignItems: "flex-start",
    };

    for (var i = 1; i <= Object.keys(available_templates).length; i++) {
      var template = (
        <div className="templateItem share-template-btns" key={i}>
          {/*key added...*/}
          <label
            style={available_templates[i].is_share_btn_color_custom ? customStyle : {}}
          >
            <input
              type="radio"
              value={i}
              checked={Number(selected_template_id) === i}
              onChange={(val) => handleTemplateChange(val)}
            />
            <span className="tmplinput">
              {available_templates[i].is_share_btn_color_custom ? (
                renderShareBtnPreview(available_templates[i], custom)
              ) : (
                <img
                  className="tmplimg"
                  src={available_templates[i].preview_url}
                  alt=""
                />
              )}
            </span>
          </label>
        </div>
      );
      if (available_templates[i].is_share_btn_color_custom) {
        customizable_templates.push(template);
      } else {
        button_templates.push(template);
      }
    }

    return (
      <div>
        {toastMarkup}

        <Modal.Section>
          <TextContainer>
            <p>
              <b>Customizable Design </b>
              <TextStyle variation="subdued">
                (select this to set custom colours)
              </TextStyle>
            </p>
          </TextContainer>
          <br />
          <div className="previewBackground">
            <Stack>{customizable_templates}</Stack>
          </div>
        </Modal.Section>

        <Modal.Section>
          <TextContainer>
            <b>Pre-configured Button Designs</b>
          </TextContainer>
          <br />
          <div className="previewBackground">
            <Stack>{button_templates}</Stack>
          </div>
        </Modal.Section>
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        props.onModalClose();
      }}
      title="Share Button Templates"
      primaryAction={{
        content: "Save",
        onAction: onSave,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpen(false);
            props.onModalClose();
          },
        },
      ]}
      limitHeight={false}
    >
      {getAvailableTemplates()}
    </Modal>
  );
};

export default ChangeShareTempalateModal;
