import { Banner, Layout, Modal, Page, Toast, FooterHelp, TextStyle, Link } from "@shopify/polaris";
import { PlayCircleMajor } from '@shopify/polaris-icons';
import { useEffect, useState } from "react";
import { isNullOrEmpty } from "../utils/helper";
import ReviewCard from "./ReviewCard";
import TabsWrapper from "./TabsWrapper";

const PageMarkup = (props: any) => {

    const [shop, setShop] = useState({ id: '', url: ''});
    const [openTutorial, setOpenTutorial] = useState(false);
    const [toast, setToast] = useState({ show: false, message: ''});
    const [errorToast, setErrorToast] = useState({ show: false, message: ''});
    const [reviewClicked, setReviewClicked] = useState(false);

    useEffect(() => {
        let shop = JSON.parse(localStorage.getItem("shop") || "{}");
        if (shop && shop.id) {
            setShop(shop);
        }
        let clicked = localStorage.getItem('review_clicked') === 'true' ? true : false;
        setReviewClicked(clicked);
    }, []);

    useEffect(() => {
        if (!isNullOrEmpty(props.toast)) {
            showToast(props.toast);
        }
    }, [props.toast, props.showSuccessToast]);

    useEffect(() => {
        if (!isNullOrEmpty(props.errorToast)) {
            showErrorToast(props.errorToast);
        }
    }, [props.errorToast, props.showErrorToast]);

    const showToast = (message: string) => {
        setToast({ show: true, message: message});
    }

    const showErrorToast = (message: string) => {
        setErrorToast({ show: true, message: message});
    }

    const toastMarkup = toast.show ? (
        <Toast
          content={toast.message}
          onDismiss={() => {
            setToast({ show: false, message: "" });
          }}
        />
    ) : null;

    const errorToastMarkup = errorToast.show ? (
        <Toast
            error
            content={errorToast.message}
            onDismiss={() => {
                setErrorToast({ show: false, message: "" });
            }}
        />
    ) : null;

    const handleTabChange = (selected: number) => {
        if (selected >= 0 && props.handleTabChange) {
            props.handleTabChange(selected);
        }
    }

    const tabsMarkup = props.tabs ? (
        <TabsWrapper 
            tabs={props.tabs} 
            content={props.tabContent} 
            handleTabChange={handleTabChange}
            selected={props.selected}></TabsWrapper>
    ) : null;

    const updateReviewClicked = (clicked: boolean) => {
        setReviewClicked(clicked);
    }

    // const reviewCardMarkup = !reviewClicked ? (<ReviewCard setReviewClicked={updateReviewClicked}></ReviewCard>) : null;
    const reviewCardMarkup = null;

    return (
        <Page 
            title={props.title} 
            subtitle={props.subtitle}
            secondaryActions={props.tutorial ? [
                {
                  content: 'Tutorial video',
                  icon:   PlayCircleMajor,
                  accessibilityLabel: 'Tutorial Video',
                  outline: true,
                  onAction: () => {
                    setOpenTutorial(true);
                    document.body.classList.add('tutorial-modal')
                  }
                },
              ] : []}>
            {toastMarkup}
            {errorToastMarkup}
            <Layout>
                {tabsMarkup}
                {props.children}
                <Layout.Section>
                    <Banner
                        action={
                            {
                                content: 'Contact Us',
                                url: 'mailto:raj@gupshup.io?subject=I%20need%20help%20with%20WhatsApp%20chat%20—%20' + shop.url,
                                external:true
                            }
                        }
                        status="info">
                        <p>For any bug reports or support related queries, please contact our team (raj@gupshup.io)</p>
                    </Banner>
                </Layout.Section>
                <Layout.Section>
                    {reviewCardMarkup}
                </Layout.Section>
            </Layout>
            <Modal
                open={openTutorial}
                sectioned={true}
                title={"Tutorial video"}
                onClose={()=>{
                    setOpenTutorial(false);
                    document.body.classList.remove('tutorial-modal')
                }}>
                <iframe
                    id="ytplayer"
                    width="100%"
                    height="360"
                    allow="fullscreen"
                    src={props.tutorialSrc}
                    frameBorder="0"
                    title={"Tutorial Video"}
                    >
                </iframe>
            </Modal>
            {props.showGupshupBaner ? <div className="powered-by">
                <FooterHelp>
                  <TextStyle variation="subdued">Powered by</TextStyle>
                  <Link url="https://gupshup.io" external={true}>
                     <img
                      src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/gupshup_logo_high_1.png?v=1596613734"
                      alt={"Gupshup"}
                      style={{ height: '50%', width: '50%', display: 'inline', 'verticalAlign': 'middle' }}/>
                  </Link>
                </FooterHelp>
                </div>
            : null}
        </Page>
    );
}

export default PageMarkup;