import api from "../../utils/api";
import httpClient from "../../utils/http-client";
import {
  AGENT_TIMINGS,
  CHAT,
  CHAT_DEVICES,
  CHAT_BUTTON_TEMPLATES,
  STORE_TIMINGS,
  CUSTOM_THEMES,
  CHAT_BUTTON_STATUS,
  CHAT_BUTTON_DISPLAY_PAGE,
  CHAT_BUTTON_DESIGN,
} from "../../constants/api-constants";
import { CONST_CHAT_BUTTON_TEMPLATES, DAY } from "../../constants/constants";
import { CALL_OUT, GREETING_WIDGET } from "../../constants/api-constants";
import CallOut from "../../types/Callout.type";

export const getCallOutData = (shopId: string) => {
  return new Promise((resolve, reject) => {
    api.get(CALL_OUT.replace("$shopId", shopId)).then((response: any) => {
      if (response.errorMessage) {
        reject(response.message);
      } else if (response.id) {
        let tempResponse = {
          shopId: response?.shopDetail?.id,
          callout_card_delay: response?.delay,
          timeDelay: response?.delay,
          is_callout_card_enabled: response?.calloutCardAboveShow,
          is_callout_card_sound_enabled: response?.soundEnable,
          callout_card_text: response?.text,
        };
        resolve(tempResponse);
      }
      resolve(response);
    });
  });
};
export const getAgentTimings = (shopId: string) => {
  return new Promise((resolve, reject) => {
    api.get(AGENT_TIMINGS.replace("$shopId", shopId))
      .then((response: any) => {
        let agents: any[] = [];
        response.forEach((agent: any) => {
          agents.push({
            agentId: agent.id,
            timings: DAY.map((e, i) => ({ day: i, end_time: agent[`${e.toLocaleLowerCase()}End`], start_time: agent[`${e.toLocaleLowerCase()}Start`] }))
          });
        });
        resolve(agents);
      }).catch(error => {
        reject(error);
      });
  });
};

export const getGreetingWidgetData = (shopId: string) => {
  return new Promise((resolve, reject) => {
    api
      .get(GREETING_WIDGET.replace("$shopId", shopId))
      .then((response: any) => {
        if (response.errorMessage) {
          reject(response.message);
        } else if (response.id) {
          const tempResponse = {
            "is_multi_agent_enabled": response?.enable,
            "greeting_template": response?.greetingTemplateId ? response?.greetingTemplateId : 1,
            "greeting_template_bg_color_1": response?.bgColor1 ? response?.bgColor1 : "",
            "greeting_template_bg_color_2": response?.bgColor2 ? response?.bgColor2 : "",
            "greeting_template_desc_text_color": response?.descriptionTextColor ? response?.descriptionTextColor : "",
            "greeting_template_head_text_color": response?.headTextColor ? response?.headTextColor : "",
            "multi_agent_msg": response?.helpText ? response?.helpText : "",
            "multi_agent_title": response?.title ? response?.title : "",
            "offline_agent_msg": response?.offlineAgentMessage ? response?.offlineAgentMessage : "",
            "offline_store_msg": response?.offlineStoreMessage ? response?.offlineStoreMessage : "",
            "is_greeting_template_solid_background": response?.bgColor1 === "" || response?.bgColor2 === "" ? true : false,
            "should_shuffle_agents": response?.randomOrderAgent
          };
          resolve(tempResponse);
        }
        resolve(response);
      });
  });
};

export const updateGreetingWidgetData = (shopId: string, params: any) => {
  const tempParams = {
    "enable": params?.is_multi_agent_enabled,
    "greetingTemplateId": params?.greeting_template ? params?.greeting_template : 1,
    "bgColor1": params?.bg_color_1 ? params?.bg_color_1.replace("#", "") : "",
    "bgColor2": params?.bg_color_2 ? params?.bg_color_2.replace("#", "") : "",
    "headTextColor": params?.greeting_template_head_text_color ? params?.greeting_template_head_text_color.replace("#", "") : "FFFFFF",
    "descriptionTextColor": params?.greeting_template_desc_text_color ? params?.greeting_template_desc_text_color.replace("#", "") : "FFFFFF",
    "title": params?.multi_agent_title ? params?.multi_agent_title : "",
    "helpText": params?.multi_agent_msg ? params?.multi_agent_msg : "",
    "offlineAgentMessage": params?.offline_agent_msg ? params?.offline_agent_msg : "",
    "offlineStoreMessage": params?.offline_store_msg ? params?.offline_store_msg : "",
    "randomOrderAgent": params?.should_shuffle_agents,

    // "is_greeting_template_solid_background": false,
    // "should_shuffle_agents": false
  };

  return new Promise((resolve, reject) => {
    httpClient.put(GREETING_WIDGET.replace("$shopId", shopId), tempParams).then((response: any) => {
      if (response.errorMessage) {
        reject(response.message);
      } else if (response.id) {
        let tempResponse = response;
        tempResponse = { ...tempResponse, "status": "success" };
        resolve(tempResponse);
      }
      resolve("Something want to wrong");
    });
  });
};

export const getChatButtonStatus = () => {
  return httpClient.get(CHAT_BUTTON_STATUS);
};

export const updateChatButtonStatus = (params: any) => {
  return httpClient.put(CHAT_BUTTON_STATUS, params);
};

export const getChatDevices = (shopId: string) => {
  return new Promise((resolve, reject) => {
    httpClient.get(CHAT_DEVICES.replace("$shopId", shopId)).then((response: any) => {
      let tempResponse = {};
      if (response && response.id) {
        let mobile = {
          "deviceType": "mobile",
          "chatPosition": response?.mobilePosition,
          "sharePosition": "",
          "verticalOffset": response?.mobileHeightOffset + "",
          "horizontalOffset": response?.mobileEdgeOffset + "",
          "productPageHeight": response?.mobileProductPageHeight,
          "productPageOffset": response?.mobileProductPageOffset + "",
          "verticalOffsetShare": "",
        };
        let desktop = {
          "deviceType": "desktop",
          "chatPosition": response?.desktopPosition,
          "sharePosition": "",
          "verticalOffset": response?.desktopHeightOffset + "",
          "horizontalOffset": response?.desktopEdgeOffset + "",
          "productPageHeight": response?.desktopProductPageHeight,
          "productPageOffset": response?.desktopProductPageOffset + "",
          "verticalOffsetShare": "",
        };
        let devices: any[] = [];
        if (response?.displayType === 1) {
          devices = [mobile];
        } else if (response?.displayType === 2) {
          devices = [desktop];
        } else if (response?.displayType === 3) {
          devices = [mobile, desktop];
        }
        tempResponse = { ...tempResponse, "status": "success", "devices": devices }
      }
      resolve(tempResponse);
    }).catch(error => {
      reject(error);
    });
  });
};

export const updateChatDevices = (shopId: string, params: any) => {
  return httpClient.put(CHAT_DEVICES.replace("$shopId", shopId), params);
}

export const deleteChatDevices = (shopId: string, params: any) => {
  return httpClient.delete(CHAT_DEVICES.replace("$shopId", shopId), { data: params });
}


export const getChatIconTemplates = (shopId: string) => {
  return new Promise((resolve, reject) => {
    resolve(CONST_CHAT_BUTTON_TEMPLATES);
    /*api.get(CHAT_BUTTON_TEMPLATES.replace('$shopId', shopId)).then(
      (response: any) => {
        if (response.status === "success") {
          resolve(response.templates);
        } else {
          reject(response.message);
        }
      },
      (err) => {
        reject(err);
      }
    );*/
  });
};

export const getChatDetails = (shopId: string) => {
  return new Promise((resolve, reject) => {
    httpClient.get(CHAT_BUTTON_DESIGN.replace("$shopId", shopId)).then((response: any) => {
      if (response.errorMessage) {
        reject(response.errorMessage);
      } else if (response.id) {
        let initResponse = {
          buttonId: response?.buttonTemplateId,
          btn_cta: response?.text,
          prefilled_msg: response?.message,
          include_product_link: response?.include,
          is_chat_btn_color_custom: false,
          is_chat_btn_solid_background: response?.bgColor1 === "" || response?.bgColor2 === "" ? true : false,
          chat_btn_bg_color_1: '#'+response?.bgColor1,
          chat_btn_bg_color_2: '#'+response?.bgColor2,
          chat_btn_text_color: '#'+response?.textColor,
          chat_btn_icon_color: '#'+response?.iconColor,
          solidBg: response?.backgroundStyleType === "single" ? true : false,
        }
        let tempResponse = { 'chat': initResponse }
        resolve(tempResponse);
      }
    }).catch(error => {
      reject(error);
    });
  });
};

export const updateChatDetails = (shopId: string, params: any) => {
  let tempRequest = {
    "buttonTemplateId": params?.templateId,
    "backgroundStyleType": params?.solidBg ? "single" : "",
    "bgColor1": (params?.chat_btn_bg_color_1).replace("#", ""),
    "bgColor2": (params?.chat_btn_bg_color_2).replace("#", ""),
    "iconColor": (params?.chat_btn_icon_color).replace("#", ""),
    "textColor": (params?.chat_btn_text_color).replace("#", ""),
    "text": params?.buttonCta,
    "message": params?.chatMessage,
    "include": params?.productLink
  }
  return httpClient.put(CHAT_BUTTON_DESIGN.replace("$shopId", shopId), tempRequest);
};

export const updateCallout = (shopId: string, params: any) => {
  params.calloutCardAboveShow = params?.enabled;
  params.soundEnable = params?.notification;
  return httpClient.put(CALL_OUT.replace("$shopId", shopId), params);
};

export const getStoreTimings = (shopId: string) => {
  return new Promise((resolve, reject) => {
    httpClient.get(STORE_TIMINGS.replace("$shopId", shopId))
      .then((response: any) => {
        let tempResponse = {
          status: "success",
          timings: DAY.map((e, i) => ({ day: i, end_time: response[`${e.toLocaleLowerCase()}End`], start_time: response[`${e.toLocaleLowerCase()}Start`] }))
        };
        resolve(tempResponse);
      }).catch(error => {
        reject(error);
      });
  });
};

export const updateChatTheme = (shopId: string, buttonId: string, params: any) => {
  return httpClient.put(CUSTOM_THEMES.replace("$shopId", shopId).replace("$buttonId", buttonId), params);
}

/** Display Page  Function */
export const getChatButtonDisplayPages = () => {
  return new Promise((resolve, reject) => {
    httpClient.get(CHAT_BUTTON_DISPLAY_PAGE).then((response: any) => {
      let tempDisplayScope = [];
      if (response.displayScope) {
        tempDisplayScope = response.displayScope.split(',');
      }
      resolve(tempDisplayScope);
    }).catch(error => {
      reject(error);
    });
  });
};
export const updateChatButtonDisplayPages = (shopId: string, params: any) => {
  params = { "displayScope": params?.toString() };
  // return new Promise(() => {});
  return httpClient.put(CHAT_BUTTON_DISPLAY_PAGE.replace("$shopId", shopId), params);
};