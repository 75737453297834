import { OWNER_DETAILS, SHOP_DETAILS, SHOP_SETTINGS, SHOP_TIMINGS, STORE_TIMINGS } from "../../constants/api-constants";
import { DAY } from "../../constants/constants";
import httpClient from "../../utils/http-client";

export const getOwnerDetails = (shopId: string) => {
  return httpClient.get(OWNER_DETAILS.replace('$shopId', shopId))
};

export const getShopDetails = () => {
  return httpClient.get(SHOP_DETAILS);
};

export const getShopSettings = (shopId: string) => {
  return new Promise((resolve, reject) => {
    httpClient.get(SHOP_SETTINGS.replace('$shopId', shopId))
      .then((response: any) => {
        resolve({
          status: "success",
          settings: {
            buttonEnabled: response.buttonEnabled,
            is_default_store_timings: response.isDefaultStoreTimings,
            is_store_open: response.isStoreOpen,
            store_timezone: response.storeTimeZone,
            multiAgentEnabled: response.multiAgentEnabled,
          }
        });
      }).catch(error => {
        reject(error);
      });
  });
}

export const updateShopSettings = (shopId: string, params: any) => {
  return httpClient.put(SHOP_SETTINGS.replace('$shopId', shopId), params);
}

export const updateShopTimings = (shopId: string, params: any) => {
  let tempShopTimings: any = {};
  DAY.forEach((e, i) => {
    let dayData = params.find((day: any) => day.day == i);
    tempShopTimings[`${e.toLocaleLowerCase()}Start`] = dayData.start_time;
    tempShopTimings[`${e.toLocaleLowerCase()}End`] = dayData.end_time;
  });
  return new Promise((resolve, reject) => {
    httpClient.put(STORE_TIMINGS.replace('$shopId', shopId), tempShopTimings)
      .then((response: any) => {
        let tempResponse = {
          status: "success",
          timings: DAY.map((e, i) => ({ day: i, end_time: response[`${e.toLocaleLowerCase()}End`], start_time: response[`${e.toLocaleLowerCase()}Start`] }))
        };
        resolve(tempResponse);
      }).catch(error => {
        reject(error);
      });
  });
}
