import { MESSAGING } from "../../constants/api-constants";
import { getAutomateMessageLanguage } from "../../utils/common";
import httpClient from "../../utils/http-client";

let config = {
  baseURL: MESSAGING.baseUrl
};

export const getAbondonedCartSettings = () => {
  return httpClient.get(MESSAGING.api.ABANDONED_CART_SETTINGS).then((res: any) => {
    let languageSetting = getAutomateMessageLanguage(res);

    const data: any = {
      discount: res?.setting?.discountEnable,
      discountRatio: res?.setting?.discountRatio,
      discountType: res?.setting?.discountType,
      enable: res?.setting?.enable,
      couponCode: res?.setting?.couponCode,
      language: languageSetting
    };
    return [data, res];
  });
};

export const getOptinWidgetSettings = (shopId: string) => {
  return httpClient.get(MESSAGING.api.OPTIN_WIDGET_SETTINGS.replace("$shopId", shopId), config);
};

export const automatedPhoneSettings = (shopId: string) => {
  return httpClient.get(MESSAGING.api.AUTOMATED_PHONE_SETTINGS.replace("$shopId", shopId), config);
};

export const updateAbandonedCartSettings = (params: any) => {
  const body: any = {
    discountEnable: params.discount,
    discountType: params.discountType,
    discountRatio: params.discountRatio,
    couponCode: params.couponCode,
    enable: params.enable,
    waAutomateMessageTemplateId: params?.language?.id,
  };
  return httpClient.put(MESSAGING.api.ABANDONED_CART_SETTINGS, body, config).then((res: any) => {
    let languageSetting = getAutomateMessageLanguage(res);

    const data: any = {
      discount: res?.setting?.discountEnable,
      discountRatio: res?.setting?.discountRatio,
      discountType: res?.setting?.discountType,
      enable: res?.setting?.enable,
      couponCode: res?.setting?.couponCode,
      language: languageSetting
    };
    return [data, res];
  });
};

export const updateOptinWidgetSettins = (shopId: string, params: any) => {
  return httpClient.put(MESSAGING.api.OPTIN_WIDGET_SETTINGS.replace("$shopId", shopId), params, config);
};
