import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Layout, Page, Stack } from '@shopify/polaris';

const Landing = (props: any) => {
    const history = useHistory();
    const getApp = () => {
        props.hideFrame(false);
        history.push('/login');
    }
    return (
        <Page>
            <Layout>
                <Layout.Section>
                    <Card sectioned>
                        <Stack spacing="loose" alignment="center">
                            <Stack.Item fill>
                                <p>SuperLemon - Best CRM tool to provide personalized chat support to your customers via WhatsApp</p>
                            </Stack.Item>
                            <Button primary onClick={getApp}>Get App</Button>
                        </Stack>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
}

export default Landing;