import React, { useState, useEffect } from "react";
import {
  Card,
  FormLayout,
  Layout,
  RadioButton,
  Stack,
  TextContainer
} from "@shopify/polaris";
import { getChatDevices } from "../../../redux/actions/chat-settings";
import DevicesType from "../../../types/Devices.type";
import {
  DEVICE_TYPE_DESKTOP_ONLY,
  DEVICE_TYPE_MOBILE_AND_DESKTOP,
  DEVICE_TYPE_MOBILE_ONLY,
} from "../../../constants/constants";
import MobileConfig from "./MobileConfig";
import DesktopConfig from "./DesktopConfig";

const Devices = (props: any) => {
  const [mobileDeviceData, setMobileDeviceData] = useState<DevicesType>({
    deviceType: "mobile",
    chatPosition: "left",
    sharePosition: "",
    verticalOffset: "10",
    horizontalOffset: "10",
    productPageHeight: true,
    productPageOffset: "10",
    verticalOffsetShare: ""
  });
  const [desktopDeviceData, setDesktopDeviceData] = useState<DevicesType>({
    deviceType: "desktop",
    chatPosition: "left",
    sharePosition: "",
    verticalOffset: "10",
    horizontalOffset: "10",
    productPageHeight: true,
    productPageOffset: "10",
    verticalOffsetShare: ""
  });
  const [updatedDevices, setUpdatedDevices] = useState<any>({
    mobile: {},
    desktop: {},
  });
  const [selectedDeviceType, setSelectedDeviceType] = useState(DEVICE_TYPE_MOBILE_AND_DESKTOP);
  const [initialDeviceType, setInitialDeviceType] = useState(DEVICE_TYPE_MOBILE_AND_DESKTOP);

  useEffect(() => {
    if (selectedDeviceType === 1) {
      setDesktopDeviceData({
        deviceType: "desktop",
        chatPosition: "",
        sharePosition: "",
        verticalOffset: "",
        horizontalOffset: "",
        productPageHeight: true,
        productPageOffset: "",
        verticalOffsetShare: ""
      })

      props.setDesktopData({})

    }
    else if (selectedDeviceType === 2) {
      setMobileDeviceData({
        deviceType: "mobile",
        chatPosition: "",
        sharePosition: "",
        verticalOffset: "",
        horizontalOffset: "",
        productPageHeight: true,
        productPageOffset: "",
        verticalOffsetShare: ""
      })

      props.setMobileData({
      })
    }

  }, [props.saveFlag])

  useEffect(() => {
    let data = updatedDevices;
    switch (selectedDeviceType) {
      case DEVICE_TYPE_MOBILE_ONLY:
        data['desktop'] = {};
        break;
      case DEVICE_TYPE_DESKTOP_ONLY:
        data['mobile'] = {};
        break;
    }
    props.onChanges(data);
  }, [updatedDevices]);

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      fetchDevicesData(shop.id);
    }
  }, []);

  useEffect(() => {
    if (props.reset) {
      setSelectedDeviceType(initialDeviceType);
      props.setHasChanges(false);
    }
  }, [props.reset]);

  useEffect(() => {
    if (props.isDataSubmitted) {
      setInitialDeviceType(selectedDeviceType)
    }
  }, [props.isDataSubmitted]);

  const fetchDevicesData = (shopId: string) => {
    getChatDevices(shopId).then(
      (data: any) => {
        if (data && data.status === "success") {
          let mobile = data.devices.find((m: any) => m.deviceType === "mobile");
          let desktop = data.devices.find((m: any) => m.deviceType === "desktop");

          if (mobile) {
            setMobileDeviceData(mobile);
            props.setMobileData(mobile);
          } else {
            // sets initial data if undefined || null
            props.setMobileData(mobileDeviceData);
          }

          if (desktop) {
            setDesktopDeviceData(desktop);
            props.setDesktopData(desktop);
          } else {
            // sets initial data if undefined || null
            props.setDesktopData(desktopDeviceData);
          }

          if (mobile && !desktop) {
            setSelectedDeviceType(DEVICE_TYPE_MOBILE_ONLY);
            setInitialDeviceType(DEVICE_TYPE_MOBILE_ONLY);
            props.onTypeChange(DEVICE_TYPE_MOBILE_ONLY, false);
          } else if (desktop && !mobile) {
            setSelectedDeviceType(DEVICE_TYPE_DESKTOP_ONLY);
            setInitialDeviceType(DEVICE_TYPE_DESKTOP_ONLY);
            props.onTypeChange(DEVICE_TYPE_DESKTOP_ONLY, false);
          } else {
            setSelectedDeviceType(DEVICE_TYPE_MOBILE_AND_DESKTOP);
            setInitialDeviceType(DEVICE_TYPE_MOBILE_AND_DESKTOP);
            props.onTypeChange(DEVICE_TYPE_MOBILE_AND_DESKTOP, false);
          }
        }
      },
      (err) => { }
    );
  };

  const handleDevicesChange = (value: number) => {
    setSelectedDeviceType(value);
    props.onTypeChange(value, true);
  };

  const updateChanges = (data: any) => {
    if (data) {
      if (data.deviceType === 'mobile') {
        setUpdatedDevices({ ...updatedDevices, mobile: data });
        props.setMobileData((old: any) => ({
          ...old,
          ...data
        }))

      } else if (data.deviceType === 'desktop') {
        setUpdatedDevices({ ...updatedDevices, desktop: data });
        props.setDesktopData((old: any) => ({
          ...old,
          ...data
        }))
      }
    }
  }

  const deviceMarkup = (
    <>
      <MobileConfig
        data={mobileDeviceData}
        selected={selectedDeviceType}
        onChanges={updateChanges}
        isDataSubmitted={props.isDataSubmitted}
        reset={props.reset}
        onError={props.onError}
        setHasChanges={props.setHasChanges}></MobileConfig>
      <DesktopConfig
        data={desktopDeviceData}
        selected={selectedDeviceType}
        onChanges={updateChanges}
        isDataSubmitted={props.isDataSubmitted}
        reset={props.reset}
        onError={props.onError}
        setHasChanges={props.setHasChanges}></DesktopConfig>
    </>
  );

  return (
    <Layout.AnnotatedSection
      title="Button Display &amp; Position"
      description="Change the display position of the chat button to best suit your store’s design."
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <TextContainer>Chat Button Display</TextContainer>
              <Stack>
                <RadioButton
                  label="Mobile + Desktop"
                  checked={
                    selectedDeviceType === DEVICE_TYPE_MOBILE_AND_DESKTOP
                  }
                  name="mobilecheck"
                  onChange={() =>
                    handleDevicesChange(DEVICE_TYPE_MOBILE_AND_DESKTOP)
                  }
                />
                <RadioButton
                  label="Mobile only"
                  name="mobilecheck"
                  checked={selectedDeviceType === DEVICE_TYPE_MOBILE_ONLY}
                  onChange={() => handleDevicesChange(DEVICE_TYPE_MOBILE_ONLY)}
                />
                <RadioButton
                  label="Desktop only"
                  name="mobilecheck"
                  checked={selectedDeviceType === DEVICE_TYPE_DESKTOP_ONLY}
                  onChange={() => handleDevicesChange(DEVICE_TYPE_DESKTOP_ONLY)}
                />
              </Stack>
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      {deviceMarkup}
    </Layout.AnnotatedSection>
  );
};

export default Devices;
