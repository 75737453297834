import { useEffect, useState } from "react";
import {
	FormLayout,
	Modal,
	Stack,
	TextContainer,
	TextField,
	TextStyle
} from "@shopify/polaris";
import { DAY } from "../../../constants/constants";
import { updateShopTimings } from "../../../redux/actions/shop";
import { getShopId } from "../../../utils/helper";
import { getShopSettings } from "../../../redux/actions/shop";


const StoreTimingsModal = (props: any) => {
	const { setToast, setErrorToast, setShowErrorToast, setShowSuccessToast } = props;	
	const [shopId] = useState(getShopId());
	const [openModal, setOpenModal] = useState(props.open);
	const [storeTimings, setStoreTimings] = useState(props.storeTimings);

	useEffect(() => {
		setOpenModal(props.open);
		setStoreTimings(props.storeTimings);
	}, [props.open]);

	const checkTime = (start_time: any, end_time: any) => {
		if (start_time > end_time) {
			return "End time should be greater than start time";
		}

		return false;
	};

	const onSettingSave = () => {
		if (storeTimings) {

			for (const timing of storeTimings) {
				if (validateTime(timing.start_time)) {
					setShowErrorToast((errorToast:any)=> !errorToast)
					setErrorToast("Invalid start time format");
					return;
				}
				if (validateTime(timing.end_time)) {
					setShowErrorToast((errorToast:any)=> !errorToast)
					setErrorToast("Invalid end time format");
					return;
				}
				if (timing.start_time >= timing.end_time && !(timing.start_time === '0000' && timing.end_time === '0000')) {
					setShowErrorToast((errorToast:any)=> !errorToast)
					setErrorToast("End time should be greater than start time");
					return;
				}
			}

			updateShopTimings(shopId, storeTimings).then(
				(response: any) => {
					if (response.status === "success") {
						getShopSettings(shopId).then((res:any)=>{
							res && res.settings && localStorage.setItem("shopSettings",JSON.stringify(res.settings))
							props.setIsStoreOpen(res.settings.is_store_open)
							props.fetchStoreTimings(shopId);
							setShowSuccessToast((successToast:any)=> !successToast)
							setToast("Timings updated successfully");
							props.closeModal();
						  })
					} else {
						setShowErrorToast((errorToast:any)=> !errorToast)
						setErrorToast(response);
					}
				},
				(err) => {
					setShowErrorToast((errorToast:any)=> !errorToast)
					setErrorToast("Something went wrong");
				}
			);
		}
	};

	const renderStoreTimings = () => {
		var timingsArr = [];
		if (storeTimings) {
			for (var i = 0; i < storeTimings.length; i++) {
				let day = DAY[storeTimings[i].day];
				timingsArr.push(
					<FormLayout.Group key={i}>
						<Stack vertical spacing="extraTight">
							<TextContainer>{day}</TextContainer>

							<Stack>
								<TextField
									label="Start Time"
									labelHidden={true}
									value={storeTimings[i].start_time}
									onChange={handleStartTimeChange(i)}
									error={
										validateTime(
											storeTimings[i].start_time
										) ||
										(storeTimings[i].start_time &&
											storeTimings[i].end_time &&
											checkTime(
												storeTimings[i].start_time,
												storeTimings[i].end_time
											))
									}
								></TextField>
								<span>To</span>
								<TextField
									label="End Time"
									labelHidden={true}
									value={storeTimings[i].end_time}
									onChange={handleEndTimeChange(i)}
									error={
										validateTime(
											storeTimings[i].end_time
										)
									}
								></TextField>
							</Stack>
						</Stack>
					</FormLayout.Group>
				);
			}
		}

		return timingsArr;
	};

	const handleStartTimeChange = (index: number) => {
		return (val: any) => {
			var timings = [...storeTimings];
			setStoreTimings([]);
			for (var i = 0; i < timings.length; i++) {
				if (i === index) {
					var timing = { ...timings[i] };
					timing["start_time"] = val;
					timings[i] = timing;
				}
			}

			setStoreTimings(timings);
		};
	};

	const handleEndTimeChange = (index: number) => {
		return (val: any) => {
			var timings = [...storeTimings];
			setStoreTimings([]);
			for (var i = 0; i < timings.length; i++) {
				if (i === index) {
					var timing = { ...timings[i] };
					timing["end_time"] = val;
					timings[i] = timing;
				}
			}

			setStoreTimings(timings);
		};
	};

	const validateTime = (time: any) => {
		if (time.length !== 4) {
			return "Enter time in format HHMM";
		}

		var err = "Enter time between 0000 and 2359";
		if (
			isNaN(time) ||
			parseInt(time.substring(0, 2)) > 23 ||
			parseInt(time.substring(2, 4)) > 59
		) {
			return err;
		} else {
			return false;
		}
	};

	return (
		<Modal
			open={openModal}
			onClose={() => {
				setOpenModal(false);
				props.closeModal();
			}}
			title="On/Off Hours (time in 24h format)"
			primaryAction={{
				content: "Save",
				onAction: onSettingSave,
			}}
			secondaryActions={[
				{
					content: "Cancel",
					onAction: () => {
						setOpenModal(false);
						props.closeModal();
					},
				},
			]}
		>
			<Modal.Section>
				<FormLayout>
					<FormLayout.Group>
						<TextContainer>
							<TextStyle variation="subdued">
								Set On/Off hours as per the availability of your
								agents and their Working hours.
							</TextStyle>
							<br />
							<br />
							<TextStyle variation="subdued">
								Time in 24h format (HHMM or hours minutes).
								Example - 8:30AM is 0830 and 8:30PM is 2030. By
								default, hours are set from 0000 to 2359 which
								means your agents are active all the time. If
								your agents are away for an entire day, set 0000
								to 0000. The app follows your store’s timezone (
								{props.timezone}).
							</TextStyle>
						</TextContainer>
					</FormLayout.Group>

					{renderStoreTimings()}
				</FormLayout>
			</Modal.Section>
		</Modal>
	);
};

export default StoreTimingsModal;
