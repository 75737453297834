import { Banner, Link, Page } from "@shopify/polaris";
import {
  PRICING_REDUCED_MESSAGE,
  IS_PRICING_REDUCED,
  IS_CHECKOUT_OPTIN_WARNING_MESSAGE_VISIBLE,
} from "../constants/constants";
import celebrate from "../assets/icons/celebrate.svg";
import useToken from "../hooks/useToken";
import { useEffect, useState } from "react";

const PricingBannerMarkup = ({ setShowPricingBanner }: any) => {
  const { getToken } = useToken();
  const pricingBanner = JSON.parse(
    localStorage.getItem("showPricingReducedBanner") || "{}"
  );

  const [showBanner, setshowBanner] = useState(pricingBanner);
  const [isUltimatePlanUser, setisUltimatePlanUser] = useState(false);
  let features = localStorage.getItem("features")?.split(",");

  useEffect(() => {
    if (!showBanner) {
      localStorage.setItem("showPricingReducedBanner", "false");
      setShowPricingBanner(false);
    } else {
      setShowPricingBanner(true);
    }
  }, [showBanner]);

  useEffect(() => {
    if (features) {
      const isUltimatePlan = features?.includes("AUTOMATED_MESSAGING");
      setisUltimatePlanUser(isUltimatePlan);
    }
  }, [features]);

  let token = getToken();

  const handleClose = () => {
    setshowBanner(false);
  };
  const openUrl = () => {
    window
      .open(
        "https://www.superlemon.xyz/post/opt-in-collection-at-checkout-updated",
        "_blank"
      )
      ?.focus();
  };

  let downtimeMarkup = token &&
    IS_CHECKOUT_OPTIN_WARNING_MESSAGE_VISIBLE &&
    showBanner && isUltimatePlanUser && (
      <Banner
        action={{ content: "Read more", onAction: openUrl }}
        status="critical"
        onDismiss={handleClose}
      >
        <p>
          Shopline has updated the Mobile Number Opt-in Collection Method at
          Checkout page. If you are using "Collect WhatsApp opt-in from Checkout
          Page" method to collect Opt-in, please make sure you make the
          following changes as mentioned{" "}
          <Link
            external
            url="https://www.superlemon.xyz/post/opt-in-collection-at-checkout-updated"
          >
            here
          </Link>{" "}
          , so that you don't lose out on collecting Opt-in from new customers.
        </p>
      </Banner>
    );

  //Customized Pricing Banner
  // <div>
  //   <div
  //     className="Polaris-Banner Polaris-Banner--statusInfo Polaris-Banner--hasDismiss Polaris-Banner--withinPage"
  //     role="status"
  //     aria-live="polite"
  //     aria-labelledby="PolarisBanner22Heading"
  //     aria-describedby="PolarisBanner22Content"
  //   >
  //     <div className="Polaris-Banner__Dismiss">
  //       <button
  //         className="Polaris-Button Polaris-Button--plain Polaris-Button--iconOnly"
  //         aria-label="Dismiss notification"
  //         type="button"
  //         onClick={() => handleClose()}
  //       >
  //         <span className="Polaris-Button__Content">
  //           <span className="Polaris-Button__Icon">
  //             <span className="Polaris-Icon">
  //               <span className="Polaris-VisuallyHidden"></span>
  //               <svg
  //                 viewBox="0 0 20 20"
  //                 className="Polaris-Icon__Svg"
  //                 focusable="false"
  //                 aria-hidden="true"
  //               >
  //                 <path d="m11.414 10 4.293-4.293a.999.999 0 1 0-1.414-1.414L10 8.586 5.707 4.293a.999.999 0 1 0-1.414 1.414L8.586 10l-4.293 4.293a.999.999 0 1 0 1.414 1.414L10 11.414l4.293 4.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L11.414 10z"></path>
  //               </svg>
  //             </span>
  //           </span>
  //         </span>
  //       </button>
  //     </div>
  //     <div className="Polaris-Banner__Ribbon">
  //       <img
  //         alt="Success Icon"
  //         src={CelebrateSvg}
  //         title="Success"
  //         className="celebrate-svg-icon"
  //       ></img>
  //     </div>
  //     <div className="Polaris-Banner__ContentWrapper">
  //       <div
  //         className="Polaris-Banner__Content"
  //         id="PolarisBanner22Content"
  //       >
  //         <p className="Polaris-Heading">
  //           {CHECKOUT_OPTIN_WARNING_MESSAGE}
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  //   <div id="PolarisPortalsContainer"></div>
  // </div>
  return <Page>{downtimeMarkup}</Page>;
};

export default PricingBannerMarkup;
