import { Checkbox, Modal, Select, TextField } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { createTemplate } from "../../../../redux/actions/manual-messaging";
import { handleNewLineCharacters } from "../../../../utils/helper";

function CreateTemplateModal({
  showToast,
  showErrorToast,
  open,
  closeModal,
  shopId,
}: any) {
  const [openModal, setOpenModal] = useState(open);
  const [selectedTemplate, setSelectedTemplate] = useState({
    message: "",
    name: "",
    category: "",
    id: "",
    discount: true,
    discountRatio: "",
  });
  const [initialTemplate, setInitialTemplate] = useState({
    message: "",
    name: "",
    category: "",
    id: "",
    discount: true,
    discountRatio: "",
  });
  const [activateErrorCheckForName, setActivateErrorCheckForName] =
    useState(false);
  const [activateErrorCheckForContent, setActivateErrorCheckForContent] =
    useState(false);
  const [activateErrorCheckForDiscount, setActivateErrorCheckForDiscount] =
    useState(false);
  const [isSendButtonActive, setIsSendButtonActive] = useState(true);
  const categories = [
    { label: "Abandoned Cart", value: "CART" },
    { label: "Orders", value: "ORDER" },
  ];

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  const resetData = () => {
    setSelectedTemplate(initialTemplate);
    setActivateErrorCheckForContent(false);
    setActivateErrorCheckForName(false);
    setActivateErrorCheckForDiscount(false);
  };

  const validateForm = () => {
    let template = selectedTemplate;
    if (!template.name) {
      return "Template name can not be empty";
    } else if (!template.message) {
      return "Template message can not be empty";
    } else if (!template.category) {
      return "Please select a category";
    } else if (!template.discountRatio && template.discount) {
      return "Disount value cannot be empty";
    } else if (validateTemplateName() !== false) {
      return "Template name should be less than 50 characters";
    } else if (validateTemplateContent() !== false) {
      return "Template message should be less than 1000 characters";
    }
    return false;
  };

  const validateTemplateName = () => {
    if (activateErrorCheckForName) {
      let template = selectedTemplate;
      if (template.name.length > 50) {
        return "Template name should be less than 50 characters";
      }
      if (template.name.length <= 0) {
        return "Template name cannot be empty";
      }
    }
    return false;
  };
  const validateTemplateContent = () => {
    if (activateErrorCheckForContent) {
      let template = selectedTemplate;
      if (template.message.length > 1000) {
        return "Template message should be less than 1000 characters";
      }
      if (template.message.length <= 0) {
        return "Template message cannot be empty";
      }
    }
    return false;
  };

  const handleChange = (val: any, field: string) => {
    if (!activateErrorCheckForName && field === "name") {
      setActivateErrorCheckForName(true);
    }
    if (!activateErrorCheckForContent && field === "message") {
      setActivateErrorCheckForContent(true);
    }
    setSelectedTemplate((template: any) => ({ ...template, [field]: val }));
  };

  const validateDiscountRatio = () => {
    if (activateErrorCheckForDiscount) {
      if (selectedTemplate.discount && !selectedTemplate.discountRatio) {
        return "Discount Value cannot be empty";
      }
      if (
        selectedTemplate.discount &&
        String(parseInt(selectedTemplate.discountRatio)) === "0"
      ) {
        return "Discount Value cannot be 0";
      }
    }
    return false;
  };

  const handleDiscountChange = (newValue: any) => {
    if (!activateErrorCheckForDiscount) {
      setActivateErrorCheckForDiscount(true);
    }
    if (newValue <= 100 && newValue >= 0) {
      setSelectedTemplate((template: any) => ({
        ...template,
        discountRatio: newValue,
      }));
    }
  };
  const toggleOptinBehaviour = (name: string, value: boolean) => {
    setSelectedTemplate((template: any) => ({
      ...template,
      [name]: value,
    }));
  };

  const saveTemplate = (shopId: string) => {
    let params = new URLSearchParams();
    let message = selectedTemplate.message;
    message = handleNewLineCharacters(message);
    params.append("name", selectedTemplate.name);
    params.append("category", selectedTemplate.category);
    params.append("message", message);
    // @ts-ignore
    params.append("discount", selectedTemplate.discount);
    // @ts-ignore
    let discountRatio = isNaN(parseInt(selectedTemplate.discountRatio))
      ? ""
      : parseInt(selectedTemplate.discountRatio);
    params.append("discountRatio", `${discountRatio}`);
    params.append("message", message);

    let error = validateForm();
    if (error) {
      showErrorToast(error);
      return;
    } else {
      setIsSendButtonActive(false);
      createTemplate(shopId, params).then(
        (response: any) => {
          if (response.status === "success") {
            setSelectedTemplate(initialTemplate);
            showToast("Template created successfully");
            setActivateErrorCheckForContent(false);
            setActivateErrorCheckForName(false);
            setActivateErrorCheckForDiscount(false);
            setIsSendButtonActive(true);
            closeModal();
          } else {
            showErrorToast(response);
            setIsSendButtonActive(true);
          }
        },
        (err) => {
          showErrorToast("Unable to create template");
          setIsSendButtonActive(true);
        }
      );
    }
  };

  const getTemplateMarkup = () => {
    return (
      <>
        <TextField
          label="Name"
          value={selectedTemplate.name}
          onChange={(val) => {
            handleChange(val, "name");
          }}
          autoComplete="off"
          error={validateTemplateName()}
        />
        <br />
        <TextField
          label="Message"
          value={selectedTemplate.message}
          onChange={(val) => {
            handleChange(val, "message");
          }}
          multiline={3}
          autoComplete="off"
          error={validateTemplateContent()}
        />
        <br />

        <Select
          label="Select Category"
          options={categories}
          onChange={(val) => {
            handleChange(val, "category");
          }}
          placeholder="Select Category"
          value={selectedTemplate.category}
        />
        <br />
        <Checkbox
          label={
            "Create discount code (creates a unique code for each customer)"
          }
          checked={selectedTemplate.discount}
          onChange={(value) => toggleOptinBehaviour("discount", value)}
        />
        <br />
        <br />
        <TextField
          label="Discount value (1-100)"
          suffix="%"
          type="number"
          name="discount_percentage"
          disabled={!selectedTemplate.discount}
          value={selectedTemplate.discountRatio.toString()}
          error={validateDiscountRatio()}
          onChange={(val) => handleDiscountChange(val)}
        />
        <div className="Polaris-Banner__ContentWrapper">
          <div className="Polaris-Banner__Content" id="PolarisBanner2Content">
            <div className="Polaris-Stack Polaris-Stack--distributionEqualSpacing Polaris-Stack--alignmentCenter">
              <div className="Polaris-Stack__Item">
                <p className="mt-1">Some useful tags can be found below,</p>
                <br />
                <div className="d-flex space-evenly">
                  <div className="mr-2">
                    <p>
                      <strong>1. For Orders : </strong>
                    </p>
                    <br />
                    <p>
                      &#123;&#123;shop_name&#125;&#125; -&gt; Shop name
                    </p>
                    <p>
                      &#123;&#123;store_url&#125;&#125; -&gt; Store url
                    </p>
                    <p>
                      &#123;&#123;first_name&#125;&#125; -&gt; First Name
                    </p>
                    <p>
                      &#123;&#123;order_id&#125;&#125; -&gt; Order id
                    </p>
                    <p>
                      &#123;&#123;order_number&#125;&#125; -&gt; Order Number
                    </p>
                    <p>
                      &#123;&#123;order_value&#125;&#125; -&gt;
                      Total order value
                    </p>
                    <p>&#123;&#123;order_status_url&#125;&#125; -&gt; Current order status url</p>
                    <p>&#123;&#123;customer_name&#125;&#125; -&gt; Full customer name</p>
                    <p>
                      &#123;&#123;last_name&#125;&#125; -&gt; Last name
                    </p>
                    <p>
                      &#123;&#123;order_items&#125;&#125; -&gt; Order items
                    </p>
                    <p>
                      &#123;&#123;trackingNumber&#125;&#125; -&gt; Tracking number
                    </p>
                    <p>&#123;&#123;trackingUrl&#125;&#125; -&gt; Order tracking url</p>
                    <p>
                      &#123;&#123;tracking_id&#125;&#125; -&gt; Tracking id
                    </p>
                    <p>&#123;&#123;discount&#125;&#125; -&gt; Discount %</p>
                    <p>
                      &#123;&#123;discountCode&#125;&#125; -&gt; Discount code
                    </p>
                    <p>&#123;&#123;owner_name&#125;&#125; -&gt; Owner Name</p>
                    <p>
                      &#123;&#123;order_address&#125;&#125; -&gt; Order Address
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>2. For Abandoned Cart : </strong>
                    </p>
                    <br />
                    <p>&#123;&#123;shop_name&#125;&#125; -&gt; Shop name</p>
                    <p>
                      &#123;&#123;checkout_url&#125;&#125; -&gt; checkout URL
                    </p>
                    <p>&#123;&#123;discount&#125;&#125; -&gt; Discount %</p>
                    <p>
                      &#123;&#123;discountCode&#125;&#125; -&gt; Discount code
                    </p>
                    <p>&#123;&#123;first_name&#125;&#125; -&gt; First name</p>
                    <p>&#123;&#123;order_value&#125;&#125; -&gt; Order Value</p>
                    <p>&#123;&#123;cart_items&#125;&#125; -&gt; Cart items</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setIsSendButtonActive(true);
        resetData();
        closeModal();
        setOpenModal(false);
      }}
      title={"Add Message Template"}
      primaryAction={{
        content: "Save",
        onAction: () => saveTemplate(shopId),
        disabled: !isSendButtonActive,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setIsSendButtonActive(true);
            resetData();
            closeModal();
            setOpenModal(false);
          },
        },
      ]}
    >
      <Modal.Section>{getTemplateMarkup()}</Modal.Section>
    </Modal>
  );
}

export default CreateTemplateModal;
