import { Tabs } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";

const TabsWrapper = (props: any) => {

    const [selected, setSelected] = useState<any>(0);

    // useEffect(() => {
    //     let selectedTab = localStorage.getItem('currentTab');
    //     setSelected(selectedTab ? Number(selectedTab) : 0);
    // }, []);

    useEffect(() => {
        if (props.handleTabChange) {
            props.handleTabChange(selected);
        }
    }, [selected]);

    useEffect(() => {
        if (props.selected) {
            setSelected(props.selected);
        }
    }, [props.selected]);

    const handleTabChange = useCallback((selectedTabIndex) => {
          setSelected(selectedTabIndex);
        //   localStorage.setItem('currentTab', selectedTabIndex);
        },
        []
    );

    const renderContent = () => {
        let totalTabs = props.tabs.length;
        let template = props.content[0];
        for (var i = 0; i <= totalTabs; i++) {
            if (selected === i) {
                template = props.content[i];
            }
        }
        return template;
    }
    
    return (
        <div className="w-100">
            <Tabs tabs={props.tabs} selected={selected} onSelect={handleTabChange}>
                {renderContent()}
            </Tabs>
        </div>
    );
}

export default TabsWrapper;