import {COD_ORDER, COD_SERVER_ADDR, MESSAGING, COD_ORDER_EMAIL } from "../../constants/api-constants";
import { getAutomateMessageLanguage } from "../../utils/common";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: MESSAGING.baseUrl
};

export const fetchCODSettings = () => {
  return httpClient.get(MESSAGING.api.COD_SETTINGS, config).then((res: any) => {
    let languageSetting = getAutomateMessageLanguage(res);

    const data = {
      cancelOnCancelled: res?.setting?.cancelOnCancelledEnable,
      cancelOnNoResponse: res?.setting?.cancelOnNoResponseEnable,
      cancelledTag: res?.setting?.cancelledTag,
      language: languageSetting,
      confirmedTag: res?.setting?.confirmedTag,
      countryCode: res?.setting?.countryCode,
      email: res?.setting?.email,
      emailEnabled: res?.setting?.emailEnable,
      enable: res?.setting?.enable,
      confirmationLanguage: res?.setting?.pageLanguage,
      noResponseTag: res?.setting?.noResponseTag,
      phone: res?.setting?.phoneNumber,
      phoneEnabled: res?.setting?.callEnable,
    }
    return [data, res];
  });
};

export const updateCODSettings = (params: any) => {
  const body = {
    cancelOnCancelledEnable: params.cancelOnCancelled,
    cancelOnNoResponseEnable: params.cancelOnNoResponse,
    cancelledTag: params.cancelledTag,
    confirmedTag: params.confirmedTag,
    countryCode: params.countryCode,
    email: params.email,
    emailEnable: params.emailEnabled,
    enable: params.enable,
    pageLanguage: params.confirmationLanguage,
    waAutomateMessageTemplateId: params?.language?.id,
    noResponseTag: params.noResponseTag,
    phoneNumber: params.phone,
    callEnable: params.phoneEnabled,
  };

  return httpClient.put(MESSAGING.api.COD_SETTINGS, body, config).then((res: any) => {
    let languageSetting = getAutomateMessageLanguage(res);

    const data = {
      cancelOnCancelled: res?.setting?.cancelOnCancelledEnable,
      cancelOnNoResponse: res?.setting?.cancelOnNoResponseEnable,
      cancelledTag: res?.setting?.cancelledTag,
      language: languageSetting,
      confirmedTag: res?.setting?.confirmedTag,
      countryCode: res?.setting?.countryCode,
      email: res?.setting?.email,
      emailEnabled: res?.setting?.emailEnable,
      enable: res?.setting?.enable,
      confirmationLanguage: res?.setting?.pageLanguage,
      noResponseTag: res?.setting?.noResponseTag,
      phone: res?.setting?.phoneNumber,
      phoneEnabled: res?.setting?.callEnable,
    }
    return [data, res];
  });
};

export const fetchCODOrder = (token: string) => {
  return httpClient.get(COD_ORDER.replace('$token', token), {baseURL: COD_SERVER_ADDR});
}
export const fetchCODOrderEmail = (token: string) => {
  return httpClient.get(COD_ORDER_EMAIL.replace('$token', token), {baseURL: COD_SERVER_ADDR});
}

export const updateCODOrderStatus = (token: string, params: any) => {
  return httpClient.put(COD_ORDER.replace('$token', token), params, {baseURL: COD_SERVER_ADDR});
}
