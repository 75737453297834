import { Heading, TextStyle } from "@shopify/polaris";
import { useState, useEffect } from "react";
import TooltipIcon from "../assets/icons/tooltip.svg";

function TooltipOverlay({
  obj,
  btn1Action,
  btn2Action,
  btn3Action,
  showPricingBanner,
}: any) {
  const [isUltimatePlanUser, setisUltimatePlanUser] = useState(false);
  let features = localStorage.getItem("features")?.split(",");

  useEffect(() => {
    if (features) {
      const isUltimatePlan = features?.includes("AUTOMATED_MESSAGING");
      setisUltimatePlanUser(isUltimatePlan);
    }
  }, [features]);

  function getOnclickMethod(i: number) {
    if (i === 0) {
      return btn1Action;
    }
    if (i === 1) {
      return btn2Action;
    }
    if (i === 2) {
      return btn3Action;
    }
  }
  function getContent() {
    return (
      <>
        <div className="heading">
          <Heading>{obj.tooltip_card.heading}</Heading>
        </div>
        <br />
        <div className="text">
          <TextStyle variation="subdued">
            {obj.tooltip_card.content.map((el: any, i: number) => (
              <div key={el.text + el.buttonActionText}>
                {el.text ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${el.text} `,
                    }}
                  ></div>
                ) : null}
                <br />
                {el.buttonActionText ? (
                  <div>
                    <button
                      className="Polaris-Button Polaris-Button--primary"
                      onClick={getOnclickMethod(i)}
                    >
                      {el.buttonActionText}
                    </button>
                  </div>
                ) : null}
                <br />
              </div>
            ))}
          </TextStyle>
        </div>
      </>
    );
  }
  return (
    <div
      className={`tooltip-overlay ${
        showPricingBanner || isUltimatePlanUser ? "" : ""
      }`}
    >
      <div className="tooltip-outer-container">
        <div className="icon">
          <img
            alt="Success Icon"
            src={TooltipIcon}
            title="Guide"
            className="tooltip-svg-icon"
          ></img>
        </div>
      </div>
      <div className="tooltip-hover-content">
        <div className="inner-content">{getContent()}</div>
      </div>
    </div>
  );
}

export default TooltipOverlay;
