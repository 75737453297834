import { MANUAL_MESSAGING } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: MANUAL_MESSAGING.baseUrl
};
const config2 = {
  baseURL: MANUAL_MESSAGING.accountUrl
};

export const handleFiltersAbandonedCart = (shopId:string, templateType: any,orderId:any,name: string, orderBy:string, desc:boolean, pageSize:number, pageIndex:number ) => {
  let query = `&pageSize=${pageSize}&pageNo=${pageIndex}`;
  if(orderBy){
    query = `&orderBy=${orderBy}&desc=${desc}` + query;
  }
  if(templateType){
    query = `&tagId=${templateType}` + query;
  }
  if(name){
    query = `&name=${name}` + query;
  }  
  if(orderId){
    query = `&checkoutId=${orderId}` + query;
  }  
  return httpClient.get(MANUAL_MESSAGING.api.ABANDONED_CART.replace("$shopId", shopId) + `${query}`, config2)
};
export const handleFiltersOrdersCrm = (shopId:string, templateType: any,orderId:any,name: string, orderBy:string, desc:boolean, pageSize:number, pageIndex:number ) => {
  let query = `&pageSize=${pageSize}&pageNo=${pageIndex}`;
  if(orderBy){
    query = `&orderBy=${orderBy}&desc=${desc}` + query;
  }
  if(templateType){
    query = `&tagId=${templateType}` + query;
  }
  if(name){
    query = `&name=${name}` + query;
  }  
  if(orderId){
    query = `&orderName=${orderId}` + query;
  }  
  return httpClient.get(MANUAL_MESSAGING.api.ORDERS_CRM.replace("$shopId", shopId) + `${query}`, config2)
};
export const getAllTemplates = (shopId:string) => {
  return httpClient.get(MANUAL_MESSAGING.api.GET_ALL_TEMPLATES.replace("$shopId", shopId), config)
};
export const getAllTags = (shopId:string) => {
  return httpClient.get(MANUAL_MESSAGING.api.GET_ALL_TAGS.replace("$shopId", shopId), config)
};
export const addTagAbCartOrder = (shopId:string, checkoutId:any, params: any) => {
  return httpClient.put(MANUAL_MESSAGING.api.ADD_TAG_TO_AB_CART_ORDER.replace("$shopId", shopId).replace("$checkoutId", checkoutId),params, config)
};
export const addTagOrder = (shopId:string, checkoutId:any, params: any) => {
  return httpClient.put(MANUAL_MESSAGING.api.ADD_TAG_TO_ORDER.replace("$shopId", shopId).replace("$checkoutId", checkoutId),params, config)
};
export const settings = (shopId:string) => {
  return httpClient.get(MANUAL_MESSAGING.api.SETTINGS.replace("$shopId", shopId), config)
};
export const updateSettings = (shopId:string, params:any) => {
  return httpClient.put(MANUAL_MESSAGING.api.SETTINGS.replace("$shopId", shopId), params, config)
};
export const createTemplate = (shopId:string, params:any) => {
  return httpClient.post(MANUAL_MESSAGING.api.CREATE_TEMPLATE.replace("$shopId", shopId), params, config)
};
export const deleteTemplate = (shopId:string, templateId: any) => {
  return httpClient.delete(MANUAL_MESSAGING.api.UPDATE_DELETE_TEMPLATE.replace("$shopId", shopId).replace("$templateId", templateId), config)
};
export const updateTemplate = (shopId:string,templateId: any, params:any) => {
  return httpClient.put(MANUAL_MESSAGING.api.UPDATE_DELETE_TEMPLATE.replace("$shopId", shopId).replace("$templateId", templateId), params, config)
};
export const createTag = (shopId:string, params:any) => {
  return httpClient.post(MANUAL_MESSAGING.api.CREATE_TAG.replace("$shopId", shopId), params, config)
};
export const deleteTag = (shopId:string, tagId: any) => {
  return httpClient.delete(MANUAL_MESSAGING.api.UPDATE_DELETE_TAG.replace("$shopId", shopId).replace("$tagId", tagId), config)
};
export const updateTag = (shopId:string,tagId: any, params:any) => {
  return httpClient.put(MANUAL_MESSAGING.api.UPDATE_DELETE_TAG.replace("$shopId", shopId).replace("$tagId", tagId), params, config)
};
export const getDiscountCode = (shopId:string,templateId: any) => {
  return httpClient.get(MANUAL_MESSAGING.api.GET_DISCOUNT_CODE.replace("$shopId", shopId).replace("$templateId", templateId), config)
};
