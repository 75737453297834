import React, { useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  FormLayout,
  Layout,
  Stack,
  TextStyle,
} from "@shopify/polaris";

const SharePagesToDisplay = (props: any) => {
  const [initialData, setInitialData] = useState<any>({
    // HOME: false,
    // COLLECTION: false,
    // PRODUCT: false,
    // CART_DESKTOP: false,
    // CART_MOBILE: false,
    // THANKYOU: false,
    // BLOGPOST: false,
    // PAGE: false,
    // ACCOUNT: false,
    all : false,
    checkout: false,
    online_store: false,
  });
  const [fields, setFields] = useState<any>({
    // HOME: false,
    // COLLECTION: false,
    // PRODUCT: false,
    // CART_DESKTOP: false,
    // CART_MOBILE: false,
    // THANKYOU: false,
    // BLOGPOST: false,
    // PAGE: false,
    // ACCOUNT: false,
    all : false,
    checkout: false,
    online_store: false,
  });
  const [refFields, setrefFields] = useState<any>({
    // HOME: false,
    // COLLECTION: false,
    // PRODUCT: false,
    // CART_DESKTOP: false,
    // CART_MOBILE: false,
    // THANKYOU: false,
    // BLOGPOST: false,
    // PAGE: false,
    // ACCOUNT: false,
    all : false,
    checkout: false,
    online_store: false,
  });
  const [updatedData, setUpdatedData] = useState<string[]>();

  useEffect(() => {
    if (props.isDataSubmitted) {
        setrefFields(fields);
        setInitialData(fields);
    }
  }, [props.isDataSubmitted]);

  useEffect(() => {
    if (props && props.shareData){
      setPagesToDisplay(props.shareData.buttonPages);
    }
  }, [props.shareData]);

  useEffect(() => {
    setUpdatedData([]);
    if (!haveSameData(fields, refFields)) {
      var filtered = Object.keys(fields).filter((key) => {
        return fields[key];
      });
      setUpdatedData(filtered);
    }
  }, [fields]);

  useEffect(() => {
    props.onChanges(updatedData);
  }, [updatedData]);

  useEffect(() => {
    if (props.reset) {
      setFields(initialData);
      setrefFields(initialData);
    }
  }, [props.reset]);

  const setPagesToDisplay = (pages: any) => {
    for (let i = 0; i < pages.length; i++) {
      referenceObj(pages[i], true);
    }

    for (let key in fields) {
      let exists = pages.includes(key);

      if (!exists) {
        referenceObj(key, false);
      }
    }
  };
  const referenceObj = (name: string, value: boolean) => {
    setrefFields((refFields: any) => ({ ...refFields, [name]: value }));
    setFields((fields: any) => ({ ...fields, [name]: value }));
    setInitialData((fields: any) => ({ ...fields, [name]: value }));
  };

  const handleToggle = (name: string, value: boolean) => {
    setFields((fields: any) => ({ ...fields, [name]: value }));
  };
  const haveSameData = (obj1: any, obj2: any) => {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  };
  return (
    <Layout.AnnotatedSection
      title="Pages to display"
      description="Select which pages to display the WhatsApp Share button."
    >
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <div>
              <Stack vertical={true}>
                {/* <Checkbox
                  label="Homepage"
                  checked={fields.HOME}
                  onChange={(val) => handleToggle("HOME", val)}
                />
                <Checkbox
                  label="Collections"
                  checked={fields.COLLECTION}
                  onChange={(val) => handleToggle("COLLECTION", val)}
                />
                <Checkbox
                  label="Product pages"
                  checked={fields.PRODUCT}
                  onChange={(val) => handleToggle("PRODUCT", val)}
                />
                <Checkbox
                  label="Cart Desktop"
                  checked={fields.CART_DESKTOP}
                  onChange={(val) => handleToggle("CART_DESKTOP", val)}
                />
                <Checkbox
                  label="Cart Mobile"
                  checked={fields.CART_MOBILE}
                  onChange={(val) => handleToggle("CART_MOBILE", val)}
                />
                <Checkbox
                  label="Thank You page"
                  checked={fields.THANKYOU}
                  onChange={(val) => handleToggle("THANKYOU", val)}
                />
                <Checkbox
                  label="Blog post pages"
                  checked={fields.BLOGPOST}
                  onChange={(val) => handleToggle("BLOGPOST", val)}
                />
                <Checkbox
                  label="URLs ending with /pages"
                  checked={fields.PAGE}
                  onChange={(val) => handleToggle("PAGE", val)}
                />
                <Checkbox
                  label="Account pages"
                  checked={fields.ACCOUNT}
                  onChange={(val) => handleToggle("ACCOUNT", val)}
                /> */}
                <Checkbox
                  label="All"
                  checked={fields.all}
                  onChange={(val) => handleToggle("all", val)}
                />
                <Checkbox
                  label="Checkout"
                  checked={fields.checkout}
                  onChange={(val) => handleToggle("checkout", val)}
                />
                <Checkbox
                  label="Online Store"
                  checked={fields.online_store}
                  onChange={(val) => handleToggle("online_store", val)}
                />
                <TextStyle variation="subdued">
                  By default, WhatsApp Share button will show on all other pages
                  that aren’t listed above
                </TextStyle>
              </Stack>
            </div>
          </FormLayout.Group>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );
};

export default SharePagesToDisplay;
