import { PRIVATE_WABA_BUSINESS_DETAILS, PRIVATE_WABA_CONTACT_DETAILS, PRIVATE_WABA_FACEBOOK_DETAILS, PRIVATE_WABA_PROFILE_ABOUT_DETAILS, PRIVATE_WABA_PROFILE_DETAILS, PROFILE_IMAGE, SEND_OTP, SUBMIT_OTP, WABA, WABA_TEMPLATES, LOCALES_DETAILS, MESSAGING, COUNTRY_CODES } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

let config = {
    baseURL: MESSAGING.baseUrl
};

export const loadWabaDetails = (shopId: string) => {
    return httpClient.get(WABA.replace('$shopId', shopId));
}

export const createWABA = (shopId: string, params: any) => {
    return httpClient.post(WABA.replace('$shopId', shopId), params);
}

export const sendOTP = (shopId: string, params: any) => {
    return httpClient.post(SEND_OTP.replace('$shopId', shopId), params);
}

export const verifyOTP = (shopId: string, params: any) => {
    return httpClient.put(SUBMIT_OTP.replace('$shopId', shopId), params);
}

export const getProfileImage = (shopId: string) => {
    return httpClient.get(PROFILE_IMAGE.replace('$shopId', shopId));
}

export const uploadProfileImage = (shopId: string, params: any) => {
    return httpClient.put(PROFILE_IMAGE.replace('$shopId', shopId), params);
}

export const getAutomatedTemplates = (shopId: string) => {
    return httpClient.get(WABA_TEMPLATES.replace('$shopId', shopId));
}

export const saveBusinessDetails = (shopId: string, params: any) => {
    return httpClient.put(PRIVATE_WABA_BUSINESS_DETAILS.replace('$shopId', shopId), params);
}

export const saveFacebookDetails = (shopId: string, params: any) => {
    return httpClient.put(PRIVATE_WABA_FACEBOOK_DETAILS.replace('$shopId', shopId), params);
}

export const saveProfileDetails = (shopId: string, params: any) => {
    return httpClient.put(PRIVATE_WABA_PROFILE_DETAILS.replace('$shopId', shopId), params);
}

export const saveContactDetails = (shopId: string, params: any) => {
    return httpClient.put(PRIVATE_WABA_CONTACT_DETAILS.replace('$shopId', shopId), params);
}

export const saveProfileAboutDetails = (shopId: string, params: any) => {
    return httpClient.put(PRIVATE_WABA_PROFILE_ABOUT_DETAILS.replace('$shopId', shopId), params);
}

// Supported Integrations

export const getAllIntegrations = () => {
    return httpClient.get(MESSAGING.api.INTEGRATION, config);
}

export const getIntegrationsListForShop = (shopId: string) => {
    return httpClient.get(MESSAGING.api.SHOP_INTEGRATION.replace('$shopId', shopId), config);
}

export const addIntegration = (shopId: string, params: any) => {
    return httpClient.post(MESSAGING.api.SHOP_INTEGRATION.replace('$shopId', shopId), params, config);
}

export const disableIntegration = (integrationId: string, params: any) => {
    return httpClient.put(MESSAGING.api.SHOP_INTEGRATION.replace('$shopId', integrationId), params, config);
}

//Fetch Locales

export const getAllLocales = () => {
    return httpClient.get(LOCALES_DETAILS)
}

//Fetch Country Codes

export const getCountryCodes = () => {
    return httpClient.get(COUNTRY_CODES)
}