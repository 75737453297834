import { useState, useCallback, useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./App.scss";
import { Frame, Icon, TopBar, VisuallyHidden } from "@shopify/polaris";
import useToken from "./hooks/useToken";
import { EmailMajor } from "@shopify/polaris-icons";
import Login from "./components/Login/Login";
import Pricing from "./components/Pricing/Pricing";
import Widget from "./components/Widget/Widget";
import SideMenu from "./common/SideMenu";
import Support from "./components/Support/Support";
import Templates from "./components/Templates/index";
import Landing from "./components/Landing/Landing";
import ChatSettings from "./components/Widget/ChatSettings";
import { getShopDetails } from "./redux/actions/shop";
import { getShopSettings } from "./redux/actions/shop";
import DowntimeMarkup from "./common/DowntimeMarkup";
import CreateTemplate from "./components/Templates/ExtensionTemplates/CreateTemplate";
import PricingBannerMarkup from "./common/PricingBannerMarkup";
import Confirmation from "./common/Confirmation";
import { getPlanDetails } from "./redux/actions/pricing";
import AutomatedAbandonedCart from "./components/AbandonedCart";
import AutomatedCRMPage from "./components/OrdersCRM/index";
import AutomatedCoDPage from "./components/CashOnDelivery/index";
import OptInNumberPage from "./components/OptinNumber/index";
import PrivateWABA from "./components/PrivateWABA/index";
import CODPage from "./components/CODPage/index";
import MessageLogs from "./components/MessageLogs/MessageLogs";
import AdminConfiguration from "./components/AdminConfiguration/AdminConfiguration";
import ManualMessages from "./components/ManualMessages";
import Analytics from "./components/Analytics";
import EmailTemplates from "./components/Email/Templates";
import Release_21_20_01_Modal from "./components/WhatsNew/R21.20.01";
import Release_22_02_03_Modal from "./components/WhatsNew/R22.02.03";
import Release_22_03_01_Modal from "./components/WhatsNew/R22.03.01";
import BellRinging from "./assets/icons/bell-ringing.gif";
import BellStatic from "./assets/icons/bell-static.png";
import EmailConfiguration from "./components/Email/Configuration/Configuration";
import WhatsappPreview from "./components/WhatsappPreviewPage/index";

const App = () => {
  const { token, setToken } = useToken();
  const [showMenu, setShowMenu] = useState(false);
  const [showFrame, setShowFrame] = useState(false);

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);
  const [owner, setOwner] = useState<any>();
  const [shopDetails, setShopDetails] = useState<any>();
  const [planId, setPlanId] = useState<any>();
  const [selectedTabForTemplate, setSelectedTabForTemplate] = useState(0);
  const [showWhatsNewTooltip, setShowWhatsNewTooltip] = useState(false);
  const [showReleaseModal_21_20_01, setShowReleaseModal_21_20_01] =
    useState(false);
  const [showReleaseModal_22_02_03, setShowReleaseModal_22_02_03] =
    useState(false);
  const [showReleaseModal_22_03_01, setShowReleaseModal_22_03_01] =
    useState(false);
  const [showPricingBanner, setShowPricingBanner] = useState();
  const bellClicked = localStorage.getItem("bell_clicked")
    ? localStorage.getItem("bell_clicked")
    : false;

  const location = useLocation();
  const history = useHistory();
  let currentPath = history.location.pathname;
  const isForwardSlashPresent = currentPath.split("").pop();
  if (currentPath !== "/" && isForwardSlashPresent === "/") {
    currentPath = currentPath.slice(0, -1);
  }
  useEffect(() => {
    const fetchShopDetails = () => {
      getShopDetails()
        .then(async (response: any) => {
          let shop = {
            active: response.superLemonPlan.enable,
            createdOn: response.shoplineDetail.created_at,
            featuresList: response.superLemonPlan.feature,
            id: response.shopid,
            manualMessaging: true,
            // migrated: false,
            modifiedOn: response.shoplineDetail.updated_at,
            name: response.shoplineDetail.name,
            // nonChargable: false,
            // optinFromCheckout: false,
            planId: response.superLemonPlan.id,
            timezone: response.shoplineDetail.iana_timezone,
            url: response.shoplineDetail.domain,
          };
          localStorage.setItem("currentPlan", response.superLemonPlan.id);
          setPlanId(localStorage.getItem("currentPlan"));
          setShopDetails(shop);
          //Next line to be removed if we are getting featuresList in shop API directly, no need to do another API call
          // await fetchPlanDetails(response.shop.planId);
          // INFO: static features added to show all menus
          // localStorage.setItem("features", response.superLemonPlan.feature);
          localStorage.setItem("features", "AUTOMATED_MESSAGING,PRIVATE,EXTENSION,ANALYTICS,WEBHOOK_SYNC");
          localStorage.setItem("shop", JSON.stringify(shop));
          setShowMenu(true);
          getShopSettings(response.shopid).then((res: any) => {
            res &&
              res.settings &&
              localStorage.setItem(
                "shopSettings",
                JSON.stringify(res.settings)
              );
          });
          getOwnerDetails();
        })
        .catch(() => {
          if (currentPath !== "/cod-order") {
            history.push("/login");
          }
        });
    };

    if (token) {
      fetchShopDetails();
    } else {
      setShowMenu(false);
    }
  }, [token]);

  useEffect(() => {
    if (!token && currentPath !== "/cod-order" && currentPath !== "/") {
      history.push("/login");
    }
    if (
      location.pathname.includes("/cod-order") ||
      location.pathname.includes("/login")
    ) {
      setShowFrame(false);
    } else {
      setShowFrame(true);
    }
    const protectRoutes = () => {
      const fetchShopDetails = async () => {
        await getShopDetails()
          .then((response: any) => {
            checkIfRouteAllowed(response.superLemonPlan.feature);
          })
          .catch((e) => {
            history.push("/login");
          });
      };
      const checkIfRouteAllowed = (features: any) => {
        const isUltimatePlan =
          features.includes("AUTOMATED_MESSAGING") &&
          features.includes("PRIVATE") &&
          features.includes("EXTENSION") &&
          features.includes("ANALYTICS") &&
          // features.includes("EMAIL") &&
          features.includes("WEBHOOK_SYNC");
        const isProPlan =
          features.includes("EXTENSION") &&
          features.includes("ANALYTICS") &&
          features.includes("WEBHOOK_SYNC");
        const isFreePlan = features.includes("ANALYTICS");
        const ultimatePlanRoutes = [
          // "/optins",
          "/automated-abandoned-cart",
          "/automated-orders",
          "/automated-cod",
          "/private-waba",
          "/message-logs",
          // "/admin-config",
          "/email-templates",
          "/email-config",
        ];
        if (!isUltimatePlan) {
          if (ultimatePlanRoutes.indexOf(currentPath) !== -1) {
            history.push("/pricing");
          }
        }
      };
      if (currentPath !== "/login" && currentPath !== "/") {
        fetchShopDetails();
      }
    };
    if (currentPath !== "/cod-order") {
      protectRoutes();
    }
  }, [location.pathname]);

  //To be removed if we are getting featuresList in shop API directly, no need to do another API call
  const fetchPlanDetails = async (planId: string) => {
    await getPlanDetails(planId).then((res: any) => {
      if (res && res.plan) {
        localStorage.setItem("features", res.plan.featuresList);
      }
    });
  };
  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const getOwnerDetails = () => {
    //@ts-ignore
    setOwner(JSON.parse(localStorage.getItem("owner")));
  };

  const hideFrame = (val: boolean) => {
    setShowFrame(val);
  };
  const handleMobileNavigation = () => {
    if (token) {
      setShowMobileNavigation((showMobileNavigation) => !showMobileNavigation);
    }
  };
  // TODO Add user image in place of initials
  const userMenuMarkup = token ? (
    <TopBar.UserMenu
      actions={[]}
      name={owner?.name}
      detail={owner?.shopName}
      initials={owner?.name?.charAt(0)}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  ) : null;

  const secondaryMenuMarkup = token ? (
    <TopBar.Menu
      activatorContent={
        <span>
          <span className="bell-icon">
            <img
              src={bellClicked ? BellStatic : BellRinging}
              alt="Release Notes"
            />
          </span>
          <VisuallyHidden>Secondary menu</VisuallyHidden>
        </span>
      }
      open={showWhatsNewTooltip}
      onOpen={() => {
        setShowWhatsNewTooltip(true);
        localStorage.setItem("bell_clicked", "true");
      }}
      onClose={() => setShowWhatsNewTooltip(false)}
      actions={[
        {
          items: [
            {
              content: "Whats New!",
              onAction: () => {
                alert("Please click on any of the release versions");
              },
            },
          ],
        },
        {
          items: [
            {
              content: "Release 22.08.03",
              onAction: () => {
                setShowReleaseModal_22_03_01(true);
              },
            },
          ],
        }
      ]}
    />
  ) : null;

  const topBarMarkup = (
    <TopBar
      userMenu={token ? userMenuMarkup : null}
      secondaryMenu={secondaryMenuMarkup}
      showNavigationToggle
      onNavigationToggle={handleMobileNavigation}
    />
  );

  const navigationMarkup = showMenu ? (
    <SideMenu setToken={setToken} planId={planId} shopDetails={shopDetails} />
  ) : null;

  return (
    <>
      <Route path="/cod-order">
        <CODPage hideFrame={hideFrame}></CODPage>
      </Route>
      <Route path="/template-preview">
        <WhatsappPreview hideFrame={hideFrame}></WhatsappPreview>
      </Route>
      <Route path="/login">
        <Login
          setToken={setToken}
          setOwner={() => getOwnerDetails()}
          hideFrame={hideFrame}
        />
      </Route>
      {showFrame ? (
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={showMobileNavigation}
          onNavigationDismiss={handleMobileNavigation}
        >
          {!token ? <Redirect to="/" /> : null}
          <DowntimeMarkup />
          <PricingBannerMarkup setShowPricingBanner={setShowPricingBanner} />
          <Release_22_03_01_Modal
            open={showReleaseModal_22_03_01}
            closeModal={() => setShowReleaseModal_22_03_01(false)}
          />
          <Switch>
            <Route exact path="/widget" component={Widget} />
            <Route path="/widget/settings">
              <ChatSettings showPricingBanner={showPricingBanner} />
            </Route>
            <Route path="/pricing">
              <Pricing planId={planId} setPlanId={setPlanId} />
            </Route>
            <Route path="/support" component={Support} />
            <Route exact path="/templates">
              <Templates
                selectedTabForTemplate={selectedTabForTemplate}
                showPricingBanner={showPricingBanner}
              />
            </Route>
            <Route exact path="/email-templates">
              <EmailTemplates />
            </Route>
            <Route exact path="/email-config">
              <EmailConfiguration />
            </Route>
            <Route
              path="/templates/create"
              render={(props) => (
                <CreateTemplate
                  {...props}
                  setSelectedTabForTemplate={setSelectedTabForTemplate}
                />
              )}
            />
            <Route exact path="/confirmation" component={Confirmation} />
            <Route
              path="/automated-abandoned-cart"
              component={AutomatedAbandonedCart}
            />
            <Route path="/automated-orders" component={AutomatedCRMPage} />
            <Route path="/automated-cod" component={AutomatedCoDPage} />
            <Route path="/private-waba" component={PrivateWABA} />
            <Route exact path="/optins">
              <OptInNumberPage showPricingBanner={showPricingBanner} />
            </Route>
            <Route exact path="/message-logs">
              <MessageLogs showPricingBanner={showPricingBanner} />
            </Route>
            <Route exact path="/admin-config">
              <AdminConfiguration showPricingBanner={showPricingBanner} />
            </Route>
            <Route path="/manual-messaging">
              <ManualMessages owner={owner} />
            </Route>
            <Route path="/analytics">
              <Analytics />
            </Route>
            <Route path="/">
              {!token ? (
                <Landing hideFrame={hideFrame}></Landing>
              ) : (
                <Pricing planId={planId} setPlanId={setPlanId}></Pricing>
              )}
            </Route>
          </Switch>
        </Frame>
      ) : null}
    </>
  );
};

export default App;
