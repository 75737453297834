import React, { useState, useEffect } from "react";
import {
  ContextualSaveBar,
  Layout,
  Link,
  SettingToggle,
  TextStyle
} from "@shopify/polaris";
import AgentSettings from "./Agents/AgentsSettings";
import CallOutTag from "./CallOut";
import GreetingWidget from "./Greetings/GreetingsWidget";
import ChatButtonTheme from "./ChatButtonTheme/ChatButtonTheme";
import PagesToDisplay from "./PagesToDisplay";
import Devices from "./Devices/index";
import {
  getChatDetails,
  updateCallout,
  updateChatDetails,
  updateGreetingWidgetData,
  updateChatDevices,
  deleteChatDevices,
  updateChatTheme,
  getChatButtonStatus,
  updateChatButtonStatus,
  getChatButtonDisplayPages,
  updateChatButtonDisplayPages,
} from "../../redux/actions/chat-settings";
import { getShopSettings, updateShopSettings } from "../../redux/actions/shop";
import ShareSettings from "./ShareSetting";
import { DEVICE_TYPE_DESKTOP_ONLY, DEVICE_TYPE_MOBILE_AND_DESKTOP, DEVICE_TYPE_MOBILE_ONLY } from "../../constants/constants";
import PageMarkup from "../../common/PageMarkup";
import obj from "../../content/Widget/OverlaysContent/en.json";
import TooltipOverlay from "../../common/Tooltip";
import GuideModal from "../../common/GuideModal";

const ChatSettings = ({ showPricingBanner }: any) => {
  const [isWAChatEnabled, setIsWAChatEnabled] = useState(false);
  const [shopUrl, setShopUrl] = useState("");
  const [shopId, setShopId] = useState("");
  const [toast, setToast] = useState("");
  const [errorToast, setErrorToast] = useState("");
  const [saveFlag, setSaveFlag] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);

  const [shopSettings, setShopSettings] = useState({
    isMultiAgentEnabled: true,
    buttonEnabled: true,
    is_store_open: true,
    store_timezone: 'Asia/Calcutta'
  });

  const [chatData, setChatData] = useState({
    btn_cta: "",
    buttonCustom: "",
    buttonId: "",
    buttonPages: [],
    ctaEnabled: "",
    iconCustom: "",
    iconUrl: "",
    include_product_link: "",
    is_chat_btn_color_custom: false,
    is_chat_btn_solid_background: false,
    lang: "",
    prefilled_msg: "",
  });

  const [dataChanges, setDataChanges] = useState<any>({
    agentSettings: null,
    chat: null,
    share: null,
    greeting: null,
    callout: null,
    chatTheme: null,
    pagesToDisplay: "",
    sharePagesToDisplay: "",
    devices: null
  });

  const [hasChanges, setHasChanges] = useState(false);
  const [selectedDeviceType, setSelectedDeviceType] = useState<number>();
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [mobileData, setMobileData] = useState<any>({})
  const [desktopData, setDesktopData] = useState<any>({})
  const [errorMessage, setErrorMessage] = useState<any>({
    chatTheme: '',
    callout: '',
    greeting: '',
    mobile: '',
    desktop: ''
  });


  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShopId(shop.id);
      setShopUrl(shop.url);
      fetchShopSettings(shop.id);
      fetchChatDetails(shop.id);
    }
  }, []);

  useEffect(() => {
    if (hasChanges) {
      setDiscardChanges(false);
    }
  }, [hasChanges]);

  const fetchShopSettings = (shopId: string) => {
    getShopSettings(shopId).then((response: any) => {
      setShopSettings(response.settings);
    });
  };

  const fetchChatDetails = (shopId: string) => {
    getChatButtonStatus().then((response: any) => {
      setIsWAChatEnabled(response.enable);
    });

    getChatDetails(shopId).then((response: any) => {
      // setIsWAChatEnabled(response.chat.is_chat_enabled);
      let response_data = response.chat;
      setChatData({ ...chatData, ...response_data });

      /** Display Pages  */
      getChatButtonDisplayPages().then((data: any) => {
        setChatData({ ...chatData, ...response_data, buttonPages: data });
      });
    });
  };

  const getToggleSwitchVariationStyle = () => {
    return isWAChatEnabled ? "strong" : "negative";
  };

  const getToggleSwitchTxtContent = () => {
    return isWAChatEnabled ? "enabled" : "disabled";
  };

  const getToggleSwitchBtnContent = () => {
    return isWAChatEnabled ? "Disable" : "Enable";
  };

  // Enable/Disable Chat button
  const enableWhatsAppChat = () => {
    let isChatEnabled = isWAChatEnabled ? false : true;
    updateChatButtonStatus({ enable: isChatEnabled }).then((chat: any) => {
      let toastMessage = isChatEnabled ? "Enabled Chat" : "Disabled Chat";
      setToast(toastMessage);
      setIsWAChatEnabled(chat.enable);
    });
  };

  const tabs = [
    {
      id: "Chat Settings",
      content: "Chat Settings",
      accessibilityLabel: "Chat Settings",
      panelID: "chatsettings",
    },
    {
      id: "Share Settings",
      content: "Share Settings",
      panelID: "sharesettings",
    },
  ];
  const pagesToDisplayChanges = (data: string[]) => {
    if (data && data.length > 0) {
      setHasChanges(true);

      setDataChanges({ ...dataChanges, pagesToDisplay: data.toString() });
    } else {
      setHasChanges(false);
    }
  };

  const calloutCardChanges = (data: any) => {
    if (data && Object.keys(data).length > 0) {
      setHasChanges(true);

      setDataChanges({ ...dataChanges, callout: data });
    }
  };

  const chatButtonThemeChanges = (data: any) => {
    if (data && Object.keys(data).length > 0) {
      setHasChanges(true);

      setDataChanges({ ...dataChanges, chatTheme: data });
    }
  };
  const agentSettingChanges = (data: any) => {
    if (Object.keys(data).length > 0) {

      setDataChanges({ ...dataChanges, agentSettings: data });
      setHasChanges(true);
    }
  };
  const greetingWidgetChanges = (data: any) => {
    if (Object.keys(data).length > 0) {
      if (data.hasOwnProperty("multiAgentEnabled")) {
        let params = new URLSearchParams();
        params.append("multiAgentEnabled", data.multiAgentEnabled);
        updateShopSettings(shopId, params).then((response: any) => {
          if (response.status === "success") {
            let toastMessage = data.multiAgentEnabled
              ? "Enabled Greeting Widget"
              : "Disabled Greeting Widget";
            setToast(toastMessage);
          } else {
            setShowErrorToast((errorToast: any) => !errorToast)
            setErrorToast(response);
          }
        });
      } else {

        setDataChanges({ ...dataChanges, greeting: data });
        setHasChanges(true);
      }
    }
  };

  const handleDeviceTypeChange = (type: number, showCTX: boolean) => {
    setSelectedDeviceType(type);
    setHasChanges(showCTX);
  }


  const devicesChanges = (data: any) => {
    if (Object.keys(data).length > 0) {
      if (Object.keys(data.mobile).length > 0) {

        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            mobile: data.mobile
          }
        });
        setHasChanges(true);
      }

      if (Object.keys(data.desktop).length > 0) {

        setDataChanges({
          ...dataChanges,
          devices: {
            ...dataChanges.devices,
            desktop: data.desktop
          }
        });

        setHasChanges(true);
      }
    }
  }

  const handleErrorMessage = (message: string, key: string) => {

    let obj: any = errorMessage;
    obj[key] = message;
    setErrorMessage(obj);
  }

  const isFormValid = () => {
    let valid = true, message: string = '';

    for (const item of Object.keys(errorMessage)) {
      if (item === "mobile" && selectedDeviceType === 2) continue;
      if (item === "desktop" && selectedDeviceType === 1) continue;

      if (errorMessage[item]) {
        valid = false;
        message = errorMessage[item];
      }
    }

    return { valid: valid, message: message };
  }

  const updateChatData = () => {
    let form = isFormValid();

    if (!form.valid) {
      setShowErrorToast((showErrorToast) => !showErrorToast)
      setErrorToast(form.message);
    }
    else {

      setSaveFlag(!saveFlag);
      setIsDataSubmitted(false);
      if (dataChanges.chatTheme) {
        updateChatDetails(shopId, dataChanges.chatTheme).then(
          (response: any) => {
            if (response.errorMessage) {
              setShowErrorToast((errorToast: any) => !errorToast)
              setErrorToast(response.errorMessage);

            } else if (response.id) {
              setShowSuccessToast((successToast: any) => !successToast)
              setToast("Chat Design Data Saved");

              setDataChanges({ ...dataChanges, chatTheme: null });
              setIsDataSubmitted(true);
            }
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast)
            setErrorToast("In chat design Something went wrong")
          }
        );
      }

      if (dataChanges.callout) {
        updateCallout(shopId, dataChanges.callout).then(
          (response: any) => {
            if (response.errorMessage) {
              setShowErrorToast((errorToast: any) => !errorToast);
              setErrorToast(response.errorMessage);
              fetchChatDetails(shopId);
            } else if (response.id) {
              setShowSuccessToast((successToast: any) => !successToast)
              setToast("Callout Data Saved");
              setDataChanges({ ...dataChanges, callout: "" });
              setIsDataSubmitted(true);
            }
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast)
            setErrorToast("Something went wrong")
          }
        );
      }

      //  Start Greetings Widget Save Data 
      if (dataChanges.greeting) {
        let params = dataChanges.greeting;
        updateGreetingWidgetData(shopId, params).then(
          (response: any) => {
            if (response.id) {
              setShowSuccessToast((successToast: any) => !successToast)
              setToast("Greetings Widget Data Saved");

              setDataChanges({ ...dataChanges, greeting: null });
              setIsDataSubmitted(true);
            } else {
              setShowErrorToast((errorToast: any) => !errorToast)
              setErrorToast(response);
            }
          },
          (err) => {
            setShowErrorToast((errorToast: any) => !errorToast)
            setErrorToast("Something went wrong")
          }
        );
      }
      // End Greetings Widget Save Data

      // Start Button Display & Position
      if (selectedDeviceType) {
        let putDevice = {
          "displayType": 1,
          "desktopPosition": "left",
          "desktopHeightOffset": 0,
          "desktopEdgeOffset": 0,
          "desktopProductPageHeight": false,
          "desktopProductPageOffset": 0,
          "mobilePosition": "left",
          "mobileHeightOffset": 0,
          "mobileEdgeOffset": 0,
          "mobileProductPageHeight": false,
          "mobileProductPageOffset": 0
        };

        putDevice = { ...putDevice, "displayType": selectedDeviceType };
        if (selectedDeviceType === 1 || selectedDeviceType === 3) {
          putDevice = {
            ...putDevice,
            "mobilePosition": mobileData['chatPosition'],
            "mobileHeightOffset": mobileData['verticalOffset'],
            "mobileEdgeOffset": mobileData['horizontalOffset'],
            "mobileProductPageHeight": mobileData['productPageHeight'],
            "mobileProductPageOffset": mobileData['productPageOffset']
          };
        }
        if (selectedDeviceType === 2 || selectedDeviceType === 3) {
          putDevice = {
            ...putDevice,
            "desktopPosition": desktopData['chatPosition'],
            "desktopHeightOffset": desktopData['verticalOffset'],
            "desktopEdgeOffset": desktopData['horizontalOffset'],
            "desktopProductPageHeight": desktopData['productPageHeight'],
            "desktopProductPageOffset": desktopData['productPageOffset']
          };
        }
        updateChatDevices(shopId, putDevice).then((response: any) => {
          if (response.errorMessage) {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response.errorMessage);
            fetchChatDetails(shopId);
          } else if (response.id > 0) {
            setShowSuccessToast((successToast: any) => !successToast)
            setToast("Button Display & Position Saved Successfully");
            setDataChanges({ ...dataChanges, device: "" });
            setIsDataSubmitted(true);
          }
        });
      }
      // End Button Display & Position

      if (dataChanges.pagesToDisplay) {
        updateChatButtonDisplayPages(shopId, dataChanges.pagesToDisplay).then((response: any) => {
          if (response.errorMessage) {
            setShowErrorToast((errorToast: any) => !errorToast);
            setErrorToast(response.errorMessage);
            fetchChatDetails(shopId);
          } else if (response.displayScope) {
            setShowSuccessToast((successToast: any) => !successToast)
            setToast("Data Saved Successfully");
            setDataChanges({ ...dataChanges, pagesToDisplay: "" });
            setIsDataSubmitted(true);
          }
        });
      }

      setHasChanges(false);
    }
  };

  const updateChatButtonData = (params: any) => {
    updateChatDetails(shopId, params).then(
      (response: any) => {
        if (response.status === "success") {
          setShowSuccessToast((successToast: any) => !successToast)
          setToast("Chat Design Data Saved");

          setDataChanges({ ...dataChanges, chatTheme: null });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast(response);
        }
      },
      (err) => {
        setShowErrorToast((errorToast: any) => !errorToast)
        setErrorToast("Something went wrong")
      }
    );
  }


  const updateMobileData = () => {

    if (Object.keys(mobileData).length) {
      const params = new URLSearchParams();
      var mData: any = mobileData;

      Object.keys(mData).forEach((key) => {
        params.append(key, mData[key]);
      });

      updateChatDevices(shopId, params).then((response: any) => {
        if (response.status === "success") {
          setShowSuccessToast((showSuccessToast) => !showSuccessToast)
          setToast("Data Saved Successfully");

          setDataChanges({ ...dataChanges, devices: { ...dataChanges.devices, mobile: null } });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast(response);
        }
      })
    }
  }

  const deleteMobileData = () => {
    const params = new URLSearchParams();
    params.append('deviceType', 'mobile');
    deleteChatDevices(shopId, params).then((response: any) => {
      if (response.status === "success") {

        setDataChanges({ ...dataChanges, devices: { ...dataChanges.devices, mobile: null } });
      }
    });
  }

  const updateDesktopData = () => {
    if (Object.keys(desktopData).length) {
      const params = new URLSearchParams();
      var dData: any = desktopData;

      Object.keys(dData).forEach((key) => {
        params.append(key, dData[key]);
      });
      updateChatDevices(shopId, params).then((response: any) => {
        if (response.status === "success") {
          setShowSuccessToast((showSuccessToast) => !showSuccessToast)
          setToast("Data Saved Successfully");

          setDataChanges({ ...dataChanges, devices: { ...dataChanges.devices, desktop: null } });
          setIsDataSubmitted(true);
        } else {
          setShowErrorToast((errorToast: any) => !errorToast)
          setErrorToast(response);
        }
      })
    }
  }

  const deleteDesktopData = () => {
    const params = new URLSearchParams();
    params.append('deviceType', 'desktop');
    deleteChatDevices(shopId, params).then((response: any) => {
      if (response.status === "success") {

        setDataChanges({ ...dataChanges, devices: { ...dataChanges.devices, desktop: null } });
      }
    });
  }

  const ctxSaveBarMarkup = hasChanges ? (
    <ContextualSaveBar
      message="Unsaved changes"
      saveAction={{
        content: "Save Settings",
        onAction: updateChatData,
      }}
      discardAction={{
        onAction: () => {
          setHasChanges(false);
          setDiscardChanges(true);
        },
      }}
    />
  ) : null;

  const tabContent = {
    0: (
      <React.Fragment>
        <Layout.AnnotatedSection
          title="WhatsApp Chat"
          description="Enable or Disable WhatsApp chat on your store"
        >
          <div className="pt-2">
            <SettingToggle
              action={{
                content: getToggleSwitchBtnContent(),
                onAction: enableWhatsAppChat,
              }}
              enabled={isWAChatEnabled}
            >
              WhatsApp Chat is currently{" "}
              <TextStyle variation={getToggleSwitchVariationStyle()}>
                {getToggleSwitchTxtContent()}
              </TextStyle>{" "}
              <Link url={"https://" + shopUrl} external={true}>
                {" "}
                View store
              </Link>
            </SettingToggle>
          </div>
        </Layout.AnnotatedSection>
        <AgentSettings
          onChanges={agentSettingChanges}
          shopSettings={shopSettings}
          setToast={setToast}
          setErrorToast={setErrorToast}
          setShowSuccessToast={setShowSuccessToast}
          setShowErrorToast={setShowErrorToast}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          onError={handleErrorMessage}
        />

        <ChatButtonTheme
          chatData={chatData}
          onChanges={chatButtonThemeChanges}
          setToast={setToast}
          setErrorToast={setErrorToast}
          setShowSuccessToast={setShowSuccessToast}
          setShowErrorToast={setShowErrorToast}
          setHasChanges={setHasChanges}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          onError={handleErrorMessage}
        />
        <CallOutTag
          onChanges={calloutCardChanges}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          onError={handleErrorMessage} />
        <GreetingWidget
          onChanges={greetingWidgetChanges}
          shopId={shopId}
          shopSettings={shopSettings}
          setToast={setToast}
          setErrorToast={setErrorToast}
          setShowSuccessToast={setShowSuccessToast}
          setShowErrorToast={setShowErrorToast}
          setHasChanges={setHasChanges}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          onError={handleErrorMessage}
        />
        <Devices
          onChanges={devicesChanges}
          onTypeChange={handleDeviceTypeChange}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
          saveFlag={saveFlag}
          setMobileData={setMobileData}
          setDesktopData={setDesktopData}
          onError={handleErrorMessage}
          setHasChanges={setHasChanges} />
        <PagesToDisplay
          chatData={chatData}
          onFieldChange={pagesToDisplayChanges}
          isDataSubmitted={isDataSubmitted}
          reset={discardChanges}
        />
      </React.Fragment>
    ),
    1: <ShareSettings
      setToast={setToast}
      setErrorToast={setErrorToast}
      setShowSuccessToast={setShowSuccessToast}
      setShowErrorToast={setShowErrorToast}
    ></ShareSettings>
  };

  const handleTabChange = (selected: number) => {
    setSelected(selected);
  }

  return (
    <>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        btn2Action={() => {
          setOpenGuideModal(true);;
          setTabIndex(1);
        }}
        obj={obj}
        showPricingBanner={showPricingBanner}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
      <PageMarkup
        title="WhatsApp Widget Settings"
        tutorial
        tutorialSrc={selected === 1 ? "https://www.youtube.com/embed/3rfktLjr1bs" : "https://www.youtube.com/embed/NIJVYNV-XDg"}
        tabs={tabs}
        tabContent={tabContent}
        toast={toast}
        errorToast={errorToast}
        showErrorToast={showErrorToast}
        showSuccessToast={showSuccessToast}
        handleTabChange={handleTabChange}>
        {ctxSaveBarMarkup}
      </PageMarkup>
    </>
  );
};

export default ChatSettings;
