import { useEffect, useState } from "react";
import {
  Layout,
  Page,
  Button,
  Toast,
  Icon,
  List,
  Spinner
} from "@shopify/polaris";
import {
  ChevronUpMinor,
  ChevronDownMinor,
  CircleTickMajor,
  CircleCancelMajor
} from '@shopify/polaris-icons';
import { PLAN_ULTIMATE, PLAN_FREE, PLAN_PRO } from "../../constants/constants";
import "./pricing.scss";

import { getPlanDetails, getPlans, updatePlan } from "../../redux/actions/pricing";
import Plan from "../../types/Plan.type";
import { useLocation, useHistory } from "react-router-dom";

const Pricing = (props: any) => {
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [currentPlanId, setCurrentPlanId] = useState(localStorage.getItem("currentPlan"));
  const [selectedPlanId,setSelectedPlanId] = useState(props.planId)
  const [availablePlans, setAvailablePlans] = useState<Plan[]>();
  const location = useLocation();
  const history = useHistory();
  const queryParam = new URLSearchParams(location.search);
  const queryParamPlanId = queryParam.get('id');

  const [isLoading, setIsLoading] = useState(false);
  const [showPlans, setShowPlans] = useState(false);
  const [confirmation, setConfirmation] = useState({
    show: false, plan: 0
  });

  useEffect(() => {
    const fetchPlans = () => {
      getPlans().then(
        (response: any) => {
          if (response.status === 'success') {
            response.plans.sort((a: any, b: any) => a.index < b.index ? -1 : (a.index > b.index ? 1 : 0))
            setAvailablePlans(response.plans);
          } else {
            toggleErrorToast(response);
          }
        },
        () => {}
      );
    };
    setCurrentPlanId(localStorage.getItem("currentPlan"));
    fetchPlans();
  }, [props.planId]);

  useEffect(() => {
    if (queryParam.has('id')) {
      let planId: string = queryParam.get('id') || '';
      localStorage.setItem("currentPlan", planId);
      setCurrentPlanId(planId);
      setToast({ show: true, message: "Plan Activated Successfully" });
      queryParam.delete('id')
      history.replace({
        search: queryParam.toString(),
      })
      props.setPlanId(currentPlanId);
    }
  }, [queryParamPlanId]);

  const fetchPlanDetails = async (planId: string) => {
    await getPlanDetails(planId).then((res: any) => {
      if (res) {
        // INFO: static features added to show all menus
        // localStorage.setItem("features", res.plan.featuresList);
        localStorage.setItem("features", "AUTOMATED_MESSAGING,PRIVATE,EXTENSION,ANALYTICS,WEBHOOK_SYNC");
      }
    });
  };

  const handleUpdatePlan = (planId: number) => {
    if(Number(currentPlanId) === planId) return null;
    setSelectedPlanId(planId)
    // setConfirmation({ show: true, plan: planId}); // to open popup
    activatePlan(planId);
  };

  const activatePlan = (planId: number) => {    
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    let params = new URLSearchParams();
    params.append("from", "pricing");
    params.append("returnUrl", window.location.origin + "/confirmation");
    setIsLoading(true);
    updatePlan(shop.id, planId.toString(), params).then(async (res: any) => {
      if (res.status === 'success' || res === 202) {
        await fetchPlanDetails(planId.toString());
        if (res.charge && res.charge.confirmationUrl) {
          window.open(res.charge.confirmationUrl, "_self");
        } else {
          setToast({ show: true, message: "Plan Activated Successfully" });
        }
        if (planId === PLAN_FREE) {
          localStorage.setItem("currentPlan", planId.toString());
          setCurrentPlanId(planId.toString());
          props.setPlanId(planId.toString());
        }
      } else {
        setErrorToast({ show: true, message: res });
      }
      setIsLoading(false);
      setConfirmation({ show: false, plan: 0 })
    }, () => {
      setIsLoading(false);
    });
  }

  const toggleToast = (message: string) => {
    setToast({
      show: !toast.show,
      message: message,
    });
  };

  const toggleErrorToast = (message: string) => {
    setErrorToast({
      show: !errorToast.show,
      message: message,
    });
  };

  const findSelectedPlan = (planId: number) =>{
    if (planId === PLAN_FREE) return 'Free Plan'
    if (planId === PLAN_PRO) return 'Pro Plan'
    if (planId === PLAN_ULTIMATE) return 'Ultimate Plan'
  }
  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        toggleToast("");
      }}
    />
  ) : null;
  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        toggleErrorToast("");
      }}
    />
  ) : null;

  const highlightPlanHeader = (planId: number) => {
    return `Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignBottom Polaris-DataTable__Cell--header text-center ${Number(currentPlanId) === planId ? "highlight" : ""}`
  }

  const highlightPlanBody = (planId: number) => {
    return `Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle text-center ${Number(currentPlanId) === planId ? "highlight" : "Polaris-ActionMenu-SecondaryAction"}`
  }
  const highlightCurrentPlan = (planId: number) => {
    return `Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle text-center p-0 absolute ${Number(currentPlanId) === planId ? "align-at-top" : "align-at-bottom Polaris-ActionMenu-SecondaryAction"}`
  }
  const renderButton = (planId: number) => {
    return `Polaris-Button Polaris-Button--primary ${Number(currentPlanId) === planId ? "plan-active-btn" : "Polaris-Button--outline mt-1 mb-1 pl-2 pr-2"}`
  }

  return availablePlans ? (
    <Page
      title="Select a Plan"
    >
      {toastMarkup}
      {errorToastMarkup}
      <div className="basic_info_plans">
        <Layout>
          <Layout.Section oneThird key={1}>
            <div className="Polaris-Card basic-tile">
              <div className="Polaris-Card__Header">
                <h2 className="Polaris-Heading mt-2">Free Plan</h2>
              </div>
              <div className="Polaris-Card__Section">
                <p className="text-center min-height-4">Ideal solution for beginners</p>
                <p className="plan_price_1"><span className="sup">$</span>0<span className="plan_price_month_1">/month</span></p>
              </div>
              <div className={highlightCurrentPlan(PLAN_FREE)}>
                  <button onClick={() => handleUpdatePlan(PLAN_FREE)} className={`${renderButton(PLAN_FREE)} ${selectedPlanId === PLAN_FREE ? "pointerNone" : ""}`} type="button" disabled={selectedPlanId === PLAN_FREE}>
                    <span className="Polaris-Button__Content">
                      <span className="Polaris-Button__Text">{Number(currentPlanId) === PLAN_FREE ? 'Current Plan' : 'Start'}
                      </span>
                      {isLoading && selectedPlanId == PLAN_FREE && <span className="ml-1"><Spinner size="small"/></span>}
                    </span>
                  </button>
              </div>
            </div>
          </Layout.Section>
          <Layout.Section oneThird key={2}>
            <div className="Polaris-Card basic-tile">
              <div className="Polaris-Card__Header">
                <h2 className="Polaris-Heading mt-2">Pro Plan</h2>
              </div>
              <div className="Polaris-Card__Section">
                <p className="text-center min-height-4">Ideal for P2P messaging</p>
                <p className="plan_price_1"><span className="sup">$</span>7.99<span className="plan_price_month_1">/month</span></p>
                <p className="plan_price_1_strike_through"><span className="strike-through">$9.99</span><span className="plan_price_month_1_strike_through">/month</span></p>

              </div>
              <div className={highlightCurrentPlan(PLAN_PRO)} >
                  <button onClick={() => handleUpdatePlan(PLAN_PRO)} className={`${renderButton(PLAN_PRO)} ${selectedPlanId === PLAN_PRO ? "pointerNone" : ""}`} type="button" disabled={selectedPlanId === PLAN_PRO}>
                    <span className="Polaris-Button__Content">
                      <span className="Polaris-Button__Text">{Number(currentPlanId) === PLAN_PRO ? 'Current Plan' : 'Buy'}
                      </span>
                    </span>
                    {isLoading && selectedPlanId == PLAN_PRO && <span className="ml-1"><Spinner size="small"/></span>}
                  </button>
              </div>
            </div>
          </Layout.Section>
          <Layout.Section oneThird key={3}>
            <div className="Polaris-Card basic-tile">
              <div className="Polaris-Card__Header">
                <h2 className="Polaris-Heading mt-2">Ultimate Plan</h2>
              </div>
              <div className="Polaris-Card__Section">
                <p className="text-center min-height-4">Ideal for professional business to consumer messaging</p>
                <p className="plan_price_1"><span className="sup">$</span>9.99<span className="plan_price_month_1">/month</span></p>
                <p className="plan_price_1_strike_through"><span className="strike-through">$14.99</span><span className="plan_price_month_1_strike_through">/month</span></p>
                
                <p className="text-center Polaris-TextStyle--variationSubdued"><small>WhatsApp template message cost at actuals as they vary by country. Check prices <a className="Polaris-Link" href="https://docs.google.com/spreadsheets/d/1GX6yTDK8imK0YuOFCkU-Lpa3nCFifuq_oaujFvQaiCE/" target="_blank" rel="noopener noreferrer">here</a></small></p>
              </div>
              <div className={highlightCurrentPlan(PLAN_ULTIMATE)}>
                  <button onClick={() => handleUpdatePlan(PLAN_ULTIMATE)} className={`${renderButton(PLAN_ULTIMATE)} ${selectedPlanId === PLAN_ULTIMATE ? "pointerNone" : ""}`} type="button" disabled={selectedPlanId === PLAN_ULTIMATE}>
                    <span className="Polaris-Button__Content">
                      <span className="Polaris-Button__Text">{Number(currentPlanId) === PLAN_ULTIMATE ? 'Current Plan' : 'Buy'}
                      </span>
                    </span>
                    {isLoading && selectedPlanId == PLAN_ULTIMATE && <span className="ml-1"><Spinner size="small"/></span>}
                  </button>
              </div>
            </div>
          </Layout.Section>
        </Layout>
        <div className="text-center mt-3 mb-3">
          <Button 
            primary
            icon={showPlans ? ChevronUpMinor : ChevronDownMinor}
            onClick={() => setShowPlans(!showPlans)}>{showPlans ? "Hide Features" : "Show Features"}</Button>
        </div>
      </div>
      {
        showPlans ? (
          <div className="detailed_plan_info">
            <div className="Polaris-DataTable">
              <table className="Polaris-DataTable__Table">
                <thead>
                  <tr>
                    <th data-polaris-header-cell="true" className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignBottom Polaris-DataTable__Cell--header w-40" scope="col">
                      <span className="header_only">Features</span>
                    </th>
                    <th data-polaris-header-cell="true" className={highlightPlanHeader(PLAN_FREE)} scope="col">
                      <span className="header_only">Free Plan</span>
                      <p className="plan_price_1"><span className="sup">$</span>0<span className="plan_price_month_1">/month</span></p>
                    </th>
                    <th data-polaris-header-cell="true" className={highlightPlanHeader(PLAN_PRO)} scope="col">
                      <span className="header_only">Pro Plan</span>
                      <p className="plan_price_1"><span className="strike-through pricing-color-strike-through font-weight-normal">$9.99</span><span className="sup">$</span>7.99<span className="plan_price_month_1">/month</span></p>
                    </th>
                    <th data-polaris-header-cell="true" className={highlightPlanHeader(PLAN_ULTIMATE)} scope="col">
                      <span className="header_only">Ultimate Plan</span>
                      <p className="plan_price_1"><span className="strike-through pricing-color-strike-through font-weight-normal">$14.99</span><span className="sup">$</span>9.99<span className="plan_price_month_1">/month</span></p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="Polaris-DataTable__TableRow">
                    <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop">
                      <p className="strong pb-2">WhatsApp chat widget on your personal number</p>
                      <List type="bullet">
                        <List.Item>WhatsApp chat widget</List.Item>
                        <List.Item>50+ Premium Designs</List.Item>
                        <List.Item>Multiple agents/Multiple numbers</List.Item>
                        <List.Item>Agent working hours and profile</List.Item>
                        <List.Item>Global On/Off hours</List.Item>
                        <List.Item>Callout card</List.Item>
                        <List.Item>Unlimited chats</List.Item>
                        <List.Item>Analytics</List.Item>
                      </List>
                    </td>
                    <td className={highlightPlanBody(PLAN_FREE)}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                    <td className={highlightPlanBody(PLAN_PRO)}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                    <td className={highlightPlanBody(PLAN_ULTIMATE)}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                  </tr>
                  <tr className="Polaris-DataTable__TableRow">
                    <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop">
                      <p className="strong pb-2">WhatsApp share button on your personal number</p>
                      <List type="bullet">
                        <List.Item>50+ Premium Designs</List.Item>
                        <List.Item>Product share button</List.Item>
                        <List.Item>Analytics</List.Item>
                      </List>
                    </td>
                    <td className={highlightPlanBody(PLAN_FREE)}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                    <td className={highlightPlanBody(PLAN_PRO)}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                    <td className={highlightPlanBody(PLAN_ULTIMATE)}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                  </tr>
                  <tr className="Polaris-DataTable__TableRow">
                    <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop">
                      <p className="strong pb-2">WhatsApp Support Extension on your personal number</p>
                      <List type="bullet">
                        <List.Item>Order history</List.Item>
                        <List.Item>Manual support and notifications</List.Item>
                        <List.Item>Custom order related templates</List.Item>
                        <List.Item>Custom quick replies</List.Item>
                        <List.Item>Custom abandoned cart messages</List.Item>
                        <List.Item>Multiple language support</List.Item>
                        <List.Item>Dynamic Tags &amp; Filters</List.Item>
                      </List>
                    </td>
                    <td className={highlightPlanBody(PLAN_FREE)}>
                      <Icon source={CircleCancelMajor} color="critical"></Icon>
                    </td>
                    <td className={highlightPlanBody(PLAN_PRO)}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                    <td className={highlightPlanBody(PLAN_ULTIMATE)}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                  </tr>
                  <tr className="Polaris-DataTable__TableRow">
                    <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop">
                      <p className="strong">Standard automated notifications from common number given by us</p>
                      <p className="pb-2"><i>(Automated message rates vary by country and start at $0.02 per message)</i></p>
                      <List type="bullet">
                        <List.Item>Automated order confirmations</List.Item>
                        <List.Item>Automated delivery updates</List.Item>
                        <List.Item>Automated abandoned cart notifications</List.Item>
                        <List.Item>Multiple language support</List.Item>
                        <List.Item>Custom opt-in widgets</List.Item>
                        <List.Item>Message logs &amp; analytics</List.Item>
                        <List.Item>Automatically applied discounts</List.Item>
                        <List.Item>Dynamic Tags &amp; Filters</List.Item>
                        <List.Item>Mobile &amp; Desktop support</List.Item>
                        <List.Item>Free Upgrade to Private WhatsApp Business Account</List.Item>
                      </List>
                    </td>
                    <td className={highlightPlanBody(PLAN_FREE)}></td>
                    <td className={highlightPlanBody(PLAN_PRO)}></td>
                    <td className={highlightPlanBody(PLAN_ULTIMATE)}></td>
                  </tr>
                  <tr className="Polaris-DataTable__TableRow">
                    <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop text-center no-border" scope="row">
                      <p className="strong">OR</p>
                    </th>
                    <td className={`no-border ${highlightPlanBody(PLAN_FREE)}`}>
                      <Icon source={CircleCancelMajor} color="critical"></Icon>
                    </td>
                    <td className={`no-border ${highlightPlanBody(PLAN_PRO)}`}>
                      <Icon source={CircleCancelMajor} color="critical"></Icon>
                    </td>
                    <td className={`no-border ${highlightPlanBody(PLAN_ULTIMATE)}`}>
                      <Icon source={CircleTickMajor} color="primary"></Icon>
                    </td>
                  </tr>
                  <tr className="Polaris-DataTable__TableRow">
                    <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignTop no-border">
                      <p className="strong pb-2">Custom Automated Notification from your Private WhatsApp Business Account provided by us</p>
                      <List type="bullet">
                        <List.Item>All above Standard automated notification features from your own WhatsApp business number</List.Item>
                        <List.Item>Official WhatsApp Business account</List.Item>
                        <List.Item>Custom template notifications</List.Item>
                        <List.Item>Multiple language support</List.Item>
                        <List.Item>Delightchat compatibility for Customer Support tool</List.Item>
                        <List.Item>Priority customer support</List.Item>
                      </List>
                    </td>
                    <td className={`no-border ${highlightPlanBody(PLAN_FREE)}`}></td>
                    <td className={`no-border ${highlightPlanBody(PLAN_PRO)}`}></td>
                    <td className={`no-border ${highlightPlanBody(PLAN_ULTIMATE)}`}></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td className={highlightPlanBody(PLAN_FREE)}>
                      <Button 
                        primary
                        onClick={() => handleUpdatePlan(PLAN_FREE)}
                        outline={Number(currentPlanId) !== PLAN_FREE}>
                        {Number(currentPlanId) === PLAN_FREE ? 'Current Plan' : 'Start'}
                      </Button>
                    </td>
                    <td className={highlightPlanBody(PLAN_PRO)}>
                      <Button 
                        primary
                        outline={Number(currentPlanId) !== PLAN_PRO}
                        onClick={() => handleUpdatePlan(PLAN_PRO)}>
                        {Number(currentPlanId) === PLAN_PRO ? 'Current Plan' : 'Buy'}
                      </Button>
                    </td>
                    <td className={highlightPlanBody(PLAN_ULTIMATE)}>
                      <Button 
                        primary
                        outline={Number(currentPlanId) !== PLAN_ULTIMATE}
                        onClick={() => handleUpdatePlan(PLAN_ULTIMATE)}>
                        {Number(currentPlanId) === PLAN_ULTIMATE ? 'Current Plan' : 'Buy'}
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        ) : null
      }
      {confirmation.show ? 
        <>
          <div className="modal-container">
            <div className="Polaris-Modal-Dialog__Container" data-polaris-layer="true" data-polaris-overlay="true">
                <div role="dialog" aria-modal="true" aria-labelledby="Polarismodal-header2" tabIndex={-1} className="Polaris-Modal-Dialog focus-visible" data-focus-visible-added="">
                  <div className="Polaris-Modal-Dialog__Modal modal-container">
                    <div className="Polaris-Modal-Headers">
                      <button className="Polaris-Modal-CloseButton close-btn" onClick={() => setConfirmation({ show: false, plan: 0 })} aria-label="Close"><span className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor"><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                            <path d="m11.414 10 6.293-6.293a1 1 0 1 0-1.414-1.414L10 8.586 3.707 2.293a1 1 0 0 0-1.414 1.414L8.586 10l-6.293 6.293a1 1 0 1 0 1.414 1.414L10 11.414l6.293 6.293A.998.998 0 0 0 18 17a.999.999 0 0 0-.293-.707L11.414 10z"></path>
                          </svg></span>
                      </button>
                    </div>
                    <div className="Polaris-Modal__BodyWrapper">
                      <div className="Polaris-Modal__Body Polaris-Scrollable Polaris-Scrollable--vertical" data-polaris-scrollable="true">
                        <section className="Polaris-Modal-Section">
                        <div className="text-center">
                          <p className="mt-2 fs-4">Are you sure you want to switch to</p><br/>
                          <h2 className="fs-1 strong">{findSelectedPlan(confirmation.plan)+'?'}</h2>
                        </div>
                        </section>
                      </div>
                    </div>
                    <div className="Polaris-Modal-Footer no-border mb-2">
                      <div className="Polaris-Modal-Footer__FooterContent d-content">
                        <div className="Polaris-Stack Polaris-Stack--alignmentCenter">
                          <div className="Polaris-Stack__Item Polaris-Stack__Item--fill"></div>
                          <div className="Polaris-Stack__Item">
                            <div className="Polaris-ButtonGroup">
                              <div className="Polaris-ButtonGroup__Item">
                                <Button destructive outline onClick={() => setConfirmation({ show: false, plan: 0})}>Cancel</Button>
                              </div>
                              <div className="Polaris-ButtonGroup__Item">
                                <Button 
                                  primary 
                                  outline 
                                  loading={isLoading}
                                  onClick={() => activatePlan(confirmation.plan)}>Continue</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div className="Polaris-Backdrop"></div>
        </>
      : null}
    </Page>
  ) : null;
};

export default Pricing;
