import { Card, Checkbox, FormLayout, RadioButton, Stack, TextContainer, TextField, TextStyle } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { DEVICE_TYPE_MOBILE_AND_DESKTOP, DEVICE_TYPE_MOBILE_ONLY } from "../../../constants/constants";
import DevicesType from "../../../types/Devices.type";
import { objectDifference } from "../../../utils/helper";

const MobileConfig = (props: any) => {

    const [initialData, setInitialData] = useState<DevicesType>(props.data);
    const [data, setData] = useState<DevicesType>(props.data);
    const [refData, setRefData] = useState<DevicesType>(props.data);

    const [activateErrorCheckForHeightOffset, setActivateErrorCheckForHeightOffset] = useState(false);
    const [activateErrorCheckForEdgeOffset, setActivateErrorCheckForEdgeOffset] = useState(false);
    const [activateErrorCheckForProductPageHeight, setActivateErrorCheckForProductPageHeight] = useState(false);

    const [updatedData, setUpdatedData] = useState({});

    useEffect(() => {
        setRefData(props.data);
        setData(props.data);
        setInitialData(props.data);
    }, [props.data]);

    useEffect(() => {
        if (props.isDataSubmitted) {
            setRefData(data);
            setInitialData(data);
        }
    }, [props.isDataSubmitted]);

    useEffect(() => {
        var diffObj: any = objectDifference(refData, data);
        setUpdatedData({});
        if (Object.keys(diffObj).length !== 0) {
            switch (props.selected) {
                case DEVICE_TYPE_MOBILE_AND_DESKTOP:
                case DEVICE_TYPE_MOBILE_ONLY:
                    setDataToUpdate(diffObj);
                break;
            }
        } else {
            setUpdatedData({});
        }
    }, [data]);

    useEffect(() => {
        let errorMessage = getErrorMessage();
        if (errorMessage) {
            props.onError(errorMessage, 'mobile');
            props.onChanges(null);
        } else {
            props.onError('', 'mobile');
            props.onChanges(updatedData);
        }
    }, [updatedData]);

    useEffect(() => {
        switch (props.selected) {
            case DEVICE_TYPE_MOBILE_AND_DESKTOP:
                props.onChanges(updatedData);
                break;
            case DEVICE_TYPE_MOBILE_ONLY:
                props.onChanges(updatedData);
                break;
        }
    }, [props.selected]);

    useEffect(() => {
        if (props.reset) {
            setRefData(initialData);
            setData(initialData);
            props.setHasChanges(false);
        }
    }, [props.reset]);

    const getErrorMessage = () => {
        if(!data.chatPosition){
            return "Invalid Chat Button Position"
        }
        else if (validateVerticalOffset() !== false) {
            return "Invalid Height Offset";
        } else if (validateHorizontalOffset() !== false) {
            return "Invalid Edge Offset";
        } else if (validateProductOffset() !== false) {
            return "Invalid Product Height Offset";
        } else {
            return false;
        }
    }

    const setDataToUpdate = (diffObj: any) => {
        let deviceType = "mobile";
        if ("chatPosition" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("chatPosition", diffObj.chatPosition);
        }
        if ("verticalOffset" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("verticalOffset", diffObj.verticalOffset);
        }
        if ("horizontalOffset" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("horizontalOffset", diffObj.horizontalOffset);
        }
        if ("productPageHeight" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("productPageHeight", diffObj.productPageHeight);
        }
        if ("productPageOffset" in diffObj) {
            setUpdatedMobileData("deviceType", deviceType);
            setUpdatedMobileData("productPageOffset", diffObj.productPageOffset);
        }
    };

    const setUpdatedMobileData = (field: string, value: any) => {
        setUpdatedData((mData: any) => ({ ...mData, [field]: value }));
    };

    const handleButtonPositionChange = (value: string) => {
        setData({ ...data, chatPosition: value });
    };

    const handleOffsetChange = (offsetType: string, value: string) => {
        if (offsetType === "vertical") {
            if (!activateErrorCheckForHeightOffset) {
                setActivateErrorCheckForHeightOffset(true);
            }
            setData({ ...data, verticalOffset: value });
        } else if (offsetType === "horizontal") {
            if (!activateErrorCheckForEdgeOffset) {
                setActivateErrorCheckForEdgeOffset(true);
            }
            setData({ ...data, horizontalOffset: value });
        }
    };

    const validateVerticalOffset = () => {
        // if(activateErrorCheckForHeightOffset) {
            if (data.verticalOffset && Number(data.verticalOffset) < 8) {
                return "Minimum offset is 8px";
            } else if (!data.verticalOffset) {
                return "Mobile height offset cannot be empty";
            }
        // }
        return false;
    };
    
    const validateHorizontalOffset = () => {
        // if(activateErrorCheckForEdgeOffset) {
            if (data.horizontalOffset && Number(data.horizontalOffset) < 8) {
                return "Minimum offset is 8px";
            } else if (!data.horizontalOffset) {
                return "Mobile edge offset cannot be empty";
            }
        // }
        return false;
    };

    const handleProductHeightChange = (value: boolean) => {
        setData({ ...data, productPageHeight: value });
    };

    const handleProductOffsetChange = (value: string) => {
        if (!activateErrorCheckForProductPageHeight) {
            setActivateErrorCheckForProductPageHeight(true)
        }
        setData({ ...data, productPageOffset: value });    
    };

    const validateProductOffset = () => {
        // if(activateErrorCheckForProductPageHeight) {
            if (data.productPageHeight && Number(data.productPageOffset) < 8) {
                return "Minimum offset is 8px";
            } else if (data.productPageHeight && !data.productPageOffset) {
                return "Mobile height offset cannot be empty";
            }
        // }
        return false;
    };

    const renderMarkup = () => {
        switch(props.selected) {
            case DEVICE_TYPE_MOBILE_AND_DESKTOP:
            case DEVICE_TYPE_MOBILE_ONLY:
                return (
                    <Card sectioned title="Mobile">
                        <FormLayout>
                            <FormLayout.Group>
                            <div className="mt-1">
                                <TextContainer>
                                    <p>Chat Button Position</p>
                                </TextContainer>
                                <Stack>
                                    <RadioButton
                                        label="Left"
                                        checked={data.chatPosition === "left"}
                                        id="m-left"
                                        name="btnpositionmobile"
                                        onChange={() => handleButtonPositionChange("left")}
                                    />
                                    <RadioButton
                                        label="Right"
                                        id="m-right"
                                        name="btnpositionmobile"
                                        checked={data.chatPosition === "right"}
                                        onChange={() => handleButtonPositionChange("right")}
                                    />
                                </Stack>
                            </div>
                            </FormLayout.Group>

                            <FormLayout.Group>
                                <TextField
                                    label="HEIGHT offset"
                                    type="number"
                                    suffix="px"
                                    value={data.verticalOffset}
                                    onChange={(val) =>
                                    handleOffsetChange("vertical", val)
                                    }
                                    helpText={"Change the height of chat button on mobile"}
                                    error={validateVerticalOffset()}
                                />

                                <TextField
                                    label="EDGE offset"
                                    type="number"
                                    suffix="px"
                                    value={data.horizontalOffset}
                                    onChange={(val) =>
                                    handleOffsetChange("horizontal", val)
                                    }
                                    helpText={
                                    "Change the distance of chat button from the edge of the screen on mobile"
                                    }
                                    error={validateHorizontalOffset()}
                                />
                            </FormLayout.Group>
                            <TextContainer>
                                <Checkbox
                                    label="Set custom chat button height for /product pages"
                                    checked={data.productPageHeight}
                                    onChange={(val) =>
                                    handleProductHeightChange(val)
                                    }
                                />
                            </TextContainer>
                            <TextContainer>
                                <TextStyle variation="subdued">
                                    If you have sticky add to cart or buy now buttons in your /product
                                    pages, use this setting to set a custom chat button height that
                                    applies only to the product page.
                                </TextStyle>
                            </TextContainer>
                            {data.productPageHeight ? (
                                <FormLayout.Group>
                                    <TextField
                                    label="Product HEIGHT offset"
                                    type="number"
                                    suffix="px"
                                    value={data.productPageOffset}
                                    onChange={(val) =>
                                        handleProductOffsetChange(val)
                                    }
                                    helpText={
                                        "Change the height of chat button on mobile /product pages"
                                    }
                                    error={validateProductOffset()}
                                    />
                                </FormLayout.Group>
                            ) : null}
                        </FormLayout>
                    </Card>
                );
        }
    }

    return (
        <>
            {renderMarkup()}
        </>
    );
}

export default MobileConfig;