import {
  AGENT_SETTINGS,
  CREATE_AGENT,
  UPDATE_AGENT,
  UPDATE_AGENT_DETAILS,
  UPDATE_AGENT_TIMINGS,
} from "../../constants/api-constants";
import httpClient from "../../utils/http-client";
import cookie from "react-cookies";
import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    token: cookie.load("access_token"),
  },
};

export const getAgents = (shopId: string) => {
  return new Promise((resolve, reject) => {
    httpClient.get(AGENT_SETTINGS.replace("$shopId", shopId))
      .then((response: any) => {
        let agents = response.map((e: any) => ({
          avatar_url: e.avatarUrl,
          country_code: e.countryCode,
          createdOn: 1626772013317,
          id: e.id,
          is_enabled: e.enable,
          modifiedOn: 1640351790364,
          name: e.name,
          // number: "2384723900",
          number: e.phone,
          phone: e.phone,
          role: e.role
        }));
        resolve({agents});
      })
      .catch(error => {
        reject(error);
      });
  })
};

export const updateAgent = (shopId: string, params: any) => {
  return httpClient.put(UPDATE_AGENT.replace("$shopId", shopId), params);
};

export const createAgent = (shopId: string, params: any) => {
  let newParams = {
    "countryCode": params?.countryCode ? params?.countryCode + "" : "",
    "phone": params?.number ? params?.number + "" : "",
    "name": params?.name ? params?.name + "" : "",
    "role": params?.designation ? params?.designation + "" : "",
    "avatarId": params?.avatarId ? params?.avatarId + "" : "",
    "avatarUrl": "http://img.jpg",

    "mondayStart": params?.timing?.[0] && params?.timing?.[0]?.day === 0 ? params?.timing?.[0]?.start_time : "0000",
    "mondayEnd": params?.timing?.[0] && params?.timing?.[0]?.day === 0 ? params?.timing?.[0]?.end_time : "2359",

    "tuesdayStart": params?.timing?.[1] && params?.timing?.[1]?.day === 1 ? params?.timing?.[1]?.start_time : "0000",
    "tuesdayEnd": params?.timing?.[1] && params?.timing?.[1]?.day === 1 ? params?.timing?.[1]?.end_time : "2359",

    "wednesdayStart": params?.timing?.[2] && params?.timing?.[2]?.day === 2 ? params?.timing?.[2]?.start_time : "0000",
    "wednesdayEnd": params?.timing?.[2] && params?.timing?.[2]?.day === 2 ? params?.timing?.[2]?.end_time : "2359",

    "thursdayStart": params?.timing?.[3] && params?.timing?.[3]?.day === 3 ? params?.timing?.[3]?.start_time : "0000",
    "thursdayEnd": params?.timing?.[3] && params?.timing?.[3]?.day === 3 ? params?.timing?.[3]?.end_time : "2359",

    "fridayStart": params?.timing?.[4] && params?.timing?.[4]?.day === 4 ? params?.timing?.[4]?.start_time : "0000",
    "fridayEnd": params?.timing?.[4] && params?.timing?.[4]?.day === 4 ? params?.timing?.[4]?.end_time : "2359",

    "saturdayStart": params?.timing?.[5] && params?.timing?.[5]?.day === 5 ? params?.timing?.[5]?.start_time : "0000",
    "saturdayEnd": params?.timing?.[5] && params?.timing?.[5]?.day === 5 ? params?.timing?.[5]?.end_time : "2359",

    "sundayStart": params?.timing?.[6] && params?.timing?.[6]?.day === 6 ? params?.timing?.[6]?.start_time : "0000",
    "sundayEnd": params?.timing?.[6] && params?.timing?.[6]?.day === 6 ? params?.timing?.[6]?.end_time : "2359",

    "enable": true
  };
  // return Promise.resolve(newParams);
  // return { trur: true };
  return httpClient.post(CREATE_AGENT.replace("$shopId", shopId), newParams);
};
export const updateAgentDetails = (
  shopId: string,
  agentId: any,
  params: any
) => {

  let newParams = {
    "countryCode": params?.country_code ? params?.country_code + "" : "",
    "phone": params?.number ? params?.number + "" : "",
    "name": params?.name ? params?.name + "" : "",
    "role": params?.role ? params?.role + "" : "",
    "avatarId": params?.avatarId ? params?.avatarId + "" : "",
    "avatarUrl": "http://img.jpg",

    "mondayStart": params?.timing?.[0] && params?.timing?.[0]?.day === 0 ? params?.timing?.[0]?.start_time : "0000",
    "mondayEnd": params?.timing?.[0] && params?.timing?.[0]?.day === 0 ? params?.timing?.[0]?.end_time : "2359",

    "tuesdayStart": params?.timing?.[1] && params?.timing?.[1]?.day === 1 ? params?.timing?.[1]?.start_time : "0000",
    "tuesdayEnd": params?.timing?.[1] && params?.timing?.[1]?.day === 1 ? params?.timing?.[1]?.end_time : "2359",

    "wednesdayStart": params?.timing?.[2] && params?.timing?.[2]?.day === 2 ? params?.timing?.[2]?.start_time : "0000",
    "wednesdayEnd": params?.timing?.[2] && params?.timing?.[2]?.day === 2 ? params?.timing?.[2]?.end_time : "2359",

    "thursdayStart": params?.timing?.[3] && params?.timing?.[3]?.day === 3 ? params?.timing?.[3]?.start_time : "0000",
    "thursdayEnd": params?.timing?.[3] && params?.timing?.[3]?.day === 3 ? params?.timing?.[3]?.end_time : "2359",

    "fridayStart": params?.timing?.[4] && params?.timing?.[4]?.day === 4 ? params?.timing?.[4]?.start_time : "0000",
    "fridayEnd": params?.timing?.[4] && params?.timing?.[4]?.day === 4 ? params?.timing?.[4]?.end_time : "2359",

    "saturdayStart": params?.timing?.[5] && params?.timing?.[5]?.day === 5 ? params?.timing?.[5]?.start_time : "0000",
    "saturdayEnd": params?.timing?.[5] && params?.timing?.[5]?.day === 5 ? params?.timing?.[5]?.end_time : "2359",

    "sundayStart": params?.timing?.[6] && params?.timing?.[6]?.day === 6 ? params?.timing?.[6]?.start_time : "0000",
    "sundayEnd": params?.timing?.[6] && params?.timing?.[6]?.day === 6 ? params?.timing?.[6]?.end_time : "2359",

    "enable": params?.enabled ?? true
  };
  return httpClient.put(
    UPDATE_AGENT_DETAILS.replace("$shopId", shopId).replace(
      "$agentId",
      agentId
    ),
    newParams
  );
};

export const updateAgentTiming = (
  shopId: string,
  agentId: any,
  params: any
) => {
  return axios.put(
    UPDATE_AGENT_TIMINGS.replace("$shopId", shopId).replace(
      "$agentId",
      agentId
    ),
    params,
    config
  );
};
