export const data = {
  ABCART: {
    heading: "Abandoned Cart",
    helperText:
      "Recover abandoned carts by reaching your customers on Email. Message is sent 15 minutes after the customer abandons the cart.",
    cardContent: {
      language: {
        ENGLISH: {
          withFixedDiscount:
            "Hello {Customer name},<br><br>ALL IS NOT LOST!<br>Looks like you didn't finish checking out.<br>Click on the link to complete the payment and confirm your order.<br>Apply coupon code {{discountCode}} at the time of payment.",
          withDynamicDiscount:
            "Hello {Customer name},<br><br>ALL IS NOT LOST!<br>Looks like you didn't finish checking out.<br>Click on the link to complete the payment and confirm your order.<br>Get {{discountPercent}}% off by completing the payment now.",
          withoutDiscount:
            "Hello {Customer name},<br><br>ALL IS NOT LOST!<br>Looks like you didn't finish checking out.<br>Click on the link to complete the payment and confirm your order.",
          emailSubject: "YOU LEFT SOMETHING BEHIND!",
        },
      },
    },
  },
  ORDER_SHIPMENT: {
    heading: "Order Shipment",
    helperText:
      "Order shipment email is sent when you fulfill an order in the Shopline admin page.Tracking number and URL is taken from your Shopline order data.",
    cardContent: {
      language: {
        ENGLISH: {
          withoutDiscount:
            "Hello {Customer name},<br><br>GOOD NEWS ! Your order is on its way!<br>Your order from {{shop_name}} has been shipped and is on its way.<br>Track your shipment using this link {{tracking_ID}}",
          emailSubject: "Your order is out for delivery!",
        },
      },
    },
  },
  ORDER_CONFIRMATION: {
    heading: "Order Confirmation",
    helperText:
      "Send an order confirmation email as soon as a customer places an order on your store. You can also give customers a discount coupon code for their next order.",
    cardContent: {
      language: {
        ENGLISH: {
          withFixedDiscount:
            "Hello {Customer name},<br><br>Thank you for placing an order on {Shop name}. We’re glad to inform you that we’ve received your order and will process it very soon.<br>You can view your order {{order_ID}} here<br> Apply coupon code: {{discountCode}} on your next order {{discountUrl}} <br><br>Thank you for choosing {Shop name} for your purchase.",
          withDynamicDiscount:
            "Hello {Customer name},<br><br>Thank you for placing an order on {Shop name}. We’re glad to inform you that we’ve received your order and will process it very soon.<br>You can view your order {{order_ID}} here<br> Get {{discountPercent}}% off on your next order (coupon code: {{discountCode}}) {{discountUrl}}. <br><br>Thank you for choosing {Shop name} for your purchase.",
          withoutDiscount:
            "Hello {Customer name},<br><br>Thank you for placing an order on {Shop name}. We’re glad to inform you that we’ve received your order and will process it very soon.<br><br>You can view your order {{order_ID}} here<br><br>Thank you for choosing {Shop name} for your purchase.",
          emailSubject: "Order Confirmed! Thank You for Shopping with us.",
        },
      },
    },
  },
  COD: {
    heading: "Cash on Delivery",
    helperText:
      "Verify and confirm COD orders using Email. Email is sent immediately after a COD order is placed.<br><br> <b>Note:</b> If both COD Order Confirmation and regular Order Confirmation templates are enabled, then the regular Order Confirmation email will be sent after your customer confirms a COD order.",
    cardContent: {
      language: {
        ENGLISH: {
          withoutDiscount:
            "Hello {Customer name},<br><br>Thank you for placing an order of {{order_value}} from {{shop_name}}.<br>Since you paid using the Cash on Delivery (COD) option, we need confirmation from you before we process your order and ship it. Click on this link to confirm your order.",
          emailSubject: "Order Verification for Cash on Delivery",
        },
      },
    },
  },
};
