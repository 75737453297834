import { useEffect, useState } from "react";
import FreeTemplates from "./FreeTemplates";
import ProTemplates from "./ProTemplates";
import '../template.scss';

const Templates = () => {
  const [features] = useState(localStorage.getItem("features"));
  const [shop, setShop] = useState({
    id: '',
    url: ''
  });

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
        setShop(shop);
    }
  }, []);

  return features?.includes("EXTENSION") ? (
    <ProTemplates shop={shop}></ProTemplates>
  ) : (
    <FreeTemplates></FreeTemplates>
  );
};

export default Templates;
