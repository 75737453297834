import { useEffect, useState } from "react";
import { withRouter,useHistory, useLocation } from "react-router-dom";
import {
	Form,
	FormLayout,
	TextField,
	Toast,
	Stack,
	Frame
} from "@shopify/polaris";
import cookie from "react-cookies";
import useCustomForm from "../../hooks/useCustomForm";
import { ACCOUNT_OAUTH, SERVER_ADDR } from "../../constants/api-constants";
import { getShopDetails, getOwnerDetails } from "../../redux/actions/shop";
import SuperlemonLogo from "../../assets/images/superlemon-logo_2x.png";
import LoginBanner from "../../assets/images/login-banner-2.png";
import { authorize, create_token } from "../../redux/actions/auth";

const Login = (props: any) => {
	const [fields] = useState({ shopId: "" });
	const [token, setToken] = useState<string>();
	const history = useHistory()
	let { search } = useLocation();
	useEffect(() => {
		console.log("hihihihi")
		const query = new URLSearchParams(search);
		const code = query.get('code');
		const handle = query.get('handle');
		if(code){
			create_token({name:handle,code:code}).then((r:any) => {
				console.log(r)
				props.setToken(r.accessToken);
				setToken(r.accessToken);
				document.cookie = "access_token" + "=" + r.accessToken + "; expires=" + r.expireTime ;
			}).catch(e => console.log(e))
		}
	},[])
	const [toast, setToast] = useState({
		show: false,
		message: "",
	});

	const [firstTime, setFirstTime] = useState(true);
	useEffect(() => {
		if (token) {
			fetchShopDetails();
		}
	}, [token]);
	let winObj: any;

	const toastMarkup = toast.show ? (
		<Toast
			content={toast.message}
			onDismiss={() => {
				setToast({ show: false, message: "" });
			}}
		/>
	) : null;

	const validateStoreId = () => {
		if (firstTime) {
			return false;
		}
		//eslint-disable-next-line
		var shopURLRegex = /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;

		if (
			inputs.shopId === "" ||
			inputs.shopId === undefined ||
			!shopURLRegex.test(inputs.shopId)
		) {
			return "Enter a valid store id";
		} else {
			return false;
		}
	};

	const openModal = () => {

		if (inputs.shopId != null && inputs.shopId.length > 0) {
			//eslint-disable-next-line
			var shopURLRegex = /^[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*[a-z0-9]{2,5}(:[0-9]{1,5})?$/;
			
			if (inputs.shopId === "" || inputs.shopId === undefined || !shopURLRegex.test(inputs.shopId)) {
				setToast({ show: true, message: "Invalid shop name" });
				return;
			}
			authorize({name:inputs.shopId,redirectUri:process.env.REACT_APP_REDIRECT}).then((r) => {
				console.log(r)
				window.location.href = String(r)
				// var url = String(r);
				// winObj = window.open(
				// 	url,
				// 	"_blank",
				// 	"toolbar=no,scrollable=no,left=100,width=600,height=600"
				// );

				// let interval = setInterval(() => {
				// 	if (winObj.closed) {
				// 		props.setToken(cookie.load("access_token"));
				// 		setToken(cookie.load("access_token"));
				// 		clearInterval(interval);
				// 	}
				// }, 1000);
			})
			
		}
	};

	const fetchOwnerDetails = (shopId: string) => {
		getOwnerDetails(shopId).then(
			(response: any) => {
				if (response.status === 'success') {
					localStorage.setItem("owner", JSON.stringify(response.owner));
					props.setOwner();
					props.history.push("/widget");
					window.location.reload();
				}
			},
			(err) => {
				props.history.push("/widget");
			}
		);
	};

	const fetchShopDetails = () => {
		getShopDetails().then((response: any) => {
				// fetchOwnerDetails(response.shopid);
				localStorage.setItem("owner", JSON.stringify(response.shoplineDetail));
				props.setOwner();
				// props.history.push("/pricing");
				props.history.push("/widget");
				window.location.reload();
				localStorage.setItem("currentPlan", response.superLemonPlan.id);
				window.location.reload();
			
		});
	};

	const { handleSubmit, handleInputChange, inputs } = useCustomForm(
		openModal,
		fields
	);

	return (
		<Frame>
			<div className="Polaris-Layout login-container m-0">
				<div className="Polaris-Layout__Section Polaris-Layout__Section--oneHalf m-0 login-content">
					<div className="login text-center">
						<Stack vertical>
							<img src={SuperlemonLogo} alt="Superlemon Logo" className="w-1" />
							<p className="strong fs-2 mt-2 mb-2">Login with your store</p>
							<Form onSubmit={handleSubmit}>
								<FormLayout>
									<TextField
										label="Login with your store"
										value={inputs.shopId}
										onChange={(val) => {
											handleInputChange("shopId", val);
											setFirstTime(false);
										}}
										error={validateStoreId()}
										placeholder="50kshop.myshopline.com"
										align="center"
										labelHidden={true}
									></TextField>
									<button className="Polaris-Button Polaris-Button--primary mt-1" type="submit">
										<span className="Polaris-Button__Content">
											<span className="Polaris-Button__Text">Login with Shopline</span>
										</span>
									</button>
								</FormLayout>
							</Form>
						</Stack>
					</div>
				</div>
				<div className="Polaris-Layout__Section Polaris-Layout__Section--oneHalf login-image m-0 h-100">
					<img src={LoginBanner} className="w-100 h-100" alt=""></img>
				</div>
			</div>
			{toastMarkup}
		</Frame>
	);
};

export default withRouter(Login);
