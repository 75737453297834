import { useCallback, useEffect, useState } from "react";
import {
  Card,
  DataTable,
  Layout,
  Link,
  Pagination,
  Stack,
  TextStyle,
  Filters,
  ChoiceList,
  TextField,
  Button,
  Badge,
  Tag,
  Toast,
  Banner,
} from "@shopify/polaris";
import {
  getAllTemplates,
  handleFiltersAbandonedCart,
  getAllTags,
  addTagAbCartOrder,
  settings,
} from "../../../redux/actions/manual-messaging";
import { capitalize } from "../../../utils/common";
import styles from "../manual-message.module.scss";
import AddTagModal from "./AddTagModal";
import SendMessageModal from "./SendMessageModal";

function AbandonedCart({ owner }: any) {
  const [templateType, setTemplateType] = useState(null);
  const [status, setStatus] = useState(null);
  const [name, setName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [shopUrl, setShopUrl] = useState("");
  const [shopId, setShopId] = useState("");
  const [orders, setOrders] = useState<any>([]);
  const [pageNum, setPageNum] = useState(0);
  const [descending, setDescending] = useState(true);
  const [orderBy, setOrderBy] = useState("checkoutId");
  const pageSize = 10;
  const [noDataFound, setNoDataFound] = useState(false);
  const [allTemplatesAbCart, setAllTemplatesAbCart] = useState([]);
  const [tags, setTags] = useState<any[]>([]);
  const [currentCheckoutId, setCurrentCheckoutId] = useState("");

  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [openAbandonedCartTagModal, setOpenAbandonedCartTagModal] =
    useState(false);
  const [openSendMessageModal, setOpenSendMessageModal] = useState(false);
  const [currentSelectedOrder, setCurrentSelectedOrder] = useState({});
  const [isWhatsappWebEnabled, setisWhatsappWebEnabled] = useState(true);

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShopUrl(shop.url);
      setShopId(shop.id);
      loadMessageLogs(shop.id, pageNum);
      fetchAllTemplates(shop.id);
      fetchAllTags(shop.id);
      getSettings(shop.id);
    }
  }, []);

  useEffect(() => {
    loadMessageLogs(shopId, pageNum);
  }, [pageNum]);

  useEffect(() => {
    setPageNum(0);
    loadMessageLogs(shopId, pageNum);
  }, [name, templateType, orderId, orderBy, descending]);
  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };
  const getOptionsForTagsFilter = () => {
    const options = tags.map((template: any) => ({
      label: template.name,
      value: template.id,
    }));
    return options;
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
      duration={2000}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const loadMessageLogs = (shopid: string, page_num: any) => {
    handleSearchFilters(shopid, page_num);
  };

  const getSettings = (shopId: string) => {
    if (shopId) {
      settings(shopId).then((res: any) => {
        if (res.status === "success") {
          setisWhatsappWebEnabled(res.settings.whatsappWeb);
        }
      });
    }
  };

  const fetchAllTemplates = (shopId: string) => {
    if (shopId) {
      getAllTemplates(shopId).then((res: any) => {
        if (res.status === "success" && res.templates) {
          const templatesForAbCart = res.templates.filter(
            (template: any) => template.category === "CART"
          );
          setAllTemplatesAbCart(templatesForAbCart);
        }
      });
    }
  };
  const fetchAllTags = (shopId: string) => {
    if (shopId) {
      getAllTags(shopId).then((res: any) => {
        if (res.status === "success" && res.tags && res.tags.length !== 0) {
          const tagsForAbCart = res.tags.filter(
            (tag: any) => tag.category === "CART"
          );
          setTags(tagsForAbCart);
        }
      });
    }
  };
  const handleSearchFilters = (shopId: string, page_num: number) => {
    if (shopId) {
      handleFiltersAbandonedCart(
        shopId,
        templateType,
        orderId,
        name,
        orderBy,
        descending,
        pageSize,
        page_num
      ).then((res: any) => {
        if (res.status === "success" && res.checkouts) {
          setOrders(res.checkouts);
          if (res.checkouts.length === 0) {
            setNoDataFound(true);
          } else {
            setNoDataFound(false);
          }
        }
      });
    }
  };
  const handleTemplateTypeChange = useCallback((value, i) => {
    setTemplateType(value);
  }, []);

  const handleNameChange = (value: string) => {
    setName(value);
  };
  const handleFiltersQueryChange = useCallback(
    (value) => setOrderId(value),
    []
  );
  const handleTemplateTypeRemove = useCallback(() => {
    setTemplateType(null);
  }, []);
  const handleStatusRemove = useCallback(() => {
    setStatus(null);
  }, []);
  const handleNameRemove = useCallback(() => {
    setName("");
  }, []);

  const handleOrderIdRemove = useCallback(() => setOrderId(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleTemplateTypeRemove();
    handleStatusRemove();
    handleOrderIdRemove();
  }, [handleTemplateTypeRemove, handleStatusRemove, handleOrderIdRemove]);

  const filters = [
    {
      key: "templateType",
      label: "Tag",
      filter: (
        <ChoiceList
          title="Type"
          titleHidden
          choices={getOptionsForTagsFilter()}
          selected={templateType || []}
          onChange={handleTemplateTypeChange}
        />
      ),
      shortcut: true,
    },
    {
      key: "name",
      label: "Name",
      filter: (
        <TextField
          label="Search by name"
          value={name}
          onChange={(val) => handleNameChange(val)}
          autoComplete="off"
          placeholder="eg. John Doe"
        />
      ),
      shortcut: true,
    },
    //TBD to enable it or not
    // {
    //   key: 'status',
    //   label: 'Status',
    //   filter: (
    //     <ChoiceList
    //       title="Status"
    //       titleHidden
    //       choices={[
    //         { label: 'PHONE_NO_NOT_FOUND_IN_REQUEST', value: 'PHONE_NO_NOT_FOUND_IN_REQUEST' },
    //         { label: 'AUTOMATED_MESSAGE_DISABLED', value: 'AUTOMATED_MESSAGE_DISABLED' },
    //       ]}
    //       selected={status || []}
    //       onChange={handleStatusChange}
    //       allowMultiple
    //     />
    //   ),
    //   shortcut: true,
    // },
  ];

  const appliedFilters: any = [];
  if (!isEmpty(templateType)) {
    const key = "templateType";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, templateType),
      onRemove: handleTemplateTypeRemove,
    });
  }
  if (!isEmpty(status)) {
    const key = "status";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, status),
      onRemove: handleStatusRemove,
    });
  }
  if (!isEmpty(name)) {
    const key = "name";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, name),
      onRemove: handleNameRemove,
    });
  }
  function disambiguateLabel(key: any, value: any) {
    switch (key) {
      case "templateType":
        let label = tags.find((o) => o.id === Number(value));
        return value.map((val: any) => `${label.name}`).join(", ");
      case "status":
        return value.map((val: any) => `${val}`).join(",  ");
      case "name":
        return `Name - ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  useEffect(() => {
    getRows();
  }, [orders]);
  const getMessageStatus = (status: string) => {
    status = capitalize(status.replace(/_/g, " "));
    if (status === "Closed") {
      return (
        <Badge progress="complete" status="success">
          {status}
        </Badge>
      );
    } else {
      return <Badge progress="incomplete">{status}</Badge>;
    }
  };
  const getMessageMarkup = (order: {}, checkoutId: any) => {
    return (
      <>
        {allTemplatesAbCart.length > 0 ? (
          <div className="d-flex-start">
            <div>
              <Button
                key={"enable"}
                primary
                onClick={() => {
                  //@ts-ignore
                  if (!order.phone) {
                    showErrorToast(
                      "Cannot send message because phone number is missing for this order"
                    );
                    return;
                  }
                  setCurrentCheckoutId(checkoutId);
                  setCurrentSelectedOrder({
                    ...order,
                    shop_name: owner.shopName,
                  });
                  setOpenSendMessageModal(true);
                }}
              >
                Send Message
              </Button>
            </div>
          </div>
        ) : (
          <p>Please add message templates from Settings Tab</p>
        )}
      </>
    );
  };

  const getAttemptedDate = (date: number) => {
    if (date === 0) {
      return "-";
    } else {
      const optionsDate: any = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const optionsTime: any = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const convertedDate = new Date(Number(date));
      const formattedDate = convertedDate.toLocaleDateString(
        "en-US",
        optionsDate
      );
      const formattedTime = convertedDate.toLocaleString("en-US", optionsTime);
      return (
        <TextStyle variation="subdued">
          {formattedDate}
          <br />
          {formattedTime}
        </TextStyle>
      );
    }
  };

  const getAmountText = (amount: string, currency: string) => {
    if (currency) {
      return `${amount} ${currency}`;
    } else {
      return amount;
    }
  };
  const deleteTagFromOrder = (
    shopId: string,
    checkoutId: any,
    tagId: any,
    de: any
  ) => {
    if (shopId) {
      let params = new URLSearchParams();
      params.append("tagId", tagId);
      params.append("delete", de);
      addTagAbCartOrder(shopId, checkoutId, params).then((res: any) => {
        if (res.status === "success") {
          showToast("Tag removed");
          loadMessageLogs(shopId, 0);
        }
      });
    }
  };
  const getTags = (tagId: number, checkoutId: string) => {
    const result2 = tags.filter((tag: any) => tag.id === tagId);
    return (
      <>
        <div>
          {result2.length > 0 ? (
            <Tag
              key={checkoutId}
              onRemove={() =>
                deleteTagFromOrder(shopId, checkoutId, tagId, true)
              }
            >
              {result2[0].name}
            </Tag>
          ) : tags.length > 0 ? (
            <div className="d-flex-start">
              <div>
                <Button
                  key={"enable"}
                  primary
                  onClick={() => {
                    setCurrentCheckoutId(checkoutId);
                    setOpenAbandonedCartTagModal(true);
                  }}
                >
                  Add tag
                </Button>
              </div>
            </div>
          ) : (
            <p>Please add tags from Settings Tab</p>
          )}
        </div>
        <br />
      </>
    );
  };

  const getRows = () => {
    var rows = [];
    const localOrders = [...orders];
    if (localOrders && localOrders.length !== 0) {
      for (var i = 0; i < localOrders.length; i++) {
        rows.push([
          localOrders[i].customerName &&
          localOrders[i].customerName !== null ? (
            localOrders[i].status !== "CLOSED" ? (
              <div key={localOrders[i].id}>
                <Link
                  external={true}
                  url={`https://${shopUrl}/admin/checkouts/${localOrders[i].id}`}
                >
                  {localOrders[i].id}
                </Link>
                <br />
                {localOrders[i].customerName}
              </div>
            ) : (
              <div key={localOrders[i].id}>
                {localOrders[i].id}
                <br />
                {localOrders[i].customerName}
              </div>
            )
          ) : localOrders[i].status !== "CLOSED" ? (
            <div key={localOrders[i].id}>
              <Link
                external={true}
                url={`https://${shopUrl}/admin/checkouts/${localOrders[i].id}`}
              >
                {localOrders[i].id}
              </Link>
            </div>
          ) : (
            <div key={localOrders[i].id}>{localOrders[i].id}</div>
          ),
          getAttemptedDate(localOrders[i].createdOn),
          getAmountText(
            localOrders[i].total.toString(),
            localOrders[i].currency
          ),
          getMessageStatus(localOrders[i].status),
          getTags(localOrders[i].tagId, localOrders[i].id),
          getMessageMarkup(localOrders[i], localOrders[i].id),
        ]);
      }
    }
    return rows;
  };
  const footerMarkup =
    orders && orders.length > 0 ? (
      <Stack vertical>
        <p>{"Page " + (pageNum + 1)}</p>
        <p>{"Showing " + orders.length + " results"}</p>
      </Stack>
    ) : null;

  const handleSort = (headingIndex: number, direction: string) => {
    direction === "descending" ? setDescending(true) : setDescending(false);
    if (headingIndex === 0) {
      setOrderBy("checkoutId");
    }
    if (headingIndex === 1) {
      setOrderBy("name");
    }
    if (headingIndex === 2) {
      setOrderBy("total");
    }
  };

  const crmLogsMarkup = (
    <Card>
      <Card.Section>
        <Filters
          queryValue={orderId}
          filters={filters}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleOrderIdRemove}
          onClearAll={handleFiltersClearAll}
          hideQueryField={false}
          queryPlaceholder={"Search by order id"}
        />
      </Card.Section>
      <DataTable
        columnContentTypes={["text", "text", "text", "text", "text", "text"]}
        headings={[
          "Order",
          // "Name",
          "Date",
          "Amount",
          // "Type",
          "Status",
          "Tag",
          "Message",
        ]}
        sortable={[true, false, true, false, false]}
        defaultSortDirection="descending"
        initialSortColumnIndex={0}
        hoverable={false}
        onSort={(index, direction) => handleSort(index, direction)}
        rows={getRows()}
        footerContent={
          <Stack vertical>
            {footerMarkup}
            <Pagination
              hasPrevious={pageNum > 0}
              onPrevious={() => {
                setPageNum((pageNum) => pageNum - 1);
              }}
              hasNext={orders?.length === pageSize}
              onNext={() => {
                setPageNum((pageNum) => pageNum + 1);
              }}
            />
          </Stack>
        }
      />
      <Card.Section>
        {noDataFound ? (
          <h1 className="Polaris-TextStyle text-center">No result found</h1>
        ) : null}
      </Card.Section>
    </Card>
  );

  return (
    <div>
      {toastMarkup}
      {errorToastMarkup}
      <div className="Polaris-Page mt-2">
        <div className="Polaris-Page-Header__TitleWrapper">
          <div className="Polaris-Header-Title__TitleAndSubtitleWrapper">
            <div className="Polaris-Header-Title__TitleWithMetadataWrapper">
              <h1 className="Polaris-Header-Title">
                Abandoned Cart Manual Messaging
              </h1>
            </div>
            <div className="Polaris-Header-Title__SubTitle-light Polaris-Header-Title__SubtitleCompact mt-2">
              <p>
                <b>Note:</b> Send manual messages faster by opening our app on
                your mobile phone browser, or by using WhatsApp Desktop app. Go
                to <b>Settings</b> tab to configure.
                <br />
              </p>
            </div>
          </div>
        </div>
        {/* <div className="Polaris-Page--divider"></div> */}
        <br />
      </div>
      <div className={styles.outerContainerTable}>
        {crmLogsMarkup}
        <br />
        <Layout.Section>
          <>
            <Banner
              title="We strongly recommend that you do not send more than 20-30 messages / hour"
              status="warning"
            >
              <p>
                This is to ensure that WhatsApp doesn't think you are engaging
                in spammy behaviour and ban your phone number.
                <br />
                The best practice is to send few messages at a time (10-20)
                distributed throughout the day.
              </p>
            </Banner>
            <Banner
              title="If you are using a new number, wait a few days before sending manual messages"
              status="warning"
            >
              <p>
                WhatsApp's spam detection algorithms are getting more aggressive
                day by day. If you activate a new number and suddenly start
                sending manual messages to unsaved contacts, they ban the
                number. Therefore, we recommend waiting a few days after
                activating a new number before you start sending messages.
              </p>
            </Banner>
          </>
        </Layout.Section>
        <AddTagModal
          open={openAbandonedCartTagModal}
          showToast={showToast}
          showErrorToast={showErrorToast}
          closeModal={() => setOpenAbandonedCartTagModal(false)}
          currentCheckoutId={currentCheckoutId}
          tags={tags}
          shopId={shopId}
          loadMessageLogs={loadMessageLogs}
        />
        <SendMessageModal
          open={openSendMessageModal}
          showToast={showToast}
          showErrorToast={showErrorToast}
          closeModal={() => setOpenSendMessageModal(false)}
          currentCheckoutId={currentCheckoutId}
          allTemplatesAbCart={allTemplatesAbCart}
          shopId={shopId}
          loadMessageLogs={loadMessageLogs}
          currentSelectedOrder={currentSelectedOrder}
          owner={owner}
          isWhatsappWebEnabled={isWhatsappWebEnabled}
        />
        <div />
      </div>
    </div>
  );
}

export default AbandonedCart;
