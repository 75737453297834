import { useEffect, useState } from "react";
import {
  Button,
  Layout,
  Page,
  TextContainer,
  TextStyle,
  Toast,
  Heading,
  Modal,
  Icon,
} from "@shopify/polaris";
import { EditMinor, DeleteMinor } from "@shopify/polaris-icons";
import MultipleAgentsBanner from "../../../common/MultipleAgentsBanner";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  deleteTemplate,
  editTemplate,
  getTemplates,
} from "../../../redux/actions/templates";
import Template from "../../../types/Template.type";
import Switch from "react-switch";
import { PlayCircleMajor } from "@shopify/polaris-icons";

const ProTemplates = (props: any) => {
  const { shop } = props;

  const [templates, setTemplates] = useState<Template[]>([]);
  const [toast, setToast] = useState({ show: false, message: "" });
  const [errorToast, setErrorToast] = useState({ show: false, message: "" });
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [currentTemplateDeleteId, setCurrentTemplateDeleteId] = useState<
    any | null
  >(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [openTutorial, setopenTutorial] = useState(false);

  let { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (shop && shop.id) {
      fetchTemplates();
    }
  }, [shop]);

  const showToast = (message: string) => {
    setToast({ show: true, message: message });
  };

  const showErrorToast = (message: string) => {
    setErrorToast({ show: true, message: message });
  };

  const fetchTemplates = () => {
    getTemplates().then(
      (response: any) => {
        if (response.status === "success") {
          setTemplates(response.templates);
        } else {
          showErrorToast(response);
        }
      },
      (err) => {
        showErrorToast("Something went wrong");
      }
    );
  };

  const handleDeleteTemplate = () => {
    if (currentTemplateDeleteId) {
      setSaveLoading(true);
      deleteTemplate(currentTemplateDeleteId).then(
        (response: any) => {
          if (response.status === "success") {
            setOpenConfirmation(false);
            setCurrentTemplateDeleteId(null);
            showToast("Template deleted successfully");
            fetchTemplates();
          } else {
            showErrorToast(response);
          }
          setSaveLoading(false);
        },
        (err) => {
          showErrorToast("Unable to delete template");
          setSaveLoading(false);
        }
      );
    }
  };

  const handleClick = () => {
    history.push({ pathname: `${path}/create`, state: { shop: shop } });
  };

  const handleDeleteClick = (templateId: number) => {
    setCurrentTemplateDeleteId(templateId);
    setOpenConfirmation(true);
  };

  const toastMarkup = toast.show ? (
    <Toast
      content={toast.message}
      onDismiss={() => {
        setToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const errorToastMarkup = errorToast.show ? (
    <Toast
      error
      content={errorToast.message}
      onDismiss={() => {
        setErrorToast({ show: false, message: "" });
      }}
    />
  ) : null;

  const enableTemplate = (template: any) => {
    const data = { active: (template.active ? "false" : "true").toString() }
    editTemplate(template.id, data).then(
      (response: any) => {
        if (response.status === "success") {
          showToast(
            `Template has been ${
              response.template.active ? "enabled" : "disabled"
            }`
          );
          fetchTemplates();
        } else {
          showErrorToast(response);
        }
      },
      (err) => {
        showErrorToast("Something went wrong");
      }
    );
  };

  const openEditMode = (template: any) => {
    history.push({
      pathname: `${path}/create`,
      state: { template: template, shop: shop },
    });
  };

  const renderTemplatesMarkup = () => {
    var markup = [];
    for (let i = 0; i < templates.length; i++) {
      if (templates[i]) {
        markup.push(
          <>
            <Layout.Section>
              <div className="template-info-container">
                <div className="Polaris-TextContainer Polaris-TextContainer--spacingTight template-info">
                  <Heading>{templates[i].name}</Heading>
                  <TextStyle variation="subdued">
                    {templates[i].category === "OTHERS"
                      ? "Order Template"
                      : "Quick Reply"}
                  </TextStyle>
                  <br></br>
                  <Switch
                    onChange={() => enableTemplate(templates[i])}
                    checked={templates[i].active}
                    onColor="#5DC001"
                    onHandleColor="#fff"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 6px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    handleDiameter={30}
                  />
                </div>
                <div className="Polaris-Card w-100">
                  <div className="Polaris-Card__Section break-word">
                    <TextContainer>
                      <TextStyle variation="subdued">
                        {templates[i].text.split("\\n").map((item, key) => {
                          return (
                            <span key={key}>
                              {item}
                              <br />
                            </span>
                          );
                        })}
                      </TextStyle>
                    </TextContainer>
                  </div>
                </div>
              </div>
            </Layout.Section>
            <div className="template Polaris-Layout__Section Polaris-Layout__Section--secondary m-auto text-center">
              <div className="flex-col w-100">
                <div className="">
                  <button
                    className={
                      templates[i].active
                        ? "Polaris-Button Polaris-Button--outline Polaris-Button--primary w-50"
                        : "Polaris-Button Polaris-Button--outline Polaris-Button--disabled w-50"
                    }
                    type="button"
                    disabled={!templates[i].active}
                    onClick={() => openEditMode(templates[i])}
                  >
                    <span className="Polaris-Button__Content">
                      <span className="Polaris-Button__Text">
                        <div className="d-flex-center">
                          <div>
                            <Icon source={EditMinor} />
                          </div>
                          <div>&nbsp;Edit</div>
                        </div>
                      </span>
                    </span>
                  </button>
                </div>
                <div className="delete-button-container">
                  <button
                    className="Polaris-Button Polaris-Button--outline outline Polaris-Button--destructive mt-1-half w-50"
                    type="button"
                    onClick={() => {
                      handleDeleteClick(templates[i].id);
                    }}
                  >
                    <span className="Polaris-Button__Content">
                      <span className="Polaris-Button__Text">
                        <div className="d-flex-center">
                          <div>
                            <Icon source={DeleteMinor} />
                          </div>
                          <div>&nbsp;Delete</div>
                        </div>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
    return markup;
  };

  return (
    <Page
      subtitle={"Create your own template :"}
      primaryAction={
        <Button primary onClick={() => handleClick()}>
          Create Templates
        </Button>
      }
      secondaryActions={[
        {
          content: "Tutorial video",
          icon: PlayCircleMajor,
          accessibilityLabel: "Tutorial Video",
          outline: true,
          onAction: () => {
            setopenTutorial(true);
            document.body.classList.add("tutorial-modal");
          },
        },
      ]}
    >
      {toastMarkup}
      {errorToastMarkup}
      <Layout>{templates.length > 0 ? renderTemplatesMarkup() : null}</Layout>
      <div className="Polaris-TextContainer Polaris-TextContainer--spacingLoose fs-3 text-center my-3">
        <span className="Polaris-TextStyle--variationStrong text-primary">
          You can send these manually from your WhatsApp tool
        </span>
      </div>
      <Layout>
        <Layout.Section>
          <MultipleAgentsBanner />
        </Layout.Section>
      </Layout>
      <Modal
        open={openConfirmation}
        onClose={() => {
          setOpenConfirmation(false);
          setCurrentTemplateDeleteId(null);
        }}
        title={`Are you sure you want to delete?`}
        primaryAction={{
          content: "Confirm",
          loading: saveLoading,
          onAction: () => handleDeleteTemplate(),
        }}
      >
        <Modal.Section>
          <TextContainer>
            <p>Please click Confirm to proceed</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
      <Modal
        open={openTutorial}
        sectioned={true}
        title={"Tutorial video"}
        onClose={() => {
          setopenTutorial(false);
          document.body.classList.remove("tutorial-modal");
        }}
      >
        <iframe
          id="ytplayer"
          width="100%"
          height="360"
          allow="fullscreen"
          src={"https://www.youtube.com/embed/8ug_qyXxqAs"}
          frameBorder="0"
          title={"Extension Tutorial Video"}
        ></iframe>
      </Modal>
    </Page>
  );
};

export default ProTemplates;
