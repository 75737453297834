import PieCharts from "./PieChart";
import GraphChart from "./GraphChart";
import StaticMetrics from "./StaticMetrics";
import { useEffect, useState } from "react";
import obj from "../../content/Analytics/OverlaysContent/en.json";
import TooltipOverlay from "../../common/Tooltip";
import GuideModal from "../../common/GuideModal";

function Analytics() {
  const [showAutomatedMessaging, setShowAutomatedMessaging] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [openGuideModal, setOpenGuideModal] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const features = localStorage.getItem("features")?.split(",");
    if (features) {
      const showAutomatedMessaging = features.includes("AUTOMATED_MESSAGING");
      const showAnalytics = features.includes("ANALYTICS");
      showAutomatedMessaging && setShowAutomatedMessaging(true);
      showAnalytics && setShowAnalytics(true);
    }
  }, []);

  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };

  return (
    <>
      <TooltipOverlay btn1Action={() => setOpenGuideModal(true)} obj={obj} />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
      <div className="Polaris-Page mt-2">
        <div className="Polaris-Page-Header__TitleWrapper">
          <div className="Polaris-Header-Title__TitleAndSubtitleWrapper">
            <div className="Polaris-Header-Title__TitleWithMetadataWrapper">
              <h1 className="Polaris-Header-Title">Analytics</h1>
            </div>
          </div>
        </div>
        <br />
        <div className="Polaris-Page--divider"></div>
        {showAnalytics ? (
          <>
            <StaticMetrics />
            {/* <PieCharts showAutomatedMessaging={showAutomatedMessaging} /> 
            <GraphChart showAutomatedMessaging={showAutomatedMessaging} />*/}
          </>
        ) : (
          <h1>Analytics are not enabled for this store</h1>
        )}
      </div>
    </>
  );
}

export default Analytics;
