import { useEffect, useState } from "react";
import { Stack, TextContainer, TextStyle, Modal } from "@shopify/polaris";
import ChatBtnPreview from "./ChatBtnPreview";

const ChangeDesignModal = (props: any) => {
  const [open, setOpen] = useState(props.open);
  const [selectedTemplateId, setSelectedTemplateId] = useState(props.selected_template_id);
  const [chatBtnTemplateDesign, setChatBtnTemplateDesign] = useState(props.chat_btn_template_designs);
  const [chatDetails, setChatDetails] = useState(props.chatDetails);

  useEffect(() => {
    setOpen(props.open);
    setSelectedTemplateId(props.selected_template_id);
    setChatBtnTemplateDesign(props.chat_btn_template_designs);
    setChatDetails(props.chatDetails);
  }, [props]);

  // const getPremiumDesignsText = () => {
  //   if (props.plan.allow_premium_templates) {
  //     return <b>Premium Designs</b>;
  //   } else {
  //     return (
  //       <span>
  //         <b>Premium Designs</b>{" "}
  //         <Link
  //           onClick={() => {
  //             window.location.href = "/pricing?referrer=settings";
  //           }}
  //         >
  //           {" "}
  //           (requires paid plan){" "}
  //         </Link>
  //       </span>
  //     );
  //   }
  // };

  const handleTemplateChange = (changeEvent: any) => {
    // var selected_template_id = parseInt(changeEvent.target.value);

    // if (!props.plan.allow_premium_templates) {
    //   for (var i = 0; i < props.available_templates.length; i++) {
    //     if (
    //       props.available_templates[i].id === selected_template_id &&
    //       props.available_templates[i].premium
    //     ) {
    //       return;
    //     }
    //   }
    // }
    setSelectedTemplateId(parseInt(changeEvent.target.value));
  };
  
  const onSave = () => {
    var selectedTemplate =
      props.available_templates[
      props.available_templates.findIndex(
        (a: any) => a.id === selectedTemplateId
      )
      ];
    var custom = props.customSettings;

    var data: any = {
      ...chatDetails,
      btn_template_id: selectedTemplateId,
      templateId: selectedTemplateId,
      is_chat_btn_color_custom: selectedTemplate.custom,
      is_chat_btn_solid_background: selectedTemplate.solidBg,
      chat_btn_bg_color_1: selectedTemplate.bgColor1 || '#ffffff',
      chat_btn_bg_color_2: selectedTemplate.bgColor2 || '#ffffff',
      chat_btn_text_color: selectedTemplate.textColor || '#ffffff',
      chat_btn_icon_color: selectedTemplate.iconColor || '#ffffff',
    };
    if (selectedTemplate.custom) {
      data.is_chat_btn_solid_background = custom ? custom.is_chat_btn_solid_background : selectedTemplate.solidBg
      data.chat_btn_bg_color_1 = custom ? custom.custom_chat_btn_bg_color_1 : selectedTemplate.bgColor1 || '#ffffff';
      data.chat_btn_bg_color_2 = custom ? custom.custom_chat_btn_bg_color_2 : selectedTemplate.bgColor2 || '#ffffff';
      data.chat_btn_text_color = custom ? custom.custom_chat_btn_text_color : selectedTemplate.textColor || '#ffffff';
      data.chat_btn_icon_color = custom ? custom.custom_chat_btn_icon_color : selectedTemplate.iconColor || '#ffffff';
    }
    props.changeTemplate(selectedTemplateId, data);
    props.onModalClose();
  };

  const getChatButtonImageClassName = (template: any) => {
    switch (template.templateType) {
      case null:
        return "nocssprop";
      case 1:
        return "imgchatscale1";
      case 2:
        return "imgchatscale2";
      case 3:
        return "imgchatscale3";
      case 4:
        return "imgchatscale1";
      case 5:
        return "imgchatscale1";
    }
  };

  const getAvailableTemplates = () => {
    var btn_templates = [];
    var customizable_templates = [];

    var custom = props.customSettings;

    var available_templates = props.available_templates.sort(
      (a: any, b: any) => a.ordering - b.ordering
    );

    var customStyle = {
      display: "flex",
      alignItems: "flex-start",
    };

    for (var i = 0; i < available_templates.length; i++) {
      var template = (
        <div className="templateItem" key={i}>
          <label style={available_templates[i].custom ? customStyle : {}}>
            <input
              type="radio"
              value={available_templates[i].id}
              checked={selectedTemplateId === available_templates[i].id}
              onChange={(val) => handleTemplateChange(val)}
            />
            <span className="tmplinput">
              {!available_templates[i].custom ? (
                <img
                  className={
                    getChatButtonImageClassName(available_templates[i]) +
                    " " +
                    "tmplimg"
                  }
                  src={available_templates[i].previewUrl}
                  alt=""
                />
              ) : (
                renderChatBtnPreview(available_templates[i], custom)
              )}
            </span>
          </label>
        </div>
      );

      if (available_templates[i].custom) {
        customizable_templates.push(template);
      } else {
        btn_templates.push(template);
      }
    }

    return (
      <div>
        <Modal.Section>
          <TextContainer>
            <p>
              <b>Customizable Designs </b>
              <TextStyle variation="subdued">
                (select this to set custom colours)
              </TextStyle>
            </p>
          </TextContainer>
          <br />
          <div className="previewBackground chat-btn-templates custom-chat-btn-templates">
            <Stack>{customizable_templates}</Stack>
          </div>
        </Modal.Section>

        <Modal.Section>
          <TextContainer>
            <b>Pre-configured Button Designs</b>
          </TextContainer>
          <br />
          <div className="previewBackground chat-btn-templates">
            <Stack>{btn_templates}</Stack>
          </div>
        </Modal.Section>
      </div>
    );
  };

  const renderChatBtnPreview = (template: any, custom: any) => {
    let btn_design;

    if (chatBtnTemplateDesign) {
      btn_design = chatBtnTemplateDesign.find((m: any) => m.custom_button_template === template.id);
    }

    if (btn_design) {
      custom = {
        is_chat_btn_solid_background: btn_design.is_chat_btn_solid_background,
        bgColor1: btn_design.bgColor1,
        bgColor2: btn_design.bgColor2,
        buttonTextColor: btn_design.textColor,
        iconColor: btn_design.custom_chat_btn_icon_color,
        btn_cta: chatDetails.btn_cta,
      };
    } else {
      custom = {
        is_chat_btn_solid_background: template.solidBg,
        bgColor1: template.bgColor1,
        bgColor2: template.bgColor2,
        buttonTextColor: template.textColor,
        iconColor: template.iconColor,
        btn_cta: chatDetails.btn_cta,
      };
    }

    return <ChatBtnPreview template={template} custom={custom} />;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        props.onModalClose();
      }}
      title="Chat Button Templates"
      primaryAction={{
        content: "Save",
        onAction: onSave,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setOpen(false);
            props.onModalClose();
          },
        },
      ]}
      limitHeight={false}
    >
      {getAvailableTemplates()}
    </Modal>
  );
};

export default ChangeDesignModal;
