import { MESSAGING } from "../../constants/api-constants";
import { getAutomateMessageLanguage } from "../../utils/common";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: MESSAGING.baseUrl
};

export const fetchOrderConfirmationSettings = () => {
  return httpClient.get(MESSAGING.api.CRM_ORDERS_CONFIRMATION_SETTIGS, config).then((res: any) => {
    let languageSetting = getAutomateMessageLanguage(res)
    const data = {
      confirmationEnabled: res?.setting?.enable,
      discount: res?.setting?.discountEnable,
      discountRatio: res?.setting?.discountRatio,
      couponCode: res?.setting?.couponCode,
      discountType: res?.setting?.discountType,
      language: languageSetting,
    }; 
    return [data, res];
  });
};

export const updateOrderConfirmationSettings = (params: any) => {
  const body = {
    enable: params?.confirmationEnabled,
    discountEnable: params?.discount,
    discountRatio: params?.discountRatio,
    couponCode: params?.couponCode,
    discountType: params?.discountType,
    waAutomateMessageTemplateId: params?.language?.id,
  };

  return httpClient.put(MESSAGING.api.CRM_ORDERS_CONFIRMATION_SETTIGS, body, config).then((res: any) => {
    let languageSetting = getAutomateMessageLanguage(res)

    const data = {
      confirmationEnabled: res?.setting?.enable,
      discount: res?.setting?.discountEnable,
      discountRatio: res?.setting?.discountRatio,
      couponCode: res?.setting?.couponCode,
      discountType: res?.setting?.discountType,
      language: languageSetting,
    }; 
    return [data, res];
  });
};

export const fetchOrderShipmentSettings = () => {
  return httpClient.get(MESSAGING.api.CRM_ORDERS_SHIPMENT_SETTIGS, config).then((res: any) => {
    const data = {
      shipmentEnabled: res?.setting?.enable,
      thankYouPage: res?.setting?.thankYouPageEnable,
    };
    return [data, res];
  });
};

export const updateOrderShipmentSettings = (params: any) => {
  const body = {
    enable: params?.shipmentEnabled,
    thankYouPageEnable: params?.thankYouPage,
  };

  return httpClient.put(MESSAGING.api.CRM_ORDERS_SHIPMENT_SETTIGS, body, config).then((res: any) => {
    const data = {
      shipmentEnabled: res?.setting?.enable,
      thankYouPage: res?.setting?.thankYouPageEnable,
    };
    return [data, res];
  });
};

export const fetchPhoneSettings = () => {
  return httpClient.get(MESSAGING.api.AUTOMATED_PHONE_SETTINGS, config);
};

export const updatePhoneSettings = (params: any) => {
  return httpClient.put(MESSAGING.api.AUTOMATED_PHONE_SETTINGS, params, config);
};

export const fetchOptinWidgetSettings = (shopId: string) => {
  return httpClient.get(MESSAGING.api.OPTIN_WIDGET_SETTINGS.replace("$shopId", shopId), config);
};

export const updateOptinWidgetSettins = (shopId: string, params: any) => {
  return httpClient.put(MESSAGING.api.OPTIN_WIDGET_SETTINGS.replace("$shopId", shopId), params, config);
};

export const fetchMessageLogs = (
  shopId: string,
  category: string,
  pageSize: number,
  pageIndex: number
) => {
  return httpClient.get(MESSAGING.api.MESSAGE_LOGS.replace("$shopId", shopId) + "?category=" + category + "&pageSize=" + pageSize + "&pageIndex=" + pageIndex, config);
};
