import { Link, Modal, Stack, TextContainer } from "@shopify/polaris";
import { useEffect, useState } from "react";

const GuideModal = ({ open, closeModal, obj, tabIndex }: any) => {
  const [guideStep, setGuideStep] = useState(0);
  const [shop, setShop] = useState({ id: "", url: "" });
  const totalSteps = obj.modal.tabContent[tabIndex].content.length - 1;
  const currentTabTitle = obj.modal.tabContent[tabIndex].title;

  useEffect(() => {
    let shop = JSON.parse(localStorage.getItem("shop") || "{}");
    if (shop && shop.id) {
      setShop(shop);
    }
  }, []);

  const modalContentGuideStep = () => {
    const currentStep = obj.modal.tabContent[tabIndex].content[guideStep];
    return (
      <TextContainer>
        {currentStep.text1 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text1} `,
            }}
          ></div>
        ) : null}
        {currentStep.image_1 ? (
          <img
            className="checkoutOptinModalImage"
            src={currentStep.image_1.link}
            alt={
              currentStep.image_1.alt
                ? `${currentStep.image_1.alt}`
                : "Guide Image"
            }
          />
        ) : null}
        {currentStep.text2 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text2} `,
            }}
          ></div>
        ) : null}
        {currentStep.image_2 ? (
          <img
            className="checkoutOptinModalImage"
            src={currentStep.image_2.link}
            alt={
              currentStep.image_2.alt
                ? `${currentStep.image_2.alt}`
                : "Guide Image"
            }
          />
        ) : null}
        {currentStep.text3 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text3} `,
            }}
          ></div>
        ) : null}
        {currentStep.image_3 ? (
          <img
            className="checkoutOptinModalImage"
            src={currentStep.image_3.link}
            alt={
              currentStep.image_3.alt
                ? `${currentStep.image_3.alt}`
                : "Guide Image"
            }
          />
        ) : null}
        {currentStep.text4 ? (
          <div
            dangerouslySetInnerHTML={{
              __html: `${currentStep.text4} `,
            }}
          ></div>
        ) : null}
      </TextContainer>
    );
  };

  const incrementGuideStep = () => {
    setGuideStep((guideStep) => guideStep + 1);
  };

  const decrementGuideStep = () => {
    setGuideStep((guideStep) => guideStep - 1);
  };

  const stepsCompletedActions = () => {
    closeModal();
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={
        `${currentTabTitle} ` + "(" + (guideStep + 1) + ` of ${totalSteps + 1})`
      }
      primaryAction={{
        content: guideStep === totalSteps ? "Guide Completed" : "Next",
        onAction:
          guideStep === totalSteps ? stepsCompletedActions : incrementGuideStep,
      }}
      secondaryActions={
        guideStep >= 1
          ? [
              {
                content: "Back",
                onAction: decrementGuideStep,
              },
            ]
          : undefined
      }
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>{modalContentGuideStep()}</Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};
export default GuideModal;
