import { MESSAGING_LOGS_V2 } from "../../constants/api-constants";
import httpClient from "../../utils/http-client";

const config = {
  baseURL: MESSAGING_LOGS_V2.baseUrl
};

export const handleFilters = (shopId:string, templateType: any,orderId:any,name: string, orderBy:string, desc:boolean, pageSize:number, pageIndex:number ) => {
  let query = `&pageSize=${pageSize}&pageIndex=${pageIndex}`;
  if(orderBy){
    query = `&orderBy=${orderBy}&desc=${desc}` + query;
  }
  if(templateType && templateType.length !== 0){
    query = `&templateType=${templateType.join(',')}` + query;
  }
  if(name){
    query = `&name=${name}` + query;
  }  
  if(orderId){
    query = `&orderId=${orderId}` + query;
  }  
  return httpClient.get(MESSAGING_LOGS_V2.api.MESSAGE_LOGS.replace("$shopId", shopId) + `${query}`, config)
};