import { useState } from "react";
import PageMarkup from "../../common/PageMarkup";
import AutomatedTemplates from "./Automated/AutomatedTemplates";
import ExtensionTemplates from "./ExtensionTemplates/Templates";
import AutomatedPromotionalTemplates from "./AutomatedPromotional";
import obj from "../../content/Templates/OverlaysContent/en.json";
import TooltipOverlay from "../../common/Tooltip";
import GuideModal from "../../common/GuideModal";

const CommonTemplates = ({ selectedTabForTemplate, showPricingBanner }: any) => {
  const [openGuideModal, setOpenGuideModal] =
    useState(false);
  const closeGuideModal = () => {
    setOpenGuideModal(false);
    setTabIndex(0);
  };
  const [tabIndex, setTabIndex] = useState(0);
  const tabs = [
    {
      id: "Automated",
      content: "Automated",
      accessibilityLabel: "Automated Templates",
      panelID: "automated",
    },
    // {
    //   id: "Automated Promotional",
    //   content: "Automated Promotional",
    //   accessibilityLabel: "Automated Promotional Templates",
    //   panelID: "automatedPromotional",
    // },
    {
      id: "Extension Templates",
      content: "Extension Templates",
      accessibilityLabel: "Extension Templates",
      panelID: "extensionTemplates",
    },
  ];

  const tabContent = {
    0: <AutomatedTemplates />,
    // 1: <AutomatedPromotionalTemplates />,
    1: <ExtensionTemplates />,
  };

  return (
    <>
      <PageMarkup
        title="Templates"
        tabs={tabs}
        tabContent={tabContent}
        selected={selectedTabForTemplate}
      ></PageMarkup>
      <TooltipOverlay
        btn1Action={() => setOpenGuideModal(true)}
        btn2Action={() => {
          setTabIndex(1);
          setOpenGuideModal(true);
        }}
        obj={obj}
        showPricingBanner={showPricingBanner}
      />
      {openGuideModal ? (
        <GuideModal
          open={true}
          closeModal={closeGuideModal}
          obj={obj}
          tabIndex={tabIndex}
        />
      ) : null}
    </>
  );
};
export default CommonTemplates;
