import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Icon, Navigation } from "@shopify/polaris";
import {
  AppsMajor,
  LogOutMinor,
  LanguageMinor,
  InviteMinor,
  ProductsMajor,
  SendMajor,
  AnalyticsMajor,
  FollowUpEmailMajor,
} from "@shopify/polaris-icons";
import useToken from "../hooks/useToken";
import NavigationSectionWrapper from "./NavigationSectionWrapper";
import { APP_NAME } from "../constants/constants";
import NavigationMenuItemNew from "./NavigationMenuItemNew";

const SideMenu = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const [features, setFeatures] = useState<string[]>([]);
  const [shop, setShop] = useState({
    id: "",
    url: "",
  });

  const { removeToken } = useToken();

  useEffect(() => {
    let f = localStorage.getItem("features");
    if (f) {
      setFeatures(f.split(","));
    }

    setShop(JSON.parse(localStorage.getItem("shop") || ""));
  }, [props.planId]);

  const logout = () => {
    props.setToken("");
    removeToken();
    history.push("/login");
  };
  const reviewIcon = () => {
    return (
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.1234 1C12.0592 1 10.7562 2.0046 10 2.913C9.2438 2.0046 7.941 1 5.876 1C2.361 1 0 4.2562 0 7.2972C0 11.7242 8.5036 17.6574 9.4732 18.3192C9.632 18.4274 9.816 18.4816 10 18.4816C10.184 18.4816 10.368 18.4274 10.5268 18.3192C11.4964 17.6574 20 11.7242 20 7.2972C20 4.2562 17.6386 1 14.1234 1Z"
          fill="#ED4956"
        />
      </svg>
    );
  };

  const displayAnalytics = () => {
    if (features.findIndex((el) => el === "ANALYTICS") !== -1) {
      const subNavItems = [
        {
          label: "WhatsApp Analytics",
          url: "/analytics",
          selected: location.pathname.includes("/analytics"),
        },
      ];
      const routes = ["/analytics"];
      const path = location.pathname;
      return (
        <NavigationMenuItemNew
          parentActive={routes.some((el) => path.includes(el))}
          parentTitle="Analytics"
          parentIcon={AnalyticsMajor}
          child={subNavItems}
        />
      );
    }
  };

  const displayMenuWhatsapp = () => {
    let subNavItems = [
      {
        url: "/templates",
        disabled: false,
        selected: location.pathname.includes("/templates"),
        label: "Templates",
      },
    ];
    // if (props.shopDetails?.manualMessaging) {
    //   subNavItems.push({
    //     url: "/manual-messaging",
    //     disabled: false,
    //     selected: location.pathname.includes("/manual-messaging"),
    //     label: "Manual Messages",
    //   });
    // }
    for (let key in features) {
      switch (features[key]) {
        case "PRIVATE":
          /*subNavItems.push({
            url: "/private-waba",
            disabled: false,
            selected: location.pathname.includes("/private-waba"),
            label: "Apply for WhatsApp API",
          });*/
          break;
        case "AUTOMATED_MESSAGING":
          subNavItems.push(
            /*{
              url: "/message-logs",
              disabled: false,
              selected: location.pathname.includes("/message-logs"),
              label: "Message Logs",
            },*/

            {
              url: "/optins",
              disabled: false,
              selected: location.pathname.includes("/optins"),
              label: "Opt-in Numbers",
            },
            {
              url: "/admin-config",
              disabled: false,
              selected: location.pathname.includes("/admin-config"),
              label: "Configuration",
            }
          );
          break;
      }
    }
    subNavItems.push({
      url: "/support",
      disabled: false,
      selected: location.pathname.includes("/support"),
      label: "Whatsapp Extension",
    });
    if (subNavItems.length === 5) {
      [subNavItems[3], subNavItems[4]] = [subNavItems[4], subNavItems[3]];
      var element = subNavItems[0];
      subNavItems.splice(0, 1);
      subNavItems.splice(2, 0, element);
    }
    if (subNavItems.length === 4) {
      var element = subNavItems[0];
      subNavItems.splice(0, 1);
      subNavItems.splice(1, 0, element);
    }
    const path = location.pathname;
    const routes = [
      "/templates",
      "/private-waba",
      "/message-logs",
      "/support",
      "/admin-config",
      "optins",
      "/manual-messaging",
    ];

    return (
      <NavigationMenuItemNew
        parentActive={routes.some((el) => path.includes(el))}
        parentTitle="Whatsapp"
        parentIcon={SendMajor}
        child={subNavItems}
      />
    );
  };

  const displayMenuEmail = () => {
    if (features.findIndex((el) => el === "EMAIL") !== -1) {
      const subNavItems = [
        {
          label: "Templates",
          url: "/email-templates",
          selected: location.pathname.includes("/email-templates"),
        },
        // {
        //   label: "Configuration",
        //   url: "/email-config",
        //   selected: location.pathname.includes("/email-config"),
        // },
      ];
      const routes = ["/email-templates", "/email-config"];
      const path = location.pathname;
      return (
        <NavigationMenuItemNew
          parentActive={routes.some((el) => path.includes(el))}
          parentTitle="Email"
          parentIcon={InviteMinor}
          child={subNavItems}
        />
      );
    }
  };

  return (
    <Navigation location="/">
      {/* <Navigation.Section
        key="review"
        items={[
          {
            label: "Write a Review",
            icon: reviewIcon,
            onClick: () => {
              window.open(
                "https://apps.shopify.com/" +
                  APP_NAME +
                  "?#modal-show=ReviewListingModal"
              );
            },
          },
        ]}
      /> */}
      <NavigationSectionWrapper
        icon={LanguageMinor}
        label={<div id="google_translate_element"></div>}
      />

      {displayAnalytics()}

      <Navigation.Section
        items={[
          {
            url: "/widget",
            label: "Personal Widget",
            icon: AppsMajor,
            badge: "Free",
            selected: location.pathname.includes("/widget") ? true : false,
          },
        ]}
      />

      {displayMenuWhatsapp()}
      {displayMenuEmail()}

      {/* <Navigation.Section
        key="plans"
        items={[
          {
            url: "/pricing",
            label: "Plans",
            icon: ProductsMajor,
            selected: location.pathname.includes("/pricing"),
          },
        ]}
      /> */}

      <Navigation.Section
        key="email-support"
        items={[
          {
            label: "Email Support",
            icon: FollowUpEmailMajor,
            onClick: () => {
              window.open(
                "mailto:raj@gupshup.io?subject=I%20need%20help%20with%20WhatsApp%20chat%20—%20" +
                  shop.url,
                "_blank"
              );
            },
          },
        ]}
      />
      <Navigation.Section
        key="logout"
        items={[
          {
            label: "Logout",
            icon: LogOutMinor,
            onClick: logout,
          },
        ]}
      />
    </Navigation>
  );
};

export default SideMenu;
