import { useEffect, useState } from "react";
import PageMarkup from "../../common/PageMarkup";
import { getOwnerDetails, getShopDetails } from "../../redux/actions/shop";
import AbandonedCart from "./AbandonedCart/AbandonedCart";
import OrderCrm from "./OrderCrm/OrderCrm";
import Settings from "./Settings/Settings";

function ManualMessages({ owner }: any) {
  const [ownerDetails, setownerDetails] = useState(owner);
  const [isManualMessagingEnabled, setIsManualMessagingEnabled] =
    useState(false);
  useEffect(() => {
    const fetchOwnerDetails = (shopId: string) => {
      getOwnerDetails(shopId).then(
        (response: any) => {
          if (response.status === "success") {
            setownerDetails(response.owner);
          }
        },
        (err) => {}
      );
    };

    const fetchShopDetails = () => {
      getShopDetails().then(async (response: any) => {
        if (response.status === "success") {
          setIsManualMessagingEnabled(response.shop.manualMessaging);
        } else {
          setIsManualMessagingEnabled(false);
        }
      });
    };

    if (!owner) {
      let shop = JSON.parse(localStorage.getItem("shop") || "{}");
      if (shop && shop.id) {
        fetchOwnerDetails(shop.id);
      }
    }
    fetchShopDetails();
  }, []);
  const tabs = [
    {
      id: "Abandoned Cart",
      content: "Abandoned Cart",
      accessibilityLabel: "Abandoned Cart",
      panelID: "abandonedCart",
    },
    {
      id: "Order CRM",
      content: "Order CRM",
      accessibilityLabel: "Order CRM",
      panelID: "OrderCrm",
    },
    {
      id: "settings",
      content: "Settings",
      accessibilityLabel: "Settings",
      panelID: "settings",
    },
  ];

  const tabContent = {
    0: <AbandonedCart owner={ownerDetails} />,
    1: <OrderCrm owner={ownerDetails} />,
    2: <Settings />,
  };

  return (
    <>
      {isManualMessagingEnabled ? (
        <PageMarkup
          title="Manual Messaging"
          tabs={tabs}
          tabContent={tabContent}
        ></PageMarkup>
      ) : (
        <PageMarkup
          title="Manual messaging is disabled for this shop, please contact support at
      (raj@gupshup.io) to enable manual messaging"
        ></PageMarkup>
      )}
    </>
  );
}

export default ManualMessages;
