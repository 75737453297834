import { useEffect, useState } from "react";

import {
  TextField,
  FormLayout,
  Card,
  Stack,
  TextContainer,
  TextStyle,
  RadioButton,
  Popover,
  ColorPicker,
} from "@shopify/polaris";

import {
  hexToHsv,
  hsvToHex,
  isValidHex,
  objectDifference,
} from "../utils/helper";

const CustomColorPicker = (props: any) => {
  const [picker1, setpicker1] = useState(false);
  const [picker2, setpicker2] = useState(false);
  const [picker3, setpicker3] = useState(false);
  const [picker4, setpicker4] = useState(false);
  const [picker5, setpicker5] = useState(false);

  const [colorPickerData, setColorPickerData] = useState({
    isBgStyleColorSolid: props.isBgStyleColorSolid,
    bgColor1: props.bgColor1,
    bgColor2: props.bgColor2,
    buttonTextColor: props.buttonTextColor,
    descTextColor: props.descTextColor,
    iconColor: props.iconColor,
  });
  const [refColorPickerData, setRefcolorPickerData] = useState({
    isBgStyleColorSolid: props.isBgStyleColorSolid,
    bgColor1: props.bgColor1,
    bgColor2: props.bgColor2,
    buttonTextColor: props.buttonTextColor,
    descTextColor: props.descTextColor,
    iconColor: props.iconColor,
  });
  const [inputColorData, setInputColorData] = useState({
    bgColor1HSV: props.bgColor1,
    bgColor2HSV: props.bgColor2,
    buttonTextColorHSV: props.buttonTextColor,
    descTextColorHSV: props.descTextColor,
    iconColorHSV: props.iconColor,
  });
  const [inputColorError, setInputColorError] = useState({
    bgColor1HSV: false,
    bgColor2HSV: false,
    buttonTextColorHSV: false,
    descTextColorHSV: false,
    iconColorHSV: false,
  });
  useEffect(() => {
    if (!(props.selectedTemplate && props.selectedTemplate.template)) {
      setColorPickerData({
        isBgStyleColorSolid: props.isBgStyleColorSolid,
        bgColor1: props.bgColor1,
        bgColor2: props.bgColor2,
        buttonTextColor: props.buttonTextColor,
        descTextColor: props.descTextColor,
        iconColor: props.iconColor,
      });
      setRefcolorPickerData({
        isBgStyleColorSolid: props.isBgStyleColorSolid,
        bgColor1: props.bgColor1,
        bgColor2: props.bgColor2,
        buttonTextColor: props.buttonTextColor,
        descTextColor: props.descTextColor,
        iconColor: props.iconColor,
      });
      setInputColorData({
        bgColor1HSV: props.bgColor1,
        bgColor2HSV: props.bgColor2,
        buttonTextColorHSV: props.buttonTextColor,
        descTextColorHSV: props.descTextColor,
        iconColorHSV: props.iconColor,
      });
    }
  }, [props]);

  useEffect(() => {
    if (props.selectedTemplate) {
      setColorPickerData({
        isBgStyleColorSolid: props.selectedTemplate.isBgStyleColorSolid,
        bgColor1: props.selectedTemplate.bgColor1,
        bgColor2: props.selectedTemplate.bgColor2,
        buttonTextColor: props.selectedTemplate.buttonTextColor,
        descTextColor: props.selectedTemplate.descTextColor,
        iconColor: props.selectedTemplate.iconColor,
      });
      setRefcolorPickerData({
        isBgStyleColorSolid: props.selectedTemplate.isBgStyleColorSolid,
        bgColor1: props.selectedTemplate.bgColor1,
        bgColor2: props.selectedTemplate.bgColor2,
        buttonTextColor: props.selectedTemplate.buttonTextColor,
        descTextColor: props.selectedTemplate.descTextColor,
        iconColor: props.selectedTemplate.iconColor,
      });
      setInputColorData({
        bgColor1HSV: props.selectedTemplate.bgColor1,
        bgColor2HSV: props.selectedTemplate.bgColor2,
        buttonTextColorHSV: props.selectedTemplate.buttonTextColor,
        descTextColorHSV: props.selectedTemplate.descTextColor,
        iconColorHSV: props.selectedTemplate.iconColor,
      });
    }
  }, [props.selectedTemplate]);

  useEffect(() => {
    if (Object.keys(objectDifference(refColorPickerData, colorPickerData)).length > 0) {
      props.onCustomChange(objectDifference(refColorPickerData, colorPickerData));
    }
  }, [colorPickerData]);

  const handleBgColorType = (val: any) => {
    if (val === "single") {
      setColorPickerData((data) => ({
        ...data,
        isBgStyleColorSolid: true,
      }));
    } else {
      setColorPickerData((data) => ({
        ...data,
        isBgStyleColorSolid: false,
      }));
    }
  };

  const handleColorChange = (inputfield: string, field: string, color: any) => {
    setColorPickerData((data) => ({ ...data, [field]: color }));
    setInputColorData((data) => ({ ...data, [inputfield]: null }));
  };

  const handleColorInputChange = (
    inputField: string,
    field: string,
    color: any
  ) => {
    if (isValidHex(color)) {
      setColorPickerData((data) => ({ ...data, [field]: color }));
      setInputColorData((data) => ({ ...data, [inputField]: color }));
      setInputColorError((data) => ({ ...data, [inputField]: false }));
    } else {
      setInputColorError((data) => ({ ...data, [inputField]: true }));
      setInputColorData((data) => ({ ...data, [inputField]: color }));
    }
  };

  const customColoursText = props.isCustomColors ? (
    <TextStyle variation="subdued">
      You are currently setting custom colours
    </TextStyle>
  ) : (
    <TextStyle variation="subdued">
      Want to set custom colours? Click on "Change Design" button and select one of the "Customizable Designs"
    </TextStyle>
  );

  return (
    <Card sectioned>
      <FormLayout>
        <FormLayout.Group>
          <TextContainer>{customColoursText}</TextContainer>
        </FormLayout.Group>

        {props.isCustomColors ? (
          <FormLayout.Group>
            <div>
              <TextContainer>
                <p>Background colour style</p>
              </TextContainer>
              <Stack>
                <RadioButton
                  label="Single colour"
                  helpText=""
                  checked={colorPickerData.isBgStyleColorSolid === true}
                  id={`${props.type}-solid`}
                  name={`${props.type}-isBgStyleColorSolid`}
                  onChange={() => handleBgColorType("single")}
                />
                <RadioButton
                  label="Gradient of two colours"
                  helpText=""
                  id={`${props.type}-gradient`}
                  name={`${props.type}-isBgStyleColorSolid`}
                  checked={colorPickerData.isBgStyleColorSolid === false}
                  onChange={() => handleBgColorType("multiple")}
                />
              </Stack>
            </div>
          </FormLayout.Group>
        ) : null}

        {props.isCustomColors ? (
          <FormLayout.Group>
            <Popover
              active={picker1}
              sectioned={true}
              activator={
                <TextField
                  label="Background colour 1"
                  value={
                    inputColorData.bgColor1HSV != null
                      ? inputColorData.bgColor1HSV
                      : colorPickerData.bgColor1
                  }
                  error={
                    inputColorError.bgColor1HSV
                      ? "Please enter a valid hex code"
                      : ""
                  }
                  onChange={(color: any) => {
                    handleColorInputChange("bgColor1HSV", "bgColor1", color);
                  }}
                  prefix={
                    <button
                      className="btn-in-input"
                      style={{ backgroundColor: colorPickerData.bgColor1 }}
                      onClick={() => setpicker1(true)}
                    ></button>
                  }
                />
              }
              onClose={() => {
                setpicker1(false);
              }}
            >
              <Popover.Pane fixed>
                <Popover.Section>
                  <ColorPicker
                    color={hexToHsv(colorPickerData.bgColor1)}
                    onChange={(color: any) => {
                      handleColorChange(
                        "bgColor1HSV",
                        "bgColor1",
                        hsvToHex(color.hue, color.saturation, color.brightness)
                      );
                    }}
                  />
                </Popover.Section>
              </Popover.Pane>
            </Popover>

            <div>
              {colorPickerData.isBgStyleColorSolid === false ? (
                <Popover
                  active={picker2}
                  sectioned={true}
                  activator={
                    <TextField
                      label="Background colour 2 (for gradient)"
                      value={
                        inputColorData.bgColor2HSV != null
                          ? inputColorData.bgColor2HSV
                          : colorPickerData.bgColor2
                      }
                      error={
                        inputColorError.bgColor2HSV
                          ? "Please enter a valid hex code"
                          : ""
                      }
                      onChange={(color: any) => {
                        handleColorInputChange(
                          "bgColor2HSV",
                          "bgColor2",
                          color
                        );
                      }}
                      prefix={
                        <button
                          className="btn-in-input"
                          style={{ backgroundColor: colorPickerData.bgColor2 }}
                          onClick={() => setpicker2(true)}
                        ></button>
                      }
                    />
                  }
                  onClose={() => {
                    setpicker2(false);
                  }}
                >
                  <Popover.Pane fixed>
                    <Popover.Section>
                      <ColorPicker
                        color={hexToHsv(colorPickerData.bgColor2)}
                        onChange={(color: any) => {
                          handleColorChange(
                            "bgColor2HSV",
                            "bgColor2",
                            hsvToHex(
                              color.hue,
                              color.saturation,
                              color.brightness
                            )
                          );
                        }}
                      />
                    </Popover.Section>
                  </Popover.Pane>
                </Popover>
              ) : (
                <span></span>
              )}
            </div>
          </FormLayout.Group>
        ) : null}

        {props.isCustomColors && props.allowIconColor ? (
          <FormLayout.Group>
            <Popover
              active={picker5}
              sectioned={true}
              activator={
                <TextField
                  label="Icon colour"
                  value={
                    inputColorData.iconColorHSV != null
                      ? inputColorData.iconColorHSV
                      : colorPickerData.iconColor
                  }
                  error={
                    inputColorError.iconColorHSV
                      ? "Please enter a valid hex code"
                      : ""
                  }
                  onChange={(color: any) => {
                    handleColorInputChange("iconColorHSV", "iconColor", color);
                  }}
                  prefix={
                    <button
                      className="btn-in-input"
                      style={{ backgroundColor: colorPickerData.iconColor }}
                      onClick={() => setpicker5(true)}
                    ></button>
                  }
                />
              }
              onClose={() => {
                setpicker5(false);
              }}
            >
              <Popover.Pane fixed>
                <Popover.Section>
                  <ColorPicker
                    color={hexToHsv(colorPickerData.iconColor)}
                    onChange={(color: any) => {
                      handleColorChange(
                        "iconColorHSV",
                        "iconColor",
                        hsvToHex(color.hue, color.saturation, color.brightness)
                      );
                    }}
                  />
                </Popover.Section>
              </Popover.Pane>
            </Popover>

            <div></div>
          </FormLayout.Group>
        ) : null}

        {props.isCustomColors ? (
          <FormLayout.Group>
            <Popover
              active={picker3}
              sectioned={true}
              activator={
                <TextField
                  label={props.textColorLabel1 || "Button text colour"}
                  value={
                    inputColorData.buttonTextColorHSV != null
                      ? inputColorData.buttonTextColorHSV
                      : colorPickerData.buttonTextColor
                  }
                  error={
                    inputColorError.buttonTextColorHSV
                      ? "Please enter a valid hex code"
                      : ""
                  }
                  onChange={(color: any) => {
                    handleColorInputChange(
                      "buttonTextColorHSV",
                      "buttonTextColor",
                      color
                    );
                  }}
                  prefix={
                    <button
                      className="btn-in-input"
                      style={{
                        backgroundColor: colorPickerData.buttonTextColor,
                      }}
                      onClick={() => setpicker3(true)}
                    ></button>
                  }
                />
              }
              onClose={() => {
                setpicker3(false);
              }}
            >
              <Popover.Pane fixed>
                <Popover.Section>
                  <ColorPicker
                    color={hexToHsv(colorPickerData.buttonTextColor)}
                    onChange={(color: any) => {
                      handleColorChange(
                        "buttonTextColorHSV",
                        "buttonTextColor",
                        hsvToHex(color.hue, color.saturation, color.brightness)
                      );
                    }}
                  />
                </Popover.Section>
              </Popover.Pane>
            </Popover>

            <div>
              {props.allowDescColor === true ? (
                <Popover
                  active={picker4}
                  sectioned={true}
                  activator={
                    <TextField
                      label="Description text colour"
                      value={
                        inputColorData.descTextColorHSV != null
                          ? inputColorData.descTextColorHSV
                          : colorPickerData.descTextColor
                      }
                      error={
                        inputColorError.descTextColorHSV
                          ? "Please enter a valid hex code"
                          : ""
                      }
                      onChange={(color: any) => {
                        handleColorInputChange(
                          "descTextColorHSV",
                          "descTextColor",
                          color
                        );
                      }}
                      prefix={
                        <button
                          className="btn-in-input"
                          style={{
                            backgroundColor: colorPickerData.descTextColor,
                          }}
                          onClick={() => setpicker4(true)}
                        ></button>
                      }
                    />
                  }
                  onClose={() => {
                    setpicker4(false);
                  }}
                >
                  <Popover.Pane fixed>
                    <Popover.Section>
                      <ColorPicker
                        color={hexToHsv(colorPickerData.descTextColor)}
                        onChange={(color: any) => {
                          handleColorChange(
                            "descTextColorHSV",
                            "descTextColor",
                            hsvToHex(
                              color.hue,
                              color.saturation,
                              color.brightness
                            )
                          );
                        }}
                      />
                    </Popover.Section>
                  </Popover.Pane>
                </Popover>
              ) : (
                <span></span>
              )}
            </div>
          </FormLayout.Group>
        ) : null}
      </FormLayout>
    </Card>
  );
};

export default CustomColorPicker;
